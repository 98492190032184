import { Dialog, DialogActions, DialogContent, DialogTitle,  FormControl,  Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { TopNavActions } from '../../../Subcomponents/AuditDashboardsContainer/activitiesdashboard'
import { EditButton } from '../MainSettings'

const SectionAddFromSectionDialog = ({open, setOpen,getInfoBack,company_id,getCurrentSectionData,setErrorOpen,setSuccessOpen}) => {
    let params = useParams()
    const [stage, setStage] = useState(0)
    const [region, setRegion] = useState(null)
    const [regionData, setRegionData] = useState(null)
    const [parent,  setParent] = useState(null)
    const [parentSelected,  setParentSelected] = useState(null)

    async function getCurrentSectionData () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/section/update/${params.id}/`, config);
            setParent(res.data)
        } catch (err) {
        }
    }
    async function getRegionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/main/`, config);
            setRegionData(res.data)
        } catch (err) {
        }
      }
      useEffect(() =>{
        if(open){
            getRegionInfo()
        }
    },[params?.id,open])
      useEffect(() =>{
        if(open){
            getCurrentSectionData()
        }
    },[params?.id,open])
    const handleSelectRegion = (e) => {
        setParentSelected(e.target.value)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
    }

    const [name, setName] = useState(null)

    async function handleSubmit (e) {
        e.preventDefault()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        const body = JSON.stringify({  name, region, company:company_id, parent:parent?.id  });
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/section/main/`, body, config);
            getInfoBack()
            handleCloseDialog()
            setStage(0)
            setSuccessOpen(true)
            setName(null)
        } catch (err) {
            window.scrollTo( 0, 0)
            setErrorOpen(true)
        }
      }
  return (
    <Dialog open={open} onClose={handleCloseDialog}
    sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        
        <DialogTitle>
            <Grid container spacing={2} justifyContent={"center"} alignItems="center">
                <Grid container item xs={12}>
                  <Grid item container xs={4} style={{flexDirection:"row"}}>
                  <TopNavActions id="close" style={{ marginLeft:"-5px"}} onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} onClick={handleCloseDialog} background={"#ee5c54"}>
                            <p style={{fontSize:"8px", display:iconCloseDisplay}}>X</p>
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  </Grid>
                  <Grid item xs={4} style={{fontSize:"15px", justifyContent:"center", alignItems:"center", display:"flex"}}>Yeni Departman Ekle</Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
            </Grid>
        </DialogTitle>
        {stage === 0 ? 
        <DialogContent style={{display:"flex", flexDirection:"column", gap:"15px", paddingTop:"10px"}}>
           <FormControl fullWidth
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}
           >
            <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Üst Bölüm Seçin</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={parentSelected}
                label="Üst Bölüm Seçin"
                onChange={handleSelectRegion}
                style={{color:"#fff"}}
            >
                
                <MenuItem value={parent?.id}>{parent?.name}</MenuItem>
            </Select>
            </FormControl>
        </DialogContent>
        : 
        <DialogContent style={{display:"flex", flexDirection:"column", gap:"15px", paddingTop:"10px"}}>
        <b>{regionData?.filter(e => e.id === region).map((e) => e.name)}</b>
        <TextField value={name} onChange={(e) => setName(e.target.value)} label="Bölüm İsmi" fullWidth 
        InputLabelProps={{
            style:{
                color:"#A3A2A1"
        }
        }}
        sx={{
            input: {
                color: "white",
            },
            width:"100%",
            '& label.Mui-focused': { color: 'white',},
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: 'white',
                },
                '&:hover fieldset': {
                borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                borderColor: 'white',
                },}
            }}
        /> 
        
    </DialogContent>
        }
        {stage === 0 ? 
        <DialogActions>
            <EditButton style={{marginRight:"10px"}} onClick={handleCloseDialog}>
              İptal
            </EditButton>
            {parentSelected ? 
            <EditButton active style={{marginRight:"10px"}} onClick={() => setStage(stage+1)}>
              İleri
            </EditButton>
            :
            <EditButton style={{marginRight:"10px"}} >
                İleri
            </EditButton>
            }
        </DialogActions>
        : 
        <DialogActions>
            <EditButton style={{marginRight:"10px"}} onClick={() => setStage(stage-1)}>
            Geri
            </EditButton>
            <EditButton active style={{marginRight:"10px"}} onClick={handleSubmit}>
            Kaydet
            </EditButton>
        </DialogActions>
        }
    </Dialog>
  )
}

export default SectionAddFromSectionDialog