import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ApplicationContainer } from '../../../Assets/GeneralDesignElements'
import { InnerNavigation, InnerNavigationItems } from '../../AuditApp/Activities'

const ECCManagement = () => {
    let navigate = useNavigate()
    const {pathname} = useLocation()

    return (
    <ApplicationContainer style={{width:"100%"}}>
        <InnerNavigation style={{borderBottom:"1px solid grey", paddingBottom:"10px", marginBottom:"10px"}}>
            <InnerNavigationItems active={pathname === "/app/external-customer-complaints-app/activities"} onClick={() => navigate("/app/external-customer-complaints-app/activities")}>Şikayet İşlemleri</InnerNavigationItems>
            <InnerNavigationItems active={pathname.includes("process")} onClick={() => navigate("/app/external-customer-complaints-app/activities/main/process")}>Süreç Yönetimi (Gelişim Rapor)</InnerNavigationItems>
        </InnerNavigation>
        <Outlet/>
    </ApplicationContainer>
  )
}

export default ECCManagement