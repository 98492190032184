import { Divider, Switch } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { SettingsPageActionText, SettingsPageHeading, SettingsPagesContainer, SettingsPageWrapper } from '../../../Assets/SettingPagesDesigns';
import ErrorSnackBar from '../../../Subcomponents/ErrorSnackBar';
import SuccessSnackBar from '../../../Subcomponents/SuccessSnackBar';
import { EditButton } from '../../CompanySettings/MainSettings';
import AddNewAuditQuestion from './Dialog/AddNewAuditQuestion';
import EditAuditCategory from './Dialog/EditAuditCategory';
import EditAuditQuestions from './Dialog/EditAuditQuestions';
import EditAuditType from './Dialog/EditAuditType';
import EditNumberOfQuestions from './Dialog/EditNumberOfQuestionsDialog';
import AuditQuestionsTable from './Table/AuditQuestionsTable';
const AuditDetails = () => {
    let params = useParams()
    const [successOpen, setSuccessOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [name, setName] = useState(null) 
    const [even_distribution_of_categories, setEvenDistributionCat] = useState(false)
    const [openNumberOfQuestions, setOpenNumberOfQuestions] = useState(false)
    const [numberOfQuestions, setNumberOfQuestions] = useState(null)
    
    const [data, setData] = useState(null)
    async function getAudits () {
      const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
      };
      try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/${params?.id}/`, config);
          setData(res.data)
          setEvenDistributionCat(res?.data?.audit_rules?.even_distribution_of_categories)
      } catch (err) {
      }
    }
    const [dataQuestion, setDataQuestion] = useState(null)
    async function getQuestion () {
      const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
      };
      try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/questionpool/create/${params?.id}/`, config);
          setDataQuestion(res.data)
      } catch (err) {
      }
    }
  
    useEffect(() => {getQuestion(); getAudits()},[params?.id])

    const [addQuestionOpen, setAddQuestionOpen] = useState(false)

    const handleOpenEditName = () => {
        setName(data?.name)
        setOpenEditDialog(true)
    } 
    const handleOpenEditNumberOfQuestions = () => {
        setNumberOfQuestions(data?.audit_rules?.number_of_questions)
        setOpenNumberOfQuestions(true)
    } 

    const [openEditCategory, setOpenEditCategory] = useState(false)
    
    async function handleChange() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        const body = JSON.stringify({  edq:  !even_distribution_of_categories });
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/${params?.id}/`, body, config);
            getAudits()
            setSuccessOpen(true)
        } catch (err) {
            setErrorOpen(true)
        }
    }

  return (
    <SettingsPagesContainer>
         <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
        <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
        <SettingsPageWrapper>
            <SettingsPageHeading>Denetim Bilgileri</SettingsPageHeading>
            <Divider style={{width:"100%", background:"white", marginBottom:"25px"}}/>
            <b>Denetim Bilgileri Özeti</b>
            <table>
                <tr>
                    <td align='right' style={{width:"20%"}}>
                        <p style={{color:"#d5d4d3d2"}}>Denetim İsmi: </p>
                    </td>
                    <td style={{paddingLeft:"15px"}}>
                        {data?.name}
                    </td>
                    <td>
                       <SettingsPageActionText onClick={handleOpenEditName}> İsmi Düzenle</SettingsPageActionText>
                    </td>
                </tr>
                <tr>
                    <td valign='top' align='right' style={{width:"20%"}}>
                        <p style={{color:"#d5d4d3d2"}}>Kategori İsmi: </p>
                    </td>
                    <td style={{paddingLeft:"15px" , width:"45%"}}>
                    {data?.audit_category?.map((e) => (
                        <p>{e?.name}</p>
                    ))}
                    </td>
                    <td valign='top'>
                      <SettingsPageActionText onClick={() => setOpenEditCategory(true)}> Kategorileri Düzenle</SettingsPageActionText>
                    </td>
                </tr>
            </table>
            <Divider style={{width:"100%", background:"white", marginBottom:"25px"}}/>
            <b>Denetim Oluşturma Kuralı</b>
            <table>
                <tr>
                    <td align='right' style={{width:"20%"}}>
                        <p style={{color:"#d5d4d3d2"}}>Toplam Soru Sayısı: </p>
                    </td>
                    <td style={{paddingLeft:"15px" , width:"45%"}} >
                        {data?.audit_rules?.number_of_questions}
                    </td>
                    <td>
                       <SettingsPageActionText onClick={handleOpenEditNumberOfQuestions}> Soru Sayısını Düzenle</SettingsPageActionText>
                    </td>
                </tr>
                <tr>
                    <td valign='top' align='right' style={{width:"20%"}}>
                        <p style={{color:"#d5d4d3d2"}}>Soru Dağılımı: </p>
                    </td>
                    <td style={{paddingLeft:"15px" , width:"45%"}}>
                        <p>{data?.audit_rules?.even_distribution_of_categories ? "Eşit Soru Dağılımı":"Rastgele soru dağılımı"}</p>
                    </td>
                    <td valign='top'>
                        <Switch
                            checked={even_distribution_of_categories}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </td>
                </tr>
            </table>
            <Divider style={{width:"100%", background:"white", marginBottom:"25px"}}/>
            <div style={{width: "100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <b style={{marginBottom:"25px"}} >Denetim Soruları</b>
            <EditButton active style={{marginBottom:"25px"}} onClick={() => setAddQuestionOpen(true)}>
                Denetim Sorusu Ekle
            </EditButton>
            </div>
            <AuditQuestionsTable style={{marginBottom:"25px"}} mainInfo={data} data={dataQuestion} getInfoBack={getQuestion}/>
            <AddNewAuditQuestion
            setSuccessOpen={setSuccessOpen}
            setErrorOpen={setErrorOpen}
            open={addQuestionOpen} setOpen={setAddQuestionOpen} getInfoBack={getQuestion} category={data?.audit_category} id={params?.id} />
            <EditAuditType open={openEditDialog} setOpen={setOpenEditDialog} getInfoBack={getAudits} id={params?.id} 
            name={name}
            setName={setName}
            setSuccessOpen={setSuccessOpen}
            setErrorOpen={setErrorOpen}
            />
            <EditNumberOfQuestions open={openNumberOfQuestions} setOpen={setOpenNumberOfQuestions} getInfoBack={getAudits} id={params?.id} 
            numberOfQuestions={numberOfQuestions}
            setNumberOfQuestions={setNumberOfQuestions}
            setSuccessOpen={setSuccessOpen}
            setErrorOpen={setErrorOpen}
            />
            <EditAuditCategory
             setSuccessOpen={setSuccessOpen}
             setErrorOpen={setErrorOpen}
            open={openEditCategory} setOpen={setOpenEditCategory} getInfoBack={getAudits}/>
        </SettingsPageWrapper>
    </SettingsPagesContainer>

  )
}

export default AuditDetails