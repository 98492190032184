import React, { useState } from 'react'
import SuccessSnackBar from '../../../../Subcomponents/SuccessSnackBar'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../Activities/ActivitiesStyle'
import DeleteRequestTable from './DeleteRequestTable'

const DeleteRequests = () => {
  const [successOpen, setSuccessOpen] = useState(false)
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <SuccessSnackBar
        data="İşlem Başarıyla Gerçekleşti"
        open={successOpen} setOpen={setSuccessOpen} />
        <DeleteRequestTable
        setSuccessOpen={setSuccessOpen}
        />
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default DeleteRequests