import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Input, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import moment from "moment"
import DialogTitleV2 from '../../../Assets/DialogTitle';
const AddNewWorkshopV2 = ({open, setOpen}) => {
    const [audits, setAudits] = useState([])
    const [audit, setAudit] = useState([])
    const [date, setDate] = useState(moment().format("YYYY-DD-MM"))
    const handleChange = (event) => {
        setAudit(event.target.value);
    };
    useEffect(() => {
        if(open){
            const token = localStorage.getItem("access")
                const opts = {
                    method : "GET",
                    headers : {
                        "Content-Type" : "application/json",
                        "Authorization" : "JWT " + token
                    }
                }
                fetch(`${process.env.REACT_APP_API_URL}/api/users/create/`, opts)
                .then(response => response.json()
                .then(data => {
                  setAudits(data)
                }))
        }
    }, [])
    const [subdeps, setSubdeps] = useState([])
    useEffect(() => {
        if(open){

            const token = localStorage.getItem("access")
            const opts = {
                method : "GET",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "JWT " + token
                }
            }
            fetch(`${process.env.REACT_APP_API_URL}/api/section/get_childeren/`, opts)
            .then(response => response.json()
            .then(data => {
                setSubdeps(data)
            }))
        }
    }, [])
    let navigate = useNavigate()
    const [showButton, setStartButton] = useState(false)
    async function handleStartWorkShop() {
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({region: selectedRegion?.id, department_id : selectedDepartment,audit, date })
        setStartButton(true)
        try {
           await axios.post(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/workshop/`, body, config);
            navigate("/app/audit-app/activities/workshop")
            setStartButton(false)
            window.location.reload()

        }
        catch (err) {
        setStartButton(false)
        }
      }
      const [singleDep, setSingleDep] = useState([])
      const handleChangeSubdep = (event) => {
          setSingleDep(event.target.value);
      };
    const handleCloseWorkShop = () => {
        setOpen(false)
    }

    const [region, setRegion] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const departmentOptions = region?.filter(e => e.id === selectedRegion?.id)
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const handleChangeSelectedRegion = (e) => {
        setSelectedRegion(e.target.value)
        setSelectedDepartment(null)
    }
    async function getRegionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, config);
            setRegion(res.data)
        } catch (err) {
        }
      }
      useEffect(() => {
        if(open){
            getRegionInfo()
        }
    },[])
      const handleChangeSelectedDepartment = (e) => {
        setSelectedDepartment(e.target.value)

    }
  return (
    <Dialog open={open} onClose={handleCloseWorkShop}
    sx={{
        "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#1e1f21",
            color:"#fff",
            marginBottom:"50px"
        },
        },
    }}
    >
        <DialogTitleV2 title={"Yeni Çalıştay Oluştur"} handleCloseDialog={handleCloseWorkShop}/>
        <Divider style={{background:"#A3A2A1"}}/>
        <DialogContent style={{minWidth:350}}>

        <Grid item xs={12}>
                <Box mb={2} mt={2}>
                    <FormControl
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    >
                        <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Bölge Seçin</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRegion}
                            label="Bölge Seçin"
                            onChange={handleChangeSelectedRegion}
                            style={{color:"#fff"}}
                        >
                        {region?.map((reg, index) => (
                        <MenuItem value={reg} key={reg?.id} >{reg.name}</MenuItem>
                        ))
                        } 
                    </Select>
                </FormControl>
                </Box>
            </Grid>
       
            {selectedRegion ?
            <Grid item xs={12}>
              <Box mb={2} mt={2}>
                  <FormControl
                  sx={{
                  width:"100%",
                  color:"#fff",
                  '& label.Mui-focused': { color: 'white',},
                  '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                          borderColor: 'white',
                          },
                          '&:hover fieldset': {
                          borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                          borderColor: 'white',
                          },}
                      }}
                  >
                      <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Departman Seçin</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedDepartment}
                          label="Departman Seçin"
                          onChange={handleChangeSelectedDepartment}
                          style={{color:"#fff"}}
                      >
                         
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                <MenuItem value={child?.id}>{child?.name}</MenuItem>
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        <MenuItem value={child2?.id}>{child?.name} - {child2?.name}</MenuItem>
                                    ))
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        child2?.children?.map(child3 => (
                                            <MenuItem value={child3?.id}>{child?.name} - {child2?.name} - {child3?.name}</MenuItem>
                                        ))
                                    ))
                            ))))}
                  </Select>
              </FormControl>
              </Box>
            </Grid>
            :""}


         
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Katılımcı Seç</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={audit}
                    multiple
                    label="department"
                    onChange={handleChange}
                >
                    {audits.map((deps, index) => (
                    <MenuItem value={deps.id}>{deps.name + " " + deps.surname}</MenuItem>
                    ))
                    }
                </Select>
            </FormControl>
            <br/>
            <br/>
            <Input type="datetime-local" value={date} onChange={(e) => setDate(e.target.value)} fullWidth variant="contained"/>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseWorkShop}>Kapat</Button>
        {!showButton ? <Button onClick={handleStartWorkShop} variant="contained">Çalıştayı Başlat</Button> : <Button variant="outlined">Başlatılıyor...</Button>}
        </DialogActions>
    </Dialog>
  )
}

export default AddNewWorkshopV2