import { Avatar, Divider, Grid, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState,useEffect, useRef } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import styled from "styled-components"
import { TopBarLeft, TopBarRight } from '../../Pages/MainAppScreen'
import { SettingsContainer, TopNav, TopNavTitle } from '../AuditDashboardsContainer/activitiesdashboard'
import NavBarLogo from '../NavBarLogo'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatIcon from '@mui/icons-material/Chat';
import TextFieldV2 from '../../Assets/TextFieldV2';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ChatBackground from "../../Assets/ChatBackgrounds/1.webp"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { connect } from 'react-redux';
import SuccessSnackBar from '../SuccessSnackBar';
import DoneAllIcon from '@mui/icons-material/DoneAll';
export const SetttingsContainer = styled.div`
    height: ${({ht}) => !ht ?"100vh" :"100vh"} ;
    width:100%;
    background:#211c27;
    display:flex;
    @media screen and (max-width:600px){
        height: ${({ht}) => !ht ?"100vh" :"100vh"} ;
    }

`

export const SideBar = styled.div`
    display:flex;
    flex-direction:column;
    background:#26252b;
    width:300px;
    border-right:1px solid black;
    border-radius:8px;
    z-index:99;
    @media screen and (max-width:600px){
        position:absolute;
        height:100vh;
        display:${({showSidebar}) => showSidebar ? "flex": "none"}
    }
`
export const SideBarTopElements = styled.div`
    margin-top:25px;
    display:flex;
    align-items: center;
    justify-content:space-between;
`
export const SideBarTopElementsActions = styled.div`
    display:flex;
    justify-content:start;
    align-items:left;
`
export const TopNavActions = styled(Link)`
    height:13px;
    width:13px;
    border-radius: 50%;
    background: ${({background}) => background};
    margin-left:15px;
    margin-right:15px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    color:white;
    text-decoration:none;
    
`
export const SearchContainer = styled.div`
    display:flex;
    width:100%;
    margin-top:15px;
    justify-content:center;
    align-items:center;
`
export const SearchWrapper = styled.div`
    display:flex;
    width:90%;
    align-items:center;
    justify-content:center;
    gap:8px;
    padding-top:10px;
`

export const MessagesContainer = styled.div`
    height: -webkit-fill-available;
    overflow-y:auto;
    width: 100%;
    justify-content:center;
    align-items:center;
    display:flex;
    
`
export const MessagesWrapper = styled.div`
height: -webkit-fill-available;
overflow-y: scroll;
width: 100%;
margin-top: 15px;
cursor: pointer;
display: flex;
flex-direction: column;
margin-bottom: 34px;
overflow-x:hidden;

`

export const TaskChatItem = styled.div`
    display:flex;
    height:60px;
    align-items:center;
    gap:5px;
    background: ${({active}) => active ? "#818181" : "none"};
    padding:5px;
    &:hover {
        background: ${({active}) => active ? "#818181" : "#545358"};
    }
    border-bottom:1px solid #80808070;

`

export const TaskChatItemMessageCont = styled.div`
    display:flex;
    flex-direction:column;
    gap:4px;
    width:90%;
`
export const TaskChatItemMessageName = styled.span`
    font-size:13px;
`
export const TaskChatItemMessageChat = styled.span`
    font-size:16px;
    width:300px;
    text-overflow:ellipsis;
`

export const MainApp = styled.div`
    width:100%;
    height: -webkit-fill-available;
    display:flex;
    flex-direction:column;
`
export const ChatTopBar = styled.div`
    width:100%;
    height:76px;
    background:#26252b;
    display:flex;
    align-items:center;
    justify-content:center;
`
export const ChatTopBarWrapper = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
`
export const ChatLeft = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    gap:8px;
    margin-left:15px;
`
export const ChatRight = styled.div`
margin-right:15px;

`
export const NameLastSeenHolder = styled.div`
    display:flex;
    flex-direction:column;
    gap:5px;
`

export const ChatMainScreen  =  styled.div`
    width: 100%;
    overflow-y:auto;
    height: -webkit-fill-available;
    display:flex;
    flex-direction:column-reverse;
    position: relative;
    &:before{
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(${ChatBackground});
        opacity: 0.1;
    }
`

export const ChatRow = styled.div`
display:flex;
width:100%;

`
export const ChatRowWrapper = styled.div`
display:flex;
width:100%;
padding-right: 63px;
padding-left: 63px;
margin-bottom:10px;
align-items:center;
justify-content: ${({self}) => self ? "flex-end" : "flex-start"}
`
export const ChatMain = styled.div`
    background: ${({self}) => self ? "#005c4b" : "#202c33"};
    padding:10px;
    border-radius:15px;
    max-width:280px;
`

export const ChatSentBar = styled.div`
    height:140px;
    width:-webkit-fill-available;
    background:rgb(38, 37, 43);
    display:flex;
    align-items:center;
    justify-content:center;
`
export const ChatSentBarWrapper  =styled.div`
    width: 95%;
    display:flex;
    gap:35px;
    align-items:center;
    
`
const InternalMessagingAppDashboard = ({user}) => {
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const [messages, setMessages] = useState([]);
    const [ws, setWs] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null)
    let navigate = useNavigate()
    let params = useParams()

    useEffect(() => {
      const newWs =  new WebSocket(`wss://${process.env.REACT_APP_API_URL_WS}/ws/chat/${user?.company_id}/?${"uid="+user?.id}`);
      newWs.onopen = () => {
      };
      newWs.onmessage = (event) => {
        const message = JSON.parse(event.data);
        setMessages(message);
        getChat(params?.id, message?.sender);
        if (parseInt(message?.sender) !== user?.id){
        }
      };
      newWs.onclose = () => {
      };
      setWs(newWs);
        return () => {
          newWs?.close()
        }
      }, [params]);
   
    const [message, setMessage] = useState(null)
  
     async function createChat (e){
         const body = JSON.stringify({user_ids:[e], msg:message})
         try{
             await axios.post(`${process.env.REACT_APP_API_URL}/api/message/create/`, body, axiosConfig);
             getChat()
         }
         catch{
 
         }
     }
      const sendNewFindingAdded = () => {
        createChat(selectedUserId)
        const messageObject = {
          type: 'chat_message',
          message:message,
          to: selectedUserId,
          uid: user?.id,
        };
        ws.send(JSON.stringify(messageObject));
        setMessage("")
      };

    // bu data chatlarin datası
    const [data, setData] = useState([]) 
    async function getInfo (e){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/message/chat/get/`,  axiosConfig);
            setData(res.data)
        }
        catch{

        }
    }
    const [messagesChat,setMessagesChat] = useState(null)
    async function getChat (p,e){
        if (params?.id === undefined){

        }
        else{
            try{
                getInfo()
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/message/get/?rid=${params?.id}`,  axiosConfig);
                console.log(selectedUserId,res)
                setMessagesChat(res.data.results)
            }
            catch{
    
            }
        }
      
    }
    const [contacts, setContacts] = useState(null)
    async function getContact (e){
        try{

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/create/`,  axiosConfig);
            setContacts(res.data)
        }
        catch{

        }
    }
    const selectedUser = contacts?.filter(j => j.id === selectedUserId)
    const selectedUserUserName  =  selectedUser ? selectedUser[0]: null
    
    useEffect(() => {
        getContact()
        getInfo();
    }

    ,[])
    useEffect(() => {
        getChat()
    },[selectedUserId])
   
    const [fullHeight, setFullHeight] = useState(false)
    const {pathname} = useLocation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    const [iconMinimizeDisplay, setIconMinimizeDisplay] = useState("none")
    const [iconMaximizeDisplay, setIconMaximizeDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
        else if (e.target.id ==="minimize"){
            setIconMinimizeDisplay("block")
        }
        else{
            setIconMaximizeDisplay("block")
        }
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
        setIconMinimizeDisplay("none")
        setIconMaximizeDisplay("none")

    }
    function TopbarHidden(){
        setFullHeight(true)
    }
    function TopbarShow(){
        setFullHeight(false)
    }


    function setSelectedUser(e) {
        setSelectedUserId(e.id)
        navigate("/app/chat-app/"+e.id)
    }
   

    const [openNotification, setOpenNotification] = useState(false)
    return (
    <SettingsContainer>
        <SuccessSnackBar open={openNotification} setOpen={setOpenNotification} data={`${messages?.user} sana ${messages?.message} ını gönderdi`} />
        <TopNav fullHeight={fullHeight}>
            <TopBarLeft>
                <NavBarLogo/>
                <TopNavTitle>İç Mesajlaşma Uygulaması</TopNavTitle>
            </TopBarLeft>
            <TopBarRight>
            {moment().format("llll")}
            </TopBarRight>
        </TopNav>
        <SetttingsContainer ht={fullHeight}>
            <SideBar>
            <SideBarTopElements>
                <SideBarTopElementsActions>
                    <TopNavActions id="close" onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} background={"#ee5c54"} to="/app">
                        <p style={{fontSize:"8px", display:iconCloseDisplay}}>X</p>
                    </TopNavActions>
                    <TopNavActions id="minimize" onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} background={fullHeight ? "#F8BC45" : "#6d6971"} style={{marginLeft:"-5px"}} onClick={TopbarShow}>
                        <p style={{fontSize:"8px", display:iconMinimizeDisplay}}>-</p>
                    </TopNavActions>
                    <TopNavActions id="maximize" onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} background={!fullHeight ? "#5dc942" : "#6d6971"} style={{marginLeft:"-5px"}} onClick={TopbarHidden}>
                        <p style={{fontSize:"8px", display:iconMaximizeDisplay}}>+</p>
                    </TopNavActions>
                </SideBarTopElementsActions>
                <div style={{display:"flex", gap:"5px", marginRight:"5px", alignItems:"center"}}>
                    <Tooltip title={"Profil"}>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <Avatar sx={{ width: 24, height: 24 }}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Yeni sohbet"}>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <ChatIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Menü"}>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </SideBarTopElements>
            <Divider style={{width:"100%", background:"white", marginTop:"8px"}} />
            <SearchContainer>
                <SearchWrapper>
                    <TextFieldV2 size="small" endAdornmentText={<SearchIcon/>} label={"Aratın veya yeni sohbet başlatın"} />
                    <Tooltip title={"Okunmamış sohbetler filtresi"}>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            onClick={handleClick}
                        >
                            <FilterListIcon  style={{marginBottom:"15px"}}/>
                        </IconButton>
                    </Tooltip>
                </SearchWrapper>
            </SearchContainer>
            <Divider style={{width:"100%", background:"white", marginTop:"8px"}} />

            <MessagesContainer>
                <MessagesWrapper>
                    {data?.map((e) => (

                    <TaskChatItem onClick={() => setSelectedUser(e.members?.filter(e => e?.id !== user?.id)[0])} active={e.members?.filter(e => e.id !== user?.id)[0]?.id === selectedUserId}>
                        <Avatar/>
                        <TaskChatItemMessageCont>
                            <TaskChatItemMessageName>{e.members?.filter(e => e.id !== user?.id)[0]?.name + " " + e.members?.filter(e => e.id !== user?.id)[0]?.surname}</TaskChatItemMessageName>
                            <Typography variant="inherit" noWrap>
                            {e.last_message?.sender === user?.id ? <DoneAllIcon style={{fontSize:"15px"}}/> : ""} 
                            {" " +e.last_message?.content} 
                            </Typography>
                        </TaskChatItemMessageCont>
                    </TaskChatItem>
                    ))}
                  
                </MessagesWrapper>
            </MessagesContainer>
            </SideBar>
            <MainApp>
                {!selectedUserId ?
            "":
           <React.Fragment>
                <ChatTopBar>
                    <ChatTopBarWrapper>
                        <ChatLeft>
                            <Avatar/>
                            <NameLastSeenHolder>
                                <span> {selectedUserUserName?.name + " " + selectedUserUserName?.surname}</span>
                                <span>{selectedUserUserName?.is_online ? "Çevrim İçi"  : "Son Görülme: " +  moment(selectedUserUserName?.last_seen).format("HH:mm") }</span>
                            </NameLastSeenHolder>
                        </ChatLeft>
                        <ChatRight>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            onClick={handleClick}
                        >
                            <SearchIcon/>
                        </IconButton>
                        </ChatRight>
                    </ChatTopBarWrapper>
                </ChatTopBar>
                <ChatMainScreen>
                    {messagesChat?.map((e) =>(
                        <ChatRow>
                            <ChatRowWrapper self={e?.sender === user?.id}>
                                <p style={{display:e?.sender === user?.id ?"block" :"none"}}></p>
                                <ChatMain self={e?.sender === user?.id}>
                                    {e?.content}
                                </ChatMain>
                                <p style={{display:e?.sender === user?.id ?"none" :"block"}}></p>
                            </ChatRowWrapper>
                        </ChatRow>
                       ))}
                </ChatMainScreen>
                <ChatSentBar>
                    <ChatSentBarWrapper>
                        <AttachFileIcon style={{marginBottom:"15px"}}/>
                        <TextFieldV2  onKeyPress={(e) => {
                            if (e.key === 'Enter' && message?.length > 0 ) {
                                sendNewFindingAdded("asdfasd")
                            }
                        }} value={message} setValue={setMessage} placeholder={"Bir mesaj yazın"} size="small" />
                        {message?.length > 0 ?
                        <SendIcon style={{marginBottom:"15px", color:"white", cursor:"pointer"}}/>
                        :
                        <SendIcon style={{marginBottom:"15px", color:"#525252"}}/>
                        }
                    </ChatSentBarWrapper>
                </ChatSentBar>
                </React.Fragment>
                 }
            </MainApp>
        </SetttingsContainer>
    </SettingsContainer>
  )
}

const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps) (InternalMessagingAppDashboard)