//for auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_PROCESS_START = 'LOGIN_PROCESS_START'
export const LOGIN_PROCESS_STOP = 'LOGIN_PROCESS_STOP'

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SIGNUP_EMAIL_FAIL = 'SIGNUP_EMAIL_FAIL'

export const GET_ERRORS = 'GET_ERRORS'
export const GET_ERRORS_RESET = 'GET_ERRORS_RESET'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';

export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS'
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL'

export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS'
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL'
export const LOGOUT = 'LOGOUT'


export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';

export const IS_ADMIN_TRUE = 'IS_ADMIN_TRUE';
export const IS_ADMIN_FALSE = 'IS_ADMIN_FALSE';
//for auth ends
//for home start
export const HOME_ADMIN_INFO_RECIEVED = 'HOME_ADMIN_INFO_RECIEVED'
export const HOME_ADMIN_INFO_UNRECIEVED = 'HOME_ADMIN_INFO_UNRECIEVED'
//for home end
//fro hrm start
export const EMPLOYEE_CREATE_SUCCESS = 'EMPLOYEE_CREATE_SUCCESS'
export const EMPLOYEE_CREATE_FAIL = 'EMPLOYEE_CREATE_FAIL'
export const EMPLOYEE_UPDATE_SUCCESS = 'EMPLOYEE_UPDATE_SUCCESS'
export const EMPLOYEE_UPDATE_FAIL = 'EMPLOYEE_UPDATE_FAIL'
export const EMPLOYEE_RETRIEVED_SUCCESS = ''
export const EMPLOYEE_RETRIEVED_FAIL = ''
//for crm
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS'
export const CUSTOMER_CREATE_FAIL = 'CUSTOMER_CREATE_FAIL'
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS'
export const CUSTOMER_UPDATE_FAIL = 'CUSTOMER_UPDATE_FAIL'
export const CUSTOMER_RETRIEVED_SUCCESS = ''
export const CUSTOMER_RETRIEVED_FAIL = ''
//for stock
export const STOCK_CREATE_SUCCESS = 'STOCK_CREATE_SUCCESS'
export const STOCK_CREATE_FAIL = 'STOCK_CREATE_FAIL'