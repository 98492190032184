import ExcelIcon from './FileIcons/excel.png'
import WordIcon from './FileIcons/word.png'
import PdfIcon from './FileIcons/pdf.png'
import ImageIcon from './FileIcons/Image.png'
import RarIcon from './FileIcons/rar.png'
import UnKnown from './FileIcons/UnKnown.webp'
import { FileThumbnail } from './GeneralDesignElements'


export default function  getFileIcon(extension, size) {
    switch (extension) {
      case 'xls':
      case 'xlsx':
        return <FileThumbnail size={size} src={ExcelIcon} alt="xls" />;
      case 'pdf':
        return <FileThumbnail  size={size} src={PdfIcon} alt="xls" />;
      case 'doc':
      case 'docx':
        return <FileThumbnail  size={size} src={WordIcon} alt="xls" />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'pjpeg':
      case 'webp':
        return <FileThumbnail  size={size} src={ImageIcon} alt="xls" />;
      case 'zip':
      case 'rar':
        return <FileThumbnail  size={size} src={RarIcon} alt="xls" />;
      default:
        return <FileThumbnail  size={size} src={UnKnown} alt="xls" />;
    }
  }