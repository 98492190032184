import {  Dialog, DialogActions, DialogContent,} from '@mui/material'
import React from 'react'
import DialogTitleV2 from '../../../Assets/DialogTitle'
import { EditButton } from '../../../Pages/CompanySettings/MainSettings'


const IccApplicationInfo = ({open, setOpen}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
  
 
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"İç Müşteri Şikayetleri"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
           İç Müşteri Şikayetleri Yönetimi Uygulaması, firmaların iç müşteri şikayetlerini etkili bir şekilde yönetmelerine yardımcı olmak için tasarlanmıştır. İşgücü yönetimi ve departmanlar arası koordinasyonu artırmak için kullanılabilir. Yazılım, şikayetlerin otomatik olarak toplanmasını ve yönlendirilmesini sağlar, böylece sorunlar hızla çözülebilir. Ayrıca, yazılım şikayetlerin takibini yaparak, sorunların nasıl çözüldüğüne dair raporlar oluşturur. Bu sayede, firmalar çalışan memnuniyetini artırabilir ve verimliliklerini artırabilirler. Yazılım ayrıca, tekrarlayan sorunları belirleyerek, sürekli iyileştirme ve geliştirme için fırsatlar yaratır. 
        </DialogContent>
        <DialogActions>
            <EditButton active onClick={handleCloseDialog}>Tamam</EditButton>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default IccApplicationInfo