import { Divider } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import KanbanView from '../../../Subcomponents/TaskManagementViews/KanbanView'
import TaskManagementMainBoard from '../../../Subcomponents/TaskManagementViews/ListView'
import { InnerNavigation, InnerNavigationItems } from '../../AuditApp/Activities'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../CompanySettings/MainSettings'
import { ApplicationContainer } from '../HomePage'

const TMMyTasks = () => {
  const [viewMode, setViewMode] = useState("list")
    const [data, setData] = useState(null)
    const [isFetching, setIsFetching] = useState(null)
    const [rowCount, setRowCount] = useState(null)
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    })
    console.log(pagination)
    const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
  };
    async function getTasks (){
        setIsFetching(true)
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagment/all/?page=${pagination?.pageIndex+1}`, axiosConfig)
        setData(res.data.results)
        setRowCount(res.data.count)
        setIsFetching(false)
    }
    catch{

    }
    }
   
    useEffect(() => {getTasks()},[pagination?.pageIndex])
  return (
    <ApplicationContainer style={{width:"100%"}}>
      <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
          <InfoBar style={{borderBottom:"1px solid grey", paddingBottom:"10px"}}>
            <InnerNavigation center>
                <InnerNavigationItems active={viewMode==="list"} onClick={() => setViewMode("list")}>Liste</InnerNavigationItems>
                <InnerNavigationItems active={viewMode==="kanban"} onClick={() => setViewMode("kanban")}>Kanban</InnerNavigationItems>
            </InnerNavigation>
            <EditButton active>Görev Ekle</EditButton>
          </InfoBar>
          {viewMode === "list" ?
          <TaskManagementMainBoard
          data={data}
          isFetching={isFetching}
          rowCount={rowCount}
          setPagination={setPagination}
          pagination={pagination}
          fetchBack={getTasks}
          />
          :
         <KanbanView/>
          }
        </ActivitiesMainWrapper>
      </ActivitiesMainContainer>
    </ApplicationContainer>
    
  )
}

export default TMMyTasks