import { DataGrid, trTR } from '@mui/x-data-grid'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../CompanySettings/MainSettings'
import ManagementSystemDeleteDialog from './Dialogs/ManagementSystemDeleteDialog'
import ManagementSystemEditDialog from './Dialogs/ManagementSystemEditDialog'

const ManagementSystemTable = () => {
    const [data, setData] = useState(null)
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/management-systems/`, axiosConfig)
        setData(res.data)
    }
    catch{

    }
    }
    useEffect(() => {getInfo()},[])
    
    const [id, setId] = useState(null)
    const [name, setName] =useState(null)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const handleDeleteDialogOpen = (e) => {
      setId(e)
      setOpenDeleteDialog(true)
    }

    const handleEditDialogOpen = (e) => {
      setId(e.id)
      setName(e.name)
      setOpenEditDialog(true)
    }

    const columns = [
        {
            field: "id",
            headerName: "No",
            hide:true,
            width: 50,
            }, 
            {
            field: "uuid",
            headerName: "Yönetim Sistemi No",
            width: 350,
            }, 
            {
            field: "region",
            headerName: "Yönetim Sistemi Adı",
            width: 450,
              renderCell: (params) => {
                return (
                  <p>{params?.row?.name}</p> 
                  )    
              },
            }, 
            {
            field: "edit",
            headerName: "Düzenle",
            width: 100,
              renderCell: (params) => {
                return (
                 <EditButton active onClick={() => handleEditDialogOpen(params?.row)}>Düzenle</EditButton>
                  )    
              },
            }, 
            {
            field: "delete",
            headerName: "Sil",
            width: 100,
              renderCell: (params) => {
                return (
                 <EditButton onClick={() => handleDeleteDialogOpen(params?.row?.id)} active style={{background:"red"}}>Sil</EditButton>
                  )    
              },
            }, 
    ]
  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
        <div style={{height:"75vh"}}>
            <DataGrid
            rows={data ? data : []}
            columns={columns}
            style={{color:"white"}}
            pageSize={15}
            rowsPerPageOptions={[15]}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
            />
        </div>
        </ActivitiesMainWrapper>
        <ManagementSystemDeleteDialog id={id} open={openDeleteDialog} setOpen={setOpenDeleteDialog}  getInfoBack={getInfo} />
        <ManagementSystemEditDialog name={name} setName={setName} id={id} open={openEditDialog} setOpen={setOpenEditDialog}  getInfoBack={getInfo} />
    </ActivitiesMainContainer>
  )
}

export default ManagementSystemTable