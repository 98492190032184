import {  Dialog, DialogActions, DialogContent,} from '@mui/material'
import React from 'react'
import DialogTitleV2 from '../../../Assets/DialogTitle'
import { EditButton } from '../../../Pages/CompanySettings/MainSettings'


const TaskManagementInfo = ({open, setOpen}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
  
 
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Süreç Yönetimi Uygulaması"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
        Süreç takibi uygulaması, kaizen öneri sistemi, işletmelerin süreç yönetimi uygulamaları için vazgeçilmez bir araç haline gelmiştir. Müşteri şikayetleri ve görevlerin takibi için kullanıcı dostu bir arayüz sunar. Bu arayüz, işletmelerin sorunları tanımlamasına, önceliklendirmesine ve çözmesine yardımcı olurken aynı zamanda süreçlerin sürekli olarak iyileştirilmesine de olanak tanır. Kaizen, ekip çalışmasını kolaylaştırır ve farklı departmanlar arasında iletişim kurulmasına yardımcı olur. İşletmeler, bu aracı kullanarak süreçlerini optimize edebilir, maliyetleri düşürebilir ve müşteri memnuniyetini artırabilirler. Sonuç olarak, Kaizen öneri sistemi, işletmelerin süreçlerini daha etkin ve verimli bir şekilde yönetmelerine yardımcı olur ve rekabet avantajı sağlar.
        </DialogContent>
        <DialogActions>
            <EditButton active onClick={handleCloseDialog}>Tamam</EditButton>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default TaskManagementInfo