import { Divider } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { SettingsPageHeading, SettingsPagesContainer, SettingsPageWrapper } from '../../../Assets/SettingPagesDesigns'
import { InfoBar } from '../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../CompanySettings/MainSettings'
import AddCommitteeSuggestion from './AddCommitteeMember'
import SuggestionComutiyTable from './ComuityTable'

const SuggestionSystemSettings = () => {
  const [committeeOpen, setCommitteeOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState()
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
};
  async function getMembers (){
    setIsLoading(true)
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/committee-member-create/`,  axiosConfig);
        setData(res.data)
      setIsLoading(false)

    }
    catch{
      setIsLoading(false)

    }
}
  useEffect(() => {
    getMembers()
  },[])
  return (
    <SettingsPagesContainer>
      <SettingsPageWrapper>
        <SettingsPageHeading>Öneri Sistemi Komütesi</SettingsPageHeading>
        <Divider style={{width:"100%", background:"white", marginBottom:"25px"}}/>
        <InfoBar jc={"end"} style={{marginBottom:"15px"}}>
          <EditButton active onClick={() => setCommitteeOpen(true)}> Komite Üyesi Ekle </EditButton>
        </InfoBar>
        <SuggestionComutiyTable
        data={data}
        getInfoBack={getMembers}
        isLoading={isLoading}
        />
        <AddCommitteeSuggestion
        open={committeeOpen}
        setOpen={setCommitteeOpen}
        getInfoBack={getMembers}
        />
      </SettingsPageWrapper>
    </SettingsPagesContainer>
  )
}

export default SuggestionSystemSettings