import { Avatar, Divider, Grid, Tooltip } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { CommentOutline, EmployeeCardImage, EmployeeInfoCardContainer, EmployeeInfoCardWrapper, EmployeeInfoMain, FileHolder, FileName, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer, PostedCommentContainer, PostedCommentInfo } from '../../../../Assets/GeneralDesignElements'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import getFileIcon from '../../../../Assets/GetFileIconsFunction'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { EditButton } from '../../../CompanySettings/MainSettings'
import moment from 'moment'

const TrackKaizen = () => {
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
  };
  
  const [data, setData] = useState(null)
  const [comment, setComment] = useState(null)
  const [buttonClick,setButtonClick] = useState(false)
  let params = useParams()
  async function getInfo (){
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/kaizen/create/${params?.id}/`,  axiosConfig)
      setData(res.data)

    }
    catch{

    }
  }
  async function postComment (){
    if (comment && comment?.length > 0) {
      setButtonClick(true)
      const body = JSON.stringify({kaizen_id: params?.id, comment})
      try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/kaizen/create/comment/`, body, axiosConfig)
        getInfo()
        setButtonClick(false)
        setComment("")
      }
      catch{
        setButtonClick(false)
      }
    }
    else{
    }
  } 
  useEffect(()=>{getInfo()},[])
  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
        <InfoBar around top>
          <EmployeeInfoCardContainer>
            <EmployeeInfoCardWrapper>
              <EmployeeCardImage/>
                <EmployeeInfoMain>
                <b>{data?.responsible?.name} {data?.responsible?.surname}</b>
                <p>{data?.responsible?.email}</p>
              </EmployeeInfoMain>
            </EmployeeInfoCardWrapper>
          </EmployeeInfoCardContainer>
          <HorizantalTimeLineContainer>
            <HorizantalTimelineCardWrapper>
              <Tooltip title="Kaizen Başlangıcı" arrow>
                <Avatar
                sx={{ bgcolor: green[600] }}
                >
                  <DoneOutlineIcon/>
                </Avatar>
              </Tooltip>
              <Divider style={{background:"grey", width:"25px"}}/>
              <Tooltip title="Süreç Yönetimi" arrow>
                <Avatar>
                  S
                </Avatar>
              </Tooltip>
              <Divider style={{background:"grey", width:"25px"}}/>
              <Tooltip title="Çözüm Yönetimi" arrow>
                <Avatar>
                  ÇY
                </Avatar>
              </Tooltip>
              <Divider style={{background:"grey", width:"25px"}}/>
              <Tooltip title="Tamamlandı" arrow>
                <Avatar>
                  T
                </Avatar>
              </Tooltip>
            </HorizantalTimelineCardWrapper>
          </HorizantalTimeLineContainer>
        </InfoBar>
        <Grid container direction={"row"} justifyContent={"space-between"}>
          <Grid item container md={12} xs={12}>
            <Grid item md={12} xs={12}>
              <h1>{data?.uuid} Nolu Öneri</h1>
              <b>{data?.heading}</b>
              <p>{data?.problem_statement}</p>
              <p>Bölge: {data?.region?.name + " / " + data?.section?.name}</p>
              <p>Süreç Yöneticisi: {data?.responsible?.name + "  " + data?.responsible?.surname}</p>
            </Grid>
            <Grid item md={12} xs={12} mr={15}>
            {data?.files?.length > 0 ?
              <h1>Eklenen Dökümanlar</h1>
              :""}
              <div style={{display:"flex", gap:"45px"}}>
              {data?.files?.length > 0 ?
                  <>
                  <TaskImageHolder>
                  {data?.files?.map((e,index) => (
                    <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                        <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                      <FileHolder>
                        {getFileIcon(e?.file_type)}
                        <FileName>{e?.file_name}</FileName>
                      </FileHolder>
                      </a>
                    </Tooltip>
                      ))}
                  </TaskImageHolder>
                  </>
                :
              ""}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{background:"grey", width:"100%"}} />
        <Grid container>
          <Grid item xs={12} md={12}>
            <CommentOutline>
              <Avatar/>
              <TextFieldV2 label={"Yorumunuzu Ekleyin"} value={comment} setValue={setComment} style={{marginBottom:"0px !important"}} 
              onKeyPress={(e) => {
                if (e.key === 'Enter' && comment?.length > 0 ) {
                  postComment()
                }
            }}
              disabled={data?.state==="FAILED"}
              endAdornmentText={
                buttonClick? 
                  <EditButton>
                    Gönderiliyor...
                  </EditButton>
                  :
                  <EditButton active={comment} onClick={postComment}>
                    Yorum Yap
                </EditButton>
              }
              />
             
            </CommentOutline>
            <div style={{display:"flex", flexDirection:"column", gap:"25px"}}>
            <h2>Yorumlar {data?.comment_kaizen?.length}</h2>
            {data?.comment_kaizen?.slice(0)?.reverse()?.map((e) => (
             <PostedCommentContainer key={e.id}>
              <Avatar/>
              <PostedCommentInfo>
              <span> <b style={{fontSize:"13px"}}>{e?.commenter?.name + " " +e?.commenter?.surname}</b> <span style={{fontSize:"11px", color:"grey"}}>{moment(e?.created_at).format("DD-MM-YYYY")}</span> </span>
              <b>{e.comment}</b>
              </PostedCommentInfo>
               {/* <FaTrash/> */}
            </PostedCommentContainer>
            ))}
            </div>

          </Grid>
        </Grid>
        </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default TrackKaizen