import {  Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select,  TextField } from '@mui/material'
import React, { useState } from 'react'
import { TopNavActions } from '../../../../Subcomponents/AuditDashboardsContainer/activitiesdashboard'
import { EditButton } from '../../../CompanySettings/MainSettings'

import axios from 'axios';
import ErrorSnackBar from '../../../../Subcomponents/ErrorSnackBar';


const AddNewAuditType = ({open, setOpen, getInfoBack,setSuccessOpen}) => {
    const [err, setErr] = useState(null)
    const [openErr, setOpenErr] = useState(false)
    const [name, setName] = useState(null)
    const [specialAudit, setSpecialAudit] = useState(false)
    
    const handleChangeName = (e) => {
        setName(e.target.value)
    }

    const handleChangeSpecialAudit = (e) => {
        setName(null)
        setSpecialAudit(e.target.checked)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
      
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
    }

    async function handleSubmit (e) {
        e.preventDefault()
        if(name?.length > 0){
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                    'Accept': 'application/json'
                }
            };
            const body = JSON.stringify({  name  });
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/`, body, config);
                getInfoBack()
                handleCloseDialog()
                setOpen(false)
                setName(null)
                setSuccessOpen(true)
            } catch (err) {
                setErr(err.response.data)
                setOpenErr(true)
                window.scrollTo( 0, 0)
            }
        }
        else{
           
        }     
      }


  return (
    <React.Fragment>
        <ErrorSnackBar open={openErr} setOpen={setOpenErr} data={err}/>
        <Dialog open={open} onClose={handleCloseDialog}
        sx={{
            "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here,
                background:"#211c25",
                color:"#fff",
                marginBottom:"50px",

            },
            },
        }}
        >
            <DialogTitle>
                <Grid container spacing={2} justifyContent={"center"} alignItems="center">
                    <Grid container item xs={12}>
                    <Grid item container xs={4} style={{flexDirection:"row"}}>
                    <TopNavActions id="close" style={{ marginLeft:"-5px"}} onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} onClick={handleCloseDialog} background={"#ee5c54"}>
                                <p style={{fontSize:"8px", display:iconCloseDisplay}}>X</p>
                    </TopNavActions>
                    <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                    </TopNavActions>
                    <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                    </TopNavActions>
                    </Grid>
                    <Grid item xs={5} style={{fontSize:"15px"}}>Yeni Denetim Türü Ekle</Grid>
                    <Grid item xs={3}></Grid>
                    </Grid>
                </Grid>
            </DialogTitle>
            <Divider style={{background:"white"}}/>
            <DialogContent>
                <FormControlLabel
                    style={{marginBottom:"10px"}}
                    control={
                    <Checkbox checked={specialAudit} onChange={handleChangeSpecialAudit} name="gilad" 
                    sx={{
                        color: "white",
                        '&.Mui-checked': {
                        color: "white",
                        },
                    }}
                    />
                    }
                    label="Özel isimle denetim oluştur"
                />
                {specialAudit ? 
                <TextField value={name} onChange={(e) => setName(e.target.value)} fullWidth
                label="Denetim İsmi"
                InputLabelProps={{
                    style:{
                        color:"#A3A2A1"
                }
                }}
                sx={{
                    input: {
                        color: "white",
                    },
                    width:"100%",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'white',
                        },
                        '&:hover fieldset': {
                        borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: 'white',
                        },}
                    }}
                />
                : 
                <FormControl fullWidth
                InputLabelProps={{
                    style:{
                        color:"#A3A2A1"
                }
                }}
                sx={{
                    input: {
                        color: "white",
                    },
                    width:"100%",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'white',
                        },
                        '&:hover fieldset': {
                        borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: 'white',
                        },}
                    }}
            >
                <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Denetim Seçin</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={name}
                    label="Denetim Seçin"
                    onChange={handleChangeName}
                    style={{color:"#fff"}}
                >
                
                    <MenuItem value={"5S"}>5S</MenuItem>
                    <MenuItem value={"5S Ofis"}>5S Ofis</MenuItem>
                    <MenuItem value={"PA"}>Proses Denetimi</MenuItem>
                    <MenuItem value={"HSE"}>İş Sağlığı ve Güvenliği</MenuItem>
                </Select>
                </FormControl>
                }
            </DialogContent>
            <DialogActions>
            <EditButton onClick={handleCloseDialog}>
                İptal
            </EditButton>
            <EditButton active={name?.length > 0} onClick={handleSubmit}>
                Kaydet
            </EditButton>
            </DialogActions>
        </Dialog>
    </React.Fragment>

  )
}

export default AddNewAuditType