import React, { useState } from 'react'
import SuccessSnackBar from '../../../../Subcomponents/SuccessSnackBar'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../Activities/ActivitiesStyle'
import ChangeRequestTable from './ChangeRequestTable'

const DateChangeRequests = () => {
  const [successOpen, setSuccessOpen] = useState(false)
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <SuccessSnackBar
        data="İşlem Başarıyla Gerçekleşti"
        open={successOpen} setOpen={setSuccessOpen} />
        <ChangeRequestTable
        setSuccessOpen={setSuccessOpen}
        />
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default DateChangeRequests