import React, { useState } from 'react'
import styled from "styled-components"
import { MainAppContainer } from '../../Assets/GeneralDesignElements'
import AuditIcon from "../../Assets/AppIcons/audit.png"
import SuggestionIcon from "../../Assets/AppIcons/suggestion.png"
import KaizenIcon from "../../Assets/AppIcons/kaizen.png"
import ExternalCustomer from "../../Assets/AppIcons/external_customer.png"
import SettingsIcon from "../../Assets/AppIcons/settings.png"
import InternalCustomer from "../../Assets/AppIcons/internal_customer.png"
import Smed from "../../Assets/AppIcons/smed.png"
import Polyvalans from "../../Assets/AppIcons/polyvalans.png"
import GeneralInfo from "../../Assets/AppIcons/general_info.png"
import TaskManagement from "../../Assets/AppIcons/task_managment_app.png"
import ChatApp from "../../Assets/AppIcons/chat_app.png"
import AllApps from "../../Assets/AppIcons/all_apps4.png"
import moment from "moment"
import {  Divider, Tooltip, Grid, makeStyles } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import NavBarLogo from '../../Subcomponents/NavBarLogo'
import { connect } from 'react-redux'
import NotificationsMenu from '../../Subcomponents/NotificationsBox'
import WallPaper from "../../Assets/WallPapers/wp2.png"
import WallPaper2 from "../../Assets/WallPapers/wp3.png"
import InternalMessagingAppDashboard from '../../Subcomponents/InternalMessagingApp/InternalMessagingAppDashboard'
import FileManagement from '../../Subcomponents/FilemanagementDialog'
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ConstructionIcon from '@mui/icons-material/Construction';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HelpIcon from '@mui/icons-material/Help';
import CreateAuditQuestion from './PerceptronDialogs/GenerateAuditQuestions'
import CreateKaizen from './PerceptronDialogs/GenerateKaizen'
export const Navbar = styled.div`
    width: 100%;
    height:60px;
    bottom:90px;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
     @media screen and (max-width:541px){
        display:none;
    }
`
export const NavbarMobile = styled.div`
    width: 100%;
    height:60px;
    bottom:30px;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
     @media screen and (min-width:541px){
        display:none;
    }
`

export const NavbarItemContainer = styled.div`
    padding:5px 25px 5px 25px;
    height: 100%;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    display:flex;
    align-items:center;
    justify-content:center;
    gap:8px;
`
export const NavbarItemContainerMobile = styled.div`
    padding:5px 25px 5px 25px;
    height: 100%;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    display:flex;
    align-items:center;
    justify-content:center;
    gap:15px;
`
export const NavbarItem = styled.img`
    border-radius:15px;
    height: 50px;
    transition-property: width;
    transition-duration: 2s;
    transition-timing-function: linear;
    cursor:pointer;
    transition-delay: 1s;
        &:hover{
            height: 55px;
    }
`

export const TopBar = styled.div`
    width: 100%;
    height:30px;
    font-size:14px;
    top:0px;
    position:absolute;
    display:flex;
    background: rgba(75, 23, 124, 0.5);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 40px );
    -webkit-backdrop-filter: blur( 40px );
    align-items:center;
    justify-content:space-between;
    @media screen and (max-width:541px){
        display:none;
    }
`

export const TopBarLeft = styled.div`
    margin-left:15px;
    display:flex;
    justify-content:center;
    align-items:center;
    gap:15px;
`
export const TopBarRight = styled.div`
    margin-right:15px;
    display:flex;
    align-items:center;
    gap:8px;
`

export const MainScreen = styled.div`
    display:flex;
    flex-direction:column;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    @media screen and (max-width:541px){
        display:none;
    }

`

export const MainScreenContent = styled.div`  
    margin-left:15px;
    margin-top:60px;

`

export const StartIcon = styled.img`
    height: 15px;
    object-fit:contain;
    cursor:pointer;
`
export const TopBarTabs = styled.p`
cursor:pointer;

`
export const TopBarTabsLink = styled(Link)`
cursor:pointer;
color:white;
text-decoration:none
`

export const MainScreenMobile = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    @media screen and (min-width:541px){
        display:none;
    }
`

export const MobileAppWithNameHolder = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-size:11px;
    gap:8px;
    text-align:center;
`

export const MobileScreenInnerContainer = styled.div`
    display:flex;
    padding:15px;
    gap:25px;
    flex-wrap:wrap;
    padding-top:45px;
    justify-content:center;
    align-items:center;
`

  

const MainAppScreen = ({user}) => {

    let navigate = useNavigate()
    const [filemanagementOpen, setFileManagementOpen] = useState(false)
    const [auditPerceptronOpen, setAuditPerceptronOpen] = useState(false)
    const [kaizenPerceptronOpen, setKaizenPerceptronOpen] = useState(false)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const actions = [
        { icon: <FileCopyIcon />, name: 'Denetim Sorusu Oluştur', onClick:() => setAuditPerceptronOpen(true) },
        { icon: <AutoGraphIcon />, name: 'Kaizen Fikri Oluştur', onClick:() =>setKaizenPerceptronOpen(true) },
        { icon: <AutoStoriesIcon />, name: 'Şikayet Kök Nedeni Oluştur', onClick:() =>setFileManagementOpen(true) },
        { icon: <ConstructionIcon />, name: 'Şikayet DÖF Oluştur', onClick:() =>setFileManagementOpen(true) },
        { icon: <HelpIcon />, name: 'Soru Sor', onClick:() =>setFileManagementOpen(true) },
      ];
  return (
    <MainAppContainer setUrl={WallPaper2}>
        <TopBar>
            <TopBarLeft>
                <NavBarLogo/>
                <TopBarTabs onClick={() => setFileManagementOpen(true)}>Dosyalarım</TopBarTabs>
                <TopBarTabs>Yardım</TopBarTabs>
                {user?.type === "holding_manager" || user?.type === "region_manager" || user?.type === "department_manager"
                ?
                <TopBarTabsLink to="/app/company-settings">Şirket Ayarları</TopBarTabsLink>
                :
                ""
                }
            </TopBarLeft>
            <TopBarRight>
            <NotificationsMenu/>
            {moment().format("llll")}
            </TopBarRight>
        </TopBar>
        <MainScreen>
            <MainScreenContent>
                {user ?
                <h1>Merhaba, {user?.name} {user?.surname}</h1>
                :
                ""
                }
            </MainScreenContent>
            <Backdrop open={open} />
        <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: 'absolute', bottom: 80, right: 60 }}
            icon={<SpeedDialIcon  openIcon={<ElectricBoltIcon />} />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}

        >
            {actions.map((action) => (
            <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.onClick}

            />
            ))}
        </SpeedDial>
        </MainScreen>
        <Navbar>
            <NavbarItemContainer>
                {/* <Tooltip title="Tüm Uygulamalar" placement="top" arrow>
                    <NavbarItem src={AllApps} alt="Tüm Uygulamalar" onClick={() => navigate("/app/all-apps")}/>
                </Tooltip> */}
                <Tooltip title="İç Denetim Uygulaması" placement="top" arrow>
                    <NavbarItem src={AuditIcon} alt="Denetim Uygulaması" onClick={() => navigate("/app/audit-app")}/>
                </Tooltip>
                <Tooltip title="Öneri Sistemi Uygulaması" placement="top" arrow>
                    <NavbarItem src={SuggestionIcon} alt="Öneri Sistemi Uygulaması" onClick={() => navigate("/app/suggestions-app")}/>
                </Tooltip>
                <Tooltip title="Kaizen Yönetimi Uygulaması" placement="top" arrow>
                    <NavbarItem src={KaizenIcon} alt="Kaizen Yönetimi Uygulaması" onClick={() => navigate("/app/kaizen-app/")}/>
                </Tooltip>
                <Tooltip title="Dış Müşteri Şikayetleri" placement="top" arrow>
                    <NavbarItem src={ExternalCustomer} alt="Dış Müşteri Şikayetleri" onClick={() => navigate("/app/external-customer-complaints-app")}/>
                </Tooltip>
                <Tooltip title="İç Müşteri Şikayetleri" placement="top" arrow>
                    <NavbarItem src={InternalCustomer} alt="İç Müşteri Şikayetleri" onClick={() => navigate("/app/internal-customer-complaints-app")}/>
                </Tooltip>
                <Divider style={{height: "70%", background:"rgba(255,255,255,0.1)"}} orientation="vertical"  />
                <Tooltip title="Genel Bilgiler" placement="top" arrow>
                    <NavbarItem src={GeneralInfo} alt="Genel Bilgiler" onClick={() => navigate("/app/general-info-app")}/>
                </Tooltip>
                <Tooltip title="Görev Yönetimi" placement="top" arrow>
                    <NavbarItem src={TaskManagement} alt="Görev Yönetimi" onClick={() => navigate("/app/task-management-app")}/>
                </Tooltip>
                <Tooltip title="İç Mesajlaşma" placement="top" arrow>
                    <NavbarItem src={ChatApp} alt="İç Mesajlaşma" onClick={() => navigate("/app/chat-app")}/>
                </Tooltip>
                {/* <Tooltip title="SMED Uygulaması" placement="top" arrow>
                    <NavbarItem src={Smed} alt="SMED Uygulaması"/>
                </Tooltip>
                <Tooltip title="Polyvalans Uygulaması" placement="top" arrow>
                    <NavbarItem src={Polyvalans} alt="Polyvalans Uygulaması"/>
                </Tooltip> */}
            </NavbarItemContainer>
        </Navbar>
        <MainScreenMobile>
            <MobileScreenInnerContainer>
                <Grid container gap={4} display={"flex"} direction={"row"} justifyContent={"center"}>
                    <Grid item xs={2}>
                        <MobileAppWithNameHolder>
                            <NavbarItem src={AuditIcon} alt="Denetim Uygulaması" onClick={() => navigate("/app/audit-app")}/>
                            <b>Denetim </b>
                        </MobileAppWithNameHolder>
                    </Grid>
                    <Grid item xs={2}>
                        <MobileAppWithNameHolder>
                            <NavbarItem src={SuggestionIcon} alt="Öneri Sistemi Uygulaması" onClick={() => navigate("/app/suggestions-app")}/>
                            <b>Öneri </b>
                        </MobileAppWithNameHolder>
                    </Grid>
                    <Grid item xs={2}>
                        <MobileAppWithNameHolder>
                            <NavbarItem src={KaizenIcon} alt="Kaizen Yönetimi Uygulaması"  onClick={() => navigate("/app/kaizen-app/")}/>
                            <b>Kaizen </b>
                        </MobileAppWithNameHolder>
                    </Grid>
                    <Grid item xs={2}>
                        <MobileAppWithNameHolder>
                            <NavbarItem src={ExternalCustomer} alt="Dış Müşteri Şikayetleri" onClick={() => navigate("/app/external-customer-complaints-app")}/>
                            <b>Dış Müşteri </b>
                        </MobileAppWithNameHolder>
                    </Grid>
                    <Grid item xs={2}>
                        <MobileAppWithNameHolder>
                            <NavbarItem src={InternalCustomer} alt="İç Müşteri Şikayetleri" onClick={() => navigate("/app/internal-customer-complaints-app")}/> 
                            <b>İç Müşteri </b>
                        </MobileAppWithNameHolder>
                    </Grid>
                    <Grid item xs={2}>
                        <MobileAppWithNameHolder>
                            <NavbarItem src={GeneralInfo} alt="Genel Bilgiler" onClick={() => navigate("/app/general-info-app")}/>
                            <b>Genel Bilgiler </b>
                        </MobileAppWithNameHolder>
                    </Grid>
                    <Grid item xs={2}>
                        <MobileAppWithNameHolder>
                            <NavbarItem src={SettingsIcon} alt="Ayarlar" onClick={() => navigate("/app/company-settings")}/>
                            <b> Şirket Ayarları </b>
                        </MobileAppWithNameHolder>
                    </Grid>
                    <Grid item xs={2}>
                        <MobileAppWithNameHolder>
                            <NavbarItem src={TaskManagement} alt="Ayarlar"  onClick={() => navigate("/app/task-management-app")}/>
                            <b> Süreç Yönetimi </b>
                        </MobileAppWithNameHolder>
                    </Grid>
                    {/* <Grid item xs={2}>
                        <MobileAppWithNameHolder>
                            <NavbarItem src={ChatApp} alt="Ayarlar"  onClick={() => navigate("/app/chat-app")}/>
                            <b> İç Mesajlaşma </b>
                        </MobileAppWithNameHolder>
                    </Grid> */}
                </Grid>
                {/* <MobileAppWithNameHolder>
                    <NavbarItem src={Smed} alt="SMED Uygulaması"/>
                    <b>SMED </b>
                </MobileAppWithNameHolder>
                <MobileAppWithNameHolder>
                    <NavbarItem src={Polyvalans} alt="Polyvalans Uygulaması"/>
                    <b>Polyvalans </b>
                </MobileAppWithNameHolder> */}
               
            </MobileScreenInnerContainer>
        </MainScreenMobile>
        <NavbarMobile>
            <NavbarItemContainerMobile>
                <Tooltip title="İç Denetim Uygulaması" placement="top" arrow>
                    <NavbarItem src={AuditIcon} alt="Denetim Uygulaması" onClick={() => navigate("/app/audit-app")}/>
                </Tooltip>
                <Tooltip title="Öneri Sistemi Uygulaması" placement="top" arrow onClick={() => navigate("/app/suggestions-app")}>
                    <NavbarItem src={SuggestionIcon} alt="Öneri Sistemi Uygulaması"/>
                </Tooltip>
                <Tooltip title="Kaizen Yönetimi Uygulaması" placement="top" arrow>
                    <NavbarItem src={KaizenIcon} alt="Kaizen Yönetimi Uygulaması"  onClick={() => navigate("/app/kaizen-app/")}/>
                </Tooltip>
                <Tooltip title="Ayarlar" placement="top" arrow onClick={() => navigate("/app/company-settings")}>
                    <NavbarItem src={SettingsIcon} alt="Ayarlar"/>
                </Tooltip>
            </NavbarItemContainerMobile>
        </NavbarMobile>
        <FileManagement open={filemanagementOpen} setOpen={setFileManagementOpen} />
       <CreateAuditQuestion open={auditPerceptronOpen} setOpen={setAuditPerceptronOpen}/>
       <CreateKaizen open={kaizenPerceptronOpen} setOpen={setKaizenPerceptronOpen}/>
    </MainAppContainer>
  )
}
const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps) (MainAppScreen)