import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AutoCompleteV2 from '../../../Assets/AutoCompleteV2'
import DialogTitleV2 from '../../../Assets/DialogTitle'
import ErrorSnackBar from '../../../Subcomponents/ErrorSnackBar'
import SuccessSnackBar from '../../../Subcomponents/SuccessSnackBar'
import { EditButton } from '../../CompanySettings/MainSettings'

const AddCommitteeSuggestion = ({open, setOpen, getInfoBack}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const [buttonStart, setButtonStart] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [employees, setEmployees] = useState([])
    const [member, setMember] = useState(null)
    const [is_manager, setIsManager] = useState(false)
    const [region, setRegion] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const handleChangeSelectedRegion = (e) =>{
        setSelectedRegion(e.target.value)
        setMember("")
    }
    async function getEmployees(){
        try{
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/get_by_level/?level=${"region"}&level_id=${selectedRegion}`,  axiosConfig);
          setEmployees(res.data)
        }
        catch{
    
        }
      }
 
    async function handleSubmitMember (){
        setButtonStart(true)
        const body = JSON.stringify({member_id: member?.id, region_id:selectedRegion, is_manager:is_manager})
        try{
            await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions/committee-member-create/`,  body,axiosConfig);
            setButtonStart(false)
            setSuccessMsg(true)
            setOpen(false)
            getInfoBack()
        }
        catch{
            setButtonStart(false)
            setErrorMsg(true)
        }
    }

   
    async function getRegionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, config);
            setRegion(res.data)
        } catch (err) {
        }
      }

    useEffect(() => {
        if(open){
            getRegionInfo()
        }
    },[open])
    useEffect(() => {
        if(selectedRegion){
            getEmployees()
        }
    },[selectedRegion])

  return (
    <>
    <SuccessSnackBar open={successMsg} setOpen={setSuccessMsg} data={"İşlem başarıyla tamamlandı"}/>
    <ErrorSnackBar open={errorMsg} setOpen={setErrorMsg} data={"Bu kullanıcı mevcut."}/>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Komüte Üyesi Ekle"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            <Grid item xs={12} mt={1}>
                    <FormControl
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    >
                        <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Bölge Seçin</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRegion}
                            label="Bölge Seçin"
                            onChange={(e) => handleChangeSelectedRegion(e)}
                            style={{color:"#fff"}}
                        >
                        {region?.map((reg, index) => (
                        <MenuItem value={reg?.id} key={reg?.id} >{reg.name}</MenuItem>
                        ))
                        } 
                    </Select>
                </FormControl>
            </Grid>
            {selectedRegion ? 
            <>
                <Grid xs={12} mt={2}>
                    <AutoCompleteV2 label="Komüte Üyesi" options={employees} value={member} setValue={setMember} user/>
                </Grid>
                <Grid xs={12} mt={2}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={is_manager} onChange={() => setIsManager(!is_manager)} />} label="Komüte Yöneticisi" />
                    </FormGroup>
                </Grid>
            </>
            :""}
            
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {member && selectedRegion ?
            !buttonStart ? <EditButton onClick={handleSubmitMember} active>Kaydet</EditButton> : <EditButton>Kaydediliyor...</EditButton>
            :
            <EditButton>Kaydet</EditButton>
            }
        </DialogActions>
    </Dialog>
    </>
  )
}

export default AddCommitteeSuggestion