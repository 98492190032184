import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import SuccessSnackBar from '../../../Subcomponents/SuccessSnackBar'
import DialogTitleV2 from '../../../Assets/DialogTitle'
import { EditButton } from '../../CompanySettings/MainSettings'
import { NotificationTabs, NotificationsContainer, NotificationsWrapper } from '../../../Subcomponents/OtherDialogs/NotificationsDialog'
import TextFieldV2 from '../../../Assets/TextFieldV2'
import {MutatingDots} from "react-loader-spinner"

const CreateKaizen = ({open, setOpen, getInfoBack}) => {
    const axiosConfigOpenAI = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API}`,
        }
        };
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [tab, setTab] = useState("new")
    const [buttonStart, setButtonStart] = useState(false)
    const [perceptronFetching, setPerceptronFetching] = useState(false)
    const [company_type, setCompanyType] = useState("")
    const [audit_type, setAuditType] = useState("")
    const [number_of_question, setNumberOfQuestion] = useState("")
    const [results, setResults] = useState(null)
    const [successMsg, setSuccessMsg] = useState(false)
    const [data, setData] = useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/perceptron/audit/`,  axiosConfig);
            setData(res.data)
        }
        catch{

        }
    }
    async function PostPerceptron (e){
        const body = JSON.stringify({audit_type:audit_type, questions:e})
        try{
            await axios.post(`${process.env.REACT_APP_API_URL}/api/perceptron/audit/`, body, axiosConfig);
            getInfo()
        }
        catch{

            }
        }
    async function PerceptronGet(){
        setPerceptronFetching(true)
        setButtonStart(true)
        const body = JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [{"role": "user", "content": `Bana ${company_type} üreticisi bir şirketinde ${audit_type} sorunu için olası kaizen önerileri oluşturur musun?`}]
        })
        try{
          const res = await axios.post(`${process.env.REACT_APP_OPENAI_API_URL}`,  body, axiosConfigOpenAI);
          setResults(res.data?.choices ? res?.data?.choices[0].message?.content : "")
          //PostPerceptron(res.data?.choices ? res?.data?.choices[0].message?.content : "Error")
          setPerceptronFetching(false)
          setButtonStart(false)

        }
        catch{
          setPerceptronFetching(false)
          setButtonStart(false)
    
        }
      }
   
    useEffect(() => {getInfo()},[])
  return (
    <>
    <SuccessSnackBar open={successMsg} setOpen={setSuccessMsg} data={"İşlem başarıyla tamamlandı"}/>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Kaizen Oluştur"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            <NotificationsContainer >
                <NotificationsWrapper>
                    <NotificationTabs width={"180px"} active={tab === "new"} onClick={() => setTab("new")} >
                        Kaizen Oluştur
                    </NotificationTabs>
                    <NotificationTabs width={"180px"} active={tab === "old"}  onClick={() => setTab("old")} >
                        Oluşturulan Kaizenler
                    </NotificationTabs>
                </NotificationsWrapper>
            </NotificationsContainer>
            {tab === "new" ?
            <>
            <TextFieldV2 label="Kaizen Sorunu" value={audit_type} setValue={setAuditType} />
            <TextFieldV2 label="Şirket Türü" value={company_type} setValue={setCompanyType} />
            {results ? 
            <div style={{background:"#3d3d3d", height:"150px", overflow:"auto", padding:"15px",whiteSpace: "pre-line"}}>
                {perceptronFetching ? 
                <MutatingDots/>
                :
                <text>{results}</text>}
            </div>
            :""}
            </>
            :
            data?.map((e) => (
                <p>{e.audit_type}</p>
            ))
            }
            
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={PerceptronGet} active>Oluştur</EditButton> : <EditButton>Oluşturuluyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default CreateKaizen