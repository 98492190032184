import styled from "styled-components"


export const ActivitiesNav = styled.div`
    width: 100%;
    margin-top:10px;
    border-bottom: 1px solid #A3A2A1;
    padding-bottom: 10px;
`
export const ActivitiesNavWrapper = styled.div`
    color: white;
    display:flex;
    gap:15px;
    padding-left:20px;
`

export const ActivitiesMainContainer = styled.div`
    width: 100%;
    display:flex;
`

export const ActivitiesMainWrapper = styled.div`
    color:white;
    display:flex;
    flex-direction:column;
    gap:15px;
    width:100%;
`

export const DatePickerContainer = styled.div`
  display:flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #a4a3a2;
  width:100%;
  justify-content:space-between;
  align-items:center;
  @media screen and (max-width:811px){
    flex-direction:column;
    gap:15px;
    margin-top: 15px;

  }
`
export const DatePickerContainer2 = styled.div`
  display:flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #a4a3a2;
  width:98%;
  justify-content:space-between;
  align-items:center;
  margin: 15px 0 0 10px;
  @media screen and (max-width:811px){
    flex-direction:column;
    gap:15px;
    margin: 15px 0 15px 0;

  }
`

export const DepartmentContainer  = styled.div`
    display:flex;
    border-radius:10px;
    flex-direction:column;
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 50px;
    padding:25px;
    justify-content:space-between;
    gap:15px;
`

export const DepartmentHeadingContainer = styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;
`

export const SubDepartmentContainer  = styled.div`
  display:flex;
  flex-direction:column;
`

export const SubDepartmentTestContainer = styled.div`
  border-bottom: 15px;
  gap:15px;
  display:flex;
  flex-direction:column;
`

export const FilterRow = styled.div`
  width: 100%;
  display:flex;
  align-items:flex-start;
  justify-content:flex-end;
  height:50px;
`
export const FilterContainer = styled.div`
  display:flex;
  align-items:center;
  gap:5px;
`