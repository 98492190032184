import { Avatar, Chip, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize, Tooltip } from '@mui/material'
import { green } from '@mui/material/colors'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { EmployeeCardImage, EmployeeInfoCardContainer, EmployeeInfoCardWrapper, EmployeeInfoMain, FileHolder, FileName, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer } from '../../../../Assets/GeneralDesignElements'
import { StyledBadge } from '../../../../Assets/StyledBadge'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../../CompanySettings/MainSettings'
import { styled as styledmui} from '@mui/material/styles'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import getFileIcon from '../../../../Assets/GetFileIconsFunction'
import AutoCompleteV2 from '../../../../Assets/AutoCompleteV2'
import { useNavigate } from 'react-router-dom'



const Input = styledmui('input')({
    display: 'none',
});
const CreateKaizen = ({user}) => {
  let navigate = useNavigate()
  
  // Get Consts
  const [region, setRegion] = useState(null)
  const [userList, setUserList] = useState([])
  
  // Submitable Consts
  const [heading, setHeading] = useState(null)
  const [problem_statement, setProblemStatement] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([])
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null)
  
  // Internal Consts
  const departmentOptions = region?.filter(e => e.id === selectedRegion?.id)
  const [files, setFiles] = useState([]);

  // Internal Functions
  const handleChangeSelectedRegion = (e) => {
    setSelectedRegion(e.target.value)
    setSelectedDepartment(null)
  }
  const handleChangeSelectedDepartment = (e) => {
    setSelectedDepartment(e.target.value)
  }
  const handleFileChange = (event) => {
    const newFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
      newFiles.push(event.target.files[i]);
    }
    setFiles((prevFiles) => [...newFiles, ...prevFiles]);
  };
  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(indexToRemove, 1);
      return newFiles;
    });
  };
  function getFileExtension(filename) {
    return filename.split('.').pop();
  }

  // RF Consts
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
      'Accept': 'application/json'
    }
  };

  
  // Post Consts
  const [buttonClick, setButtonClick] = useState(false)
  
  // POST RF Function
  async function submitData(){
    setButtonClick(true)
    let form_data = new FormData();
    for (let i = 0; i < files.length; i++) {
        form_data.append(`file${i + 1}`, files[i]);
    }
    heading ? form_data.append('heading', heading) : form_data.append('heading', "")
    problem_statement ? form_data.append('problem_statement', problem_statement) : form_data.append('problem_statement', "")
    selectedRegion ? form_data.append('region_id', selectedRegion?.id) : form_data.append('region_id', "")
    selectedDepartment ? form_data.append('section_id', selectedDepartment) : form_data.append('department_id', "")
    form_data.append('team_members', selectedTeamMembers?.map((e) => e.id))
    form_data.append('responsible_id',selectedTeamLeader?.id)

    const config = {
      headers: {
          'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
      }
  };
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/kaizen/create/`, form_data, config);
        navigate(`/app/kaizen-app/activities`)
    setButtonClick(false)
  } catch (err) {
    setButtonClick(false)
        
      }
  }

  // Get RF Function
  async function getRegionInfo () {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, axiosConfig);
        setRegion(res.data)
    } catch (err) {
      
    }
  }
  async function getUserList (){
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/create/`,  axiosConfig);
        setUserList(res.data)
    }
    catch{
  
    }
  }
  // Onload Functions
  useEffect(() => {
    getRegionInfo();
    getUserList();
  },[])

  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <InfoBar jc={"end"}>
          {buttonClick ?
            <EditButton>
            Kaydedilyor
            </EditButton>
            : 
            <EditButton active onClick={submitData}>
              Kaydet
            </EditButton>
          }
        </InfoBar>
        <InfoBar around top>
          <EmployeeInfoCardContainer>
            <EmployeeInfoCardWrapper>
              <EmployeeCardImage/>
              <EmployeeInfoMain>
                <b>{user?.name + " " + user?.surname}</b>
                {user?.type === "holding_manager"
                ?
                <Chip label="Şirket Yöneticisi" color="success"/>
                :
                user?.type === "holding_user"
                ?
                <Chip label="Şirket Çalışanı"  color="warning"/>
                :
                user?.type === "region_manager"
                ?
                <Chip label="Bölge Yöneticisi"  color="primary"/>
                :
                user?.type === "department_manager"
                ?
                <Chip label="Departman Yöneticisi"  color="secondary"/>
                :
                <Chip label="Bölge Çalışanı"  color="error"/> }
                <p>Email: {user?.email}</p>
                </EmployeeInfoMain>
              </EmployeeInfoCardWrapper>
          </EmployeeInfoCardContainer>
          <HorizantalTimeLineContainer>
              <HorizantalTimelineCardWrapper>
                <Tooltip title="Kaizen Başlangıcı" arrow>
                  <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot">
                    <Avatar
                    sx={{ bgcolor: green[400] }}
                    >
                      B
                    </Avatar>
                  </StyledBadge>
                  </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Süreç Yönetimi" arrow>
                  <Avatar>
                    SY
                  </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Komüte Değerlendirmesi" arrow>
                  <Avatar>
                    KD
                  </Avatar>
                </Tooltip>
               
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Tamamlandı" arrow>
                <Avatar>
                  T
                </Avatar>
                </Tooltip>
              </HorizantalTimelineCardWrapper>
            </HorizantalTimeLineContainer>
        </InfoBar>
        <Grid item xs={12} md={4}>
              <FormControl
                sx={{
                width:"100%",
                color:"#fff",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'white',
                        },
                        '&:hover fieldset': {
                        borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: 'white',
                        },}
                    }}
                >
                    <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Bölge Seçin</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedRegion}
                        label="Bölge Seçin"
                        onChange={handleChangeSelectedRegion}
                        style={{color:"#fff"}}
                    >
                    {region?.map((reg, index) => (
                    <MenuItem value={reg} key={reg?.id} >{reg.name}</MenuItem>
                    ))
                    } 
                </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
          <FormControl
                  sx={{
                  width:"100%",
                  color:"#fff",
                  '& label.Mui-focused': { color: 'white',},
                  '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                          borderColor: 'white',
                          },
                          '&:hover fieldset': {
                          borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                          borderColor: 'white',
                          },}
                      }}
                  >
                      <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Departman Seçin</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedDepartment}
                          label="Departman Seçin"
                          disabled={selectedRegion === null}
                          onChange={handleChangeSelectedDepartment}
                          style={{color:"#fff"}}
                      >
                         
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                <MenuItem value={child?.id}>{child?.name}</MenuItem>
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        <MenuItem value={child2?.id}>{child?.name} - {child2?.name}</MenuItem>
                                    ))
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        child2?.children?.map(child3 => (
                                            <MenuItem value={child3?.id}>{child?.name} - {child2?.name} - {child3?.name}</MenuItem>
                                        ))
                                    ))
                            ))))}
                  </Select>
              </FormControl>
          </Grid>
          <Divider style={{width:"100%", marginTop:"15px"}}/>

        <Grid container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item container  xs={12} md={6}>
          <h1>Genel Bilgiler</h1>
          <Grid item xs={12} md={12}>
            <TextFieldV2 label={"Kaizen Tanımı"} value={heading} setValue={setHeading} />
          </Grid>
          <Grid item xs={12} md={12}>
            <label>Kaizen Detaylı Açıklaması</label>
            <TextareaAutosize style={{width:"98%", background:"none", border:"1px solid white", padding:"5px", borderRadius:"8px",color:"white"}} 
            value={problem_statement}
            onChange={(e) =>setProblemStatement(e.target.value)}
            minRows={15}
            />
          </Grid>
          <Grid item xs={12} mt={2} mb={2} align="right">     
            <label htmlFor="contained-button">
            <Input accept="*/*" id="contained-button" multiple   type="file" onChange={handleFileChange} />
            <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={"info"}>
                  Ek döküman ekle &nbsp; <FileUploadIcon/> 
            </Button>
            </label>
          </Grid>   
          {files?.length > 0 ?
            <div style={{display:"flex", flexDirection:"column", marginLeft:"15px"}}>
            <h5>Dosyalar</h5>
            <TaskImageHolder>
            {files?.map((e,index) => (
              <Tooltip title={e?.name + "Listeden çıkarmak için tıklayın"} arrow>
                  <a onClick={() => handleRemoveFile(index)} style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                <FileHolder>
                  {getFileIcon(getFileExtension(e?.name))}
                  <FileName>{e?.name}</FileName>
                </FileHolder>
                </a>
              </Tooltip>
                ))}
            </TaskImageHolder>
            </div>
          :
          ""}
        </Grid>
          <Grid item  xs={12} md={6}>
            <h1>Ekip Bilgiler</h1>
            <Grid item xs={12}>
              <AutoCompleteV2 label={"Ekip Lideri"} user value={selectedTeamLeader} setValue={setSelectedTeamLeader} options={userList ? userList : []} />
            </Grid>
            <Grid item xs={12}>
              <AutoCompleteV2 label={"Ekip Üyeleri"} user multiple value={selectedTeamMembers} setValue={setSelectedTeamMembers} options={userList ? userList : []} />
            </Grid>
          </Grid>
        </Grid>
        </Grid>
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps) (CreateKaizen)