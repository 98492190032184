import axios from 'axios';
import React, { useEffect, useState } from 'react'
import TreeComponent from './ProcessTreeViewV2';

const ProcessTreeView = () => {
  const [data,setData] = useState([])
   const axiosConfigJson = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
    }
  };
  async function getData (e){
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/process/`, axiosConfigJson)
        setData(res.data)
    }
    catch{

    }
  }
  useEffect(() =>{getData()},[])
  return (
    <div>
      <h1>Süreç Tablosu</h1>
      <TreeComponent data={data}/>
    </div>
  )
}

export default ProcessTreeView