import { Avatar } from "@mui/material";
import { flexbox } from "@mui/system";
import styled,{ keyframes } from "styled-components";


const backgroundAnimation = keyframes`
0%{background-position:0% 94%}
50%{background-position:100% 7%}
100%{background-position:0% 94%}
` 



export const CheckListContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap:0px;

`
export const CheckListItem = styled.p`
    font-size:12px;
    fonte-weight:400;
    line-height:3px;
`


export const PageWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://4kwallpapers.com/images/wallpapers/macos-ventura-macos-13-macos-2022-stock-dark-mode-5k-retina-5120x2880-8133.jpg");
    // background: linear-gradient(50deg, rgba(2,0,36,1) 0%, rgba(170,137,254,1) 0%, rgba(167,242,242,1) 100%);
    // background-size: 200% 200%;
    // -webkit-animation: ${backgroundAnimation} 25s ease infinite;
    // -moz-animation: ${backgroundAnimation} 25s ease infinite;
    // animation: ${backgroundAnimation} 25s ease infinite;
    width:100%;
`
export const PageContainerFlexCenter = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    flex-direction:column;
`

export const AppContainer  = styled.div`
    display:flex;
    width:100vw;
    background:#1E1F21;
    min-height:100vh;
`

export const MainAppContainer = styled.div`
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${({setUrl}) => setUrl ?`linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5) ), url(${setUrl})` : 'url("https://4kwallpapers.com/images/wallpapers/macos-ventura-macos-13-macos-2022-stock-dark-mode-5k-retina-5120x2880-8133.jpg")'} ;
    // background-image: linear-gradient(-45deg, #35C3F3 0%, #8b9fe8 20%, #e681d8 39%, #ffa9a4 76%, #FED2CE 100%);
    // background-size: 200% 200%;
    // -webkit-animation: ${backgroundAnimation} 60s ease infinite;
    // -moz-animation: ${backgroundAnimation} 60s ease infinite;
    // animation: ${backgroundAnimation} 60s ease infinite;
    min-height: 100vh;
`
export const ApplicationContainer = styled.div`
    padding: 25px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`
export const ApplicationWrapper = styled.div`
    max-width:1200px;
    align-items:center;
    justify-content:center;
    width: 100%;
    text-align: -webkit-center;
`

export const LogoHolder = styled.img`
    height: 100px;
    object-fit:contain;
`

export const BeforeAfterLargeImage = styled.img`
    height:450px;
    object-fit:contain;
    border: 1px solid red;
`
export const BeforeAfterLargeImageSuccess = styled.img`
    height:450px;
    object-fit:contain;
    border: 1px solid green;
`
export const BeforeAfterLargeMedImageSuccess = styled.img`
    height:300px;
    object-fit:contain;
    border: 1px solid green;
`
export const BeforeAfterMediumImage = styled.img`
    max-height:150px;
    object-fit:contain;
    border: 1px solid red;
`
export const BeforeAfterMediumImageSuccess = styled.img`
    max-height:150px;
    object-fit:contain;
    border: 1px solid green;
`

export const RowButtonRight = styled.div`
  width: 100%;
  display:flex;
  justify-content:flex-end;
  margin-bottom: 15px;
  gap:10px;
`


// Employee Info Card

export const EmployeeInfoCardContainer = styled.div`
    display:flex;
    width:450px;
    background: rgba(255,255,255,0.1);
    border-radius:10px;
    @media screen and (max-width:1175px){
        width:320px
    }
`

export const EmployeeInfoCardWrapper = styled.div`
    display:flex;
    padding: 15px;
    gap:15px;
`

export const EmployeeCardImage  = styled(Avatar)`

`
export const EmployeeInfoMain = styled.div`
    display:flex;
    flex-direction:column;
    gap:8px;
`

// Horizantal Timeline Card

export const HorizantalTimeLineContainer = styled.div`
    display:flex;
    width:450px;
    background: rgba(255,255,255,0.1);
    border-radius:10px;
    justify-content:center;
    @media screen and (max-width:1175px){
        width:320px
    }
`
export const HorizantalTimelineCardWrapper = styled.div`
    display:flex;
    padding: 15px;
    gap:10px;
    align-items:center;
    justify-contant:start;
`



// Chatbox

export const ChatBoxContainer = styled.div`
    display:flex;
   width:450px;
    height:550px;
    background: rgba(255,255,255,0.1);
    border-radius:10px;
    justify-content:center;
     @media screen and (max-width:1175px){
        width:320px
    }
   
`

// Files
export const FileHolder = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap:5px;
    width:50px;
    cursor:pointer;
`
export const FileThumbnail = styled.img`
    height: ${({size}) => size ==="small" ? "25px" :"50px"};
    width: ${({size}) => size ==="small" ? "25px" :"50px"};
`
export const FileName = styled.span`
    font-size:13px;
    text-align:center;
    width:50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`



// Comment section

export const CommentOutline = styled.div`
    width: 100%;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:10px;
`

export const PostedCommentContainer = styled.div`
    width: 100%;
    display:flex;
    gap:10px;
    justify-content:flex-start;
    
`
export const PostedCommentInfo = styled.div`
    display:flex;
    flex-direction:column;
    gap:8px;
`

// General Info Box Suggestion

export const SuggestionInfoBoxContainer = styled.div`
        display:flex;
        width:450px;
        background: rgba(255,255,255,0.1);
        border-radius:10px;
        justify-content:center;
        @media screen and (max-width:1175px){
            width:320px
        }

`
export const SuggestionInfoBoxWrapper = styled.div`
    padding: 15px;
    width:100%;
    display:flex;
    justify-content:space-between;
`

export const SuggestionInfoBoxSection = styled.div`
    display:flex;
    flex-direction:column;
`


// This part is for task management home page containers


export const TaskFollowContainer = styled.div`
    width: 100%;
    min-width:320px;
    min-height:400px;
    background:#2a2b2d;
    border-radius: 10px;
    transition: .25s ease-in-out;
    border: 1px solid rgba(255,255,255,0.1);
    &:hover{
        border: 1px solid rgba(255,255,255,0.4);
    }
`
export const TaskFollowWrapper = styled.div`
    padding:20px;
`

export const TaskFollowHeader  = styled.div`
    display:flex;
    align-items:center;
    gap:15px;
    color:white;
    margin-bottom:15px;
    justify-content:space-between;
`

export const TaskFollowFooter = styled.div`
    display:flex;
    padding: 0 20px;
    font-size:12px;
    color:#a2a0a2;
    cursor:pointer;
`

export const TaskFollowHeaderContainer = styled.div`
    display:flex;
    gap:15px;
    align-items:center;
`

export const TaskGraphHolder = styled.div`
    width:100%;
    height:360px;
`