import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { MainSettingsContainer, MainSettingsWrapper } from '../../CompanySettings/MainSettings'
import AuditTypesTable from './Table/AuditTypesTable'

const AuditAppSettings = () => {
  const [getAudits, auditData] = useOutletContext()

  useEffect(() => {getAudits()},[])

  return (
    <MainSettingsContainer>
      <MainSettingsWrapper className='container'>
        <AuditTypesTable data={auditData} getInfoBack={getAudits}/>
      </MainSettingsWrapper>
    </MainSettingsContainer>
  )
}

export default AuditAppSettings