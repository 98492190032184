import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../Assets/DialogTitle'
import { EditButton } from '../../../../Pages/CompanySettings/MainSettings'
import SuccessSnackBar from '../../../SuccessSnackBar'

const DeleteFilesDialog = ({open, setOpen, getSingleTaskInfoWithId,uploadedFiles}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [data, setData] = useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function deleteFiles (e){
       setButtonStart(true)
        try{
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/filemanagement/delete/${e}/`,  axiosConfig);
            setButtonStart(false)
            setSuccessMsg(true)
            getSingleTaskInfoWithId()
        }
        catch{
            setButtonStart(false)

        }
    }
    console.log(uploadedFiles,"upf")
  return (
    <>
    <SuccessSnackBar open={successMsg} setOpen={setSuccessMsg} data={"İşlem başarıyla tamamlandı"}/>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "800px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Dosyaları Sil"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            <Table style={{width:"100%"}}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Dosya Adı
                        </TableCell>
                        <TableCell>
                            Sil
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {uploadedFiles?.map((e) => (
                    <TableRow>
                        <TableCell>
                            {e?.file_name}
                        </TableCell>
                        <TableCell>
                            {!buttonStart ? <EditButton onClick={() => deleteFiles(e?.id)} active style={{background:"red"}}>Sil</EditButton> : <EditButton>Siliniyor...</EditButton>}
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
            
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default DeleteFilesDialog