import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import Tooltip from '@mui/material/Tooltip';
import FactoryIcon from '@mui/icons-material/Factory';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { connect } from 'react-redux';
import AddNewGembaWalk from './ActionsDialogs/AddNewGembaWalk';
import AddNewWorkshopV2 from './ActionsDialogs/AddNewWorkshopv2';
import AddPeriodicAudit from './ActionsDialogs/AddPeriodicAudit';
import AddNearbyFindingV2 from './ActionsDialogs/AddNearbyFindingV2';
import AddNewAudit from './ActionsDialogs/AddNewAudit';

const ActionPopover = ({user, subdeps, audits}) => {
  const [openNearbyDailog, setOpenNearbyDialog] = useState(false)
  const [openNewAuditDialog, setNewAuditDialog] = useState(false)
  const [openNewGembaWalkDialog, setNewGembaWalkDialog] = useState(false)
  const [openNewWorkshop, setNewWorkshopDialog] = useState(false)
  const [automationDialog, setAutomationDialog] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Faaliyet Ekle">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 28, height: 28 }} style={{background:"#5dc942"}}> +</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            fontFamily:"apple",
            color:"white",
            background:"rgba(255,255,255,0.3)",
            backdropFilter: "blur( 20px )",
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       
        {user?.type === "holding_manager" || user?.type === "region_manager" || user?.type === "department_manager" ? 
        <>
        <MenuItem onClick={() => setOpenNearbyDialog(true)} >
          <ListItemIcon>
            <CameraEnhanceIcon fontSize="small" />
          </ListItemIcon>
          Anlık Tespit Ekle
        </MenuItem>
        <MenuItem onClick={() => setNewGembaWalkDialog(true)} > 
          <ListItemIcon>
            <FactoryIcon fontSize="small" />
          </ListItemIcon>
          Saha Turu Başlat
        </MenuItem>
        <MenuItem onClick={()=>setNewWorkshopDialog(true)} >
          <ListItemIcon>
            <HomeRepairServiceIcon fontSize="small" />
          </ListItemIcon>
          Çalıştay Başlat
        </MenuItem>
        <MenuItem onClick={() => setNewAuditDialog(true)} >
          <ListItemIcon>
            <CleaningServicesIcon fontSize="small" />
          </ListItemIcon>
          Denetim Oluştur
        </MenuItem>
       
        <MenuItem  onClick={() => setAutomationDialog(true)} >
          <ListItemIcon>
            <EventIcon fontSize="small"/>
          </ListItemIcon>
          Periyodik Denetim Oluştur
        </MenuItem>
        </>
        :
        <MenuItem onClick={() => setOpenNearbyDialog(true)} >
        <ListItemIcon>
          <CameraEnhanceIcon fontSize="small" />
        </ListItemIcon>
        Anlık Tespit Ekle
        </MenuItem>
       }
      </Menu>
      <AddNearbyFindingV2 open={openNearbyDailog} setOpen={setOpenNearbyDialog} subdeps={subdeps} audits={audits}/>
      <AddNewAudit open={openNewAuditDialog} setOpen={setNewAuditDialog} subdeps={subdeps}/>
      <AddNewGembaWalk open={openNewGembaWalkDialog} setOpen={setNewGembaWalkDialog}/>
      <AddNewWorkshopV2 open={openNewWorkshop}  setOpen={setNewWorkshopDialog} subdeps={subdeps}/>
      <AddPeriodicAudit open={automationDialog}  setOpen={setAutomationDialog}/>
    </React.Fragment>
  );
}


const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps)(ActionPopover)