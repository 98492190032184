import { Alert, Avatar, Divider, Grid, Tooltip } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { CommentOutline, EmployeeCardImage, EmployeeInfoCardContainer, EmployeeInfoCardWrapper, EmployeeInfoMain, FileHolder, FileName, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer, PostedCommentContainer, PostedCommentInfo } from '../../../../Assets/GeneralDesignElements'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { StyledBadge } from '../../../../Assets/StyledBadge'
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import getFileIcon from '../../../../Assets/GetFileIconsFunction'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import { EditButton } from '../../../CompanySettings/MainSettings'
import TaskManagementMainBoard from "../../../../Subcomponents/TaskManagementViews/ListView"
import moment from 'moment'
import AddTaskDialog from './Dialogs/AddTaskDialog'
import CompleteSuggestionDialog from './Dialogs/CompleteDialog'
import Confetti from 'react-confetti'

const SuggestionSolutionDetails = () => {
  let width = window.innerWidth
  let height = document.body.scrollHeight
  const [confetiiOpen, setConfetiiOpen] = useState(false)
  useEffect(() => {
    if (confetiiOpen){
      const timeout = setTimeout(() => {
        setConfetiiOpen(false);
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [confetiiOpen]);
  const [comment, setComment] = useState(null)
  const [buttonClick, setButtonClick] = useState(false)
  const [data, setData] = useState(null)
  const [completeDialogOpen, setCompleteDialogOpen] = useState(false)
  const [addTaskDialogOpen, setAddTaskDialogOpen] = useState(false)
  const [tasks, setTasks] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [rowCount, setRowCount] = useState(null)
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    })

  let params = useParams()
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
  };
  async function getTasks (){
    setIsFetching(true)
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/task/get-create/?page=${pagination?.pageIndex+1}&id=${params?.id}`,  axiosConfig)
      setTasks(res.data.results)
      setRowCount(res.data.count)
      setIsFetching(false)

    }
    catch{
        setIsFetching(false)

    }
  }
  async function getInfo (){
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/create/${params?.id}/`,  axiosConfig)
      setData(res.data)

    }
    catch{

    }
  }
  async function postComment (){
    if (comment && comment?.length > 0) {
      setButtonClick(true)
      const body = JSON.stringify({suggestion_id: params?.id, comment})
      try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions/create/comment/`, body, axiosConfig)
        getInfo()
        setButtonClick(false)
        setComment("")
      }
      catch{
        setButtonClick(false)
      }
    }
    else{
    }
   
  }
  useEffect(() => {getInfo();getTasks()},[params?.id])
  return (
    <ActivitiesMainContainer>
      {confetiiOpen ?
      <Confetti
        width={width}
        height={height}
      />:""}
      <ActivitiesMainWrapper>
        <InfoBar>
            <PostedCommentContainer>
              <Avatar/>
              <PostedCommentInfo>
              <span> <b style={{fontSize:"13px"}}>{data?.process_manager?.name} {data?.process_manager?.surname}</b></span>
              </PostedCommentInfo>
            </PostedCommentContainer>
            <div style={{display:"flex", gap:"15px"}}>
              <EditButton active onClick={() => setAddTaskDialogOpen(true)}>
                Görev Ekle
              </EditButton>
              <EditButton active style={{width:"125px"}} onClick={() => setCompleteDialogOpen(true)}>
                Tamamla
              </EditButton>
            </div>
        </InfoBar>
        <InfoBar around top>
        <EmployeeInfoCardContainer>
            <EmployeeInfoCardWrapper>
                <EmployeeCardImage/>
                <EmployeeInfoMain>
                    <b>{data?.suggester?.name} {data?.suggester?.surname}</b>
                    <b>Şirket Yöneticisi</b>
                    <p>{data?.suggester?.email}</p>
                </EmployeeInfoMain>
            </EmployeeInfoCardWrapper>
        </EmployeeInfoCardContainer>
        <HorizantalTimeLineContainer>
            <HorizantalTimelineCardWrapper>
                <Tooltip title="Öneri Başlangıcı" arrow>
                    <Avatar
                    sx={{ bgcolor: green[600] }}
                    >
                      <DoneOutlineIcon/>
                    </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title={`Hat Yöneticisi (${data?.process_manager?.name + "  " + data?.process_manager?.surname}) Değerlendirmesi`} arrow>
                    <Avatar
                    sx={{ bgcolor: green[600] }}
                    >
                      <DoneOutlineIcon/>
                    </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Komite Değerlendirmesi" arrow>
                    <Avatar
                    sx={{ bgcolor: green[600] }}
                    >
                      <DoneOutlineIcon/>
                    </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Tamamlandı" arrow>
                    <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant={data?.state ==="CONTINUE" ?"dot":""}>
                        <Avatar
                        sx={{ bgcolor: green[600] }}
                        >
                        {data?.state ==="CONTINUE" ?
                        "T"
                        :
                        <DoneOutlineIcon/>
                        }
                        </Avatar>
                    </StyledBadge>
                </Tooltip>
            </HorizantalTimelineCardWrapper>
        </HorizantalTimeLineContainer>
        </InfoBar>
        <Grid container direction={"row"} justifyContent={"space-between"}>
            <Grid item container md={6} xs={12}>
                <Grid item md={12} xs={12}>
                    <h1>{data?.uuid} Nolu Öneri</h1>
                    <b>{data?.heading}</b>
                    <p>{data?.suggestion}</p>
                    <p>Bölge: {data?.region?.name + " / " + data?.section?.name}</p>
                    <p>Çözüm Yöneticisi: {data?.solution_responsible?.name + "  " + data?.solution_responsible?.surname}</p>
                </Grid>
                <Grid item md={12} xs={12} mr={15}>
                    {data?.files?.length > 0 ?
                    <h1>Eklenen Dökümanlar</h1>
                    :""}
                    <div style={{display:"flex", gap:"45px"}}>
                        {data?.files?.length > 0 ?
                         <>
                         <TaskImageHolder>
                         {data?.files?.map((e,index) => (
                           <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                               <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                             <FileHolder>
                               {getFileIcon(e?.file_type)}
                               <FileName>{e?.file_name}</FileName>
                             </FileHolder>
                             </a>
                           </Tooltip>
                             ))}
                         </TaskImageHolder>
                         </>
                          :
                        ""}
                    </div>
                </Grid>
                <Alert style={{marginTop:"15px"}}>
                    {data?.additional_info_for_solution_team}
                </Alert>
            </Grid>
            <Grid item container md={6} xs={12}>
                <Grid item md={12} xs={12} mr={15}>
                    {data?.files_for_solution_team?.length > 0 ?
                    <h1>Çözüm Ekibi Dökümanları</h1>
                    :""}
                    <div style={{display:"flex", gap:"45px"}}>
                        {data?.files_for_solution_team?.length > 0 ?
                         <>
                         <TaskImageHolder>
                         {data?.files_for_solution_team?.map((e,index) => (
                           <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                               <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                             <FileHolder>
                               {getFileIcon(e?.file_type)}
                               <FileName>{e?.file_name}</FileName>
                             </FileHolder>
                             </a>
                           </Tooltip>
                             ))}
                         </TaskImageHolder>
                         </>
                          :
                        ""}
                    </div>
                </Grid>
            </Grid>
        </Grid>
        <Divider style={{background:"grey", width:"100%"}} />
        <TaskManagementMainBoard rowCount={rowCount }pagination={pagination} setPagination={setPagination} data={tasks} isFetching={isFetching}/> 
        <Divider style={{background:"grey", width:"100%"}} />
        <Grid container>
          <Grid item xs={12} md={12}>
          <CommentOutline>
              <Avatar/>
              <TextFieldV2 label={"Yorumunuzu Ekleyin"} value={comment} setValue={setComment} style={{marginBottom:"0px !important"}} 
              onKeyPress={(e) => {
                if (e.key === 'Enter' && comment?.length > 0 ) {
                  postComment()
                }
            }}
              disabled={data?.state==="FAILED"}
              endAdornmentText={
                buttonClick? 
                  <EditButton>
                    Gönderiliyor...
                  </EditButton>
                  :
                  <EditButton active={comment} onClick={postComment}>
                    Yorum Yap
                </EditButton>
              }
              />
             
            </CommentOutline>
            <div style={{display:"flex", flexDirection:"column", gap:"25px"}}>
                <h2>Yorumlar {data?.comment_suggestions?.length}</h2>
                    {data?.comment_suggestions?.slice(0)?.reverse()?.map((e) => (
                        <PostedCommentContainer key={e.id}>
                            <Avatar/>
                            <PostedCommentInfo>
                            <span> <b style={{fontSize:"13px"}}>{e?.commenter?.name + " " +e?.commenter?.surname}</b> <span style={{fontSize:"11px", color:"grey"}}>{moment(e?.created_at).format("DD-MM-YYYY")}</span> </span>
                            <b>{e.comment}</b>
                            </PostedCommentInfo>
                        </PostedCommentContainer>
                    ))}
            </div>
          </Grid>
        </Grid>
        <AddTaskDialog callBackBroadCast={getTasks} open={addTaskDialogOpen} setOpen={setAddTaskDialogOpen} selectedRegion={data?.region} departmentSelected={data?.section?.id} suggestion_id={params?.id} />
        <CompleteSuggestionDialog setConfetiiOpen={setConfetiiOpen} open={completeDialogOpen} setOpen={setCompleteDialogOpen} id={params?.id} getInfoBack={getInfo} />
      
      </ActivitiesMainWrapper>
      
    </ActivitiesMainContainer>
  )
}

export default SuggestionSolutionDetails