import styled from "styled-components"

export const SettingsPagesContainer = styled.div`
    display:flex;
    width:100%;
    justify-content:center;
    align-items:start;
    margin-top:25px;
`

export const SettingsPageWrapper = styled.div`
    width: 85%;
    display:flex;
    flex-direction:column;
`

export const SettingsPageHeading = styled.h1``


export const SettingsPageActionText = styled.p`
    cursor:pointer;
    color:#1c76dc
`