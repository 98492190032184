export const TMAppsNav = [
    {
        id:1,
        name:"İç Denetim",
        color:"#36ff1f",
        urlPath: "/app/task-management-app/apps/internal_audit"
    },
    {
        id:2,
        name:"Öneri Sistemi",
        color:"#d300ff",
        urlPath: "/app/task-management-app/apps/suggestion_system"
    },
    {
        id:3,
        name:"Kaizen",
        color:"#00fff1",
        urlPath: "/app/task-management-app/apps/kaizen_system"
    },
    {
        id:4,
        name:"Dış Müşteri Şikayetleri",
        color:"#ff8a8a",
        urlPath: "/app/task-management-app/apps/ecc"
    },
    {
        id:5,
        name:"İç Müşteri Şikayetleri",
        color:"#ff8900",
        urlPath: "/app/task-management-app/apps/icc"
    },
]