import React, {  useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Menu, MenuItem, Divider, Chip } from '@mui/material';
import { EditButton } from '../MainSettings';
import { connect } from 'react-redux';
import UserEditDialog from '../DialogContaners/UserEditDialog';
import DialogTitleV2 from '../../../Assets/DialogTitle';
import SuccessSnackBar from '../../../Subcomponents/SuccessSnackBar';
import ErrorSnackBar from '../../../Subcomponents/ErrorSnackBar';
const UserTable = ({data, getDepartments,user}) => {
    const [editDialogOpen,setEditDialogOpen] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [surname, setSurname] = useState(null)
    const [phone, setPhone] = useState(null)
    const [holdingManager, setHoldingManager] = useState(false)
    const [UserDepartmentRelation, setUserDepartmentRelation] = useState(null)
    const [region, setRegion] = useState(null)
    const [regionManager, setRegionManager] = useState(false)
    const [parent,  setParent] = useState(null)
    const [departmentManager, setDepartmentManager] = useState(false)

    const handleOpenEditDialog = (e) => {
        console.log(e)
        setId(e.id)
        setName(e.name)
        setSurname(e.surname)
        setEmail(e.email)
        setPhone(e.phone)
        setHoldingManager(e.is_holding_manager)
        setUserDepartmentRelation(e.is_holding_user ? "holding" : "region")
        setRegion(e.region?.length > 0 ? e.region[0]?.id: null)
        setRegionManager(e.is_region_manager)
        setDepartmentManager(e.is_department_manager)
        setParent(e.departments?.length > 0 ? e.departments[0].id: null)
        setEditDialogOpen(true)

    }


    const [deleteDialog, setDeleteDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };
    const [id, setId] = useState(null)
    const handleDeleteDialog = (e) => {
        setDeleteDialog(true)
        setId(e)
    }
    const handleCloseDeleteDialog = () => {
        setDeleteDialog(false)
    }

    async function handleDelete() {
        console.log("test")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/users/edit/${id}/`,  config);
            getDepartments()
            handleCloseDeleteDialog()
            setSuccessOpen(true)
        } catch (err) {
          window.scrollTo( 0, 0)
          setErrorOpen(true)
        }
    }
        let navigate = useNavigate()
      const columns = [

        {
        field: "id",
        headerName: "No",
            renderCell: (params) => {
                return (
                    <p>{params.row.id}</p> 
                )    
            },
        }, 
        {
        field: "name",
        headerName: "İsmi Soyismi",
        width: 350,
            renderCell: (params) => {
                return (
                    <p>{params.row.name} {params.row.surname}</p> 
                )    
            },
        }, 
        {
        field: "adress",
        headerName: "E-Posta Adresi",
        width: 350,
            renderCell: (params) => {
                return (
                    <p>{params.row.email}</p> 
                )    
            },
        }, 
        {
            field: "phone_number",
            headerName: "Telefon Numarası",
            width: 150,
            renderCell: (data) => (
                <p>{data.row.phone}</p> 
            ),
        },
        {
            field: "type1",
            headerName: "Kullanıcı Türü",
            width: 200,
            renderCell: (data) => (
                data.row.type === "holding_manager"
                ?
                <Chip label="Şirket Yöneticisi" color="success"/>
                :
                data.row.type === "holding_user"
                ?
                <Chip label="Şirket Çalışanı"  color="warning"/>
                :
                data.row.type === "region_manager"
                ?
                <Chip label="Bölge Yöneticisi"  color="primary"/>
                :
                data.row.type === "department_manager"
                ?
                <Chip label="Departman Yöneticisi"  color="secondary"/>
                :
                <Chip label="Bölge Çalışanı"  color="error"/>

                
            ),
        },
        {
            field: "delete",
            headerName: "Aksiyonlar",
            align:"left",
            width: 200,
            renderCell: (data) => (
                <div style={{display:"flex", gap:"15px"}}>
                {user?.type?.includes("manager") ? 
                <EditButton onClick={() => handleOpenEditDialog(data.row)} >
                    Düzenle
                </EditButton>
                :""}
                {user?.type == "holding_manager"  || user?.type == "region_manager" ? 
                <EditButton style={{background:"#dc1c1c"}} onClick={() => handleDeleteDialog(data.row?.id)} >
                    Sil
                </EditButton>
                :
                ""
                }
                </div>
              ),
        },
        // {
        //     field: "app_perms",
        //     headerName: "Uygulama İzinleri",
        //     align:"left",
        //     width: 200,
        //     renderCell: (data) => (
        //         <div style={{display:"flex", gap:"15px"}}>
        //            <EditButton active onClick={() => handleOpenEditDialog(data.row)} >
        //             Uygulama İzinleri
        //         </EditButton>
        //         </div>
        //       ),
        // },
      
    ]
    const [validation, setValidation] = useState(null)
    return(
        <React.Fragment>
        <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
        <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
            <div style={{ height: "75vh", width: '100%' }}>
                <DataGrid
                sx={{color:"white"}}
                rows={data ? data : [] }
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                />
            </div>
            <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here,
                    background:"#211c25",
                    color:"#fff",
                    marginBottom:"50px",
        
                  },
                },
              }}
            >
                <DialogTitleV2 title={"Kullanıcıyı Sil"}  handleCloseDialog={handleCloseDeleteDialog}/>
                <DialogContent>
                    <DialogContentText style={{color:"white"}}>
                    Eğer kullanıcıyı silerseniz, kullanıcı ile ilgili tüm veriler ve eylemler de silinecektir. Eğer kullanıcının adını yanlış girdiyseniz, düzenle sekmesinden kullanıcının adını düzenleyebilirsiniz. Eğer silmek istediğinizden eminseniz, lütfen aşağıdaki kutuya "Silmek İstiyorum." yazınız ve ardından silme butonu aktif olacaktır. Doğrulama kutusu büyük küçük harf duyarlıdır.
                    </DialogContentText>
                    <hr/>
                    <TextField value={validation} onChange={(e) => setValidation(e.target.value)} fullWidth
                     InputLabelProps={{
                        style:{
                            color:"#A3A2A1"
                    }
                    }}
                    sx={{
                        input: {
                            color: "white",
                        },
                        width:"100%",
                        '& label.Mui-focused': { color: 'white',},
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCloseDeleteDialog}> İptal</Button>
                    <Button variant="contained" disabled={validation?.includes("Silmek İstiyorum") ? false : true} color="warning" onClick={handleDelete}> Sil</Button>
                </DialogActions>
            </Dialog>
            <UserEditDialog open={editDialogOpen} setOpen={setEditDialogOpen}
            name={name}
            setName={setName}
            surname={surname}
            setSurname={setSurname}
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
            holdingManager={holdingManager}
            setHoldingManager={setHoldingManager}
            UserDepartmentRelation={UserDepartmentRelation}
            setUserDepartmentRelation={setUserDepartmentRelation}
            id={id}
            region={region}
            setRegion={setRegion}
            regionManager={regionManager}
            setRegionManager={setRegionManager}
            parent={parent}
            setParent={setParent}
            departmentManager={departmentManager}
            setDepartmentManager={setDepartmentManager}
            getInfoBack={getDepartments}
            setSuccessOpen={setSuccessOpen}
            setErrorOpen={setErrorOpen}
            />

        </React.Fragment>

    )
}

const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps)(UserTable)