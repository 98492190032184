import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { InnerNavigation, InnerNavigationItems } from '../../AuditApp/Activities'
import { ApplicationContainer } from '../../AuditApp/HomePage'

const KaizenActivities = () => {
  let navigate = useNavigate()
  const {pathname} = useLocation()
  return (
    <ApplicationContainer style={{width:"100%"}}>
    <InnerNavigation style={{borderBottom:"1px solid grey", paddingBottom:"10px", marginBottom:"10px"}}>
        <InnerNavigationItems active={pathname === "/app/kaizen-app/activities"} onClick={() => navigate("/app/kaizen-app/activities")}>Kaizenlerim</InnerNavigationItems>
        <InnerNavigationItems active={pathname.includes("process")} onClick={() => navigate("/app/kaizen-app/activities/process")}>Süreç Yönetimi</InnerNavigationItems>
        <InnerNavigationItems active={pathname.includes("solution")} onClick={() => navigate("/app/kaizen-app/activities/solution")}>Çözüm Yönetimi</InnerNavigationItems>
    </InnerNavigation>
    <Outlet/>
  </ApplicationContainer>
  )
}

export default KaizenActivities