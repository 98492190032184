import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { InnerNavigation, InnerNavigationItems } from '../../../AuditApp/Activities'
import { ApplicationContainer } from '../../../AuditApp/HomePage'

const SuggestionCommittee = () => {
    let navigate = useNavigate()
    const {pathname} = useLocation()
  return (
    <ApplicationContainer style={{width:"100%"}}>
        <InnerNavigation style={{borderBottom:"1px solid grey", paddingBottom:"10px", marginBottom:"10px"}}>
            <InnerNavigationItems active={pathname === "/app/suggestions-app/committee"} onClick={() => navigate("/app/suggestions-app/committee")}>Güncel Komite Değerlendirmeleri</InnerNavigationItems>
            <InnerNavigationItems active={pathname.includes("past")} onClick={() => navigate("/app/suggestions-app/committee/past")}>Geçmiş Komite Değerlendirmeleri</InnerNavigationItems>
        </InnerNavigation>
        <Outlet/>
    </ApplicationContainer>
  )
}

export default SuggestionCommittee