import { Chip } from '@mui/material'
import { DataGrid, trTR } from '@mui/x-data-grid'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { EditButton } from '../../../CompanySettings/MainSettings'

const SolutionManagmentTable = ({data}) => {
    let navigate = useNavigate()
    const columns = [
        {
        field: "id",
        headerName: "No",
        hide:true,
        width: 50,
        }, 
        {
        field: "uuid",
        headerName: "Öneri Numarası",
        width: 135,
        }, 
        {
        field: "region",
        headerName: "Bölge",
        width: 200,
          renderCell: (params) => {
            return (
              <p>{params?.row?.region?.name}</p> 
              )    
          },
        }, 
        {
        field: "section",
        headerName: "Departman",
        width: 200,
          renderCell: (params) => {
            return (
              <p>{params?.row?.section?.name}</p> 
              )    
          },
        }, 
        {
        field: "heading",
        headerName: "Öneri Başlığı",
        width: 350,
          renderCell: (params) => {
            return (
              <p>{params?.row?.heading}</p> 
              )    
          },
        }, 
        {
        field: "start_date",
        headerName: "Başlama Tarihi",
        width: 200,
          renderCell: (params) => {
            return (
              <p>{moment(params?.row?.created_at).format("DD/MM/YYYY")}</p> 
              )    
          },
        }, 
        {
        field: "manager",
        headerName: "Çözüm Yöneticisi",
        width: 200,
          renderCell: (params) => {
            return (
              params?.row?.solution_responsible ?
              <p>{params?.row?.solution_responsible?.name +" " + params?.row?.solution_responsible?.surname}</p> 
              :
              <p>Çözüm Yöneticisi Atanmadı</p> 

              )    
          },
        }, 
        {
        field: "stage",
        headerName: "Durumu",
        width: 200,
          renderCell: (params) => {
            return (
              params?.row?.state === "CONTINUE" ?
              <Chip label={"İşlem Bekliyor"} color="primary"/>
              :
              params?.row?.state === "Pass" ? 
              <Chip label={"Tamamlandı"} color="success"/>
              :   
              <Chip label={"Reddedildi"} color="error"/>
              )    
          },
        }, 
        {
        field: "action",
        headerName: "İncele",
        width: 200,
            renderCell: (params) => {
            return (
                <EditButton active onClick={() => navigate(`/app/suggestions-app/activities/solution/${params?.row?.id}`)}>
                    İncele
                </EditButton> 
                )    
            },
        }, 
        
    ]
  return (
    <div style={{height:"75vh"}}>
          <DataGrid
            rows={data ? data : []}
            columns={columns}
            style={{color:"white"}}
            pageSize={15}
            rowsPerPageOptions={[15]}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
          />
    </div>
  )
}

export default SolutionManagmentTable