import { Alert, Avatar, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { green, grey, red, yellow } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { CommentOutline, FileHolder, FileName, FileThumbnail, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer, PostedCommentContainer, PostedCommentInfo, SuggestionInfoBoxContainer, SuggestionInfoBoxSection, SuggestionInfoBoxWrapper } from '../../../../Assets/GeneralDesignElements'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import { ApplicationContainer } from '../../../AuditApp/HomePage'
import { EditButton } from '../../../CompanySettings/MainSettings'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { StyledBadge } from '../../../../Assets/StyledBadge'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import axios from 'axios'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ExcelIcon from '../../../../Assets/FileIcons/excel.png'
import WordIcon from '../../../../Assets/FileIcons/word.png'
import PdfIcon from '../../../../Assets/FileIcons/pdf.png'
import ImageIcon from '../../../../Assets/FileIcons/Image.png'
import RarIcon from '../../../../Assets/FileIcons/rar.png'
import UnKnown from '../../../../Assets/FileIcons/UnKnown.webp'
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import RejectTheSuggestionDialog from './Dialogs/RejectTheSuggestionDialog'
import SendToSolutionTeam from './Dialogs/SendToSolutionTeam'
const CommitteeEvaluation = () => {
  const [rejectSuggestionDialog, setRejectSuggesionDialog] = useState(false)
  const [solutionTeamDialog, setSolutionTeamDialog] = useState(false)
  function getFileIcon(extension) {
    switch (extension) {
      case 'xls':
      case 'xlsx':
        return <FileThumbnail src={ExcelIcon} alt="xls" />;
      case 'pdf':
        return <FileThumbnail src={PdfIcon} alt="xls" />;
      case 'doc':
      case 'docx':
        return <FileThumbnail src={WordIcon} alt="xls" />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'pjpeg':
      case 'webp':
        return <FileThumbnail src={ImageIcon} alt="xls" />;
      case 'zip':
      case 'rar':
        return <FileThumbnail src={RarIcon} alt="xls" />;
      default:
        return <FileThumbnail src={UnKnown} alt="xls" />;
    }
  }
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
  };
  let params = useParams()
  const [comment, setComment] = useState(null)
  const [buttonClick, setButtonClick] = useState(false)
  const [data, setData] = useState([])
 

  useEffect(() => {getInfo()},[params?.id])
  async function getInfo (){
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/create/${params?.id}/`,  axiosConfig)
      setData(res.data)

    }
    catch{

    }
  }
  async function postComment (){
    if (comment && comment?.length > 0) {
      setButtonClick(true)
      const body = JSON.stringify({suggestion_id: params?.id, comment})
      try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions/create/comment/`, body, axiosConfig)
        getInfo()
        setButtonClick(false)
        setComment("")
      }
      catch{
  
        setButtonClick(false)
      }
    }
    else{
      
    }
  }
  return (
    <ApplicationContainer style={{width:"100%"}}>
      <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
          <InfoBar around top>
            <PostedCommentContainer>
              <Avatar/>
              <PostedCommentInfo>
              <span> <b style={{fontSize:"13px"}}>{data?.process_manager?.name} {data?.process_manager?.surname}</b></span>
              </PostedCommentInfo>
            </PostedCommentContainer>
            {data?.stage === "COMMITEECONTROL" ?
            <div style={{display:"flex", gap:"15px"}}>
              <EditButton active onClick={() => setRejectSuggesionDialog(true)}>
                Reddet
              </EditButton>
              <EditButton active style={{width:"125px"}} onClick={() => setSolutionTeamDialog(true)}>
                Çözüm Ekibine İlet
              </EditButton>
            </div>
            :
            ""
            }
          </InfoBar>
          <InfoBar around top>
          <SuggestionInfoBoxContainer>
            <SuggestionInfoBoxWrapper>
              <SuggestionInfoBoxSection>
                  <b>Öneren Bilgileri</b>
                  <p>{data?.suggester?.name} {data?.suggester?.surname}</p>
                  <p>{data?.suggester?.email}</p>
              </SuggestionInfoBoxSection>
              <SuggestionInfoBoxSection>
                  <b>Öneri Detayları</b>
                  <p>Öneri No: {data?.uuid}</p>
              </SuggestionInfoBoxSection>
            </SuggestionInfoBoxWrapper>
          </SuggestionInfoBoxContainer>
          <HorizantalTimeLineContainer>
              <HorizantalTimelineCardWrapper>
                <Tooltip title="Öneri Başlangıcı" arrow>
                    <Avatar
                    sx={{ bgcolor: green[600] }}
                    >
                      <DoneOutlineIcon/>
                    </Avatar>
                  </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title={`Hat Yöneticisi (${data?.process_manager?.name + "  " + data?.process_manager?.surname}) Değerlendirmesi`} arrow>
                  <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant={data?.stage ==="MANAGERCONTROL" && data?.state === "CONTINUE" ?"dot":""}>
                  <Avatar
                  sx={{ bgcolor: data?.stage ==="MANAGERCONTROL" ? data?.state === "CONTINUE" || data?.state === "PASS" ? green[400] : red[400] : green[600]}}
                  >
                    {data?.stage ==="MANAGERCONTROL" && data?.state === "CONTINUE" ?
                    "YD"
                    :
                    data?.state === "FAILED" ?
                    <HighlightOffIcon/>
                    :
                    <DoneOutlineIcon/>
                    }
                  </Avatar>
                  </StyledBadge>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Komite Değerlendirmesi" arrow>
                  <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant={data?.stage ==="COMMITEECONTROL" ?"dot":""}>
                  <Avatar
                  sx={{ bgcolor: data?.stage ==="COMMITEECONTROL" ? data?.state === "CONTINUE" || data?.state === "PASS" ? green[400] : red[400] : green[600]}}
                  >
                    KD
                  </Avatar>
                  </StyledBadge>
                </Tooltip>
               
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Tamamlandı" arrow>
                <Avatar>
                  T
                </Avatar>
                </Tooltip>
              </HorizantalTimelineCardWrapper>
            </HorizantalTimeLineContainer>
        </InfoBar>
        <Divider style={{width:"100%", background:"grey"}} />
        <Grid container direction={"row"} justifyContent={"space-between"}>
          <Grid item container md={6} xs={12}>
            <Grid item md={12} xs={12}>
              <h1>{data?.uuid} Nolu Öneri</h1>
              <b>{data?.heading}</b>
              <p>{data?.suggestion}</p>
              <p>Bölge: {data?.region?.name + ' ' + data?.section?.name}</p>
              <p>Süreç Yöneticisi: {data?.process_manager?.name + ' ' + data?.process_manager?.surname}</p>
              {data?.state === "FAILED" ? 
              <Alert color="error" style={{width: "90%"}}>
               {data?.reject_reason}
              </Alert>
              :
              data?.state === "PASS" ?
              <Alert color="error" style={{width: "90%"}}>
                {`Tebrikler öneriniz kabul edilierek uygulamaya koyuldu ve ${data?.earned_point} kazandınız`}
              </Alert>
              :
              ""
              }
              {data?.additional_info ?
              <Alert color="warning" style={{width: "90%"}}>
                {`${data?.additional_info}`}
              </Alert>
                :
                ""
              }
             
            </Grid>
            <Grid item md={12} xs={12} mr={15}>
              {data?.files?.length > 0 ?
              <h1>Eklenen Dökümanlar</h1>
              :""}
              <div style={{display:"flex", gap:"45px"}}>
              {data?.files?.length > 0 ?
                  <>
                  <TaskImageHolder>
                  {data?.files?.map((e,index) => (
                    <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                        <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                      <FileHolder>
                        {getFileIcon(e?.file_type)}
                        <FileName>{e?.file_name}</FileName>
                      </FileHolder>
                      </a>
                    </Tooltip>
                      ))}
                  </TaskImageHolder>
                  </>
                :
              ""}
              </div>
            </Grid>
          </Grid>
            <Grid item xs={12} md={6}>
              <h1>Hesaplanan Maliyet</h1>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>İş Gücü</TableCell>
                    <TableCell>Gerekli Materyaller</TableCell>
                    <TableCell>Gerekli Ekipmalar</TableCell>
                    <TableCell>Sermaye (Para) Eğer Gerekiyorsa</TableCell>
                    <TableCell>Diğer Kaynaklar</TableCell>
                    <TableCell>Tahmini Maliyet</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{data?.needed_employee_force}</TableCell>
                    <TableCell>{data?.needed_materials}</TableCell>
                    <TableCell>{data?.needed_equipments}</TableCell>
                    <TableCell>{data?.needed_cash}</TableCell>
                    <TableCell>{data?.other_needs}</TableCell>
                    <TableCell>{data?.estimated_cost}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <h1>Tahmini Kazanç</h1>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Şirkete Faydalarını</TableCell>
                    <TableCell>Tahmini Finansal Kazanç</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{data?.benefits_to_company}</TableCell>
                    <TableCell>{data?.estimated_financial_earnings}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
          </Grid>
        </Grid>
        <Divider style={{background:"grey", width:"100%"}} />
        <Grid container>
          <Grid item xs={12} md={12}>
          <CommentOutline>
              <Avatar/>
              <TextFieldV2 label={"Yorumunuzu Ekleyin"} value={comment} setValue={setComment} style={{marginBottom:"0px !important"}} 
              onKeyPress={(e) => {
                if (e.key === 'Enter' && comment?.length > 0 ) {
                  postComment()
                }
            }}
              disabled={data?.state==="FAILED"}
              endAdornmentText={
                buttonClick? 
                  <EditButton>
                    Gönderiliyor...
                  </EditButton>
                  :
                  <EditButton active={comment} onClick={postComment}>
                    Yorum Yap
                </EditButton>
              }
              />
             
            </CommentOutline>
            <div style={{display:"flex", flexDirection:"column", gap:"25px"}}>
            <h2>Yorumlar {data?.comment_suggestions?.length}</h2>
             {data?.comment_suggestions?.slice(0)?.reverse()?.map((e) => (
             <PostedCommentContainer key={e.id}>
              <Avatar/>
              <PostedCommentInfo>
              <span> <b style={{fontSize:"13px"}}>{e?.commenter?.name + " " +e?.commenter?.surname}</b> <span style={{fontSize:"11px", color:"grey"}}>{moment(e?.created_at).format("DD-MM-YYYY")}</span> </span>
              <b>{e.comment}</b>
              </PostedCommentInfo>
              {/* <FaTrash/> */}
            </PostedCommentContainer>
            ))}
            </div>
           
          </Grid>
        </Grid>
        <RejectTheSuggestionDialog open={rejectSuggestionDialog} setOpen={setRejectSuggesionDialog} getInfoBack={getInfo} id={params?.id}/>
        <SendToSolutionTeam open={solutionTeamDialog} setOpen={setSolutionTeamDialog} getInfoBack={getInfo} id={params?.id}/>
        </ActivitiesMainWrapper>
      </ActivitiesMainContainer>
    </ApplicationContainer>
  )
}

export default CommitteeEvaluation