import HomeIcon from '@mui/icons-material/Home';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RuleIcon from '@mui/icons-material/Rule';
import ConstructionIcon from '@mui/icons-material/Construction';
import WavesIcon from '@mui/icons-material/Waves';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
export const KaizenNav = [
    {
        id:1,
        name:"Anasayfa",
        icon:(<HomeIcon fontSize="small"/>),
        urlPath: "/app/kaizen-app/",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:2,
        name:"Kaizen Faaliyetleri",
        icon:(<ConstructionIcon fontSize="small"/>),
        urlPath: "/app/kaizen-app/activities",
        childeren_urlPath : [
            {
                id:1,
                urlPath:"/app/kaizen-app/activities/process"
            },
            {
                id:2,
                urlPath:"/app/kaizen-app/activities/solution"
            },
            {
                id:3,
                urlPath:"/app/kaizen-app/activities/create"
            },
        ],
        show_on_side_bar: true,
    },
    {
        id:3,
        name:"Geliştirme Havuzu",
        icon:(<WavesIcon fontSize="small"/>),
        urlPath: "/app/kaizen-app/improvement/pool",
        childeren_urlPath : [
            {
                id:1,
                urlPath:"/app/kaizen-app/improvement/pool/internal-customers"
            },
            {
                id:2,
                urlPath:"/app/kaizen-app/improvement/pool/external-customers"
            },
        ],
        show_on_side_bar: true,
    },
    // {
    //     id:3,
    //     name:"Kazançlar",
    //     icon:(<LightbulbIcon fontSize="small"/>),
    //     urlPath: "/app/kaizen-app/earnings",
    //     childeren_urlPath : null,
    //     show_on_side_bar: true,
    // },
    {
        id:4,
        name:"Reports",
        icon:(<AssessmentIcon fontSize="small"/>),
        urlPath: "/app/kaizen-app/reports",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:5,
        name:"Kaizen Süreç Yönetimi",
        icon:(null),
        urlPath: "/app/kaizen-app/activities/process",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:6,
        name:"Kaizen Çözüm Yönetimi",
        icon:(null),
        urlPath: "/app/kaizen-app/activities/solution",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:7,
        name:"İç Müşteri Kaizenleri",
        icon:(null),
        urlPath: "/app/kaizen-app/improvement/pool/internal-customers",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:8,
        name:"Dış Müşteri Kaizenleri",
        icon:(null),
        urlPath: "/app/kaizen-app/improvement/pool/external-customers",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:9,
        name:"Kaizen Oluştur",
        icon:(null),
        urlPath: "/app/kaizen-app/activities/create",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
]