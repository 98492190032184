import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Divider from "@mui/material/Divider"
import Chip from "@mui/material/Chip"
import React, {useState, useEffect} from 'react'

import moment from "moment"
import { BeforeAfterLargeImage, BeforeAfterLargeImageSuccess, LogoHolder } from "../../../../Assets/GeneralDesignElements"

const BeforeAfterDialog = ({open, setOpen,completed_at,multiple_action_needed,findingsAction, data,setId, id,done_by, finding_image_path, finding, responsible_by, sectionArray,regionArray, dueDate, pdca, after_image_path, after_text}) => {
    const handleCloseBeforeAfterDialog = () => {
        setOpen(false)
        setId(null)
    }
    const[logo_path,setLogoPath] = useState(null)
   useEffect(()=>{
     const token = localStorage.getItem("access")
     const opts = {
     method : "GET",
     headers : {
     "Content-Type" : "application/json",
     "Authorization" : "JWT " + token
     }
     }
     fetch(`${process.env.REACT_APP_API_URL}/api/company/main/`, opts)
     .then(response => response.json()
     .then(data => {
      setLogoPath(data[0].logo_path)
   }))
   }, [])
  return (
    <React.Fragment>
        
    <Dialog open={open} onClose={handleCloseBeforeAfterDialog}
        sx={{
            "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1100px",  // Set your width here,
                background:"#1e1f21",
                color:"#fff",
                marginBottom:"50px"
            },
            },
        }}
        >
        <DialogTitle>
            <Grid container style={{justifyContent:"space-between", alignItems:"center"}}>
                <Grid item sx={6}>
                    <LogoHolder src={logo_path} alt="logo"/>
                </Grid>
                <Grid item sx={6}>
                   {id} NOLU BULGU ÖNCESİ VE SONRASI
                </Grid>
            </Grid>
        </DialogTitle>
        <Divider style={{background:"#A3A2A1"}} />
        <DialogContent>
        {id !== null ?
        <Grid container spacing={2} style={{ justifyContent:"center"}}>
            <Grid item container sm={12} md={6} xl={6} gap={2} style={{justifyContent:"center", marginBottom:"15px"}}>
                <Grid item sx={12}>
                    <Chip color="error" style={{width:"150px"}} label="ÖNCESİ"/>
                </Grid>
                <Grid item container  style={{height:"90%"}} justifyContent={"space-between"} sx={12}>
                {finding_image_path ? 
                <Grid itme sm={12} xs={12}>
                    <BeforeAfterLargeImage src={finding_image_path} alt="/" style={{width:"100%", height:"350px", objectFit:"contained", border:"1px solid red"}} />
                </Grid>
                :
                <Grid itme  sx={12}>
                    uygunsuzluk görseli eklenmemiştir
                </Grid>
                }
                <Grid item sx={12} mb={1} style={{background: "#990a00", width:"100%", padding:"15px",borderRadius:"8px"}}>
                    Bulgu: {finding}
                </Grid>
                <Grid  container item sx={12} gap={1} style={{background: "#990a00", width:"100%", padding:"15px",borderRadius:"8px", justifyContent:"space-between"}}>
                    <Grid item xs={5}>
                        Talep Eden:<br/>
                        {done_by?.name + " " + done_by?.surname}
                    </Grid>
                    <Grid item xs={5}>
                        Bölüm:<br/>
                        {regionArray?.name} / &nbsp;
                        {sectionArray?.department_hierarchy}

                    </Grid>
                    <Grid item xs={5}>
                        Ana sorumlu: <br/>
                        {responsible_by?.name + " " + responsible_by?.surname}

                    </Grid>
                    <Grid item xs={5}>
                        Ana Termin:<br/>
                        {moment(dueDate).format("DD-MM-YYYY")}

                    </Grid>
                </Grid>
                </Grid>
            </Grid>
            {pdca !== "Plan" ?
            <Grid item container sm={12} md={6} xl={6} gap={2} style={{justifyContent:"center", marginBottom:"15px"}}>
                <Grid item sx={12}>
                    <Chip color="success" style={{width:"150px"}} label="SONRASI"/>
                </Grid>
                <Grid item container style={{height:"90%"}} justifyContent={"space-between"} sx={12}>
                {after_image_path ? 
                <Grid itme sm={12} xs={12}>
                    <BeforeAfterLargeImageSuccess src={after_image_path} alt="/" style={{width:"100%", height:"350px", objectFit:"contained", border:"1px solid green"}} />
                </Grid>
                :
                <Grid itme  sx={12}>
                    uygunsuzluk görseli eklenmemiştir
                </Grid>
                }
                 <Grid item container sx={12} style={{background: "#109900", borderRadius:"8px", width:"100%", padding:"15px"}}>
                    <Grid item xs={12}>
                        Alınan Aksiyon:<br/> {after_text}
                    </Grid>
                    <Grid item xs={6}>
                        Aksiyonu Alan:<br/>   {responsible_by?.name + " " + responsible_by?.surname}
                    </Grid>
                    <Grid item xs={6}>
                        Gerçekleşen Termin:<br/> {moment(completed_at).format("DD-MM-YYYY")}
                    </Grid>
                </Grid>
                {
                multiple_action_needed ?
                <Grid  container item sx={12} gap={1} style={{background: "green", width:"100%", padding:"15px", justifyContent:"space-between", overflowX:"scroll"}}>
                    
                        <table>
                            <thead>
                                <th>Aksiyonlar</th>
                                <th>Alt Sorumlu</th>
                                <th>Termin</th>
                                <th>Gerçekleşen Termin</th>
                            </thead>
                            <tbody>
                            {findingsAction.map((e) => (
                                <tr>
                                    <td>
                                        <p>{e.action_brief}</p>
                                    </td>
                                    <td>
                                        <p>{e.responsible_by?.name + " " + e.responsible_by?.surname}</p>
                                    </td>
                                    <td>
                                        <p>{e.action_due}</p>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
          
                </Grid>
                :
                ""
                }
            </Grid>
            </Grid>
                :
            <Grid item container sm={12} md={6} xl={6} gap={2} style={{justifyContent:"center", marginBottom:"15px"}}>
                <Grid item sx={12}>
                    <Chip color="success" style={{width:"150px"}} label="SONRASI"/>
                </Grid>
                <Grid item sx={12} style={{background: "green", width:"100%", padding:"15px"}}>
                    Aksiyon Alınmadı
                </Grid>
            </Grid>
                }
            </Grid>
        
        :
        ""
        }
        </DialogContent>
    </Dialog>
    </React.Fragment>
  )
}

export default BeforeAfterDialog