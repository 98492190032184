import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ActivitiesMainContainer, ActivitiesMainWrapper} from '../ActivitiesStyle';
import { DataGrid , trTR } from '@mui/x-data-grid'
import axios from "axios"
import {useNavigate} from "react-router-dom"
import moment from "moment"
import {Chip, Button, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from "@mui/material"
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { EditButton } from '../../../CompanySettings/MainSettings';
import { BoxBlink } from '../GembaWalk';
import DialogTitleV2 from '../../../../Assets/DialogTitle';


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const Workshop = ({user}) => {
  const [dataLoading, setDataLoading] = useState(true)
  const [startButton, setStartButton] = useState(false)
  const[data,setData] =useState(null)

  async function handleGetTheCurrentWorkShops () {
    const token = localStorage.getItem("access")
    const opts = {
        method : "GET",
        headers : {
        "Content-Type" : "application/json",
        "Authorization" : "JWT " + token
    }}
    fetch(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/workshop/`, opts)
    .then(response => response.json()
    .then(data => {
        setData(data)
        setDataLoading(false)
    }))
  }
  useEffect(() =>{
      handleGetTheCurrentWorkShops()
  }, [])
  
  const [id, setId] = useState(null)
  //delete starts from here
  const [deleteDialog, setDeleteDialog] = useState(false)
  const handleOpenDeleteDialog = (e) => {
    setId(e)
    setDeleteDialog(true)
  }
  const handleCloseDeleteDialgo = () => {
    setDeleteDialog(false)

  }
  async function handleDeleteWorkshop () {
    const config = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
  };
  setStartButton(true)

  try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/workshop/${id}/`,  config);
      handleGetTheCurrentWorkShops()
      handleCloseDeleteDialgo()
      setStartButton(false)
      setDeleteSuccessMsg(true)

  } catch (err) {
    setStartButton(false)

  }
  }
  let navigate = useNavigate()
  const columns = [
      {
      field: "id",
      headerName: "No",
      width: 50,
      }, 
      {
      field: "region",
      headerName: "Bölge",
      width: 350,
        renderCell: (params) => {
          return (
            params?.row?.is_started && !params?.row?.is_done
            ?
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"8px"}}>
                <p>{params?.row?.region?.name + " / " + params?.row?.department?.department_hierarchy}</p>
                <BoxBlink/> 
            </div>
            :
            <p>{params?.row?.region?.name + " / " + params?.row?.department?.department_hierarchy}</p> 
            )    
        },
      }, 
      {
      field: "date start",
      headerName: "Çalıştay Tarihi",
      width: 250,
        renderCell: (params) => {
          return (
              <p>{params?.row?.date ? moment(params?.row?.date).utc().format("LLL"): moment(params?.row?.date).utc().format("LLL")}</p> 
            )    
        },
      }, 
      {
      field: "re_control_date",
      headerName: "Başlatan Kullanıcı",
      width: 150,
      renderCell: (params) => {
          return (
              <p>{params?.row?.created_by?.name + " " + params?.row?.created_by?.surname}</p> 
            )    
        },
  
      },
      {
       field: "status",
       headerName: "Denetim Durumu",
       width:          150,
         renderCell: (params) => {
           return (
              <Chip label={params?.row?.is_done === false ? params?.row?.is_started === false ? "Henüz Başlamadı": "Devam Ediyor" : "Tamamlandı"} color={params?.row?.is_done === false ? params?.row?.is_started === false ? "warning":"success" : "error"}/> 
             )    
        },
              
      },  
     {
       field: "report",
       headerName: "Raporu Görüntüle",
       width: 200,
  
       renderCell: (data) => (
          <>
          {data?.row?.is_done === true ?
         <EditButton active disabled={data?.row?.is_done === false}  onClick={() => navigate(`/app/audit-app/activities/workshop/${data?.row?.id}`)}>Çalıştay Raporu</EditButton> 
         :
         <EditButton active disabled={data?.row?.is_done === true} style={{background:"green"}} onClick={()=> navigate(`/app/audit-app/activities/workshop/${data?.row?.id}`)}>Çalıştay Katıl</EditButton> 
          }
         </>
       ),
      },
      user?.type?.includes("manager") ?
     {
       field: "delete",
       headerName: "Sil",
       width: 100,
  
       renderCell: (data) => (
         <EditButton style={{background:"red"}} onClick={() => handleOpenDeleteDialog(data?.row?.id)}>Sil</EditButton> 
       ),
      }:"",
     
  ]
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false)
  const handleCloseSaveSccMsg = () => {
    setSaveSuccessMsg(false)
  }
  const [deleteSuccessMsg, setDeleteSuccessMsg] = useState(false)
  const handleCloseDeleteSccMsg = () => {
    setDeleteSuccessMsg(false)
  }

  return (
    <>
    <ActivitiesMainContainer>
          <Snackbar open={saveSuccessMsg} autoHideDuration={6000} onClose={handleCloseSaveSccMsg} style={{zIndex:"9999 !important"}}>
              <Alert onClose={handleCloseSaveSccMsg} severity="success" sx={{ width: '100%'}}>
                 Çalıştay Tamamlandı...
              </Alert>
          </Snackbar>
          <Snackbar open={deleteSuccessMsg} autoHideDuration={6000} onClose={handleCloseDeleteSccMsg} style={{zIndex:"9999 !important"}}>
              <Alert onClose={handleCloseDeleteSccMsg} severity="warning" sx={{ width: '100%'}}>
                 Çalıştay  Silindi...
              </Alert>
          </Snackbar>
          <ActivitiesMainWrapper>
          <div style={{height:"75vh"}}>
            <DataGrid
              loading={dataLoading}
              rows={data ? data : []}
              columns={columns}
              style={{color:"white"}}
              pageSize={15}
              rowsPerPageOptions={[15]}
              localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
           
            />
          </div>
          </ActivitiesMainWrapper>
     </ActivitiesMainContainer>
        <Dialog open={deleteDialog} onClose={handleCloseDeleteDialgo}>
            <DialogTitleV2 title={"Çalıştayı Sil"} handleCloseDialog={handleCloseDeleteDialgo}/>
            <DialogContent>Çalıştay silmek istediğinize emin misiniz?</DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDeleteDialgo} color ="error">İptal</Button>
                {!startButton ? <EditButton onClick={handleDeleteWorkshop} style={{background:"red"}}>Çalıştay Sil</EditButton> : <EditButton variant="outlined" color ="error">Siliniyor...</EditButton> }
            </DialogActions>
        </Dialog>
      </>
   
  )
}

const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps) (Workshop)