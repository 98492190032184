import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Snackbar, Table, TableHead } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import { EditButton } from '../../../../CompanySettings/MainSettings'

const AttendanceDialog = ({open, setOpen, getAttendance,attandees,getInfoBack, gemba_id}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [attandance, setAttandace] = useState([])
    useEffect(() => {
        setAttandace(attandees)
    },[attandees])
    console.log(
        attandance
    )
    const updateObjectInArray = (j) => {
        setAttandace(current =>
          current.map(obj => {
            if (obj.id === j.id) {
                if (j.did_attended === false){
                    return {...obj, did_attended: true};
                }
                else{
                    return {...obj, did_attended: false};
                }
            }
            return obj;
          }),
        );
      };
    const [buttonStart, setButtonStart] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    async function handleDelete() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({attandance})
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/update/workshop/attendees/${gemba_id}/`, body, config);
            setOpen(false)
            getInfoBack()

            setSuccessMsg(true)
            setButtonStart(false)

        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }
      console.log(attandance,"info")
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
    sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}>
        <DialogTitleV2 handleCloseDialog={handleCloseDialog} title={"Saha Turu Yoklaması"} />
        <DialogContent>
             <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                {attandance?.map((e) => (
                <FormControlLabel
                    control={
                    <Checkbox checked={e.did_attended} onChange={() => updateObjectInArray(e)} id={e.attendee.id} />
                    }
                    label={e.attendee.name +  " " + e.attendee.surname}
                />
                ))}
                </FormGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton active onClick={handleDelete}>Tamamla</EditButton> : <EditButton>Tamamlanıyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default AttendanceDialog