import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom';
import { MainSettingsContainer, MainSettingsWrapper } from './MainSettings';
import UserTable from './Tables/UserTable';

const UserSettings = () => {
  const [regionData,getRegionInfo, sectionData, getSectionInfo,sectionRegionData,getSectionRegionInfo,sectionSectionData,getSectionSectionInfo, treeView,getUserInfo,userData] = useOutletContext();
  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line
  },[])
  return (
    <MainSettingsContainer>
        <MainSettingsWrapper className='container'>
            <UserTable data={userData} getDepartments={getUserInfo}/>
        </MainSettingsWrapper>
    </MainSettingsContainer>
  )
}

export default UserSettings