import {  Dialog, DialogActions, DialogContent,} from '@mui/material'
import React from 'react'
import DialogTitleV2 from '../../../Assets/DialogTitle'
import { EditButton } from '../../../Pages/CompanySettings/MainSettings'


const AuditApplicationInfo = ({open, setOpen}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
  
 
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"İç Denetim Uygulaması"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>Yalın üretim süreçlerini takip edebileceğiniz iç denetim uygulaması, şirketlerin üretim süreçlerini izlemek ve analiz etmek için kullanabileceği bir araçtır. Bu uygulama, şirketlerin yalın üretim süreçlerini takip etmelerini, atıkları ve hataları belirlemelerini ve ortadan kaldırmalarını sağlar. Ayrıca, bu uygulama, şirketlerin verimliliğini artırmalarına, işletme risklerini azaltmalarına ve iş süreçlerini iyileştirmelerine yardımcı olur.</DialogContent>
        <DialogActions>
            <EditButton active onClick={handleCloseDialog}>Tamam</EditButton>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default AuditApplicationInfo