import React, {  useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Menu, MenuItem, Divider } from '@mui/material';
import { EditButton } from '../MainSettings';
import ReigonEditDialog from '../DialogContaners/RegionEditDialog';
import SectionEditDialog from '../DialogContaners/SectionEditDialog';
import { connect } from 'react-redux';
const DepartmentTable = ({data, getDepartments, user,setSuccessOpen,setErrorOpen}) => {
    const [editDialogOpen,setEditDialogOpen] = useState(false)
    const [name, setName] = useState(null)
    const [adress, setAdress] = useState(null)
    
    const handleOpenEditDialog = (e) => {
        setName(e.name)
        setAdress(e.adress)
        setId(e.id)
        setEditDialogOpen(true)
        setAnchorEl(null)
        
    }


    const [deleteDialog, setDeleteDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };
    const [id, setId] = useState(null)
    const handleDeleteDialog = (e) => {
        setDeleteDialog(true)
        setId(e.id)
    }
    const handleCloseDeleteDialog = () => {
        setDeleteDialog(false)
    }

    async function handleDelete() {
        console.log("test")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/section/update/${id}/`,  config);
            getDepartments()
            handleCloseDeleteDialog()
            setSuccessOpen(true)
        } catch (err) {
          window.scrollTo( 0, 0)
          setErrorOpen(true)
          
        }
    }
        let navigate = useNavigate()
      const columns = [

        {
        field: "id",
        headerName: "No",
            renderCell: (params) => {
                return (
                    <p>{params.row.id}</p> 
                )    
            },
        }, 
        {
        field: "name",
        headerName: "Departman İsmi",
        width: 350,
            renderCell: (params) => {
                return (
                    <p>{params.row?.name}</p> 
                )    
            },
        }, 
        
        {
        field: "parent",
        headerName: "Üst Birimi",
        width: 350,
            renderCell: (params) => {
                return (
                    <p>{params.row.parent ?params.row.parent?.name : params.row?.region?.name }</p> 
                )    
            },
        }, 
        {
            field: "Alt Birimleri Görüntüle",
            headerName: "Alt Birimleri Görüntüle",
            width: 300,
            renderCell: (data) => (
                <EditButton active  onClick={() => navigate("/app/company-settings/department-settings/" + data.row.id)} >
                    Alt Birimleri Görüntüle
                </EditButton>
            ),
        },
        {
        field: "delete",
        headerName: "Aksiyonlar",
        align:"left",
        width: 200,
        renderCell: (data) => (
            <div style={{display:"flex", gap:"15px"}}>
               <EditButton onClick={() => handleOpenEditDialog(data.row)} >
                Düzenle
            </EditButton>
            {user?.type == "holding_manager"  || user?.type == "region_manager" ? 
            <EditButton style={{background:"#dc1c1c"}} onClick={() => handleDeleteDialog(data.row)} >
                Sil
            </EditButton>
            :
            ""
            }
            </div>
          
          ),

        },
      
    ]
    const [validation, setValidation] = useState(null)
    return(
        <React.Fragment>
            <div style={{ height: "75vh", width: '100%' }}>
                <DataGrid
                sx={{color:"white"}}
                rows={data ? data : [] }
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                />
            </div>
            <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here,
                    background:"#211c25",
                    color:"#fff",
                    marginBottom:"50px",
        
                  },
                },
              }}
            >
                <DialogTitle>Departman Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{color:"white"}}>
                        Eğer departmanı silerseniz bu departman ile bağı olan bütün bulgular ve aksiyonlar da silinecektir. 
                        Girdiğiniz departmanın adını yanlış girdiyseniz düzenle sekmesinden departmanın adını düzenleyebilirsiniz.
                        Eğer silmek istediğinizden eminseniz lütfen aşağıdaki kutuya "Silmek İstiyorum." yazınız ve ardından silme butonu
                        aktif olacaktır. Doğrulama kutusu büyük küçük harf duyarlıdır.
                    </DialogContentText>
                    <hr/>
                    <TextField value={validation} onChange={(e) => setValidation(e.target.value)} fullWidth
                     InputLabelProps={{
                        style:{
                            color:"#A3A2A1"
                    }
                    }}
                    sx={{
                        input: {
                            color: "white",
                        },
                        width:"100%",
                        '& label.Mui-focused': { color: 'white',},
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCloseDeleteDialog}> İptal</Button>
                    <Button variant="contained" disabled={validation?.includes("Silmek İstiyorum") ? false : true} color="warning" onClick={handleDelete}> Sil</Button>
                </DialogActions>
            </Dialog>
            <SectionEditDialog setSuccessOpen={setSuccessOpen} setErrorOpen={setErrorOpen}  open={editDialogOpen} setOpen={setEditDialogOpen} name={name} setName={setName} adress={adress} setAdress={setAdress} id={id} getInfoBack={getDepartments} />

        </React.Fragment>

    )
}
const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps)  (DepartmentTable)