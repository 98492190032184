import React, {  useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Menu, MenuItem, Divider } from '@mui/material';
import { EditButton } from '../../../CompanySettings/MainSettings';
import { connect } from 'react-redux';
import EditAuditType from '../Dialog/EditAuditType';
import SuccessSnackBar from '../../../../Subcomponents/SuccessSnackBar';
import ErrorSnackBar from '../../../../Subcomponents/ErrorSnackBar';
const AuditTypesTable = ({data, getInfoBack, user}) => {
    const [successOpen, setSuccessOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const [editDialogOpen,setEditDialogOpen] = useState(false)
    const [name, setName] = useState(null)

    const handleOpenEditDialog = (e) => {
        setName(e.name)
        setId(e.id)
        setEditDialogOpen(true)
        setAnchorEl(null)
        
    }


    const [deleteDialog, setDeleteDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };
    const [id, setId] = useState(null)
    const handleDeleteDialog = (e) => {
        setDeleteDialog(true)
        setId(e.id)
    }
    const handleCloseDeleteDialog = () => {
        setDeleteDialog(false)
    }

    async function handleDelete() {
        console.log("test")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/${id}/`,  config);
            getInfoBack()
            handleCloseDeleteDialog()
            setSuccessOpen(true)
        } catch (err) {
          window.scrollTo( 0, 0)
          setErrorOpen(true)
        }
    }
        let navigate = useNavigate()
      const columns = [

        {
        field: "id",
        headerName: "No",
            renderCell: (params) => {
                return (
                    <p>{params.row.id}</p> 
                )    
            },
        }, 
        {
        field: "name",
        headerName: "Denetim İsmi",
        width: 350,
            renderCell: (params) => {
                return (
                    <p>{params.row?.name}</p> 
                )    
            },
        }, 
        {
        field: "Alt Birimleri Görüntüle",
        headerName: "Denetim Detay Ayarları",
        width: 300,
        renderCell: (data) => (
            <EditButton active  onClick={() => navigate("/app/audit-app/settings/" + data.row.id)} >
                Denetim Detay Ayarları
            </EditButton>
        ),
        },
        {
        field: "delete",
        headerName: "Aksiyonlar",
        align:"left",
        width: 200,
        renderCell: (data) => (
            <div style={{display:"flex", gap:"15px"}}>
                {user?.type?.includes("manager") ? 
               <EditButton onClick={() => handleOpenEditDialog(data.row)} >
                Düzenle
                </EditButton>
                :""}
            {user?.type == "holding_manager"  || user?.type == "region_manager" ? 
            <EditButton style={{background:"#dc1c1c"}} onClick={() => handleDeleteDialog(data.row)} >
                Sil
            </EditButton>
            :
            ""
            }
            </div>
          
          ),

        },
      
    ]
    const [validation, setValidation] = useState(null)
    return(
        <React.Fragment>
            <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
            <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
            <div style={{ height: "75vh", width: '100%' }}>
                <DataGrid
                sx={{color:"white"}}
                rows={data ? data : [] }
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                />
            </div>
            <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here,
                    background:"#211c25",
                    color:"#fff",
                    marginBottom:"50px",
        
                  },
                },
              }}
            >
                <DialogTitle>Denetimi Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{color:"white"}}>
                        Eğer denetimi silerseniz bu denetime bağlı olan kategoriler, sorular ve geçmiş denetim kartları da silinecektir. Lütfen eğer isim değişikliği yapamak istiyorsanız,
                        denetim bilgilerini düzenleyiniz. Eğer silmek istediğinizden eminseniz lütfen aşağıdaki kutuya "Silmek İstiyorum." yazınız ve ardından silme butonu
                        aktif olacaktır. Doğrulama kutusu büyük küçük harf duyarlıdır.
                    </DialogContentText>
                    <TextField value={validation} onChange={(e) => setValidation(e.target.value)} fullWidth
                     InputLabelProps={{
                        style:{
                            color:"#A3A2A1"
                    }
                    }}
                    sx={{
                        input: {
                            color: "white",
                        },
                        width:"100%",
                        '& label.Mui-focused': { color: 'white',},
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCloseDeleteDialog}> İptal</Button>
                    <Button variant="contained" disabled={validation?.includes("Silmek İstiyorum") ? false : true} color="warning" onClick={handleDelete}> Sil</Button>
                </DialogActions>
            </Dialog>
            <EditAuditType setSuccessOpen={setSuccessOpen} open={editDialogOpen} setOpen={setEditDialogOpen} getInfoBack={getInfoBack} name={name} setName={setName} id={id}/>
        </React.Fragment>

    )
}
const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps)  (AuditTypesTable)