import { Avatar, Divider,Button, FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize, Tooltip, Chip } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { EmployeeCardImage, EmployeeInfoCardContainer, EmployeeInfoCardWrapper, EmployeeInfoMain, FileHolder, FileName, FileThumbnail, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer } from '../../../../Assets/GeneralDesignElements'
import { StyledBadge } from '../../../../Assets/StyledBadge'
import { ActivitiesMainContainer, ActivitiesMainWrapper,InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import { FaPlusCircle} from "react-icons/fa"
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import { EditButton } from '../../../CompanySettings/MainSettings'
import { styled as styledmui} from '@mui/material/styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ExcelIcon from '../../../../Assets/FileIcons/excel.png'
import WordIcon from '../../../../Assets/FileIcons/word.png'
import PdfIcon from '../../../../Assets/FileIcons/pdf.png'
import ImageIcon from '../../../../Assets/FileIcons/Image.png'
import RarIcon from '../../../../Assets/FileIcons/rar.png'
import UnKnown from '../../../../Assets/FileIcons/UnKnown.webp'
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import { connect } from 'react-redux'
import { MutatingDots } from 'react-loader-spinner'
const CreateNewSuggestion = ({user}) => {
  function getFileIcon(extension) {
    switch (extension) {
      case 'xls':
      case 'xlsx':
        return <FileThumbnail src={ExcelIcon} alt="xls" />;
      case 'pdf':
        return <FileThumbnail src={PdfIcon} alt="xls" />;
      case 'doc':
      case 'docx':
        return <FileThumbnail src={WordIcon} alt="xls" />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'pjpeg':
      case 'webp':
        return <FileThumbnail src={ImageIcon} alt="xls" />;
      case 'zip':
      case 'rar':
        return <FileThumbnail src={RarIcon} alt="xls" />;
      default:
        return <FileThumbnail src={UnKnown} alt="xls" />;
    }
  }
  const [files, setFiles] = useState([]);

  function getFileExtension(filename) {
    return filename.split('.').pop();
  }
  const handleFileChange = (event) => {
    const newFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
      newFiles.push(event.target.files[i]);
    }
    setFiles((prevFiles) => [...newFiles, ...prevFiles]);
  };
  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(indexToRemove, 1);
      return newFiles;
    });
  };
  let navigate = useNavigate()
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
  };
  const [region, setRegion] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const departmentOptions = region?.filter(e => e.id === selectedRegion?.id)
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const handleChangeSelectedRegion = (e) => {
      setSelectedRegion(e.target.value)
      setSelectedDepartment(null)
  }
  async function getRegionInfo () {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, axiosConfig);
        setRegion(res.data)
    } catch (err) {
      
    }
  }
  useEffect(() => {getRegionInfo()},[])
  const handleChangeSelectedDepartment = (e) => {
    setSelectedDepartment(e.target.value)
  }
  const [heading, setHeading] = useState(null)
  const [suggestion, setSuggestion] = useState(null)
  const [buttonClick, setButtonClick] = useState(false)

  async function submitData(){
    setButtonClick(true)
    const body = JSON.stringify({heading,suggestion,region_id:selectedRegion?.id, department_id:selectedDepartment})
    let form_data = new FormData();
    for (let i = 0; i < files.length; i++) {
        form_data.append(`file${i + 1}`, files[i]);
    }
    heading ? form_data.append('heading', heading) : form_data.append('heading', "")
    suggestion ? form_data.append('suggestion', suggestion) : form_data.append('suggestion', "")
    selectedRegion ? form_data.append('region_id', selectedRegion?.id) : form_data.append('region_id', "")
    selectedDepartment ? form_data.append('department_id', selectedDepartment) : form_data.append('department_id', "")
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
      }
  };
    try{
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions/create/`, form_data, config)
      navigate(`/app/suggestions-app/activities/create/${res?.data?.id}`)
      setButtonClick(false)
    }
    catch{
      setButtonClick(false)

    }
  }
  const Input = styledmui('input')({
    display: 'none',
});

  const axiosConfigOpenAI = {
  headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API}`,
  }
  };
  const [DOnePerceptronFetching, setDOnePerceptronFetching] = useState(false)
  const [PerceptronOne, PerceptronOneEdit] = useState(false)
  async function ReWritePerceptronGet(){
    setDOnePerceptronFetching(true)
    const body = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [{"role": "user", "content": `Bana "${suggestion}", bu cümleyi iş için daha resmi bir cümle olarak yazar mısın?`}]
    })
    try{
      const res = await axios.post(`${process.env.REACT_APP_OPENAI_API_URL}`,  body, axiosConfigOpenAI);
      PerceptronOneEdit(res.data?.choices ? res?.data?.choices[0].message?.content : "")
      setDOnePerceptronFetching(false)
    }
    catch{
      setDOnePerceptronFetching(false)

    }
  }


  return (
  <ActivitiesMainContainer>
       <ActivitiesMainWrapper>
        <InfoBar around top>
            <EmployeeInfoCardContainer>
              <EmployeeInfoCardWrapper>
                <EmployeeCardImage/>
                <EmployeeInfoMain>
                  <b>{user?.name + " " + user?.surname}</b>
               {user?.type === "holding_manager"
                ?
                <Chip label="Şirket Yöneticisi" color="success"/>
                :
               user?.type === "holding_user"
                ?
                <Chip label="Şirket Çalışanı"  color="warning"/>
                :
               user?.type === "region_manager"
                ?
                <Chip label="Bölge Yöneticisi"  color="primary"/>
                :
               user?.type === "department_manager"
                ?
                <Chip label="Departman Yöneticisi"  color="secondary"/>
                :
                <Chip label="Bölge Çalışanı"  color="error"/> }
                  <p>Email: {user?.email}</p>
                </EmployeeInfoMain>
              </EmployeeInfoCardWrapper>
            </EmployeeInfoCardContainer>
            <HorizantalTimeLineContainer>
              <HorizantalTimelineCardWrapper>
                <Tooltip title="Öneri Başlangıcı" arrow>
                  <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot">
                    <Avatar
                    sx={{ bgcolor: green[400] }}
                    >
                      B
                    </Avatar>
                  </StyledBadge>
                  </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Hat Yöneticisi Değerlendirmesi" arrow>
                  <Avatar>
                    YD
                  </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Komite Değerlendirmesi" arrow>
                  <Avatar>
                    KD
                  </Avatar>
                </Tooltip>
               
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Tamamlandı" arrow>
                <Avatar>
                  T
                </Avatar>
                </Tooltip>
              </HorizantalTimelineCardWrapper>
            </HorizantalTimeLineContainer>
            {buttonClick ?
            <EditButton>
            Kaydedilyor
            </EditButton>
            : 
            <EditButton active onClick={submitData}>
              Kaydet
            </EditButton>
            }

        </InfoBar>
        <h1>Genel Bilgiler</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextFieldV2 label={"Öneri Başlığı"} value={heading} setValue={setHeading} />
          </Grid>
          <Grid item xs={12} md={4}>
                    <FormControl
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    >
                        <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Bölge Seçin</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRegion}
                            label="Bölge Seçin"
                            onChange={handleChangeSelectedRegion}
                            style={{color:"#fff"}}
                        >
                        {region?.map((reg, index) => (
                        <MenuItem value={reg} key={reg?.id} >{reg.name}</MenuItem>
                        ))
                        } 
                    </Select>
                </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
          <FormControl
                  sx={{
                  width:"100%",
                  color:"#fff",
                  '& label.Mui-focused': { color: 'white',},
                  '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                          borderColor: 'white',
                          },
                          '&:hover fieldset': {
                          borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                          borderColor: 'white',
                          },}
                      }}
                  >
                      <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Departman Seçin</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedDepartment}
                          label="Departman Seçin"
                          disabled={selectedRegion === null}
                          onChange={handleChangeSelectedDepartment}
                          style={{color:"#fff"}}
                      >
                         
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                <MenuItem value={child?.id}>{child?.name}</MenuItem>
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        <MenuItem value={child2?.id}>{child?.name} - {child2?.name}</MenuItem>
                                    ))
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        child2?.children?.map(child3 => (
                                            <MenuItem value={child3?.id}>{child?.name} - {child2?.name} - {child3?.name}</MenuItem>
                                        ))
                                    ))
                            ))))}
                  </Select>
              </FormControl>
          </Grid>
        </Grid>
        <div style={{display:"flex", gap:"15px", alignItems:"center"}}>
          <h1>Sorunun Açıkalaması</h1>
          {suggestion?.length > 15 ?
          DOnePerceptronFetching ?
          <MutatingDots
                    height="100"
                    width="100"
                    color="#4fa94d"
                    secondaryColor= '#4fa94d'
                    radius='12.5'
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
          :
          <Tooltip title="Sorun açıklamasını yapay zeka ile tekrar yaz">
            <h1 style={{cursor:"pointer"}} onClick={ReWritePerceptronGet}>⚡</h1>
          </Tooltip>
          
          :""}
        </div>
        {PerceptronOne?
        <Grid item xs={12}>
          <div style={{width:"100%", bakcground:"#2a2b2d", borderRadius:"8px"}}>
            <div style={{padding:"15px"}}>
                {PerceptronOne}
            </div>
          </div>
        </Grid>
        :""}
        <Grid item xs={12}>
        <TextareaAutosize style={{width:"100%",background:"none",  borderRadius:"8px", color:"white", fontFamily:"-moz-initial", fontSize:"15px"}} 
        value={suggestion}
        onChange={(e) =>setSuggestion(e.target.value)}
        minRows={15}
        />
        </Grid> 
       <Grid item xs={12} mt={2} mb={2} align="right">     
            <label htmlFor="contained-button">
            <Input accept="*/*" id="contained-button" multiple   type="file" onChange={handleFileChange} />
            <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={"info"}>
                  Ek döküman ekle &nbsp; <FileUploadIcon/> 
            </Button>
            </label>
        </Grid>   
        {files?.length > 0 ?
            <>
            <h5>Dosyalar</h5>
            <TaskImageHolder>
            {files?.map((e,index) => (
              <Tooltip title={e?.name + "Listeden çıkarmak için tıklayın"} arrow>
                  <a onClick={() => handleRemoveFile(index)} style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                <FileHolder>
                  {getFileIcon(getFileExtension(e?.name))}
                  <FileName>{e?.name}</FileName>
                </FileHolder>
                </a>
              </Tooltip>
                ))}
            </TaskImageHolder>
            </>
          :
          ""}
       </ActivitiesMainWrapper>
  </ActivitiesMainContainer>
  )
}

const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps) (CreateNewSuggestion)