import LoadingButton from '@mui/lab/LoadingButton'
import { Button, TextField } from '@mui/material'
import React,{useState, useEffect} from 'react'
import { PageContainerFlexCenter, PageWrapper } from '../../Assets/GeneralDesignElements'
import { List, ListItem, ListItemLink, LoginContainer, LoginFooter, LogoHolder, MainForm } from '../Login/LoginElements'
import InlineLogo from "../../Assets/Images/Logos/logoshadowed.png"
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { reset_password} from '../../Actions/auth';

const ResetPassword = ({ reset_password, loginProcess }) => {
    useEffect(() => {
        document.title = "Şifre Yenile - Onkasa QMOS"
    }, [])
    let navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: ''
    });
    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        reset_password(email);
    };
  return (
    <PageWrapper>
        <PageContainerFlexCenter>
        <LoginContainer>
            <LogoHolder src={InlineLogo} alt="test" />
            
                <MainForm noValidate  onSubmit={e => onSubmit(e)} style={{marginTop:"20px"}} >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-Posta Adresi"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange = {e  => onChange (e)}
                        value={email}
                        style={{marginBottom:"30px"}}
                    />
                    {!loginProcess ? 
                         <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{marginBottom:"15px", backgroundColor:"#575089", borderRadius:"8px"}}
                        >
                            ŞİFRE YENİLE
                        </Button>
                        :
                        <LoadingButton fullWidth loading variant="outlined"  style={{marginBottom:"15px", backgroundColor:"#575089", borderRadius:"8px", color:"white"}}>
                            MAİL GÖNDERİLİYOR...
                        </LoadingButton>
                    }
                </MainForm>
                <p>Hesabına erişimin var mı? <b style={{color:"#037dac", fontWeight:"600", cursor:"pointer"}} onClick={() => navigate("/")}>Hemen Giriş Yap</b></p>
            </LoginContainer>
            <LoginFooter>
                    <nav>
                        <List>
                            <ListItem><ListItemLink href="//www.onkasa.com" target="__blank">Onkasa</ListItemLink></ListItem>
                            <ListItem><ListItemLink href="//onkasa.com/user-agreement" target="__blank">Koşullar</ListItemLink></ListItem>
                            <ListItem><ListItemLink href="//onkasa.com/gdpr" target="__blank">KVKK</ListItemLink></ListItem>
                            <ListItem><ListItemLink href="//onkasa.com/gdpr" target="__blank">Veri Güvenliği</ListItemLink></ListItem>
                            <ListItem><ListItemLink href="//onkasa.com/blog" target="__blank">Blog</ListItemLink></ListItem>
                        </List>
                    </nav>
                </LoginFooter>
        </PageContainerFlexCenter>
    </PageWrapper>
  )
}

const mapStateToProps = state => ({
    loginProcess: state.auth.loginProcess
})
export default connect(mapStateToProps, { reset_password })(ResetPassword);