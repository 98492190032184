import {  Dialog, DialogActions, DialogContent,} from '@mui/material'
import React from 'react'
import DialogTitleV2 from '../../../Assets/DialogTitle'
import { EditButton } from '../../../Pages/CompanySettings/MainSettings'


const SuggestionApplicationInfo = ({open, setOpen}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
  
 
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"İç Denetim Uygulaması"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
        İç denetim uygulaması, şirket içinde çalışanların önerilerini toplayarak iş verimliliğini artırmayı hedefleyen bir öneri sistemi olarak tasarlanmıştır. Çalışanlar, bu yazılım aracılığıyla fikirlerini ve önerilerini sunabilirler ve yöneticiler de bu önerileri inceleyebilirler. Sistem, önerileri kategorilere ayırarak daha kolay bir şekilde yönetilmesine olanak tanır ve her önerinin takip edilmesi için bir süreç oluşturur. Ayrıca, yazılım, önerilerin uygulanması sonrasında sonuçlarını da takip ederek çalışanların motivasyonunu artırır. Bu öneri sistemi, şirket içindeki iletişimi ve işbirliğini artırarak daha verimli bir çalışma ortamı oluşturmayı amaçlamaktadır.

        </DialogContent>
        <DialogActions>
            <EditButton active onClick={handleCloseDialog}>Tamam</EditButton>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default SuggestionApplicationInfo