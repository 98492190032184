import React, { useEffect, useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Badge, Divider, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import AboutUsDialog from '../OtherDialogs/AboutUsDialog';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ChatApp from "../../Assets/AppIcons/chat_app.png"
import AuditIcon from "../../Assets/AppIcons/audit.png"
import SuggestionIcon from "../../Assets/AppIcons/suggestion.png"
import KaizenIcon from "../../Assets/AppIcons/kaizen.png"
import ExternalCustomer from "../../Assets/AppIcons/external_customer.png"
import SettingsIcon from "../../Assets/AppIcons/settings.png"
import InternalCustomer from "../../Assets/AppIcons/internal_customer.png"
import Smed from "../../Assets/AppIcons/smed.png"
import Polyvalans from "../../Assets/AppIcons/polyvalans.png"
import GeneralInfo from "../../Assets/AppIcons/general_info.png"
import TaskManagement from "../../Assets/AppIcons/task_managment_app.png"
import AllApps from "../../Assets/AppIcons/all_apps.png"

import NotificationsDialog from '../OtherDialogs/NotificationsDialog';
import axios from 'axios';
import moment from 'moment';


export const NotificationHeader = styled.div`
  display:flex;
`
export const NotificationHeaderWrapper = styled.div`
  padding:10px;
  display:flex;
  margin-left:5px;
  align-items:center;
  justify-content:space-between;
  width:100%
`
export const NotificationHeaderTextWrapper = styled.div`
  display:flex;
  flex-direction:column;
`
export const NotificationHeaderTextH1 = styled.span`
  font-size:18px;
  font-weight:600;
  font-family:helvetica;
`
export const NotificationHeaderTextSpan = styled.span`
  font-size:14px;
  font-family:helvetica;
`

export const NotificationMainWrapper = styled.div`
  padding:10px;
  display:flex;
  margin-left:5px;
  align-items:center;
  justify-content:space-between;
  width:100%
`
export const NotificationMainTextWrapper = styled.div`
display:flex;
flex-direction:column;
`
export const NotificationMainTextH1 = styled.span`
  font-size:14px;
  font-family:helvetica;
  width:100%;
`


const NotificationsMenu = () => {

    let navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    makeSeen()
    getInfo()
    };


    const [aboutUsDialogOpen, setAboutUsDialogOpen] = useState(false)
    const handleOpenAboutUsDialog = () => {
      setAboutUsDialogOpen(true)
      setAnchorEl(null);

    }
    


    const [data, setData] = useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/notifications/top_notification/`,  axiosConfig);
            setData(res.data.results)
        }
        catch{

        }
    }
    async function makeSeen (){
      const body = JSON.stringify({})
        try{
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/notifications/set_seen/`, body, axiosConfig);
        }
        catch{

        }
    }
    useEffect(() => {getInfo()},[])

    function getAppLogo (e){
      if (e === 1){
        return(AuditIcon)
      }
    }
  return (
    <React.Fragment>
      <Badge variant={data?.map((e) => e.seen)?.includes(false) ? "dot": ""} color="primary">
        <NotificationsActiveIcon onClick={handleClick} fontSize="small" />
      </Badge>
    <Menu
        id="basic-menu"
        style={{maginTop:"10px",backgroundColor:"rgba(255,255,255,0)"}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              fontFamily:"apple",
              width:"350px",
              color:"white",
              background:"rgba(255,255,255,0.3)",
              backdropFilter: "blur( 20px )",
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
             
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{
        'aria-labelledby': 'basic-button',
        }}
    >
        <NotificationHeader>
          <NotificationHeaderWrapper>
            <NotificationHeaderTextWrapper>
              <NotificationHeaderTextH1>Bildirimler</NotificationHeaderTextH1>
              <NotificationHeaderTextSpan>Okunmamış 6 bildirminiz var</NotificationHeaderTextSpan>
            </NotificationHeaderTextWrapper>
            <DoneAllIcon/>
          </NotificationHeaderWrapper>
        </NotificationHeader>
        <Divider light={true}>
          Yeni
        </Divider>
        <NotificationMainTextWrapper>

        <List sx={{ width: '100%'}}>
          {data?.map((e) => (
            <Tooltip title={e.message}>
            <MenuItem onClick={() => navigate(e.redirect_uri)}  sx={{ width: '100%', bgcolor:!e?.seen ?"#3b3b3b4d" : "none",marginBottom:"2px"}}> 
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={getAppLogo(e.from_app)} />
                </ListItemAvatar>
                <ListItemText primary={
                  <Typography variant="inherit" noWrap>
                  {e.message}
                </Typography>
                } 
                secondary={moment(e?.send_at).format("ll hh:mm")} />
              </ListItem>
            </MenuItem>
            </Tooltip>
          ))}
        </List>
        </NotificationMainTextWrapper>
       
          <Divider light={true}/>
          <MenuItem  sx={{ width: '100%', alignItems:"center", justifyContent:"center"}} onClick={handleOpenAboutUsDialog}> 
              <Typography variant="inherit" color={"rgb(0, 171, 85)"} fontWeight={600}>
              Tümünü Gör
              </Typography>
          </MenuItem>
    </Menu>
    <NotificationsDialog open={aboutUsDialogOpen} setOpen={setAboutUsDialogOpen}/>
    </React.Fragment>
  )
}

export default NotificationsMenu