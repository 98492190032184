import React, { useState } from 'react'
import MainIcon from "../../Assets/AppIcons/MainIcon.png"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import AboutUsDialog from '../OtherDialogs/AboutUsDialog';

export const StartIcon = styled.img`
    height: 15px;
    object-fit:contain;
    cursor:pointer;
`


const NavBarLogo = () => {

    let navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };


    const [aboutUsDialogOpen, setAboutUsDialogOpen] = useState(false)
    const handleOpenAboutUsDialog = () => {
      setAboutUsDialogOpen(true)
      setAnchorEl(null);

    }

  return (
    <React.Fragment>
    <StartIcon src={MainIcon} alt="Başlat" onClick={handleClick}/>
    <Menu
        id="basic-menu"
        style={{maginTop:"10px",backgroundColor:"rgba(255,255,255,0)"}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              fontFamily:"apple",
              width:"200px",
              color:"white",
              background:"rgba(255,255,255,0.3)",
              backdropFilter: "blur( 20px )",
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
             
            },
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        MenuListProps={{
        'aria-labelledby': 'basic-button',
        }}
    >
        <MenuItem onClick={() => handleOpenAboutUsDialog()}>QMOS Hakkında</MenuItem>
        <Divider style={{color:"white", backgroundColor:"white"}}/>
        <MenuItem onClick={handleClose}>Sistem Ayarları</MenuItem>
        <MenuItem onClick={handleClose}>Hesap Bilgileri</MenuItem>
        <Divider style={{color:"white", backgroundColor:"white"}}/>

        <MenuItem onClick={() => navigate("/")}>Çıkış...</MenuItem>
    </Menu>
    <AboutUsDialog open={aboutUsDialogOpen} setOpen={setAboutUsDialogOpen}/>
    </React.Fragment>
  )
}

export default NavBarLogo