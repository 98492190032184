import React, {  useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Menu, MenuItem, Divider } from '@mui/material';
import { EditButton } from '../../../CompanySettings/MainSettings';
import { connect } from 'react-redux';
import DialogTitleV2 from '../../../../Assets/DialogTitle';
import EditEccCategoryDialog from '../Dialogs/EditCategoryDialog';
const EccCategoriesTable = ({data, getInfoBack, user}) => {
    const [editDialogOpen,setEditDialogOpen] = useState(false)
    const [name, setName] = useState(null)
    const [adress, setAdress] = useState(null)

    const [buttonClick, setButtonClicked] = useState(false)

    const handleOpenEditDialog = (e) => {
        setName(e.name)
        setId(e.id)
        setEditDialogOpen(true)
        setAnchorEl(null)
        
    }


    const [deleteDialog, setDeleteDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };
    const [id, setId] = useState(null)
    const handleDeleteDialog = (e) => {
        setDeleteDialog(true)
        setId(e.id)
    }
    const handleCloseDeleteDialog = () => {
        setDeleteDialog(false)
    }

    async function handleDelete() {
        setButtonClicked(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/ecc/category/${id}/`,  config);
            getInfoBack()
            handleCloseDeleteDialog()
            setButtonClicked(false)

        } catch (err) {
          window.scrollTo( 0, 0)
        }
    }
        let navigate = useNavigate()
      const columns = [

        {
        field: "id",
        headerName: "No",
            renderCell: (params) => {
                return (
                    <p>{params.row.id}</p> 
                )    
            },
        }, 
        {
        field: "name",
        headerName: "Denetim İsmi",
        width: 350,
            renderCell: (params) => {
                return (
                    <p>{params.row?.name}</p> 
                )    
            },
        }, 
        {
        field: "delete",
        headerName: "Aksiyonlar",
        align:"left",
        width: 200,
        renderCell: (data) => (
            <div style={{display:"flex", gap:"15px"}}>
               <EditButton onClick={() => handleOpenEditDialog(data.row)} active>
                Düzenle
            </EditButton>
            {user?.type == "holding_manager"  || user?.type == "region_manager" ? 
            <EditButton style={{background:"#dc1c1c"}} onClick={() => handleDeleteDialog(data.row)} >
                Sil
            </EditButton>
            :
            ""
            }
            </div>
          
          ),

        },
      
    ]
    const [validation, setValidation] = useState(null)
    return(
        <React.Fragment>
            <div style={{ height: "75vh", width: '100%' }}>
                <DataGrid
                sx={{color:"white"}}
                rows={data ? data : [] }
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                />
            </div>
            <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here,
                    background:"#211c25",
                    color:"#fff",
                    marginBottom:"50px",
        
                  },
                },
              }}
            >
                <DialogTitleV2 handleCloseDialog={handleCloseDeleteDialog} title={"Kategorileri Sil"} />
                <DialogContent>
                    <DialogContentText style={{color:"white"}}>
                        Kategorileri kalıcı olarak silmek istiyor musunuz? Eğer üst bir kategoriyi siliyorsanız alt kategorilerin de tamamı silinecektir.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleCloseDeleteDialog}> İptal</Button>
                    {!buttonClick ?
                    <EditButton style={{background:"red"}} onClick={() => handleDelete()}> Sil</EditButton>
                    :
                    <EditButton> Siliniyor..</EditButton>
                    }
                </DialogActions>
            </Dialog>
            <EditEccCategoryDialog open={editDialogOpen} setOpen={setEditDialogOpen} name={name} setName={setName} id={id}  getInfoBack={getInfoBack}/> 
        </React.Fragment>

    )
}
const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps)  (EccCategoriesTable)