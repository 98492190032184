import {  Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select,  TextField } from '@mui/material'
import React, { useState } from 'react'
import { TopNavActions } from '../../../../Subcomponents/AuditDashboardsContainer/activitiesdashboard'
import { EditButton } from '../../../CompanySettings/MainSettings'

import axios from 'axios';
import ErrorSnackBar from '../../../../Subcomponents/ErrorSnackBar';
import DialogTitleV2 from '../../../../Assets/DialogTitle';
import TextFieldV2 from '../../../../Assets/TextFieldV2';


const EditNumberOfQuestions = ({setSuccessOpen,setErrorOpen, open, setOpen, getInfoBack, numberOfQuestions,setNumberOfQuestions,id}) => {
    const [err, setErr] = useState(null)
    const [openErr, setOpenErr] = useState(false)

    const handleCloseDialog = () => {
        setOpen(false)
    }
 
    async function handleSubmit (e) {
        e.preventDefault()
        if(numberOfQuestions?.length > 0){
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                    'Accept': 'application/json'
                }
            };
            const body = JSON.stringify({  numberOfQuestions  });
            try {
                await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/${id}/`, body, config);
                getInfoBack()
                handleCloseDialog()
                //setAdress(null)
                setOpen(false)
                setNumberOfQuestions(null)
                setSuccessOpen(true)
            } catch (err) {
                setErr(err.response.data)
                setOpenErr(true)
                window.scrollTo( 0, 0)
            }
        }
        else{
           
        }     
      }


  return (
    <React.Fragment>
        <ErrorSnackBar open={openErr} setOpen={setOpenErr} data={err}/>
        <Dialog open={open} onClose={handleCloseDialog}
        sx={{
            "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here,
                background:"#211c25",
                color:"#fff",
                marginBottom:"50px",

            },
            },
        }}
        >
            <DialogTitleV2 title={"Denetim Soru Sayısını Düzenle"}/>
            <Divider style={{background:"white"}}/>
            <DialogContent>
                <TextFieldV2 value={numberOfQuestions} setValue={setNumberOfQuestions} type={"number"} label="Denetim Soru Sayısı"/>
            </DialogContent>
            <DialogActions>
            <EditButton onClick={handleCloseDialog}>
                İptal
            </EditButton>
            <EditButton active={numberOfQuestions?.length > 0} onClick={handleSubmit}>
                Kaydet
            </EditButton>
            </DialogActions>
        </Dialog>
    </React.Fragment>

  )
}

export default EditNumberOfQuestions