import styled from "styled-components";


export const LogoHolder = styled.img`
    width: 55%;
    object-fit:contain;
    margin-bottom:15px;
    cursor:pointer;
    filter: invert(100%);
    -webkit-filter: invert(100%); /* Safari/Chrome */
`

export const LoginContainer = styled.div`
    background: #0000005e;
    filter: drop-shadow(2px 4px 6px black);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    border-radius:10px;
    padding:50px;
    width:400px; 
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    box-shadow: 0 5px 20px 0 rgba(109, 110, 111, 0.08);
    @media screen and (max-width:420px) {
        width:320px;
        padding:20px;
    }
`
export const LargeHeading = styled.h1`
    font-size:32px;
    line-height:40px;
    margin-bottom:32px;
    text-align:center;
    font-weight:400;
    color:#1e1f21;
`

export const MainForm = styled.form`
    width: '100%', // Fix IE 11 issue.
    margin-top: 10px,
    height: 100vh;
    align-self:center;
`

export const LoginFooter = styled.div`
    align-items: center;
    color: #6d6e6f;
    display: flex;
    flex-direction: column;
    max-width:350px;
`

export const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap:25px;
    justify-content: center;
    align-items:center;
    padding-inline-start: 0;
    color:#fff;

`
export const ListItem = styled.li`
    font-size: 14px;
`
export const ListItemLink = styled.a`
    text-decoration:none;
    color:#fff;
`