import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,  FormControl,  FormControlLabel,  FormLabel,  Grid, InputLabel,  MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { TopNavActions } from '../../../Subcomponents/AuditDashboardsContainer/activitiesdashboard'
import { EditButton } from '../MainSettings'

const UserAddDialog = ({open, setOpen,getInfoBack,company_id,setErrorOpen,setSuccessOpen}) => {
    const [stage, setStage] = useState(0)
    const [region, setRegion] = useState(null)
    const [regionData, setRegionData] = useState(null)
    const [parent,  setParent] = useState(null)
    const [regionManager, setRegionManager] = useState(false)
    const [departmentManager, setDepartmentManager] = useState(false)
    const handelChangeRegionManager = (event) =>{
        setRegionManager(event.target.checked)
        setParent(null)
        setDepartmentManager(false)
    }
    const handelChangeDepartmentManager = (event) =>{
        setDepartmentManager(event.target.checked)
    }
    async function getRegionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, config);
            setRegionData(res.data)
        } catch (err) {
        }
      }
      useEffect(() =>{
        if(open){
            getRegionInfo()
        }
    },[open])
    const handleSelectRegion = (e) => {
        console.log(e,"ee bune")
        setRegion(e.target.value)
    }
    const handleSelectParents = (e) => {
        console.log(e,"ee bune")
        setParent(e.target.value)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
    }


  

      const [UserDepartmentRelation, setUserDepartmentRelation] = useState(null)
      const handleChange = (event) => {
        if(event.target.value === "holding"){
            setUserDepartmentRelation(event.target.value)
            setRegion(null)
            setParent(null)
            setDepartmentManager(false)
            setRegionManager(false)
        }
        else{
            setUserDepartmentRelation(event.target.value)
        }
      }
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [surname, setSurname] = useState(null)
    const [phone, setPhone] = useState(null)
    const [password, setPassword] = useState(null)
    const [password_re, setPasswordRe] = useState(null)
    const [agreement, setAgreement] = useState(false)
    function handleChangeAgreement(event){
        setAgreement(event.target.checked)
    }

    const [sectionRegionData, setSectionRegionData] = useState([])
    async function getSectionRegionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/section/get_childeren/region/${region}/`, config);
            setSectionRegionData(res.data)
        } catch (err) {
        }
      }
      useEffect(() => {
        if (open){
            getSectionRegionInfo()
        }
         // eslint-disable-next-line
      },[region,open])
      
      const departmentOptions = regionData?.filter(e => e.id === region)

      const [holdingManager, setHoldingManager] = useState(false)

      const handleChangeHoldingManger = (event) => {
        setHoldingManager(event.target.checked)
      } 


      async function handleSubmitUserCreate(){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        const body = JSON.stringify({
            email: email,
            name: name,
            surname: surname,
            phone:phone,
            company_name: localStorage.getItem('company'),
            region: [region],
            department: [parent],
            is_department_manager: departmentManager,
            is_region_manager: regionManager,
            is_holding_manager: holdingManager,
            is_holding_user: UserDepartmentRelation === "holding" ? true : false,
            password:password, 
            re_password:password_re
        })
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/create/`, body, config);
            getInfoBack()
            handleCloseDialog()
            setEmail(null)
            setName(null)
            setSurname(null)
            setPhone(null)
            setRegion(null)
            setParent(null)
            setDepartmentManager(false)
            setRegionManager(false)
            setHoldingManager(false)
            setUserDepartmentRelation(null)
            setPassword(null)
            setPasswordRe(null)
            setSuccessOpen(true)
        } catch (err) {
            getInfoBack()
            setErrorOpen(true)
        }
      }
     



    
  return ( 
    <Dialog open={open} onClose={handleCloseDialog}
    sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        
        <DialogTitle>
            <Grid container spacing={2} justifyContent={"center"} alignItems="center">
                <Grid container item xs={12}>
                  <Grid item container xs={4} style={{flexDirection:"row"}}>
                  <TopNavActions id="close" style={{ marginLeft:"-5px"}} onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} onClick={handleCloseDialog} background={"#ee5c54"}>
                            <p style={{fontSize:"8px", display:iconCloseDisplay}}>X</p>
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  </Grid>
                  <Grid item xs={4} style={{fontSize:"15px", justifyContent:"center", alignItems:"center", display:"flex"}}>Yeni Kullanıcı Ekle</Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
            </Grid>
        </DialogTitle>
        {stage  === 0 ?
        <DialogContent style={{display:"flex", flexDirection:"column", gap:"15px", paddingTop:"10px"}}>
            <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"  style={{color:"white"}}>Kullanıcının Birimini Seçiniz</FormLabel>
            <RadioGroup
                style={{color:"white"}}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="holding"
                name="radio-buttons-group"
                value={UserDepartmentRelation}
                onChange={handleChange}
            >
                <FormControlLabel value="holding" control={<Radio
                sx={{
                    color: "white",
                    '&.Mui-checked': {
                    color: "white",
                    },
                }}
                />} label="Merkeze bağlı kullanıcı" />
                <FormControlLabel value="region" control={<Radio
                sx={{
                    color: "white",
                    '&.Mui-checked': {
                    color: "white",
                    },
                }}
                />} label="Bir bölgeye bağlı kullanıcı" />
            </RadioGroup>
            </FormControl>
        </DialogContent>
        :
        stage === 1 ?
        UserDepartmentRelation === "holding" ? 
        <DialogContent style={{display:"flex", flexDirection:"column", gap:"15px", paddingTop:"10px"}}>
            <TextField value={name} onChange={(e) => setName(e.target.value)} label="Kullanıcı İsmi" fullWidth 
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}
            /> 
            <TextField value={surname} onChange={(e) => setSurname(e.target.value)} label="Kullanıcı Soyismi" fullWidth 
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}
            /> 
            <TextField value={phone} type="number" onChange={(e) => setPhone(e.target.value)} label="Kullanıcı Telefonu" fullWidth 
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}
            /> 
            <TextField value={email} onChange={(e) => setEmail(e.target.value)} label="Kullanıcı E-Posta Adresi" fullWidth 
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}
            /> 
            <TextField value={password} type="password" onChange={(e) => setPassword(e.target.value)} label="Kullanıcı Şifresi" fullWidth 
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}
            /> 
            <TextField value={password_re} type="password" onChange={(e) => setPasswordRe(e.target.value)} label="Kullanıcı Şifresi Tekrarı" fullWidth 
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}
            /> 
              <FormControlLabel
                control={
                <Checkbox checked={holdingManager} onChange={handleChangeHoldingManger} name="gilad" 
                sx={{
                    color: "white",
                    '&.Mui-checked': {
                    color: "white",
                    },
                }}
                />
                }
                label="Yönetici Kullanıcı"
            />
            <a href="//onkasa.com/user-agreement" style={{color:"white"}}>
              <FormControlLabel
                control={
                <Checkbox checked={agreement} onChange={handleChangeAgreement} name="gilad" 
                sx={{
                    color: "white",
                    '&.Mui-checked': {
                    color: "white",
                    },
                }}
                />
                }
                label="Kullanıcı sözleşmesini onaylıyorum"
            />
            
            </a>
        </DialogContent>
        :
        <DialogContent style={{display:"flex", flexDirection:"column", gap:"15px", paddingTop:"10px"}}>
        <FormControl fullWidth
         InputLabelProps={{
             style:{
                 color:"#A3A2A1"
         }
         }}
         sx={{
             input: {
                 color: "white",
             },
             width:"100%",
             '& label.Mui-focused': { color: 'white',},
             '& .MuiOutlinedInput-root': {
                 '& fieldset': {
                 borderColor: 'white',
                 },
                 '&:hover fieldset': {
                 borderColor: 'white',
                 },
                 '&.Mui-focused fieldset': {
                 borderColor: 'white',
                 },}
             }}
        >
         <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Bölge Seçin</InputLabel>
         <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             value={region}
             label="Bölge Seçin"
             onChange={handleSelectRegion}
             style={{color:"#fff"}}
         >
             {regionData?.map((e) => (
             <MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>
             ))}
         </Select>
         </FormControl>
        {region ? 
        <React.Fragment>
            <FormControlLabel
               control={
               <Checkbox checked={regionManager} onChange={handelChangeRegionManager} name="gilad" 
               sx={{
                   color: "white",
                   '&.Mui-checked': {
                   color: "white",
                   },
               }}
               />
               }
               label="Bölge Sorumlusu"
           />
           {!regionManager ?
            <FormControl fullWidth
         InputLabelProps={{
             style:{
                 color:"#A3A2A1"
         }
         }}
         sx={{
             input: {
                 color: "white",
             },
             width:"100%",
             '& label.Mui-focused': { color: 'white',},
             '& .MuiOutlinedInput-root': {
                 '& fieldset': {
                 borderColor: 'white',
                 },
                 '&:hover fieldset': {
                 borderColor: 'white',
                 },
                 '&.Mui-focused fieldset': {
                 borderColor: 'white',
                 },}
             }}
        >
         <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Departman Seçin</InputLabel>
         <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             value={parent}
             label="Departman Seçin"
             onChange={handleSelectParents}
             style={{color:"#fff"}}
         >
                {departmentOptions?.map(parent => (
                    <MenuItem value={parent?.id}>{parent?.name}</MenuItem>
                ))}
                 {departmentOptions?.map(parent => (
                    parent?.children?.map(child => (
                    <MenuItem value={child?.id}>{parent?.name} - {child?.name}</MenuItem>
                ))))}
                 {departmentOptions?.map(parent => (
                    parent?.children?.map(child => (
                        child?.children?.map(child2 => (
                            <MenuItem value={child2?.id}>{parent?.name} - {child?.name} - {child2?.name}</MenuItem>
                        ))
                ))))}
                 {departmentOptions?.map(parent => (
                    parent?.children?.map(child => (
                        child?.children?.map(child2 => (
                            child2?.children?.map(child3 => (
                                <MenuItem value={child3?.id}>{parent?.name}-{child?.name}-{child2?.name}-{child3?.name}</MenuItem>
                            ))
                        ))
                ))))}
         </Select>
         <FormControlLabel
               control={
               <Checkbox checked={departmentManager} onChange={handelChangeDepartmentManager} name="gilad" 
               sx={{
                   color: "white",
                   '&.Mui-checked': {
                   color: "white",
                   },
               }}
               />
               }
               label="Departman Sorumlusu"
           />
         </FormControl>
         :
            ""
            }
        </React.Fragment>
              
            :
          ""
        }   
     </DialogContent>
        :
        <DialogContent style={{display:"flex", flexDirection:"column", gap:"15px", paddingTop:"10px"}}>
        <TextField value={name} onChange={(e) => setName(e.target.value)} label="Kullanıcı İsmi" fullWidth 
        InputLabelProps={{
            style:{
                color:"#A3A2A1"
        }
        }}
        sx={{
            input: {
                color: "white",
            },
            width:"100%",
            '& label.Mui-focused': { color: 'white',},
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: 'white',
                },
                '&:hover fieldset': {
                borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                borderColor: 'white',
                },}
            }}
        /> 
        <TextField value={surname} onChange={(e) => setSurname(e.target.value)} label="Kullanıcı Soyismi" fullWidth 
        InputLabelProps={{
            style:{
                color:"#A3A2A1"
        }
        }}
        sx={{
            input: {
                color: "white",
            },
            width:"100%",
            '& label.Mui-focused': { color: 'white',},
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: 'white',
                },
                '&:hover fieldset': {
                borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                borderColor: 'white',
                },}
            }}
        /> 
        <TextField value={phone} type="number" onChange={(e) => setPhone(e.target.value)} label="Kullanıcı Telefonu" fullWidth 
        InputLabelProps={{
            style:{
                color:"#A3A2A1"
        }
        }}
        sx={{
            input: {
                color: "white",
            },
            width:"100%",
            '& label.Mui-focused': { color: 'white',},
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: 'white',
                },
                '&:hover fieldset': {
                borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                borderColor: 'white',
                },}
            }}
        /> 
        <TextField value={email} onChange={(e) => setEmail(e.target.value)} label="Kullanıcı E-Posta Adresi" fullWidth 
        InputLabelProps={{
            style:{
                color:"#A3A2A1"
        }
        }}
        sx={{
            input: {
                color: "white",
            },
            width:"100%",
            '& label.Mui-focused': { color: 'white',},
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: 'white',
                },
                '&:hover fieldset': {
                borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                borderColor: 'white',
                },}
            }}
        /> 
        <TextField value={password} type="password" onChange={(e) => setPassword(e.target.value)} label="Kullanıcı Şifresi" fullWidth 
        InputLabelProps={{
            style:{
                color:"#A3A2A1"
        }
        }}
        sx={{
            input: {
                color: "white",
            },
            width:"100%",
            '& label.Mui-focused': { color: 'white',},
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: 'white',
                },
                '&:hover fieldset': {
                borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                borderColor: 'white',
                },}
            }}
        /> 
        <TextField value={password_re} type="password" onChange={(e) => setPasswordRe(e.target.value)} label="Kullanıcı Şifresi Tekrarı" fullWidth 
        InputLabelProps={{
            style:{
                color:"#A3A2A1"
        }
        }}
        sx={{
            input: {
                color: "white",
            },
            width:"100%",
            '& label.Mui-focused': { color: 'white',},
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: 'white',
                },
                '&:hover fieldset': {
                borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                borderColor: 'white',
                },}
            }}
        /> 
        <a href="//onkasa.com/user-agreement" style={{color:"white"}}>
          <FormControlLabel
            control={
            <Checkbox checked={agreement} onChange={handleChangeAgreement} name="gilad" 
            sx={{
                color: "white",
                '&.Mui-checked': {
                color: "white",
                },
            }}
            />
            }
            label="Kullanıcı sözleşmesini onaylıyorum"
        />
        </a>
        </DialogContent>
        }
        {stage === 0 ? 
        <DialogActions>
            <EditButton style={{marginRight:"10px"}} onClick={handleCloseDialog}>
              İptal
            </EditButton>
            {UserDepartmentRelation ? 
            <EditButton active style={{marginRight:"10px"}} onClick={() => setStage(stage+1)}>
              İleri
            </EditButton>
            :
            <EditButton style={{marginRight:"10px"}} >
                İleri
            </EditButton>
            }
        </DialogActions>
        :
        stage === 1 
        ?
        <DialogActions>
            <EditButton style={{marginRight:"10px"}} onClick={() => setStage(stage-1)}>
            Geri
            </EditButton>
            {UserDepartmentRelation === "holding" ? 
            agreement ?
            <EditButton active style={{marginRight:"10px"}} onClick={handleSubmitUserCreate}>
            Kaydet
            </EditButton>
            :
            <EditButton style={{marginRight:"10px"}}>
            Kaydet
            </EditButton>
            :
            region ? 
            <EditButton active style={{marginRight:"10px"}}  onClick={() => setStage(stage+1)}>
                İleri
            </EditButton>
            :
            <EditButton style={{marginRight:"10px"}} >
                İleri
            </EditButton>
            }
        </DialogActions>
        :
        <DialogActions>
        <EditButton style={{marginRight:"10px"}} onClick={() => setStage(stage-1)}>
        Geri
        </EditButton>
        {
        agreement ?
        <EditButton active style={{marginRight:"10px"}} onClick={handleSubmitUserCreate}>
        Kaydet
        </EditButton>
        :
        <EditButton style={{marginRight:"10px"}}>
        Kaydet
        </EditButton>
        
        }
         </DialogActions>
        }
      
    </Dialog>
  )
}

export default UserAddDialog