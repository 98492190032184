import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import React, {useState, useEffect} from 'react'
import AutoCompleteV2 from '../../../../../Assets/AutoCompleteV2'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import TextFieldV2 from '../../../../../Assets/TextFieldV2'
import { EditButton } from '../../../../CompanySettings/MainSettings'

const EditCardInfo = ({open, setOpen,data,cardId,getAuditCards, setSuccessMsgForUpdateCard,getCardsAgain}) => {
    const [employees, setEmployees] = useState([])
    const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
  };
    async function getEmployees(){
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/get_by_level/`,  axiosConfig);
        setEmployees(res.data)
      }
      catch{
  
      }
    }
    useEffect(()=>{
      if(open){
        getEmployees()
      }
    },[open])
    
    const [singleAudit, setSingleAudit] = useState(null)
    const handleChangeSingleAudit = (event) => {
        setSingleAudit(event.target.value)
      }
     
    const [buttonStart, setButtonStart] = useState(false)
    const handleCloseDialog = () => {
        setOpen(false)
        setDate(null)
    }
    async function handleDelete (){
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({date: date ? date: null, audit_id: singleAudit?.id, cardUpdate: true})
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/update-delete/${cardId}/`, body, config);
            getAuditCards()
            setOpen(false)
            setSuccessMsgForUpdateCard(true)
            setButtonStart(false)
            setDate(data?.filter(j => j?.id === cardId).map((e) => e?.date)[0])
            getCardsAgain()
        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)
            setDate(data?.filter(j => j?.id === cardId).map((e) => e?.date)[0])
            setSingleAudit(null)
            getAuditCards()
            
        }
    } 
    const [date, setDate] = useState(data?.filter(j => j?.id === cardId).map((e) => e?.date)[0])

  return (
        <Dialog open={open} onClose={handleCloseDialog}
        sx={{
            "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here,
                background:"#1e1f21",
                color:"#fff",
                marginBottom:"50px"
            },
            },
        }}
        >
        <DialogTitleV2 handleCloseDialog={handleCloseDialog} title={"Denetim Kartını Düzenle"}/>
        <DialogContent>
        <Grid item xs={12} mt={2} mb={2}>
            Mevcut Sorumlu: {data?.filter(j => j?.id === cardId).map((e) => e?.auditer?.name + " " + e?.auditer?.surname)}
        </Grid>
        <Grid item xs={12} mt={2} mb={2}>
        <Grid item xs={12} mt={2} mb={2}>
            <AutoCompleteV2 label="Sorumlu Seç" options={employees} value={singleAudit} setValue={setSingleAudit} user/>
        </Grid>
        </Grid>
        <Grid item xs={12}>  
            <TextFieldV2 label="Termin Tarihi" type="date" value={date} setValue={setDate}/>   
        </Grid> 
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleDelete} active>Düzenle</EditButton> : <EditButton>Düzenleniyor...</EditButton>}
        </DialogActions>
    </Dialog>
  )
}

export default EditCardInfo