import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';



export const SuggestionPDCASidebarItems = [
    {
        id:1,
        name:"Termini Geçenler",
        icon:(<RunningWithErrorsIcon fontSize="small"/>),
        urlPath: "/app/suggestions-app/over_due"
    },
    {
        id:2,
        name:"Yapılacaklar",
        icon:(<TimerIcon fontSize="small"/>),
        urlPath: "/app/suggestions-app/doing"
    },
    {
        id:3,
        name:"Onay Bekleyenler",
        icon:(<TimerOffIcon fontSize="small"/>),
        urlPath: "/app/suggestions-app/control"
    },
    {
        id:4,
        name:"Tamamlananlar",
        icon:(<AlarmOnIcon fontSize="small"/>),
        urlPath: "/app/suggestions-app/done"
    },
]