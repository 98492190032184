import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const OverDueChart = ({on_time_complete, over_due_complete}) => {
  
  const data = { 
    labels: ['Zamanında Tamamlanan', 'Termini Geçirilen'],
    datasets: [
    {
      label: '# of Votes',
      data: [on_time_complete, over_due_complete],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
  return (
    <div style={{width:"250px", height:"250px" }}>
      <Doughnut data={data}
       options={{
        responsive: true,
        maintainAspectRatio: true,
      }}
      />
    </div>
  )
}

export default OverDueChart