import { FormControl, Grid, InputLabel, Button, MenuItem, Select, Tooltip } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import AutoCompleteV2 from '../../../Assets/AutoCompleteV2'
import TextFieldV2 from '../../../Assets/TextFieldV2'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../CompanySettings/MainSettings'
import { styled as styledmui} from '@mui/material/styles'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import getFileIcon from '../../../Assets/GetFileIconsFunction'
import { TaskImageHolder } from '../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import { FileHolder, FileName } from '../../../Assets/GeneralDesignElements'

const CreateComplaints = () => {
    const Input = styledmui('input')({
        display: 'none',
    });
    let navigate = useNavigate()
    const [files, setFiles] = useState([])
    function getFileExtension(filename) {
        return filename.split('.').pop();
      }
      const handleFileChange = (event) => {
        const newFiles = [];
        for (let i = 0; i < event.target.files.length; i++) {
          newFiles.push(event.target.files[i]);
        }
        setFiles((prevFiles) => [...newFiles, ...prevFiles]);
      };
      const handleRemoveFile = (indexToRemove) => {
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles.splice(indexToRemove, 1);
          return newFiles;
        });
      };
  const [customerList, setCustomerList] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [heading, setHeading] = useState(null)
  const [details, setDetails] = useState(null)
  const [regionList, setRegionList] = useState([])
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [selectedSection, setSelectedSection] = useState(null)
  const departmentOptions =regionList? regionList?.filter(e => e.id === selectedRegion?.id) : []
  const [categoryList, setCategoryList] = useState([])
  const [selectedCategory,setSelectedCategory] = useState([]) 
  const [userList, setUserList] = useState([])
  const [selectedInformedUsers, setSelectedInformedUsers] = useState([])
  const [selectedManagementSystems, setSelectedManagementSystems] = useState(null)
  const [managementSystemsList, setManagementSystemsList] = useState([])
  const [processList, setProcessList] = useState([])
  const [selectedProcess, setSelectedProcess]  = useState(null)
  const [productsList, setProductsList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null)
  const [selectedSolutionTeamMember, setSelectedSolutionTeamMember] = useState([])
  const [due_date, setDueDate] = useState(moment().add(7,"days").format("YYYY-MM-DD"))
    const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
    };
    const axiosConfigFormData = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };
    async function getCustomerInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/customers/`,  axiosConfig);
            setCustomerList(res.data)
        }
        catch{

        }
    }
    async function getRegionInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`,  axiosConfig);
            setRegionList(res.data)
        }
        catch{

        }
    }
    async function getCategoryInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/ecc/category/`,  axiosConfig);
            setCategoryList(res.data)
        }
        catch{

        }
    }
    async function getUserList (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/create/`,  axiosConfig);
            setUserList(res.data)
        }
        catch{

        }
    }
    async function getManagementSystems (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/management-systems/`,  axiosConfig);
            setManagementSystemsList(res.data)
        }
        catch{

        }
    }
    async function getProcessList (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/process/`,  axiosConfig);
            setProcessList(res.data)
        }
        catch{

        }
    }
    async function getProductsList (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/products/`,  axiosConfig);
            setProductsList(res.data)
        }
        catch{

        }
    }
useEffect(() => {
    getCustomerInfo();
    getRegionInfo();
    getCategoryInfo();
    getUserList();
    getManagementSystems();
    getProcessList();
    getProductsList();
},[])

    async function handleSubmit(){
    
        let form_data = new FormData();
        for (let i = 0; i < files.length; i++) {
            form_data.append(`file${i + 1}`, files[i]);
        }
        form_data.append('customer_id', selectedCustomer?.id)
        form_data.append('heading', heading)
        form_data.append('details', details)
        form_data.append('region_id', selectedRegion?.id)
        form_data.append('section_id', selectedSection)
        form_data.append('category', selectedCategory?.map((e) => e.id))
        form_data.append('informed_users', selectedInformedUsers?.map((e) => e.id))
        form_data.append('quality_system_id', selectedManagementSystems?.id)
        form_data.append('process_id', selectedProcess?.id)
        form_data.append('product', selectedProduct?.id)
        form_data.append('team_leader', selectedTeamLeader?.id)
        form_data.append('solution_team', selectedSolutionTeamMember?.map((e) => e.id))
        form_data.append('due_date', moment(due_date).format("YYYY-MM-DD"))
        try{
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/ecc/create/`, form_data, axiosConfigFormData);
            setProductsList(res.data)
            navigate(`/app/external-customer-complaints-app/activities`)
        }
        catch{

        }
    }
return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <Grid container gap={1} justifyContent={"space-between"}>
          <Grid item  container xs={12} md={7} justifyContent={"space-between"}>
            <InfoBar>
                <h1>Şikayet Detayları</h1>

                <EditButton active onClick={handleSubmit}>Kaydet</EditButton>
            </InfoBar>
            <AutoCompleteV2 label={"Müşteri"} value={selectedCustomer} setValue={setSelectedCustomer} options={customerList} />
            <TextFieldV2 label={"Şikayet Tanımı"} value={heading} setValue={setHeading} />
            <TextFieldV2 label={"Şikayet Detayı"} value={details} setValue={setDetails} />
            <Grid item xs={5}>
            <AutoCompleteV2 label={"Bölge"} options={regionList}  value={selectedRegion} setValue={setSelectedRegion} />
            </Grid>
            <Grid item xs={5}>
            <FormControl fullWidth
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
            marginBottom:"15px",
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}>
                      <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Departman Seçin</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedSection}
                        label="Departman Seçin"
                        onChange={(e)=> setSelectedSection(e.target.value)}
                        style={{color:"#fff"}}
                      >
                         
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                <MenuItem value={child?.id}>{child?.name}</MenuItem>
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        <MenuItem value={child2?.id}>{child?.name} - {child2?.name}</MenuItem>
                                    ))
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        child2?.children?.map(child3 => (
                                            <MenuItem value={child3?.id}>{child?.name} - {child2?.name} - {child3?.name}</MenuItem>
                                        ))
                                    ))
                            ))))}
                  </Select>
              </FormControl>
              </Grid>
            <FormControl fullWidth
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
            marginBottom:"15px",
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}>
                <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Şikayet Kategorisi</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCategory}
                    label="Şikayet Kategorisi"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    style={{color:"#fff"}}
                    multiple
                >
                
                {categoryList?.map((e) => (
                    <MenuItem value={e} key={e.id}>{e.name}</MenuItem>
                ))}
                </Select>
            </FormControl>
            <AutoCompleteV2 label={"Bilgilendirilecek Kişiler"} user multiple value={selectedInformedUsers} setValue={setSelectedInformedUsers} options={userList} />
            <Grid item xs={5}>
                <AutoCompleteV2 label={"Yönetim Sistemi"} options={managementSystemsList} value={selectedManagementSystems} setValue={setSelectedManagementSystems} />
            </Grid>
            <Grid item xs={5}>
                <AutoCompleteV2 label={"Süreç"} options={processList} value={selectedProcess} setValue={setSelectedProcess} />
            </Grid>
            <AutoCompleteV2 label={"Ürün"} options={productsList} value={selectedProduct} setValue={setSelectedProduct}/>
            <Grid item xs={12} mt={2} mb={2} align="right">     
            <label htmlFor="contained-button">
                <Input accept="*/*" id="contained-button" multiple   type="file" onChange={handleFileChange} />
                <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={"info"}>
                    Ek döküman ekle &nbsp; <FileUploadIcon/> 
                </Button>
            </label>
            </Grid>
            {files?.length > 0 ?
            <>
            <h5>Dosyalar</h5>
            <TaskImageHolder>
            {files?.map((e,index) => (
              <Tooltip title={e?.name + "Listeden çıkarmak için tıklayın"} arrow>
                  <a onClick={() => handleRemoveFile(index)} style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                <FileHolder>
                  {getFileIcon(getFileExtension(e?.name))}
                  <FileName>{e?.name}</FileName>
                </FileHolder>
                </a>
              </Tooltip>
                ))}
            </TaskImageHolder>
            </>
          :
          ""}
          </Grid>
          <Grid item xs={12} md={4}>
              <h1>Çözüm Ekibi</h1>
                <AutoCompleteV2 label={"Ekip Lideri"} user options={userList} value={selectedTeamLeader} setValue={setSelectedTeamLeader} />
                <AutoCompleteV2 label={"Çözüm Ekibi"} user multiple options={userList} value={selectedSolutionTeamMember} setValue={setSelectedSolutionTeamMember} />
                <TextFieldV2 type={"date"} value={due_date} setValue={setDueDate} label={"Termin Tarihi"} focused />
          </Grid>
        </Grid>
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default CreateComplaints