import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import ProcessManagmentTable from './ProcessManagmentTable'

const SuggestionProcessManagment = ({user}) => {
    const [level, setLevel] = useState(false)
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
        setIsLoading(true)
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/get/process_manager_suggestions/?t=${level ? "admin": "user"}`,  axiosConfig);
            setData(res.data)
            setIsLoading(false)
        }
        catch{
            setIsLoading(false)

        }
    }
    useEffect(() => {getInfo()},[level])
  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
            {user?.type?.includes("manager") ?
            <InfoBar>
                <FormGroup>
                    <FormControlLabel
                    FormControlLabel control={<Switch checked={level} onChange={(e) => setLevel(!level)} defaultChecked />} label="Tümünü Görüntüle" 
                    />
                </FormGroup>
            </InfoBar>
            :""}
            <ProcessManagmentTable isLoading={isLoading}data={data}/>
        </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}
const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps)(SuggestionProcessManagment)