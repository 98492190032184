import HomeIcon from '@mui/icons-material/Home';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArchiveIcon from '@mui/icons-material/Archive';
export const ECCNav = [
    {
        id:1,
        name:"Anasayfa",
        icon:(<HomeIcon fontSize="small"/>),
        urlPath: "/app/external-customer-complaints-app",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:2,
        name:"Şikayet İşlemleri",
        icon:(<SupportAgentIcon fontSize="small"/>),
        urlPath: "/app/external-customer-complaints-app/activities",
        childeren_urlPath : [
            {
                id:1,
                urlPath:"/app/external-customer-complaints-app/activities/main/process",
            }
        ],
        show_on_side_bar: true,
    },
    {
        id:3,
        name:"Çözüm Yönetimi",
        icon:(<DashboardCustomizeIcon fontSize="small"/>),
        urlPath: "/app/external-customer-complaints-app/requests",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:9,
        name:"Şikayet Havuzu",
        icon:(<ArchiveIcon fontSize="small"/>),
        urlPath: "/app/external-customer-complaints-app/pool",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:4,
        name:"Raporlar",
        icon:(<AssessmentIcon fontSize="small"/>),
        urlPath: "/app/external-customer-complaints-app/reports",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:6,
        name:"Ayarlar",
        icon:(null),
        urlPath: "/app/external-customer-complaints-app/settings",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:7,
        name:"Yeni Şikayet Oluştur",
        icon:(null),
        urlPath: "/app/external-customer-complaints-app/activities/create",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:8,
        name:"Süreç Yönetimi",
        icon:(null),
        urlPath: "/app/external-customer-complaints-app/activities/main/process",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
   
   
    
]