import HomeIcon from '@mui/icons-material/Home';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RuleIcon from '@mui/icons-material/Rule';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import Diversity3Icon from '@mui/icons-material/Diversity3';

export const SuggestionSidebarItems = [
    {
        id:1,
        name:"Anasayfa",
        icon:(<HomeIcon fontSize="small"/>),
        urlPath: "/app/suggestions-app",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:2,
        name:"Öneri Faaliyetleri",
        icon:(<VerifiedUserIcon fontSize="small"/>),
        urlPath: "/app/suggestions-app/activities",
        childeren_urlPath : [
         {
            id:1,
            urlPath:"/app/suggestions-app/activities/create"
         },
         {
            id:2,
            urlPath:"/app/suggestions-app/activities/process"
         },
         {
            id:3,
            urlPath:"/app/suggestions-app/activities/solution"
         },
        ],
        show_on_side_bar: true,
    },
    {
        id:9,
        name:"Komüte Değerlendirmesi",
        icon:(<Diversity3Icon fontSize="small"/>),
        urlPath: "/app/suggestions-app/committee",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    // {
    //     id:3,
    //     name:"Kazançlar",
    //     icon:(<RuleIcon fontSize="small"/>),
    //     urlPath: "/app/suggestions-app/earnings",
    //     childeren_urlPath : null,
    //     show_on_side_bar: true,
    // },
    
    {
        id:7,
        name:"Öneri Havuzu",
        icon:(<DynamicFormIcon fontSize="small"/>),
        urlPath: "/app/suggestions-app/pool",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:4,
        name:"Raporlar",
        icon:(<AssessmentIcon fontSize="small"/>),
        urlPath: "/app/suggestions-app/reports",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:5,
        name:"Öneri Sistemi Ayarları",
        icon:(null),
        urlPath: "/app/suggestions-app/settings",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:6,
        name:"Yeni Öneri Oluştur",
        icon:(null),
        urlPath: "/app/suggestions-app/activities/create",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:8,
        name:"Öneri Süreç Yönetimi",
        icon:(null),
        urlPath: "/app/suggestions-app/activities/process",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:9,
        name:"Öneri Çözüm Yönetimi",
        icon:(null),
        urlPath: "/app/suggestions-app/activities/solution",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
]