import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Table, TableBody,  TableContainer, TableHead } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { connect } from 'react-redux';

const FollowTheActionsDialog = ({user,open, setOpen, handleClickOpenCompleteFindingDialog, findingDetailArray,handleOpenEditAction, handleCloseRequestAdditionalActionDialog, handleOpenDeleteActionRequest, handleOpenControlSubActionDialog}) => {
    const handelCloseFollowTheActionDialog = () => {
        setOpen(false)
    }
    const addNewActionRequest = () => {
        setOpen(false)
        handleCloseRequestAdditionalActionDialog()
    } 
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: "white",
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          color:"white"
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        color:"white",
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
          color:"white"
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
  return (
    <Dialog open={open} onClose={handelCloseFollowTheActionDialog}
    sx={{
        "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "900px",  // Set your width here,
            background:"#1e1f21",
            color:"#fff",
            marginBottom:"50px"
        },
        },
    }}
    >
        <DialogTitle>Aksiyonları Takip Et</DialogTitle>
        <Divider style={{background:"#a3a2a1"}}/>
        <DialogContent>
        <TableContainer>
            <Table sx={{minWidth:600}}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Sorumlu</StyledTableCell>
                        <StyledTableCell>pdca</StyledTableCell>
                        <StyledTableCell>Aksiyon</StyledTableCell>
                        <StyledTableCell>Termin</StyledTableCell>
                        <StyledTableCell>İncele Onayla</StyledTableCell>
                        <StyledTableCell>Aksiyon Ekle</StyledTableCell>
                        <StyledTableCell>Sil</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{color:"white"}}>
                    {findingDetailArray?.findings_action?.map((e) => (
                    <StyledTableRow key={e.id}>
                        <StyledTableCell>
                            {e.responsible_by ? e.responsible_by?.name + " " + e.responsible_by?.surname : "sorumlu atanmadı"}
                        </StyledTableCell>
                        <StyledTableCell>
                            {e.pdca}
                        </StyledTableCell>
                        <StyledTableCell>
                            {e.action_brief}
                        </StyledTableCell>
                        <StyledTableCell>
                            {e.action_due}
                        </StyledTableCell>
                        <StyledTableCell>
                            <Button variant="contained"
                            disabled={e.pdca === "Do" || e.pdca === "Act"}
                            onClick={() => handleOpenControlSubActionDialog(e)}
                            >
                             {e.pdca === "Do" ? "Aksiyon Alınmadı" : e.pdca === "Control" ? "İncele Onayla" : "Onaylanan Aksiyonu Düzenle"}   
                            </Button>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Button variant="contained" onClick={() => handleOpenEditAction(e)}
                            color={e.pdca === "Do" ? "primary" : "warning"}
                            >
                                {e.pdca === "Do" ? "Aksiyon Ekle" : e.pdca === "Control" ? "Aksiyonu Düzenle" : "Onaylanan Aksiyonu Düzenle"}   
                            </Button>
                        </StyledTableCell>
                        {e.created_by?.id === user?.id || user?.type === "Manager" ?
                        <StyledTableCell>
                         <Button variant="contained" color="error" onClick={() => handleOpenDeleteActionRequest(e)}>Sil</Button>
                        </StyledTableCell>
                        :
                        null
                        }
                    </StyledTableRow>
                   ))}
                </TableBody>
            </Table>
            </TableContainer>
        </DialogContent>
        <Divider style={{background:"#a3a2a1"}}/>
        <DialogActions>
              <Button onClick={handelCloseFollowTheActionDialog}>İptal</Button>  
              <Button onClick={addNewActionRequest} variant='contained' color="warning">Ek Aksyion Ekle</Button>  
              <Button onClick={() => handleClickOpenCompleteFindingDialog(findingDetailArray)} variant='contained' color="success">Tüm Aksiyonları Tamamla</Button>  
        </DialogActions>        
    </Dialog>
  )
}

const mapStateToProps = state => (
{   
    user: state.auth.user
})
export default connect(mapStateToProps)(FollowTheActionsDialog)