import { DialogTitle, Grid } from '@mui/material'
import React, { useState } from 'react'
import { TopNavActions } from '../Subcomponents/AuditDashboardsContainer/activitiesdashboard'

const DialogTitleV2 = ({title,handleCloseDialog}) => {
    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
    }
  return (
    <DialogTitle>
        <Grid container spacing={2} justifyContent={"center"} alignItems="center">
            <Grid container item xs={12}>
            <Grid item container xs={4} style={{flexDirection:"row"}}>
            <TopNavActions id="close" style={{ marginLeft:"-5px"}} onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} onClick={handleCloseDialog} background={"#ee5c54"}>
                        <p style={{fontSize:"8px", display:iconCloseDisplay}}>X</p>
            </TopNavActions>
            <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
            </TopNavActions>
            <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
            </TopNavActions>
            </Grid>
            <Grid item xs={6} style={{fontSize:"15px"}}>{title}</Grid>
            <Grid item xs={2}></Grid>
            </Grid>
        </Grid>
    </DialogTitle>
  )
}

export default DialogTitleV2