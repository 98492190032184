import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Button, Divider, Grid, Table, TableCell, TableRow, TextareaAutosize, Tooltip, Typography } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { MutatingDots } from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import { EmployeeCardImage, EmployeeInfoCardContainer, EmployeeInfoCardWrapper, EmployeeInfoMain, FileHolder, FileName, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer } from '../../../../Assets/GeneralDesignElements'
import getFileIcon from '../../../../Assets/GetFileIconsFunction'
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../../CompanySettings/MainSettings'
import { styled } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';




const Input = styled('input')({
    display: 'none',
  });

const IccManagementDetails = () => {
    const [data, setData] = useState(null)
    const [processReport,setProcessReport] = useState(null)
    function getFileExtension(filename) {
        return filename.split('.').pop();
      }
      const handleFileChange = (event) => {
        const newFiles = [];
        for (let i = 0; i < event.target.files.length; i++) {
          newFiles.push(event.target.files[i]);
        }
        setFiles((prevFiles) => [...newFiles, ...prevFiles]);
      };
      const handleRemoveFile = (indexToRemove) => {
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles.splice(indexToRemove, 1);
          return newFiles;
        });
      };
      const axiosConfigFormData = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };
  const [files, setFiles] = useState([])

    let params = useParams()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
        };
      const axiosConfigOpenAI = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API}`,
        }
        };
    const [expanded, setExpanded] = React.useState('panel1');
    const [expandedMain, setExpandedMain] = React.useState(false);
  
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    const handleChangeMain = () => {
      setExpandedMain(!expandedMain);
    };
  
    async function MoveToSolutionTeam(){
        const body = JSON.stringify({pdca:"Control"})
        try{
          const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/icc/create-read/${params?.id}/`,  body, axiosConfig);
          setData(res.data)
        }
        catch{
    
        }
    }
    async function EditProcessReport(e){
        const body = JSON.stringify({process_report:e})
        try{
          const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/icc/create-read/${params?.id}/`,  body, axiosConfig);
          setData(res.data)
        }
        catch{
    
        }
      }

    const [dOnePerceptronFetching, setDOnePerceptronFetching] = useState(false)
    const [dTwoPerceptronFetching, setDTwoPerceptronFetching] = useState(false)
    const [dThreePerceptronFetching, setDThreePerceptronFetching] = useState(false)
    async function DOnePerceptronGet(){
        setDOnePerceptronFetching(true)
        const body = JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [{"role": "user", "content": `Bana ${data?.heading} sorunu için Öncelikli düzeltici faaliyet önerileri söyleyebilir misin?`}]
        })
        try{
          const res = await axios.post(`${process.env.REACT_APP_OPENAI_API_URL}`,  body, axiosConfigOpenAI);
          PerceptronOneEdit(res.data?.choices ? res?.data?.choices[0].message?.content : "")
          setDOnePerceptronFetching(false)
        }
        catch{
          setDOnePerceptronFetching(false)
    
        }
      }
      async function PerceptronOneEdit(e){
        const body = JSON.stringify({d_one_perceptron:e})
        try{
          const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/icc/create-read/${params?.id}/`,  body, axiosConfig);
          setData(res.data)
        }
        catch{
    
        }
      }
      async function DTwoPerceptronGet(){
        setDTwoPerceptronFetching(true)
        const body = JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [{"role": "user", "content": `Bana ${data?.heading} sorunu için potansiyel kök neden analizi ve kaçan nokta tanımlamalarını söyler misin?`}]
        })
        try{
          const res = await axios.post(`${process.env.REACT_APP_OPENAI_API_URL}`,  body, axiosConfigOpenAI);
          PerceptronTwoEdit(res.data?.choices ? res?.data?.choices[0].message?.content : "")
          setDTwoPerceptronFetching(false)
    
        }
        catch{
          setDTwoPerceptronFetching(false)
    
        }
      }
    
      async function PerceptronTwoEdit(e){
        const body = JSON.stringify({d_two_perceptron:e})
        try{
          const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/icc/create-read/${params?.id}/`,  body, axiosConfig);
          setData(res.data)
        }
        catch{
    
        }
      }
      async function DThreePerceptronGet(){
        setDThreePerceptronFetching(true)
    
        const body = JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [{"role": "user", "content": `Bana ${data?.heading} sorunu için potansiyel düzenleyici önleyici faaliyetleri söylebilir misin? Bu sorun tekrarlanmaması için nasıl önlemler alınabilir?`}]
        })
        try{
          const res = await axios.post(`${process.env.REACT_APP_OPENAI_API_URL}`,  body, axiosConfigOpenAI);
          PerceptronThreeEdit(res.data?.choices ? res?.data?.choices[0].message?.content : "")
          setDThreePerceptronFetching(false)
        }
        catch{
          setDThreePerceptronFetching(false)
    
        }
      }
      async function PerceptronThreeEdit(e){
        const body = JSON.stringify({d_three_perceptron:e})
        try{
          const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/icc/create-read/${params?.id}/`,  body, axiosConfig);
          setData(res.data)
        }
        catch{
    
        }
      }
    
      async function AddNewFilesToIccProcess (){
        let form_data = new FormData();
        for (let i = 0; i < files.length; i++) {
            form_data.append(`file${i + 1}`, files[i]);
        }
        form_data.append("id", params?.id)
        try{
              const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/icc/create/process_files/`, form_data, axiosConfigFormData);
              getInfo()
              setFiles([])
          }
          catch{
  
          }
      }
        
    async function getInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/icc/create-read/${params?.id}/`,  axiosConfig);
            setData(res.data)
            setProcessReport(res.data.process_report)
        }
        catch{

        }
    }

    useEffect(() => {
        getInfo()
    },[params])


  
  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
            <InfoBar>
                {data?.process_report?
                <EditButton active onClick={MoveToSolutionTeam}>Çözüm Ekibine Yönlendir</EditButton>
                :
                <EditButton>Çözüm Ekibine Yönlendir</EditButton>
                }
          </InfoBar>
          <InfoBar around top>
            <EmployeeInfoCardContainer>
                <EmployeeInfoCardWrapper>
                    <EmployeeInfoMain>
                        <b>Çözüm Lideri: {data?.solution_team?.team_lead?.name + " " + data?.solution_team?.team_lead?.surname}</b>
                        <b>Çözüm Takım Üyeleri</b>
                        <div style={{display:"flex" , gap:"5px"}}>
                            {data?.solution_team?.users?.map((e) => (

                            <Tooltip title={e?.name  +  " " +  e?.surname} arrow>
                                <EmployeeCardImage small>
                                    {e?.name?.charAt(0) + " " + e?.surname?.charAt(0)}
                                </EmployeeCardImage>
                            </Tooltip>
                            ))}
            
                        </div>
                    </EmployeeInfoMain>
                </EmployeeInfoCardWrapper>
            </EmployeeInfoCardContainer>
            <HorizantalTimeLineContainer>
              <HorizantalTimelineCardWrapper>
                <Tooltip title="PLAN" arrow>
                    <Avatar
                    sx={{ bgcolor: green[600] }}
                    >
                      P
                    </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="DO" arrow>
                    <Avatar
                    sx={{ bgcolor: data?.pdca === "Plan" ? grey[500] : green[600] }}
                    >
                      D
                    </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="CONTROL" arrow>
                    <Avatar
                    sx={{ bgcolor: data?.pdca === "Plan" || data?.pdca === "Do"  ? grey[500] : green[600] }}
                    >
                      C
                    </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="ACT" arrow>
                    <Avatar
                    sx={{ bgcolor: data?.pdca === "Plan" || data?.pdca === "Do" || data?.pdca === "Control" ? grey[500] : green[600] }}
                    >
                      A
                    </Avatar>
                </Tooltip>
              </HorizantalTimelineCardWrapper>
            </HorizantalTimeLineContainer>
          </InfoBar>
            <Grid container display={"flex"} justifyContent={"space-between"} >
                <Grid items md={6} xs={12}>
                    <h1>Şikayet Detayları</h1>
                    <Accordion sx={{width:"100%"}} expanded={expandedMain} onChange={() => handleChangeMain()}>
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                            <Typography>Öncelikli düzeltici faaliyet önerisi</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableRow>
                                <TableCell>
                                    Müşteri:
                                </TableCell>
                                <TableCell>
                                    {data?.customer?.name}
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell>
                                    Şikayet Tanımı:
                                </TableCell>
                                <TableCell>
                                    {data?.heading}
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell>
                                    Şikayet Detayları:
                                </TableCell>
                                <TableCell>
                                    {data?.details}
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell>
                                    Bölge:
                                </TableCell>
                                <TableCell>
                                    {data?.region?.name + " / " + data?.section?.department_hierarchy}
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell>
                                    Şikayet Kategorisi:
                                </TableCell>
                                <TableCell>
                                    {data?.category?.map((e) =>(
                                        <p>{e?.name}</p>
                                    ))}
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell>
                                    Yönetim sistemleri:
                                </TableCell>
                                <TableCell>
                                    {data?.quality_system?.name}
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell>
                                    Süreç:
                                </TableCell>
                                <TableCell>
                                    {data?.process?.name}
                                </TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell>
                                    Ürün:
                                </TableCell>
                                <TableCell>
                                {data?.product?.name}
                                </TableCell>
                                </TableRow>
                                {data?.files?.length > 0 ?
                                <TableRow>
                                <TableCell>
                                    Dosyalar:
                                </TableCell>
                                <TableCell>
                                    <div style={{display:"flex", gap:"45px"}}>
                                    {data?.files?.length > 0 ?
                                        <>
                                        <TaskImageHolder>
                                        {data?.files?.map((e,index) => (
                                        <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                                            <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                                            <FileHolder>
                                            {getFileIcon(e?.file_type)}
                                            <FileName>{e?.file_name}</FileName>
                                            </FileHolder>
                                            </a>
                                        </Tooltip>
                                            ))}
                                        </TaskImageHolder>
                                        </>
                                    :
                                    ""}
                                    </div>
                                </TableCell>
                                </TableRow>
                                :""}
                            </Table>
                            </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid items md={4} xs={12} display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
                    <h1>Perceptron Desteği⚡</h1>
                    <Accordion sx={{width:"100%"}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Öncelikli düzeltici faaliyet önerisi</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        {data?.d_one_perceptron?
                        "":
                        dOnePerceptronFetching ?
                        <>
                            <MutatingDots
                            height="100"
                            width="100"
                            color="#4fa94d"
                            secondaryColor= '#4fa94d'
                            radius='12.5'
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                        <Alert color="warning">Perceptronlar Öncelikli Düzeltici Faaliyet Önerisi Buluyor. Bu işlem biraz zaman alabilir lütfen sayfayı kapatmayın.</Alert>
                        </>
                        :
                        <EditButton active onClick={DOnePerceptronGet}>
                                Perceptron Desteği⚡ Oluştur
                        </EditButton>
                        }
                    
                        <Typography>
                                {data?.d_one_perceptron}
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{width:"100%"}} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Kök neden ve kaçak noktası tanımlama</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        {data?.d_two_perceptron?
                        "":
                        dTwoPerceptronFetching ?
                        <>
                            <MutatingDots
                            height="100"
                            width="100"
                            color="#4fa94d"
                            secondaryColor= '#4fa94d'
                            radius='12.5'
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                        <Alert color="warning">Perceptronlar Kök Neden Analizi Yapıyor. Bu işlem biraz zaman alabilir lütfen sayfayı kapatmayın.</Alert>
                        </>
                        :
                        <EditButton active onClick={DTwoPerceptronGet}>
                                Perceptron Desteği⚡ Oluştur
                        </EditButton>
                        }
                        
                        <Typography>
                            {data?.d_two_perceptron}
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{width:"100%"}} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Düzenleyici önleyici faaliyet önerisi</Typography>
                        </AccordionSummary>
                            <AccordionDetails>
                            {data?.d_three_perceptron?
                            "":
                            dThreePerceptronFetching ?
                            <>
                                <MutatingDots
                                height="100"
                                width="100"
                                color="#4fa94d"
                                secondaryColor= '#4fa94d'
                                radius='12.5'
                                ariaLabel="mutating-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                            <Alert color="warning">Perceptronlar Düzenleyici Önleyici Faaliyetleri Buluyor. Bu işlem biraz zaman alabilir lütfen sayfayı kapatmayın.</Alert>
                            </>
                            :
                            <EditButton active onClick={DThreePerceptronGet}>
                                    Perceptron Desteği⚡ Oluştur
                            </EditButton>
                            }
                            
                            <Typography>
                                {data?.d_three_perceptron}
                            </Typography>
                            </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <h1>Gelişme Raporu</h1>
                    <TextareaAutosize
                    style={{width:"100%"}}
                    value={processReport}
                    onChange={(e) => setProcessReport(e.target.value)}
                    onBlur={(e) =>EditProcessReport(e.target.value)}
                    minRows={10}/>
                </Grid>
                <Grid item xs={12}>
                    <div style={{display:"flex", gap:"45px"}}>
                        {data?.process_files?.length > 0 ?
                            <>
                            <TaskImageHolder>
                            {data?.process_files?.map((e,index) => (
                                <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                                    <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                                <FileHolder>
                                    {getFileIcon(e?.file_type)}
                                    <FileName>{e?.file_name}</FileName>
                                </FileHolder>
                                </a>
                                </Tooltip>
                                ))}
                            </TaskImageHolder>
                            </>
                            :
                        ""}
                    </div>
                </Grid>
                <Grid item xs={12} mt={2} mb={2} align="right">     
                    <label htmlFor="contained-button">
                        <Input accept="*/*" id="contained-button" multiple   type="file" onChange={handleFileChange} />
                        <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={"info"}>
                            Ek döküman ekle &nbsp; <FileUploadIcon/> 
                        </Button>
                    </label>
                    {files?.length > 0 ?
                    <EditButton style={{width: "150px", marginTop:"15px"}} active onClick={AddNewFilesToIccProcess}>Dosyaları Yükle</EditButton>
                    :""}
                    {files?.length > 0 ?
                    <>
                    <h5>Dosyalar</h5>
                    <TaskImageHolder>
                    {files?.map((e,index) => (
                    <Tooltip title={e?.name + "Listeden çıkarmak için tıklayın"} arrow>
                        <a onClick={() => handleRemoveFile(index)} style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                        <FileHolder>
                        {getFileIcon(getFileExtension(e?.name))}
                        <FileName>{e?.name}</FileName>
                        </FileHolder>
                        </a>
                    </Tooltip>
                        ))}
                    </TaskImageHolder>
                    </>
                :
                ""}
                    </Grid>
            </Grid>
        </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default IccManagementDetails