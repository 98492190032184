import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'

const StayInControl = ({open, setOpen, revision, setRevision, handleGetTheDataOfGembaWalk, findingId, revisionText, setRevisionText, data}) => {
    const [showButton, setShowButton] = useState(false)
    const setControlDialogClose = () => {
        setOpen(false)
        setRevision(false)
    }
    const startRevisionPanel = () => {
        setRevision(true)
    }
    async function AcceptAction () {
    const config = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
    };

    const body = JSON.stringify({
        is_approved: true,
        done: true,
    })
    setShowButton(true)
    try {
        await axios.patch(`${process.env.REACT_APP_API_URL}/api/control/edit-findings/${findingId}/`, body, config);
        handleGetTheDataOfGembaWalk()
        setControlDialogClose()
        setShowButton(false)

    } catch (err) {
        setShowButton(false)

    }
    };
    async function SayInControl () {
    const config = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
    };

    const body = JSON.stringify({
        cancel_stay_in_control: true,
    })
    setShowButton(true)
    try {
        await axios.patch(`${process.env.REACT_APP_API_URL}/api/control/edit-findings/${findingId}/`, body, config);
        handleGetTheDataOfGembaWalk()
        setControlDialogClose()
        setShowButton(false)

    } catch (err) {
        setShowButton(false)

    }
    };
    async function RequestRevision () {
    const config = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
    };

    const body = JSON.stringify({
        needs_revision: true,
        revision_request: true,
        revision: revisionText
    })
    setShowButton(true)
    try {
        await axios.patch(`${process.env.REACT_APP_API_URL}/api/control/edit-findings/${findingId}/`, body, config);
        handleGetTheDataOfGembaWalk()
        setControlDialogClose()
        setShowButton(false)

    } catch (err) {
        setShowButton(false)

    }
    };
  return (
    <Dialog open={open} onClose={setControlDialogClose}>
        {!revision ?
        <>
        <DialogTitle>Bu Aksiyon Kontrol Edilecektir</DialogTitle>
        <DialogContent>
           Aksiyon {data?.who_controls?.name + " " + data?.who_controls?.surname+ ", tarafından kontrol edildikten sonra onaylanacaktır"   }
        </DialogContent>            
        <DialogActions>
        <Button onClick={setControlDialogClose}>İptal</Button>
            <Button variant="outlined" color="warning" onClick={SayInControl}>Kontrolü İptal Et</Button>
            <Button onClick={startRevisionPanel}  variant="outlined" color="error">Revizyon İste</Button>
            {!showButton ? <Button onClick={AcceptAction} variant="contained">Kabul Et</Button> : <Button variant="outlined">Kabul ediliyor...</Button> }
        </DialogActions>
        </>
        :
        <>
        <DialogTitle>Aksiyona Revizyon Talep Et</DialogTitle>
        <DialogContent>
            <br/>
            <TextField style={{width:450}} mt={2}  label="Revizyon Talebinizi Giriniz" value={revisionText} onChange={(e) => setRevisionText(e.target.value)} InputLabelProps={{
            style:{
                color:"#A3A2A1", marginBottom:"15px"
            }
        }}
         sx={{
            input: {
                color: "white",
            },
            width:"100%",
            '& label.Mui-focused': { color: 'white',},
            '& .MuiOutlinedInput-root': {
                 '& fieldset': {
                   borderColor: 'white',
                 },
                 '&:hover fieldset': {
                   borderColor: 'white',
                 },
                 '&.Mui-focused fieldset': {
                   borderColor: 'white',
                 },}
             }}
        />
        </DialogContent>            
        <DialogActions>
        <Button onClick={setControlDialogClose}>İptal</Button>
        <Button variant="contained" onClick={RequestRevision}>Revizyon İste</Button> 
        </DialogActions>
        </>
        }
  </Dialog>
  )
}

export default StayInControl