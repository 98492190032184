import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import IconButton from "@mui/material/IconButton"
import React, { useEffect, useState } from 'react'
import {  useParams } from 'react-router-dom'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from './QAStyle'
import AddNewFindingDialog from './Dialogs/AddNewFindingDialog'
import { DataGrid, trTR } from '@mui/x-data-grid'
import { connect } from 'react-redux'
import moment from 'moment'
import {GrMail} from "react-icons/gr"
import BeforeAfterDialog from './Dialogs/BeforeAfterDialog'
import AddActionToFindingDialog from './Dialogs/AddActionToFindingDialog'
import {WiTime3, WiTime6, WiTime9} from "react-icons/wi"
import EditActionDialog from './Dialogs/EditActionDialog'
import * as markerjs2 from 'markerjs2';
import EditFindingDialog from './Dialogs/EditFindingDialog'
import AproveActionDialog from './Dialogs/AproveActionDialog'
import DeleteDialog from './Dialogs/DeleteDialog'
import SendMailDialog from './Dialogs/SendMailDialog'
import CustomNoRowsOverlay from './DataGridErrors/DataLoading'
import NoDataOverlay from './DataGridErrors/NoData'
import ActionTypeDialog from './Dialogs/ActionTypeDialog'
import RequestAdditionalActionsDialog from './Dialogs/RequestAdditionalActionsDialog'
import FollowTheActionsDialog from './Dialogs/FollowTheActionsDialog'
import DeleteActionRequest from './Dialogs/DeleteActionRequest'
import ControlSubActionDialog from './Dialogs/ControlSubActionDialog'
import FinalCompleteDialog from './Dialogs/FinalCompleteDialog'
import CompleteRevision from "./Dialogs/CompleteRevision"
import {IoMdReturnRight} from "react-icons/io"
import StayInControl from "./Dialogs/StayInControl"


const OnkasaPdca = ({user, rowCount, setPage, subdeps, data, setData, dataLoading, handleGetTheDataOfGembaWalk, setFindingDetailArray, findingDetailArray, newActions}) => {
  let params = useParams()
  const [audits, setAudits] = useState(null)
  const [id, setId] = useState(null)
  const [actionId, setActionId] = useState(null)
  const [finding_id, setFindingId] = useState(null)
  const [finding_image_path, setFindingImagePath] = useState(null)
  const [finding, setFinding] = useState(null)
  const [done_by, setDoneBy] = useState(null)
  const [dueDate, setDueDate] = useState(null)
  const [pdca, setPdca] = useState(null)
  const [text, setText] = useState(null)
  const [after_text, setAfterText] = useState(null)
  const [imgData, setImgData] = useState(null);
  const [product_image_path, setProductImagePath] = useState()
  const [after_image_path, setAfterImagePath] = useState()
  const [updateId, setUpdateId] = useState(null)
  const [responsible_by, setResponsibleby] = useState(null)
  const [subdepartments, setSubdepartments] = useState(null)
  const [revision, setRevision] = useState(false)
  const [revisionText, setRevisionText] = useState(null)
  const [deleteId, setDeleteId] = useState(null)
  const [mail, setMail] = useState(null)
  const [emailTo, setEmailTo] = useState(null)
  const [multiple_action_needed, setMultipleActionNeeded] = useState(false)
  const [findingsAction, setFindingsAction] = useState([])
  const [departmentId, setDepartmentId] = useState(null)
  const [overAllData, setOverAllData] = useState(null)
  const [stayInRevisionDialog, setStayInRevisionDialog] = useState(false)
  const handleOpenStayInRevisionDialog = (e) => {
    setStayInRevisionDialog(true)
    setFindingId(e?.id)
    setOverAllData(e)
  }

  const onChangePicture = e => {
    if (e.target.files[0]) {
      setProductImagePath(e.target.files[0])
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  let imgRef = React.createRef()
  function showMarkerArea() {
    if (imgRef.current !== null) {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      // attach an event handler to assign annotated image back to our image element
      markerArea.addEventListener("render", (event) => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;
          fetch(event.dataUrl)
          .then(res => res.blob())
          .then(blob2 => setProductImagePath(blob2))
        }
      });
      // launch marker.js
      markerArea.show();
    }
  }
  function showMarkerArea2() {
    if (imgRef.current !== null) {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      // attach an event handler to assign annotated image back to our image element
      markerArea.addEventListener("render", (event) => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;
          fetch(event.dataUrl)
          .then(res => res.blob())
          .then(blob2 => setProductImagePath(blob2))
        }
      });
      markerArea.settings.defaultColor = 'green' ;
      // launch marker.js
      markerArea.show();
    }
  }
  
 
   
    const [addNewFindingDialog, setAddNewFindingDialog] = useState(false)
    const [beforeAfterDialog, setBeforeAfterDialog] = useState(false)
    const handleClickOpenBeforeAfterDialog   = (e) => {
      setId(e.id)
      setFindingImagePath(e.finding_image_path)
      setDoneBy(e.audit)
      setMultipleActionNeeded(e.multiple_action_needed)
      setSubdepartments(e.Subdepartment)
      setResponsibleby(e.responsible_by)
      setFinding(e.text)
      setDueDate(e.re_control_date)
      setPdca(e.pdca)
      setFindingsAction(e.findings_action)
      setAfterText(e.after_text)
      setAfterImagePath(e.after_image_path)

      setBeforeAfterDialog(true)
    }
    const [addActionToFindingDialog, setAddActionToFindingDialog] = useState(false)
    const handleClickOpenAddActionToFindingDialog  = (e) => {
      setAddActionToFindingDialog(true)
    }
   
    const [completeFindingDialog, setCompleteFindingDialog] = useState(false)
    const handleClickOpenCompleteFindingDialog  = (e) => {
      setCompleteFindingDialog(true)
      setId(e.id)
      setFindingImagePath(e.finding_image_path)
      setFinding(e.text)
      console.log(e)
    }
    
    const [editActionDialog, setEditActionDialog] = useState(false)
    const handleOpenEditAction2 = (e) => {
      setActionId(e.id)
      setPdca("Control")
      setEditActionDialog(true)
      setText(e.text)
      setImgData(e.action_image_path)
      setFindingId(e.finding_id)
    }
    const handleOpenEditAction   = (e) => {
      setActionId(e?.findings_action[0].id)
      setEditActionDialog(true)
      setText(e.findings_action[0].text)
      setImgData(e.after_image_path)
    }
    const [completeRevisionDialog, setCompleteRevisionDialog] = useState(false)
    const handleClickOpenCompleteRevisionToFindingDialog  = (e) => {
      console.log(e)
      setCompleteRevisionDialog(true)
      setText(e.findings_action[0].text)
      setImgData(e.findings_action[0].action_image_path)
      setActionId(e?.findings_action[0].id)
      setRevisionText(e?.revision)
      setDepartmentId(e?.Subdepartment?.department?.id)
      setFindingId(e?.id)
    }
    const [editFindingDialog, setEditFindingDialog] = useState(false)
    const handleOpenEditFinding   = (e) => {
      console.log(e)
      setEditFindingDialog(true)
      setUpdateId(e.id)
      setText(e?.text)
      setResponsibleby(e.responsible_by)
      setImgData(e.finding_image_path)
      setSubdepartments(e.Subdepartment)
    }
    const [approveActionDialog, setApproveActionDialog] = useState(false)
    const handleOpenApproveActionDialog = (e) => {
      setApproveActionDialog(true)
      setFindingId(e?.id)
    }
    const [deleteDialog, setDeleteDialog] = useState(false)
    const handleOpenDeleteDialog = (e) => {
      setDeleteId(e?.id)
      setDeleteDialog(true)
    }
    const [actionTypeDialog, setActionTypeDialog] = useState(false)
    const handleOpenActionTypeDialog = (e) => {
      setId(e.id)
      setFindingId(e.id)
      setDepartmentId(e?.Subdepartment?.department?.id)
      setFindingImagePath(e.finding_image_path)
      setFinding(e.text)
      setActionTypeDialog(true)
    }
    const [requestAdditionAction, setRequestAdditionalOption] = useState(false)
    const handleCloseRequestAdditionalActionDialog = (e) => {
      setRequestAdditionalOption(true)
    } 
    const [mailDialog, setMailDialog] = useState(false)
    const handleOpenMailDialog = (e) => {
      setFindingId(e?.id)
      setMailDialog(true)
      setEmailTo(e.responsible_by?.email)
      // eslint-disable-next-line
      setMail("Sayın " + e.responsible_by?.name + " " + e.responsible_by?.surname  + ", \n\n" + moment(e.created_at).format("DD MM YYYY") + " tarihinde gerçekleştirdiğimiz saha denetimi sonucunda tarafınıza aksiyon alınması gereken bir uygunsuzluk atanmıştır. \n\nGerçekleşmesi gereken termin tarihi: " + e.re_control_date + "\nBulgu No: " + e.id + "\nBulgu Açıklaması:" + e.text + "\n\nGereğinin yapılmasını rica ederim." + `\n\nUygulamaya: qms.onkasa.com adresi üzerinden ulaşabilirsiniz` + "\n\nİyi Çalışmalar Dilerim,")
    }
    const [followTheAction, setFollowTheAction] = useState(false)
    const handleFollowTheActionOpen = (e) => {
      setFollowTheAction(true)
      setFindingDetailArray(e)
      setFindingId(e.id)
      setDepartmentId(e?.Subdepartment?.department?.id)

    }
    const [deleteActionRequest, setDeleteActionRequest] = useState(false)
    const handleOpenDeleteActionRequest = (e) => {
      setActionId(e.id)
      setFindingId(e.finding_id)
      setDeleteActionRequest(true)
    }
    const [controlSubAction, setControlSubActionDialog] = useState(false)
    const [actionResponsbile, setActionResponsible] = useState(null)
    const [actionDue, setActionDue] = useState(null)
    const handleOpenControlSubActionDialog = (e) => {
      setActionId(e.id)
      setText(e.text)
      setImgData(e.action_image_path)
      setActionResponsible(e.responsible_by)
      setActionDue(e.action_due)
      setFindingId(e.finding_id)
      setControlSubActionDialog(true)
    }
    const columns = [
      {
      field: "id",
      headerName: "No",
      width: 70,
      }, 
      {
        field:"departman",
        headerName:"Uygunsuzluk Bölümü",
        width: 200,
        renderCell: (params) => {
          return (
            <Chip label={params?.row?.region === null ? "Departman Belirtilmedi" : params?.row?.region?.name + " - " + params?.row?.section?.name } style={{background:"orange"}} />
          )
        },
        valueGetter : (params) => {
          return (
            params?.row?.Subdepartment === null ? "Alt Departman Belirtilmedi" : params?.row?.Subdepartment?.department?.name + " - " + params?.row?.Subdepartment?.name 
          )
        },
       
      },
      {
        field: "PDCA",
        headerName: "PDCA",
        hide: newActions ? true : false,
        width: 100,
          renderCell: (params) => {
            return (
              params?.row?.pdca === "Act" ? 
               <>
               <IconButton   color="success" style={{width:"100px", height:"10px", fontSize:"15px"}} >
                Act &nbsp;
               </IconButton>
               </>
               :
               params?.row?.pdca === "Control" ? 
               <>
               <IconButton  color="primary" style={{width:"100px", height:"10px", fontSize:"15px"}} variant="outlined">
                  Control &nbsp; <WiTime9/>
               </IconButton>
               </>
               :
               params?.row?.pdca === "Do" ? 
               <>
               <IconButton  color="warning" style={{width:"100px", height:"10px", fontSize:"15px"}} variant="outlined">
                  Do &nbsp; <WiTime6/>
               </IconButton>
               </>
               :
               <>
               <IconButton  color="error" style={{width:"100px", height:"10px", fontSize:"15px"}} variant="outlined">
                 Plan &nbsp; <WiTime3/>
               </IconButton>

               </>
              
              )    
         },
         valueGetter: (params) => {
          return (
            params?.row?.pdca
          )
         }
       },  
       {
        field: "beforeafter",
        headerName: "Öncesi Sonrası Raporu",
        width: 180,
        hide: newActions ? true : false,
        renderCell: (params) => {
          return (
            <Chip label="Öncesi Sonrası" component="a" onClick={() => handleClickOpenBeforeAfterDialog(params?.row)}  clickable color="secondary" /> 
            )    
        },
      },
      {
        field: "topic",
        headerName: "Konu",
        width: 380,
        renderCell: (params) => {
          return (
          <>
          <p style={{color:"grey"}}>
            {params?.row?.question_text ? params?.row?.question_text + " - " : ""}
          </p>
          <p>
            {params?.row?.task ? params?.row?.task : "Bulgu belirtilmemiştir"}
          </p>
          </>
            )    
        },
      },

      {
      field: "action",
      headerName: "Aksiyon",
      hide: newActions ? true : false,
      width: 180,

      valueGetter: (params) => {
          return (
              params?.row?.after_text ? params?.row?.after_text: "Aksiyon Alınmamıştır"
          )    
      },
      },
      {
      field: "photo",
      headerName: "Öncesi Fotoğrafı",
      width: 150,
      renderCell: (params) => {
          return (
              params?.row?.before_image_path ? 
              <img src={params?.row?.before_image_path.path} alt="/" style={{width: "100%", objectFit:"contain"}}/>
              :
              ""
            )    
        },
      },
      {
      field: "later_photo",
      headerName: "Sonrası Fotoğrafı",
      hide: newActions ? true : false,
      width: 150,
      renderCell: (params) => {
          return (
              params?.row?.after_image_path?
              <img src={params?.row?.after_image_path.path} alt="/" style={{width: "100%", objectFit:"contain"}}/>
              :
              ""
            )    
        },
      },
      {
       field: "requester",
       headerName: "Talep Eden",
       width: 150,
         valueGetter: (params) => {
           return (
              params?.row?.created_by ? params?.row?.created_by?.name + " " + params?.row?.created_by?.surname : "Denetçi Silinmiştir" 
             )    
        },
              
      },  
      {
       field: "responsible",
       headerName: "Sorumlu",
       width: 150,
       renderCell: (params) => {
        return (
          <>
          {params?.row?.assignee ? params?.row?.assignee?.name + " " + params?.row?.assignee?.surname : "Denetçi Silinmiştir" }
          <br/>
          {params?.row?.redirected_employee ? <IoMdReturnRight/>: ""}
          {params?.row?.redirected_employee ? params?.row?.redirected_employee?.name + " " + params?.row?.redirected_employee?.surname : "" }
           </>
          )    
        },
        valueGetter: (params) => {
           return (
              params?.row?.assignee ? params?.row?.assignee?.name + " " + params?.row?.assignee?.surname : "Denetçi Silinmiştir" 
             )    
        },
      },  
      {
       field: "termin",
       headerName: "Planlanan Termin",
       width: 180,
         renderCell: (params) => {
           return (
              <p>{params?.row?.due_date ? moment(params?.row?.due_date).format("DD-MM-YYYY") : "Termin Tarihi Girilmedi"}</p> 
             )    
        },
      },  
      {
       field: "donear",
       headerName: "Gerçekleşen Termin",
       width: 180,
         renderCell: (params) => {
           return (
            <p>{params?.row?.completed_at ?  moment(params?.row?.completed_at).format("DD-MM-YYYY"): "Henüz Tamamlanmadı"}</p> 

             )    
        },
      },  
     {
       field: "aksiyon",
       headerName: "Aksiyon Ekle / Düzenle",
       hide: newActions ? true : false,
       width: 180,
  
       renderCell: (data) => (
        data?.row?.needs_revision === true 
        ?
        <Button 
        disabled={data?.row?.is_done === true} 
        variant="contained" 
        onClick={() => handleClickOpenCompleteRevisionToFindingDialog(data?.row)} 
        style={data?.row?.is_approved ? {background:"grey"} : {background:"red", color:"white"}} 
        >
          Revizyonu Tamamla
        </Button> 
        :
        data?.row?.pdca !== "Plan" ?
          data?.row?.multiple_action_needed === true
          ?
          <Button 
          disabled={data?.row?.is_done === true} 
          variant="contained" 
          onClick={() => handleFollowTheActionOpen(data?.row)} 
          style={data?.row?.is_approved ? {background:"grey"} : {background:"orange"}} 
          >
            Aksiyon Takip Et
          </Button> 
          :
          <Button 
          disabled={data?.row?.is_done === true} 
          variant="contained" 
          onClick={() => handleOpenEditAction(data?.row)} 
          style={data?.row?.is_approved ? {background:"grey"} : {background:"orange"}} 
          >
            Aksiyonu Düzenle
          </Button> 
          :
          <Button disabled={data?.row?.is_done === true} variant="contained" onClick={() => handleOpenActionTypeDialog(data?.row)}>Aksiyon Ekle</Button> 
       ),
      },
      user?.type === "holding_manager" | user?.type === "region_manager"| user?.type === "department_manager" ?
     {
       field: "edit_finding",
       headerName: "Bulgu Düzenle",
       hide: newActions ? true : false,
       width: 180,
  
       renderCell: (data) => (
         <Button disabled={data?.row?.is_done === true} variant="contained" onClick={() => handleOpenEditFinding(data?.row)} style={data?.row?.is_approved ? {background:"grey"} : {background:"green"}}>Bulgu Düzenle</Button> 
       ),
      }
      :"",
      user?.type === "holding_manager" | user?.type === "region_manager"| user?.type === "department_manager" ?
     {
       field: "totalgross2",
       headerName: "Yönetici Kontrolü",
       hide: newActions ? true : false,
       width: 180,
  
       renderCell: (data) => (
        data?.row?.pdca === "Control" | data?.row?.pdca === "Act" ?
          data?.row?.is_in_control === true
          ?
          <Button variant="outlined" color="secondary"
          onClick={() => handleOpenStayInRevisionDialog(data?.row)}
          >
            Kontrol edilecek
          </Button> 
          :
          <Button disabled={data?.row?.is_done === true} variant="contained"
          onClick={() => handleOpenApproveActionDialog(data?.row)}
          style={data?.row?.is_approved ? {background:"grey"} : {background:"green"}}>
          {data?.row?.is_approved ?"Onaylanan Aksyion" : "Onay Ver"}
          </Button> 
          :
          <Button disabled variant="contained">Aksiyon Alınmadı</Button> 

       ),
      }:"",
      user?.type === "holding_manager" | user?.type === "region_manager"| user?.type === "department_manager" ?
     {
       field: "done",
       headerName: "Sil",
       hide: newActions ? true : false,
       width: 80,
  
       renderCell: (data) => (
        <Button variant="contained" disabled={data?.row?.is_done === true} color="error" onClick={() => handleOpenDeleteDialog(data?.row)}>Sil</Button> 
       ),
      }: "",
      user?.type === "holding_manager" | user?.type === "region_manager"| user?.type === "department_manager" ?
      {
        field: "commentsextra",
        headerName: "Revizyon Talebi",
        hide: newActions ? true : false,
        width: 250,
        renderCell: (params) => {
          return (
            params.row.responsible_by === null || params.row === null?
            <Button variant="contained" disabled>Denetçi Atanmadı &nbsp; <GrMail/> </Button>
            :
             <Button variant="contained" onClick={() => handleOpenMailDialog(params?.row)}>Mail Gönder &nbsp; <GrMail/> </Button>
            )    
         },
      }:"", 
  ]
 
  const [queryOptions, setQueryOptions] = React.useState({});
      console.log(queryOptions)
      const onFilterChange = React.useCallback((filterModel) => {
        // Here you save the data you need from the filter model
        setQueryOptions({ filterModel: { ...filterModel } });
      }, []);
    

  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <div style={{ height: "75vh", width: '100%' }}>
            <DataGrid
              loading={dataLoading}
                rows={data ? data : []}
                columns={columns}
                rowCount={rowCount}
                paginationMode="server"
                experimentalFeatures={{ newEditingApi: true }}
                pagination
                pageSize={15}
                onPageChange={(newPage) => setPage(newPage)}
                style={{color:"white"}}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
            />
        </div>
      </ActivitiesMainWrapper>
     
    </ActivitiesMainContainer>
  )
}

const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps)(OnkasaPdca)

