import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, TextareaAutosize, Tooltip } from '@mui/material'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import { EditButton } from '../../../../CompanySettings/MainSettings'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import AutoCompleteV2 from '../../../../../Assets/AutoCompleteV2'
import { TaskImageHolder } from '../../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import { FileHolder, FileName } from '../../../../../Assets/GeneralDesignElements'
import getFileIcon from '../../../../../Assets/GetFileIconsFunction'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled as styledmui} from '@mui/material/styles'
import TextFieldV2 from '../../../../../Assets/TextFieldV2'
import moment from 'moment'

const SendToSolutionTeam = ({open, setOpen, getInfoBack,id}) => {
    const Input = styledmui('input')({
        display: 'none',
    });
    function getFileExtension(filename) {
        return filename.split('.').pop();
      }
    const handleFileChange = (event) => {
        const newFiles = [];
        for (let i = 0; i < event.target.files.length; i++) {
          newFiles.push(event.target.files[i]);
        }
        setFiles((prevFiles) => [...newFiles, ...prevFiles]);
      };
      const handleRemoveFile = (indexToRemove) => {
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles.splice(indexToRemove, 1);
          return newFiles;
        });
      };
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const axiosConfigFormData = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };
    const [additionalInfo, setAdditionalInfo] =useState(null)
    const [userList, setUserList] = useState([])
    async function getUserList (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/create/`,  axiosConfig);
            setUserList(res.data)
        }
        catch{

        }
    }
    const [teamLeader, setTeamLeader] = useState(null)
    const [teamMember, setTeamMember] = useState([])
    const [files,setFiles] = useState([])
    const [additional_info_for_solution_team, setAIFST] = useState(null)
    const [date, setDate] = useState(moment().add(7,"days").format("YYYY-MM-DD"))
    const handleCloseDialog = () => {
        setOpen(false)
    }
   
    const [buttonStart, setButtonStart] = useState(false)
    const steps = [
        'Çözüm Ekibi Oluştur',
        'Çözüm Ekbine Ek Bilgi Ekle',
        'Termin Tarihi Oluştur',
      ];
     
    const [currentStep, setCurrentStep] = useState(0)
      
    async function handleUpdate() {
      setButtonStart(true)
      
        const body = JSON.stringify({additionalInfo})
        let form_data = new FormData();
        for (let i = 0; i < files.length; i++) {
            form_data.append(`file${i + 1}`, files[i]);
        }
        teamLeader ? form_data.append('teamLeader', teamLeader?.id) : form_data.append('teamLeader', "")
        teamMember ? form_data.append('teamMember', teamMember?.map((e) => e.id)) : form_data.append('teamMember', "")
        additional_info_for_solution_team ? form_data.append('additional_info_for_solution_team', additional_info_for_solution_team) : form_data.append('additional_info_for_solution_team', "")
        date ? form_data.append('solution_team_due_date', date) : form_data.append('solution_team_due_date', "")
        form_data.append('send_to_solution', true)
      try {
          await axios.patch(`${process.env.REACT_APP_API_URL}/api/suggestions/get/process_manager_suggestions/${id}/`, form_data,axiosConfigFormData);
          setOpen(false)
          setButtonStart(false)
          getInfoBack()

      }
      catch (err) {
          setButtonStart(false)
          setOpen(false)

      }
      }
      useEffect(() => {
        if (open){
            getUserList()
        }
    },[open])
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Öneriyi Komitye İlet"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            <Box sx={{ width: '100%' }} mb={2}>
            <Stepper activeStep={currentStep} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            </Box>
            {currentStep ===0 ?
            <Grid container>
                <Grid item xs={12}>
                    <AutoCompleteV2 value={teamLeader} setValue={setTeamLeader} options={userList} label={"Çözüm Ekip Lideri"} user/>
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteV2 value={teamMember} setValue={setTeamMember} options={userList} label={"Çözüm Ekip Üyeleri"} multiple user/>
                </Grid>
            </Grid>
            :
            currentStep ===1 ?
            <div style={{width:"100%", display:"flex",flexDirection:"column",alignItems:"flex-end", gap:"15px"}}>
            <label>Ek açıklama:</label>
             <TextareaAutosize minRows={6} style={{width:"100%"}}
             value={additional_info_for_solution_team}
             onChange={(e) => setAIFST(e.target.value)}
             />
             <Grid item xs={12} mt={2} mb={2} align="right">     
                <label htmlFor="contained-button">
                <Input accept="*/*" id="contained-button" multiple   type="file" onChange={handleFileChange} />
                <EditButton active>
                    Ek döküman ekle &nbsp; <FileUploadIcon/> 
                </EditButton>
                </label>
            </Grid>   
             <div style={{display:"flex", gap:"45px"}}>
            {files?.length > 0 ?
                <>
                <TaskImageHolder>
                {files?.map((e,index) => (
                    <Tooltip title={e?.name + "Listeden çıkarmak için tıklayın"} arrow>
                        <a onClick={() => handleRemoveFile(index)} style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                    <FileHolder>
                        {getFileIcon(getFileExtension(e?.name))}
                        <FileName>{e?.name}</FileName>
                    </FileHolder>
                    </a>
                    </Tooltip>
                    ))}
                </TaskImageHolder>
                </>
                :
            ""}
            </div>
            </div>
            
            :
            <TextFieldV2 type={"date"} value={date} setValue={setDate}/>   
            }
          
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {currentStep !== 2 ?
            <>
            {currentStep !== 0 ?
            <EditButton onClick={() => setCurrentStep(currentStep-1)} active>Geri</EditButton> 
            :
            ""
            }
            <EditButton onClick={() => setCurrentStep(currentStep+1)} active>İleri</EditButton> 
            </>
            :
            <>
            <EditButton onClick={() => setCurrentStep(currentStep-1)} active>Geri</EditButton> 
            {!buttonStart ? <EditButton onClick={handleUpdate} active>İlet</EditButton> : <EditButton>İletiliyor...</EditButton>}
            </>
            }
        </DialogActions>
    </Dialog>
    </>
  )
}

export default SendToSolutionTeam