import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'

const DeleteWarning = ({open, setOpen, cardId, getAuditCards,getCardsAgain}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const handleCloseSuccessMsg = () => {
        setSuccessMsg(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const vertical = "bottom"
    const horizontal = "center"
    const [successMsg, setSuccessMsg] = useState(false)
    async function handleDelete() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/internalaudit/update-delete/${cardId}/`, config);
            getAuditCards()
            setOpen(false)
            setSuccessMsg(true)
            setButtonStart(false)
            getCardsAgain()

        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }
  return (
    <>
    <Snackbar open={successMsg} autoHideDuration={6000} onClose={handleCloseSuccessMsg} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleCloseSuccessMsg} severity="warning" sx={{ width: '100%' }}>
            Denetim Kartı Kalıcı Olarak Silinmiştir!
        </Alert>
    </Snackbar>
    <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Denetim Kartını Sil</DialogTitle>
        <DialogContent>Denetim kartını kalıcı olarak silmek istiyor musunuz?</DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <Button onClick={handleDelete} variant="contained" color="error">Sil</Button> : <Button variant="outlined" color="error">Siliniyor...</Button>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default DeleteWarning