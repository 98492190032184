import { Dialog, DialogActions, DialogContent, DialogTitle,  Grid, TextField } from '@mui/material'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { TopNavActions } from '../../../Subcomponents/AuditDashboardsContainer/activitiesdashboard'
import { EditButton } from '../MainSettings'

const CompanyInfoEditDialog = ({open, id, setOpen,getInfoBack,data,setSuccessOpen,setErrorOpen}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
    }
    console.log(data)

    const [phone, setPhone] = useState(data?.phone)
    const [email, setEmail] = useState(data?.email)

    useEffect(() => {
        setPhone(data?.phone)
        setEmail(data?.email)
    },[data])

    async function handleSubmitTaxInfo (e) {
        e.preventDefault()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        const body = JSON.stringify({  phone, email });
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/company/update/${id}/`, body, config);
            getInfoBack()
            handleCloseDialog()
            setSuccessOpen(true)
        } catch (err) {
            window.scrollTo( 0, 0)
            setErrorOpen(true)
        }
      }
  return (
    <Dialog open={open} onClose={handleCloseDialog}
    sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        
        <DialogTitle>
            <Grid container spacing={2} justifyContent={"center"} alignItems="center">
                <Grid container item xs={12}>
                  <Grid item container xs={4} style={{flexDirection:"row"}}>
                  <TopNavActions id="close" style={{ marginLeft:"-5px"}} onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} onClick={handleCloseDialog} background={"#ee5c54"}>
                            <p style={{fontSize:"8px", display:iconCloseDisplay}}>X</p>
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  </Grid>
                  <Grid item xs={6} style={{fontSize:"15px"}}>İletişim Bilgisini Güncelle</Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent style={{display:"flex", flexDirection:"column", gap:"15px", paddingTop:"10px"}}>
            <TextField value={phone} onChange={(e) => setPhone(e.target.value)} label="Telefon Numarası" fullWidth 
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}
            /> 
            <TextField value={email} onChange={(e) => setEmail(e.target.value)} label="E-Posta Adresi" fullWidth 
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}
            /> 

        </DialogContent>
        <DialogActions>
            <EditButton style={{marginRight:"10px"}} onClick={handleCloseDialog}>
              İptal
            </EditButton>
            <EditButton active style={{marginRight:"10px"}} onClick={handleSubmitTaxInfo}>
              Kaydet
            </EditButton>
        </DialogActions>
    </Dialog>
  )
}

export default CompanyInfoEditDialog