import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../../AuditApp/Activities/ActivitiesStyle'
import MyKaizenTable from './Table/MyKaizenTable'

const KaizenSolution = () => {
  const [data, setData] = useState()
  const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
  };
  async function getInfo (){
      try{
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/kaizen/get/solutions/`,  axiosConfig);
          setData(res.data)
      }
      catch{

      }
  }
  useEffect(() => {getInfo()},[])
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <MyKaizenTable data={data} setData={setData}/>
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default KaizenSolution