import './App.css';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import { Provider } from "react-redux";
import store from './store';
import AuthCheck from './components/Layout/AuthCheck';
import LoginPage from './components/Pages/Login';
import ResetPassword from './components/Pages/ResetPassword';
import MainAppScreen from './components/Pages/MainAppScreen';
import CompanySettings from './components/Pages/CompanySettings';
import MainSettings from './components/Pages/CompanySettings/MainSettings';
import DepartmentSettings from './components/Pages/CompanySettings/DepartmentSettings';
import UserSettings from './components/Pages/CompanySettings/UserSettings';
import ActivitiesDashBoard from './components/Subcomponents/AuditDashboardsContainer/activitiesdashboard';
import Activities from './components/Pages/AuditApp/Activities';
import AuditReports from './components/Pages/AuditApp/Reports';
import AuditDeflects from './components/Pages/AuditApp/Deflects';
import RegionSettings from './components/Pages/CompanySettings/RegionSettings';
import SubDepartmentSettings from './components/Pages/CompanySettings/SubDepartmentSettings';
import SubRegionSettings from './components/Pages/CompanySettings/SubRegionSettings';
import SuggestionsDashBoard from './components/Subcomponents/SuggestionDashboardsContainer/SuggestionsDashboard';
import KaizenDashboardsContainer from './components/Subcomponents/KaizenDashboardsContainer/KaizenDashboardsContainer';
import AuditAppSettings from './components/Pages/AuditApp/Settings';
import AuditDetails from './components/Pages/AuditApp/Settings/AuditDetails';
import AuditAppHomePage from './components/Pages/AuditApp/HomePage';
import ToDoTaskManagment from './components/Pages/AuditApp/ToDoTaskManagment';
import Audit from './components/Pages/AuditApp/Activities/Audit/Audit';
import GembaWalk from './components/Pages/AuditApp/Activities/GembaWalk';
import GembaWalkDetails from './components/Pages/AuditApp/Activities/GembaWalk/GembaWalkDetails';
import Workshop from './components/Pages/AuditApp/Activities/Workshops';
import WorkshopDetails from './components/Pages/AuditApp/Activities/Workshops/WorkshopDetails';
import SuggestionAppActivities from './components/Pages/SuggetionApp/Activities';
import SuggestionAppHomePage from './components/Pages/SuggetionApp/Home';
import SuggestionSystemSettings from './components/Pages/SuggetionApp/Settings';
import CreateNewSuggestion from './components/Pages/SuggetionApp/Activities/CreateNewSuggestion';
import TrackSuggestion from './components/Pages/SuggetionApp/Activities/TrackSuggestion';
import MySuggestions from './components/Pages/SuggetionApp/Activities/MySuggestions';
import SuggestionProcessManagment from './components/Pages/SuggetionApp/Activities/ProcessManagment';
import ProcessManagmentInspect from './components/Pages/SuggetionApp/Activities/ProcessManagment/ProcessManagmentInspect';
import SuggestionCommittee from './components/Pages/SuggetionApp/Activities/Committee';
import ExternalCustomerComplaintsDashboar from './components/Subcomponents/ExternalCustomerComplaints/ExternalCustomerComplaintsDashboard';
import ECCHome from './components/Pages/ExternalCustomerComplaintsApp/HomePage';
import ECCManagement from './components/Pages/ExternalCustomerComplaintsApp/ComplaintsManagment';
import GeneralInfoDashboard from './components/Subcomponents/GeneralInfoDashboard/GeneralInfoDashboard';
import GeneralInfoHomePage from './components/Pages/GeneralInfoApp/HomePage';
import GeneralInfoCustomers from './components/Pages/GeneralInfoApp/Customers';
import GeneralInfoProducts from './components/Pages/GeneralInfoApp/Products';
import GeneralInfoManagmentSystems from './components/Pages/GeneralInfoApp/ManagmentSystems';
import GeneralInfoProcess from './components/Pages/GeneralInfoApp/Process';
import NewCustomerAdd from './components/Pages/GeneralInfoApp/Customers/NewCustomerAdd';
import CustomerTable from './components/Pages/GeneralInfoApp/Customers/CustomerTable';
import NewProductsAdd from './components/Pages/GeneralInfoApp/Products/NewProductsAdd';
import ProductsTable from './components/Pages/GeneralInfoApp/Products/ProductsTable';
import ManagementSystemTable from './components/Pages/GeneralInfoApp/ManagmentSystems/ManagementSystemTable';
import NewManagementSystemAdd from './components/Pages/GeneralInfoApp/ManagmentSystems/NewManagementSystemAdd';
import NewProcessAdd from './components/Pages/GeneralInfoApp/Process/NewProcessAdd';
import ProcessTreeView from './components/Pages/GeneralInfoApp/Process/ProcessTreeView';
import ProcessTable from './components/Pages/GeneralInfoApp/Process/ProcessTable';
import ExternalCustomersComplaintSettings from './components/Pages/ExternalCustomerComplaintsApp/Settings';
import CreateComplaints from './components/Pages/ExternalCustomerComplaintsApp/CreateComplaints';
import ComplaintsManagmentTable from './components/Pages/ExternalCustomerComplaintsApp/ComplaintManagementTable';
import ComplaintsDetailsPage from './components/Pages/ExternalCustomerComplaintsApp/ComplaintDetailsPage';
import InternalCustomersComplaintsDashboard from './components/Subcomponents/InternalCustomerComplaints/InternalCustomersComplaintsDashboard';
import TaskManagementDashboard from './components/Subcomponents/TaskManagementDashboard/TaskManagementDashboard';
import TMHomePage from './components/Pages/TaskManagementApp/HomePage/index.js';
import TMMyTasks from './components/Pages/TaskManagementApp/Tasks';
import InternalMessagingAppDashboard from './components/Subcomponents/InternalMessagingApp/InternalMessagingAppDashboard';
import SuggestionPool from './components/Pages/SuggetionApp/SuggestionPool';
import CommitteeEvaluation from './components/Pages/SuggetionApp/Activities/Committee/CommitteeEvaluation';
import KaizenHomePage from './components/Pages/KaizenApp/HomePage';
import KaizenActivities from './components/Pages/KaizenApp/Activities';
import KaizenPool from './components/Pages/KaizenApp/KaizenPool';
import KaizenReports from './components/Pages/KaizenApp/Reports';
import Earnings from './components/Pages/KaizenApp/Earnings';
import SuggestionSystemSolutionProcess from './components/Pages/SuggetionApp/Activities/SolutionProcess';
import EccSolutionProcess from './components/Pages/ExternalCustomerComplaintsApp/SolutionProcess';
import SuggestionAppEarnings from './components/Pages/SuggetionApp/Earnings';
import SuggestionAppReports from './components/Pages/SuggetionApp/Reports';
import IccHomePage from './components/Pages/InternalCustomersComplaintsApp/HomePage';
import SuggestionSolutionDetails from './components/Pages/SuggetionApp/Activities/SolutionProcess/SolutionDetails';
import EccProcessManagement from './components/Pages/ExternalCustomerComplaintsApp/ComplaintProcessManagement';
import ComplaintsManagementProcessDetails from './components/Pages/ExternalCustomerComplaintsApp/ComplaintProcessManagement/ComplaintsManagementProcessDetails';
import EccPool from './components/Pages/ExternalCustomerComplaintsApp/EccPool';
import EccReports from './components/Pages/ExternalCustomerComplaintsApp/Reports';
import IccComplaintManagement from './components/Pages/InternalCustomersComplaintsApp/ComplaintProcess';
import IccComplaintSolution from './components/Pages/InternalCustomersComplaintsApp/ComplaintSolutionProcess';
import IccReports from './components/Pages/InternalCustomersComplaintsApp/Reports';
import IccCreateComplaints from './components/Pages/InternalCustomersComplaintsApp/ComplaintProcess/CreateComplaint';
import IccComplaintManagementMain from './components/Pages/InternalCustomersComplaintsApp/ComplaintProcess/ComplaintManagementMain';
import IccSettings from './components/Pages/InternalCustomersComplaintsApp/IccSettings';
import IccComplaintProcessDetails from './components/Pages/InternalCustomersComplaintsApp/ComplaintProcess/ComplaintProcessDetails';
import IccComplaintDevelopmentReport from './components/Pages/InternalCustomersComplaintsApp/ComplaintProcess/ComplaintDevelopmentReport';
import IccManagementDetails from './components/Pages/InternalCustomersComplaintsApp/ComplaintProcess/ComplaintDevelopmentReport/IccManagementDetails';
import MyKaizen from './components/Pages/KaizenApp/Activities/MyKaizen';
import KaizenSolution from './components/Pages/KaizenApp/Activities/KaizenSolution';
import KaizenProcess from './components/Pages/KaizenApp/Activities/KaizenProcess';
import CreateKaizen from './components/Pages/KaizenApp/Activities/CreateKaizen';
import KaizenSettings from './components/Pages/KaizenApp/Settings';
import InternalAuditsKaizens from './components/Pages/KaizenApp/KaizenPool/InternalAudit';
import IccKaizen from './components/Pages/KaizenApp/KaizenPool/Icc';
import EccKaizen from './components/Pages/KaizenApp/KaizenPool/Ecc';
import AuditFollowUp from './components/Pages/AuditApp/Activities/AuditDetails';
import DateChangeRequests from './components/Pages/AuditApp/Deflects/DateChangeRequests';
import DeleteRequests from './components/Pages/AuditApp/Deflects/DeleteRequests';
import AuditOverDueTasks from './components/Pages/AuditApp/ToDoTaskManagment/OverDue';
import AuditToDoTasks from './components/Pages/AuditApp/ToDoTaskManagment/TodDo';
import AuditControlTasks from './components/Pages/AuditApp/ToDoTaskManagment/Control';
import AuditCompleteTasks from './components/Pages/AuditApp/ToDoTaskManagment/Complete';
import SuggestionCommitteePast from './components/Pages/SuggetionApp/Activities/Committee/PastCommittee';
import SuggestionCommitteeCurrent from './components/Pages/SuggetionApp/Activities/Committee/CurrentCommittee';
import SuggestionToDoTasks from './components/Pages/SuggetionApp/PDCA/ToDo';
import SuggestionOverDueTasks from './components/Pages/SuggetionApp/PDCA/OverDue';
import ControlControlTasks from './components/Pages/SuggetionApp/PDCA/Control';
import SuggestionCompleteTasks from './components/Pages/SuggetionApp/PDCA/Complete';
import TrackKaizen from './components/Pages/KaizenApp/Activities/MyKaizen/TrackKaizen';
import ProcessDetails from './components/Pages/KaizenApp/Activities/KaizenProcess/ProcessDetails';
import KaizenSolutionDetails from './components/Pages/KaizenApp/Activities/KaizenSolution/SolutionDetails';
import KaizenCompleteTasks from './components/Pages/KaizenApp/PDCA/Complete';
import KaizenControlTasks from './components/Pages/KaizenApp/PDCA/Control';
import KaizenToDoTasks from './components/Pages/KaizenApp/PDCA/ToDo';
import KaizenOverDueTasks from './components/Pages/KaizenApp/PDCA/OverDue';
import ComplaintsManagementSolutionDetails from './components/Pages/ExternalCustomerComplaintsApp/SolutionProcess/SolutionDetails';
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthCheck>
          <Routes>
            <Route path="/" element={<LoginPage/>} />
            <Route path="*" element={<MainAppScreen/>} />
            <Route path="/password-reset" element={<ResetPassword/>} />
            {/* buradan sonrası private route içine girecek */}
            <Route path="/app" element={<MainAppScreen/>} />
            <Route path="/app/company-settings" element={<CompanySettings/>}>
              <Route path="/app/company-settings" element={<MainSettings/>}/>
              <Route path="/app/company-settings/region-settings" element={<RegionSettings/>}/>
              <Route path="/app/company-settings/region-settings/:id" element={<SubRegionSettings/>}/>
              <Route path="/app/company-settings/department-settings" element={<DepartmentSettings/>}/>
              <Route path="/app/company-settings/department-settings/:id" element={<SubDepartmentSettings/>}/>
              <Route path="/app/company-settings/user-settings" element={<UserSettings/>}/>
            </Route>
            {/* Auditing App */}
            <Route path="/app/audit-app" element={<ActivitiesDashBoard/>}>
              <Route path="/app/audit-app" element={<AuditAppHomePage/>}/>
              <Route path="/app/audit-app/activities" element={<Activities/>}>
                <Route path="/app/audit-app/activities" element={<Audit/>}/>
                <Route path="/app/audit-app/activities/:id" element={<AuditFollowUp/>}/>
                <Route path="/app/audit-app/activities/gemba" element={<GembaWalk/>}/>
                <Route path="/app/audit-app/activities/gemba/:id" element={<GembaWalkDetails/>}/>
                <Route path="/app/audit-app/activities/workshop" element={<Workshop/>}/>
                <Route path="/app/audit-app/activities/workshop/:id" element={<WorkshopDetails/>}/>
              </Route>
              <Route path="/app/audit-app/reports" element={<AuditReports/>}/>
              <Route path="/app/audit-app/deflects" element={<AuditDeflects/>}>
                <Route path="/app/audit-app/deflects" element={<DeleteRequests/>}/>
                <Route path="/app/audit-app/deflects/date" element={<DateChangeRequests/>}/>

              </Route>
              <Route path="/app/audit-app/settings" element={<AuditAppSettings/>}/>
              <Route path="/app/audit-app/settings/:id" element={<AuditDetails/>}/>
              <Route path="/app/audit-app/over_due" element={<AuditOverDueTasks/>}/>
              <Route path="/app/audit-app/doing" element={<AuditToDoTasks/>}/>
              <Route path="/app/audit-app/control" element={<AuditControlTasks/>}/>
              <Route path="/app/audit-app/done" element={<AuditCompleteTasks/>}/>
            </Route>
            {/* Suggestion App */}
            <Route path="/app/suggestions-app" element={<SuggestionsDashBoard/>}>
              <Route path="/app/suggestions-app" element={<SuggestionAppHomePage/>}/>
              <Route path="/app/suggestions-app/settings" element={<SuggestionSystemSettings/>}/>
              <Route path="/app/suggestions-app/activities" element={<SuggestionAppActivities/>}>
                <Route path="/app/suggestions-app/activities" element={<MySuggestions/>}/>
                <Route path="/app/suggestions-app/activities/create" element={<CreateNewSuggestion/>}/>
                <Route path="/app/suggestions-app/activities/create/:id" element={<TrackSuggestion/>}/>
                <Route path="/app/suggestions-app/activities/process" element={<SuggestionProcessManagment/>}/>
                <Route path="/app/suggestions-app/activities/process/:id" element={<ProcessManagmentInspect/>}/>
                <Route path="/app/suggestions-app/activities/solution" element={<SuggestionSystemSolutionProcess/>}/>
                <Route path="/app/suggestions-app/activities/solution/:id" element={<SuggestionSolutionDetails/>}/>
              </Route>
              <Route path="/app/suggestions-app/committee" element={<SuggestionCommittee/>}>
                <Route path="/app/suggestions-app/committee" element={<SuggestionCommitteeCurrent/>}/>
                <Route path="/app/suggestions-app/committee/past" element={<SuggestionCommitteePast/>}/>
              </Route>
              <Route path="/app/suggestions-app/committee/:id" element={<CommitteeEvaluation/>}/>
              <Route path="/app/suggestions-app/pool" element={<SuggestionPool/>}/>
              <Route path="/app/suggestions-app/earnings" element={<SuggestionAppEarnings/>}/>
              <Route path="/app/suggestions-app/reports" element={<SuggestionAppReports/>}/>
              <Route path="/app/suggestions-app/over_due" element={<SuggestionOverDueTasks/>}/>
              <Route path="/app/suggestions-app/doing" element={<SuggestionToDoTasks/>}/>
              <Route path="/app/suggestions-app/control" element={<ControlControlTasks/>}/>
              <Route path="/app/suggestions-app/done" element={<SuggestionCompleteTasks/>}/>
            </Route>
            {/* Kaizen app */}
            <Route path="/app/kaizen-app" element={<KaizenDashboardsContainer/>}>
              <Route path="/app/kaizen-app" element={<KaizenHomePage/>}/>
              <Route path="/app/kaizen-app/activities" element={<KaizenActivities/>}>
                <Route path="/app/kaizen-app/activities" element={<MyKaizen/>}/>
                <Route path="/app/kaizen-app/activities/create" element={<CreateKaizen/>}/>
                <Route path="/app/kaizen-app/activities/create/:id" element={<TrackKaizen/>}/>
                <Route path="/app/kaizen-app/activities/solution" element={<KaizenSolution/>}/>
                <Route path="/app/kaizen-app/activities/solution/:id" element={<KaizenSolutionDetails/>}/>
                <Route path="/app/kaizen-app/activities/process" element={<KaizenProcess/>}/>
                <Route path="/app/kaizen-app/activities/process/:id" element={<ProcessDetails/>}/>
              </Route>
              <Route path="/app/kaizen-app/improvement/pool" element={<KaizenPool/>}>
                <Route path="/app/kaizen-app/improvement/pool" element={<InternalAuditsKaizens/>}/>
                <Route path="/app/kaizen-app/improvement/pool/internal-customers" element={<IccKaizen/>}/>
                <Route path="/app/kaizen-app/improvement/pool/external-customers" element={<EccKaizen/>}/>
              </Route>
              <Route path="/app/kaizen-app/reports" element={<KaizenReports/>}/>
              <Route path="/app/kaizen-app/done" element={<KaizenCompleteTasks/>}/>
              <Route path="/app/kaizen-app/control" element={<KaizenControlTasks/>}/>
              <Route path="/app/kaizen-app/doing" element={<KaizenToDoTasks/>}/>
              <Route path="/app/kaizen-app/over_due" element={<KaizenOverDueTasks/>}/>
            </Route>
            {/* External Customers Complaint */}
            <Route path="/app/external-customer-complaints-app" element={<ExternalCustomerComplaintsDashboar/>}>
              <Route path="/app/external-customer-complaints-app" element={<ECCHome/>}/>
              <Route path="/app/external-customer-complaints-app/settings" element={<ExternalCustomersComplaintSettings/>}/>
              <Route path="/app/external-customer-complaints-app/activities" element={<ECCManagement/>}>
                <Route path="/app/external-customer-complaints-app/activities" element={<ComplaintsManagmentTable/>}/>
                <Route path="/app/external-customer-complaints-app/activities/create" element={<CreateComplaints/>}/>
                <Route path="/app/external-customer-complaints-app/activities/:id" element={<ComplaintsDetailsPage/>}/>
                <Route path="/app/external-customer-complaints-app/activities/main/process" element={<EccProcessManagement/>}/>
                <Route path="/app/external-customer-complaints-app/activities/main/process/:id" element={<ComplaintsManagementProcessDetails/>}/>
              </Route>
              <Route path="/app/external-customer-complaints-app/requests" element={<EccSolutionProcess/>}/>
              <Route path="/app/external-customer-complaints-app/requests/:id" element={<ComplaintsManagementSolutionDetails/>}/>
              <Route path="/app/external-customer-complaints-app/pool" element={<EccPool/>}/>
              <Route path="/app/external-customer-complaints-app/reports" element={<EccReports/>}/>
            </Route>
            {/* Internal Customers Complaints */}
            <Route path="/app/internal-customer-complaints-app" element={<InternalCustomersComplaintsDashboard/>}>
              <Route path="/app/internal-customer-complaints-app" element={<IccHomePage/>}/>
              <Route path="/app/internal-customer-complaints-app/activities" element={<IccComplaintManagement/>}>
                <Route path="/app/internal-customer-complaints-app/activities" element={<IccComplaintManagementMain/>}/>
                <Route path="/app/internal-customer-complaints-app/activities/create" element={<IccCreateComplaints/>}/>
                <Route path="/app/internal-customer-complaints-app/activities/:id" element={<IccComplaintProcessDetails/>}/>
                <Route path="/app/internal-customer-complaints-app/activities/main/process" element={<IccComplaintDevelopmentReport/>}/>
                <Route path="/app/internal-customer-complaints-app/activities/main/process/:id" element={<IccManagementDetails/>}/>
              </Route>
              <Route path="/app/internal-customer-complaints-app/requests" element={<IccComplaintSolution/>}/>
              <Route path="/app/internal-customer-complaints-app/requests/:id" element={<ComplaintsManagementSolutionDetails/>}/>
              <Route path="/app/internal-customer-complaints-app/reports" element={<IccReports/>}>
              </Route>
              <Route path="/app/internal-customer-complaints-app/settings" element={<IccSettings/>}/>
            </Route>
            {/* Task Management Complaints */}
            <Route path="/app/task-management-app" element={<TaskManagementDashboard/>}>
              <Route path="/app/task-management-app" element={<TMHomePage/>}/>
              <Route path="/app/task-management-app/my-tasks" element={<TMMyTasks/>}/>
              <Route path="/app/task-management-app/apps/internal_audit" element={<TMMyTasks/>}/>
            </Route>
            {/* General Info Application */}
            <Route path="/app/general-info-app" element={<GeneralInfoDashboard/>}>
              <Route path="/app/general-info-app" element={<GeneralInfoCustomers/>}/>
              <Route path="/app/general-info-app/customers" element={<GeneralInfoCustomers/>}>
                <Route path="/app/general-info-app/customers" element={<CustomerTable/>}/>
                <Route path="/app/general-info-app/customers/create" element={<NewCustomerAdd/>}/>
              </Route>
              <Route path="/app/general-info-app/products" element={<GeneralInfoProducts/>}>
                <Route path="/app/general-info-app/products" element={<ProductsTable/>}/>
                <Route path="/app/general-info-app/products/create" element={<NewProductsAdd/>}/>
              </Route>
              <Route path="/app/general-info-app/management-system" element={<GeneralInfoManagmentSystems/>}>
                <Route path="/app/general-info-app/management-system" element={<ManagementSystemTable/>}/>
                <Route path="/app/general-info-app/management-system/create" element={<NewManagementSystemAdd/>}/>
              </Route>
              <Route path="/app/general-info-app/process" element={<GeneralInfoProcess/>}>
                <Route path="/app/general-info-app/process" element={<ProcessTable/>}/>
                <Route path="/app/general-info-app/process/tree" element={<ProcessTreeView/>}/>
                <Route path="/app/general-info-app/process/create" element={<NewProcessAdd/>}/>
              </Route>
            </Route>
            {/* Internal Chating App */}
            <Route path="/app/chat-app" element={<InternalMessagingAppDashboard/>}>
              <Route path="/app/chat-app/*" element={<InternalMessagingAppDashboard/>}/>
              <Route path="/app/chat-app/:id" element={<InternalMessagingAppDashboard/>}/>
            </Route>
          </Routes>
        </AuthCheck>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

// En pahalı 10 çalışanınızı alalım ve onları 50% daha verimli hale getirelim


//  To do list
// start from the bottom and go each application at the end it will end and the problem will be solved in order to solve the problem we have to code as fast as possilbe
