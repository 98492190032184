import { Chip } from '@mui/material'
import { DataGrid, trTR } from '@mui/x-data-grid'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../CompanySettings/MainSettings'
import CustomerDeleteDialog from './Dialogs/EccDeleteDialog'

const ComplaintsManagmentProcessTable = ({user}) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [id,setId] = useState(null)
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
        };
    const handleOpenDeleteDialog = (e) => {
      setId(e)
      setOpenDeleteDialog(true)
    }
    let navigate = useNavigate()
    const [data, setData] = useState(null)


    async function getInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/ecc/create/`,  axiosConfig);
            setData(res.data)
        }
        catch{

        }
    }
    useEffect(() => {getInfo()},[])



    const columns = [
        {
        field: "id",
        headerName: "No",
        width: 50,
        }, 
        {
        field: "pdca",
        headerName: "Durumu",
        width: 110,
        }, 
        {
        field: "customer",
        headerName: "Müşteri",
        width: 250,
        renderCell: (params) => {
            return (
                <p>{params?.row?.customer?.name }</p> 
              )    
          },
    
        },
        {
        field: "created_at",
        headerName: "Açma Tarihi",
        width: 110,
        renderCell: (params) => {
            return (
                <p>{moment(params.row.created_at).format("DD.MM.YYYY")}</p> 
              )    
          },
    
        },
        {
         field: "created_by",
         headerName: "Açan",
         width:          150,
           renderCell: (params) => {
             return (
                <p>{params?.row?.created_by ? params?.row?.created_by?.name + " " + params?.row?.created_by?.surname : "-" }</p> 
               )    
          },
                
        },  
        {
         field: "team_lead",
         headerName: "Takım Lideri",
         width:          150,
           renderCell: (params) => {
             return (
                <p>{params?.row?.solution_team ?  params?.row?.solution_team?.team_lead?.name  + " " + params?.row?.solution_team?.team_lead?.surname:  "-"}</p> 
               )    
          },
                
        },  
       {
         field: "heading",
         headerName: "Şikayetin Tanımı",
         width: 200,
         renderCell: (data) => (
            <p>{data?.row?.heading}</p>
         ),
        },
       {
         field: "due",
         headerName: "Termin Tarihi",
         width: 200,
         renderCell: (data) => (
            <p>{data?.row?.due_date ? moment(data?.row?.due_date).format("DD.MM.YYYY") : ""}</p>                       
         ),
        },
       {
         field: "ürün",
         headerName: "Ürün",
         width: 350,
         renderCell: (data) => (
            <p>{data?.row?.product?.name}</p>
         ),
        },
        {
            field: "details",
            headerName: "Detaylar",
            width: 200,
            renderCell: (data) => (
               <EditButton active onClick={() => navigate(`/app/external-customer-complaints-app/activities/main/process/${data?.row?.id}`)}>Süreci İncele</EditButton>
            ),
           },
    ]
  return (
    <ActivitiesMainContainer>
         <ActivitiesMainWrapper>
            <div style={{height:"75vh"}}>
            <DataGrid
            rows={data ? data : []}
            columns={columns}
            style={{color:"white"}}
            pageSize={15}
            rowsPerPageOptions={[15]}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
        
            />
            </div>
       </ActivitiesMainWrapper>
       <CustomerDeleteDialog id={id} open={openDeleteDialog} setOpen={setOpenDeleteDialog} getInfoBack={getInfo} />
    </ActivitiesMainContainer>
  )
}

const mapStateToProps = state => ({   
    user: state.auth.user
  })
  export default connect(mapStateToProps)  (ComplaintsManagmentProcessTable)