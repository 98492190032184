import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../../AuditApp/Activities/ActivitiesStyle'
import MySuggestionTable from './MySuggestionTable'

const MySuggestions = () => {
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
        setIsLoading(true)
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/my-suggestions/`,  axiosConfig);
            setData(res.data)
            setIsLoading(false)
        }
        catch{
            setIsLoading(false)

        }
    }
    useEffect(() => {getInfo()},[])
  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
            <MySuggestionTable data={data} getInfoBack={getInfo} isLoading={isLoading}/>
        </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default MySuggestions