import styled from "styled-components";


export const KanbanContainer = styled.div`
    display:flex;
    width: 100%;
`

export const KanbanWrapper = styled.div`
    display:flex;
    gap:15px;
    overflow-x:auto;
    width:100%;
    justify-content: space-between;
    @media screen and (max-width:1322px){
        width:950px;
    }
    @media screen and (max-width:1176px){
        width:900px;
    }
    @media screen and (max-width:1122px){
        width:850px;
    }
    @media screen and (max-width:1076px){
        width:800px;
    }
    @media screen and (max-width:1020px){
        width:750px;
    }
    @media screen and (max-width:968px){
        width:700px;
    }
    @media screen and (max-width:920px){
        width:650px;
    }
    @media screen and (max-width:868px){
        width:600px;
    }
    @media screen and (max-width:818px){
        width:550px;
    }
    @media screen and (max-width:762px){
        width:500px;
    }
    @media screen and (max-width:712px){
        width:450px;
    }
    @media screen and (max-width:650px){
        width:400px;
    }
    @media screen and (max-width:600px){
        width:550px;
    }
    @media screen and (max-width:550px){
        width:500px;
    }
    @media screen and (max-width:550px){
        width:450px;
    }
    @media screen and (max-width:450px){
        width:350px;
    }
    @media screen and (max-width:400px){
        width:325px;
    }
`
export const KanbanSingleLine = styled.div`
    display:flex;
    flex-direction:column;
    width: 280px;
    margin: 10px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.25);
    filter:drop-shadow(0 2px 2px #555) 
`

export const KanbanSingleLineHeading = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width: 280px;
    background:rgb(27, 27, 27);
    height:80px;
    border-radius: 10px 10px 0 0;
`

export const KanbanSingleLineMainContainer  = styled.div`
    display:flex;
    justify-content:start;
    flex-direction:column;
    align-items:center;
    width: 280px;
    gap: 35px;
    background:#121212;
    height:60vh;
    overflow-y:auto;
    border-radius: 0 0 10px 10px;

`
export const KanbanSingleLineMainWrapper  = styled.div`
    display:flex;
    justify-content:start;
    flex-direction:column;
    align-items:center;
    gap: 35px;
    padding:10px;
`

export const KanbanSingleLineMainItem = styled.div`
    display:flex;
    width: 180px;
    height: 110px;
    background:#2d2d2d;
    cursor:pointer;
    padding:15px
`