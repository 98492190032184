import { Autocomplete, Grid, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AutoCompleteV2 from '../../../Assets/AutoCompleteV2'
import TextFieldV2 from '../../../Assets/TextFieldV2'
import SuccessSnackBar from '../../../Subcomponents/SuccessSnackBar'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../CompanySettings/MainSettings'
import ProcessTreeView from './ProcessTreeView'
import TreeComponent from './ProcessTreeViewV2'

const NewProcessAdd = () => {
  const options = [];
  const [data, setData] = useState([])
  const [openFeedback, setOpenFeedbacks] = useState(false)
  const [feedback, setFeedback] = useState(null)
  const [parent, setParent] = React.useState(null);
  const [name, setName] = useState(null)
  const [process_code, setProcessCode] = useState(null)
  const axiosConfigJson = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
    }
  };
  async function getData (e){
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/process/`, axiosConfigJson)
        setData(res.data)
    }
    catch{

    }
  }
async function createNewProcess (){
    const body = JSON.stringify({name,process_code,parent:parent?.id})
    try{
      axios.post(`${process.env.REACT_APP_API_URL}/api/general-info/process/`, body,axiosConfigJson)
      setFeedback("Yönetim sistemi Başarıyla Eklendi")
      setOpenFeedbacks(true)
      setName("")
      setParent("")
      setProcessCode("")
      getData()
    }
    catch{

    }
  }
  

  useEffect(() => {getData()},[])
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <Grid container>
          <Grid item xs={12} md={6}>
            <TextFieldV2 label={"Süreç Kodu"} value={process_code} setValue={setProcessCode} />
            <TextFieldV2 label={"Süreç Adı"} value={name} setValue={setName} />
            <AutoCompleteV2 value={parent} setValue={setParent} options={data}  label={"Üst Kategori"} />
            <EditButton active onClick={createNewProcess}>Kaydet</EditButton>
          </Grid>
          <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
            <h1>Süreç Ağacı</h1>
            <TreeComponent data={data}/>
          </Grid>
        </Grid>
      </ActivitiesMainWrapper>
      <SuccessSnackBar open={openFeedback} setOpen={setOpenFeedbacks} data={feedback}/>
    </ActivitiesMainContainer>
  )
}

export default NewProcessAdd