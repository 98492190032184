import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, Tooltip } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../Assets/DialogTitle'
import { EditButton } from '../../../../Pages/CompanySettings/MainSettings'
import SuccessSnackBar from '../../../SuccessSnackBar'
import { styled } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { TaskImageHolder } from '../../ListView/ListViewStyle'
import { FileHolder, FileName } from '../../../../Assets/GeneralDesignElements'
import getFileIcon from '../../../../Assets/GetFileIconsFunction'

const Input = styled('input')({
    display: 'none',
  });
const UploadFilesToTask = ({open, setOpen, getSingleTaskInfoWithId,id}) => {
    const [files, setFiles] = useState([])
    function getFileExtension(filename) {
        return filename.split('.').pop();
      }
      const handleFileChange = (event) => {
        const newFiles = [];
        for (let i = 0; i < event.target.files.length; i++) {
          newFiles.push(event.target.files[i]);
        }
        setFiles((prevFiles) => [...newFiles, ...prevFiles]);
      };
      const handleRemoveFile = (indexToRemove) => {
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles.splice(indexToRemove, 1);
          return newFiles;
        });
      };
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [data, setData] = useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };
    async function submitFiles (){
        setButtonStart(true)
        let form_data = new FormData();
        for (let i = 0; i < files.length; i++) {
            form_data.append(`file${i + 1}`, files[i]);
        }
        form_data.append(`id`, id);
        try{
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/taskmanagment/upload-files/`,  form_data, axiosConfig);
            setSuccessMsg(true)
            setButtonStart(false)
            getSingleTaskInfoWithId()
            setOpen(false)
        }
        catch{
            setOpen(false)
            setButtonStart(false)
            getSingleTaskInfoWithId()

        }
    }
  return (
    <>
    <SuccessSnackBar open={successMsg} setOpen={setSuccessMsg} data={"Dosyalar başarıyla yüklendi."}/>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Göreve Dosya Yükle"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            <Grid item xs={12} align="right">     
            <label htmlFor="contained-button">
                <Input accept="*/*" id="contained-button" multiple   type="file" onChange={handleFileChange} />
                <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={"info"}>
                    Ek döküman ekle &nbsp; <FileUploadIcon/> 
                </Button>
            </label>
            </Grid>
            {files?.length > 0 ?
            <>
            <h5>Dosyalar</h5>
            <TaskImageHolder>
            {files?.map((e,index) => (
              <Tooltip title={e?.name + "Listeden çıkarmak için tıklayın"} arrow>
                  <a onClick={() => handleRemoveFile(index)} style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                <FileHolder>
                  {getFileIcon(getFileExtension(e?.name))}
                  <FileName>{e?.name}</FileName>
                </FileHolder>
                </a>
              </Tooltip>
                ))}
            </TaskImageHolder>
            </>
          :
          ""}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={submitFiles} active>Kaydet</EditButton> : <EditButton>Kaydediliyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default UploadFilesToTask