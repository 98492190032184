import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../Assets/DialogTitle'
import { EditButton } from '../../../CompanySettings/MainSettings'

const ManagementSystemDeleteDialog = ({open, setOpen, getInfoBack,id}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const handleCloseSuccessMsg = () => {
        setSuccessMsg(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const vertical = "bottom"
    const horizontal = "center"
    const [successMsg, setSuccessMsg] = useState(false)
    async function handleDelete() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/general-info/update-delete/management-systems/${id}/`, config);
            setOpen(false)
            setSuccessMsg(true)
            setButtonStart(false)
            getInfoBack()

        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Yönetim Sistemini Sil"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>Yönetim Sistemini kalıcı olarak silmek istiyor musunuz?</DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleDelete} active>Sil</EditButton> : <EditButton>Siliniyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default ManagementSystemDeleteDialog