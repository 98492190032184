import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Input, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import moment from "moment"
import DialogTitleV2 from '../../../Assets/DialogTitle';

const AddNewGembaWalk = ({open, setOpen}) => {
  async function getRegionInfo () {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, config);
        setRegion(res.data)
    } catch (err) {
    }
  }
  useEffect(() => {
    if(open){
      getRegionInfo()
    }
  },[open])
  const [region, setRegion] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(null)
    const handleChangeSelectedRegion = (e) => {
      setSelectedRegion(e.target.value)
  }
    let navigate = useNavigate()
    const [audits, setAudits] = useState([])
    const [audit, setAudit] = useState([])
    const [date, setDate] = useState(moment().format("YYYY-DD-MM"))
    const handleChange = (event) => {
        setAudit(event.target.value);
    };
    useEffect(() => {
      if(open){
        const token = localStorage.getItem("access")
        const opts = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "JWT " + token
            }
        }
        fetch(`${process.env.REACT_APP_API_URL}/api/users/create/`, opts)
        .then(response => response.json()
        .then(data => {
          setAudits(data)
        }))
      }
     
    }, [open])
    const [startButton, setStartButton] = useState(false)
    async function handleStartFieldWalk() {
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({region:selectedRegion?.id ,audit, date})
        setStartButton(true)
        try {
           await axios.post(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/gembawalk/`, body, config);
            navigate("/app/audit-app/activities/gemba")
            setStartButton(false)
            window.location.reload()

        }
        catch (err) {
        setStartButton(false)
        }
      }
    const handleCloseNewGembaWalk = () => {
        setOpen(false)
    }
  return (
    <Dialog open={open} onClose={handleCloseNewGembaWalk}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "500px",  // Set your width here,
          background:"#1e1f21",
          color:"#fff",
          marginBottom:"50px"
        },
      },
    }}
    >
        <DialogTitleV2 title={"Saha Turu Başlat"} />
        <DialogContent>
        <Grid item xs={12}>
                <Box mb={2} mt={2}>
                    <FormControl
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    >
                        <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Bölge Seçin</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRegion}
                            label="Bölge Seçin"
                            onChange={handleChangeSelectedRegion}
                            style={{color:"#fff"}}
                        >
                        {region?.map((reg, index) => (
                        <MenuItem value={reg} key={reg?.id} >{reg.name}</MenuItem>
                        ))
                        } 
                    </Select>
                </FormControl>
                </Box>
            </Grid>
          <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Katılımcı Seç</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={audit}
                    multiple
                    label="department"
                    onChange={handleChange}
                >
                    {audits.map((deps, index) => (
                    <MenuItem value={deps.id}>{deps.name + " " + deps.surname}</MenuItem>
                    ))
                    }
                </Select>
            </FormControl>
            <br/>
            <br/>
            <Input type="datetime-local" value={date} onChange={(e) => setDate(e.target.value)} fullWidth variant="contained"/>

        </DialogContent>
        <DialogActions>
        
        <Button onClick={handleCloseNewGembaWalk}>İptal</Button>
         {!startButton ? <Button onClick={handleStartFieldWalk} variant="contained" color="primary">Saha Turu Başlat</Button> : <Button variant="outlined" color="primary">Başlatılıyor...</Button> }
        </DialogActions>
    </Dialog>
  )
}

export default AddNewGembaWalk