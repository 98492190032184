import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import { EditButton } from '../../../../CompanySettings/MainSettings'

const GembaWalkFinishDialog = ({setOpenSuccess,open, setOpen, getInfoBack,gemba_id,reOpen}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    async function handleDelete() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify(reOpen ?{re_open: true}: {finish_action: true})
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/workshop/${gemba_id}/`,body, config);
            setOpen(false)
            setButtonStart(false)
            getInfoBack()
            setOpenSuccess(true)
        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
         sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here,
                background:"#211c25",
                color:"#fff",
                marginBottom:"50px",
    
              },
            },
          }}
    >
        {reOpen ?
          <DialogTitleV2 title={"Çalıştayı Tekrar Başlat"} handleCloseDialog={handleCloseDialog} />
        :  
          <DialogTitleV2 title={"Çalıştayı Tamamla"} handleCloseDialog={handleCloseDialog} />
        }
        {reOpen ?
        <DialogContent>Çalıştayı tekrar başlatabilirsiniz.</DialogContent>
        :  
        <DialogContent>Çalıştayı tamamlayarak raporunu oluşturabilirsiniz.</DialogContent>
        }
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {reOpen ?
            !buttonStart ? <EditButton onClick={handleDelete} active>Geri Başlat</EditButton> : <EditButton>Başlatılıyor...</EditButton>
            :
            !buttonStart ? <EditButton onClick={handleDelete} active>Tamamla</EditButton> : <EditButton>Tamamlanıyor...</EditButton>
            }
        </DialogActions>
    </Dialog>
    </>
  )
}

export default GembaWalkFinishDialog