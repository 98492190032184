import React from 'react'
import { InnerNavigation, InnerNavigationItems } from '../../AuditApp/Activities'
import { ApplicationContainer } from '../../AuditApp/HomePage'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const SuggestionAppActivities = () => {
    let navigate = useNavigate()
    const {pathname} = useLocation()
  return (
    <ApplicationContainer style={{width:"100%"}}>
        <InnerNavigation style={{borderBottom:"1px solid grey", paddingBottom:"10px", marginBottom:"10px"}}>
            <InnerNavigationItems active={pathname === "/app/suggestions-app/activities"} onClick={() => navigate("/app/suggestions-app/activities")}>Önerilerim</InnerNavigationItems>
            <InnerNavigationItems active={pathname.includes("process")} onClick={() => navigate("/app/suggestions-app/activities/process")}>Süreç Yönetimi</InnerNavigationItems>
            <InnerNavigationItems active={pathname.includes("solution")} onClick={() => navigate("/app/suggestions-app/activities/solution")}>Çözüm Yönetimi</InnerNavigationItems>
        </InnerNavigation>
        <Outlet/>
    </ApplicationContainer>
  )
}

export default SuggestionAppActivities