import React, {useEffect, useState} from 'react'
import TextFieldV2 from '../../../Assets/TextFieldV2';
import SendIcon from '@mui/icons-material/Send';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { FileHolder, FileName, FileThumbnail } from '../../../Assets/GeneralDesignElements';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CompareIcon from '@mui/icons-material/Compare';
import ExcelIcon from '../../../Assets/FileIcons/excel.png'
import WordIcon from '../../../Assets/FileIcons/word.png'
import PdfIcon from '../../../Assets/FileIcons/pdf.png'
import ImageIcon from '../../../Assets/FileIcons/Image.png'
import RarIcon from '../../../Assets/FileIcons/rar.png'
import UnKnown from '../../../Assets/FileIcons/UnKnown.webp'
import { GetGeneralInfoBox, InnnerInfoBox, ListTableContainer, ListTableWrapper, MainTaskContainer, MainTaskWrapper, OverLay, QuickActionsContainer, TaskChatBoxContainer, TaskChatBoxInner, TaskChatComBar, TaskChatComBarInner, TaskChatItem, TaskChatItemMessageChat, TaskChatItemMessageCont, TaskChatItemMessageName, TaskImageHolder  } from '../ListView/ListViewStyle';
import moment from 'moment';
import DialogTitleV2 from '../../../Assets/DialogTitle';
import { Alert, Avatar, Chip, CircularProgress, Divider, Grid, Menu, MenuItem,  Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import axios from 'axios';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddActionDialog from './Dialogs/AddActionDialog';
import EditActionDialog from './Dialogs/EditActionDialog';
import ActionControlDialog from './Dialogs/ControlDialog';
import DeleteTaskDialog from './Dialogs/DeleteTaskDialog';
import EditTaskDialog from './Dialogs/EditDialog';
import { connect } from 'react-redux';
import UploadFilesToTask from './Dialogs/UploadFiles';
import DeleteFilesDialog from './Dialogs/DeleteFiles';
import BeforeAfterDialog from './Dialogs/BeforeAfterDialog';
const TaskTrackingOverLay = ({editBarOpen,setEditBarOpen, handleCloseDialog, selectedTask,setSelectedTask, id,user,isFetchingDetailMain}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
  setAnchorEl(null);
  };
    const [isFetchingDetails, setIsFetchingDetails] = useState(false)
    const [addActionDialogOpen, setAddActionDialogOpen] = useState(false)
    const [editActionDialogOpen, setEditActionDialogOpen] = useState(false)
    // Edit Action Consts
    const [action_text, setActionText] = useState(null)
    const [after_image_path, setAfterImagePath] = useState(null)
    const handleOpenEditActionDialog = () => {
      setActionText(selectedTask?.action_text)
      setAfterImagePath(selectedTask?.after_image_path?.path)
      setEditActionDialogOpen(true)
    }
    // Accept Dialog
    const [acceptActionDialogOpen, setAcceptActionDialogOpen] = useState(false)
    // Delete Dialog
    const [deleteActionDialogOpen, setDeleteActionDialogOpen] = useState(false)
    const handleOpenDeleteActionDialog = () => {
      handleClose()
      setDeleteActionDialogOpen(true)
    }
    // Görev Düzenle Dialog
    const [editTaskDialogOpen, setEditTaskDialogOpen] = useState(false)
    const [task, setTask] = useState(null)
    const [region, setRegion] = useState(null)
    const [department, setDepartment] = useState(null)
    const [responsible_by, setResponsibleBy] = useState(null)
    const [due_date, setDueDate] = useState(null)
    const [before_image_path, setBeforeImagePath] = useState(null)
    const handleOpenEditTaskDialog = () => {
      setEditTaskDialogOpen(true)
      handleClose()
      setTask(selectedTask?.task)
      setRegion(selectedTask?.region?.id)
      setDepartment(selectedTask?.section?.id)
      setResponsibleBy(selectedTask?.assignee)
      setDueDate(selectedTask?.due_date)
      setBeforeImagePath(selectedTask?.before_image_path?.path)
    }
    // upload files dialog
    const [uploadFilesToTaskDialogOpen, setUploadFilesToTaskDialogOpen] = useState(false)
    const handleOpenUploadFiles = () => {
      setUploadFilesToTaskDialogOpen(true)
      handleClose()
    }
    // Ekli dosyaları silme
    const [deleteUploadedFilesDialogOpen, setDeleteUploadedFilesDialogOpen] = useState(false)
    const [uploadedFiles,setUploadedFiles] = useState(null)
    const [fileFrom, setFileFrom] = useState(false)
    const handleOpenDeleteFiles = (e) =>{
      if(e === "task"){
        setUploadedFiles(selectedTask?.files)
      }
      else{
        setUploadedFiles(selectedTask?.action_files)
        
      }
      setDeleteUploadedFilesDialogOpen(true)
      setFileFrom(e)
    }
    // Before after dailog
    const [beforeAfterDialogOpen, setBeforeAfterDialogOpen] = useState(false)
    const [done_by, setDoneBy] = useState(null)
    const [sectionArray, setSectionArray] = useState(null)
    const [regionArray, setRegionArray] = useState(null)
    const [completed_at, setCompletedAt] = useState(null)
    const handleOpenBeforeAfterDailog = () => {
      setTask(selectedTask?.task)
      setRegion(selectedTask?.region?.id)
      setDepartment(selectedTask?.section?.id)
      setResponsibleBy(selectedTask?.assignee)
      setDueDate(selectedTask?.due_date)
      setBeforeImagePath(selectedTask?.before_image_path?.path)
      setActionText(selectedTask?.action_text)
      setAfterImagePath(selectedTask?.after_image_path?.path)
      setDoneBy(selectedTask?.created_by)
      setSectionArray(selectedTask?.section)
      setRegionArray(selectedTask?.region)
      setCompletedAt(selectedTask?.completed_at)
      setBeforeAfterDialogOpen(true)
    }

    // this is for preventing the overlay close when the dialogs are open
    const preventCloseOnActionTaken = () => {
      if(addActionDialogOpen ||editActionDialogOpen || acceptActionDialogOpen || editTaskDialogOpen ||uploadFilesToTaskDialogOpen ||deleteUploadedFilesDialogOpen||beforeAfterDialogOpen){

      }
      else{
        return handleCloseDialog()
      }
    }

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    function getFileIcon(extension) {
        switch (extension) {
          case 'xls':
          case 'xlsx':
            return <FileThumbnail src={ExcelIcon} alt="xls" />;
          case 'pdf':
            return <FileThumbnail src={PdfIcon} alt="xls" />;
          case 'doc':
          case 'docx':
            return <FileThumbnail src={WordIcon} alt="xls" />;
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'pjpeg':
            return <FileThumbnail src={ImageIcon} alt="xls" />;
          case 'zip':
          case 'rar':
            return <FileThumbnail src={RarIcon} alt="xls" />;
          default:
            return <FileThumbnail src={UnKnown} alt="xls" />;
        }
      }
      
  const [chatOpen, setChatOpen] = useState(false)
  const toggleChat = () => {
    setChatOpen(!chatOpen)
  }

  const [message, setMessage] = useState(null)
  async function createChatInfo (){
    const body = JSON.stringify({task_id: selectedTask?.id, message})
    try{
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/taskmanagment/chat-create/`, body, axiosConfig)
      setMessage("")
      getSingleTaskInfoWithId()
  }
  catch{

  }
  }
  async function getSingleTaskInfoWithId (e){
    setEditBarOpen(true)
    setIsFetchingDetails(true)
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagment/single-task/${id}/`,  axiosConfig)
      setSelectedTask(res.data)
      setIsFetchingDetails(false)

  }
  catch{

  }
  }
  async  function fileFromChange () {
        setEditBarOpen(true)
        setIsFetchingDetails(true)
        try{
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagment/single-task/${id}/`,  axiosConfig)
        setSelectedTask(res.data)
        if(fileFrom === "task"){
            setUploadedFiles(res.data?.files)
          }
          else{
            setUploadedFiles(res.data?.action_files)
            
          }
          setIsFetchingDetails(false)

      }
      catch{

      }
    
  }
  console.log(uploadedFiles,"upfd2")



  return (
    <OverLay editBarOpen={editBarOpen} onClick={preventCloseOnActionTaken}>
        <GetGeneralInfoBox editBarOpen={editBarOpen} onClick={(event) => event.stopPropagation()}>
          <InnnerInfoBox>
          <DialogTitleV2 handleCloseDialog={handleCloseDialog} title={"Görevi Takip Et"}  />
          <Divider style={{width:"100%", background:"gray"}} />
          {isFetchingDetails || isFetchingDetailMain ?
          <div style={{height:"100%", width:"100%", display:"flex", justifyContent:"center",alignItems:"center"}}>
            <CircularProgress/>
          </div>
          :
          <MainTaskContainer > 
            <MainTaskWrapper chatOpen={chatOpen}>
              <QuickActionsContainer>
              {selectedTask?.pdca === "Plan" ?
              <Tooltip title="Aksiyon Ekle" arrow>
                <Chip 
                onClick={() =>setAddActionDialogOpen(true)}
                color="warning" label={"Aksiyon Al"} style={{cursor:"pointer"}}/>
              </Tooltip>
              :
              <Tooltip title="Aksiyon Düzenle" arrow>
                <Chip 
                onClick={() =>handleOpenEditActionDialog()}
                color="info" label={"Aksiyon Düzenle"} style={{cursor:"pointer"}}/>
              </Tooltip>
              }
              {selectedTask?.pdca === "Control" && user?.type?.includes("manager") ?
              <Tooltip title="Kontrol Kararı" arrow>
                <Chip 
                onClick={() =>setAcceptActionDialogOpen(true)}
                color="warning" label={"Kontrol Kararı"} style={{cursor:"pointer"}}/>
              </Tooltip>
              :""}
              {/* <Tooltip title="Alt Görev Ekle" arrow>
                <AccountTreeIcon style={{cursor:"pointer"}}/>
              </Tooltip> */}
              <Tooltip title="Öncesi Sonrası" arrow>
                <CompareIcon style={{cursor:"pointer"}} onClick={()=>handleOpenBeforeAfterDailog()}/>
              </Tooltip>
              <Tooltip title="Diğer İşlemler" arrow>
                <MoreHorizIcon onClick={handleClick} style={{cursor:"pointer"}}/>
              </Tooltip>
              <Menu
                id="long-menu"
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                style: {
                    width: '25ch',
                },
                }}
              >
                <MenuItem onClick={() => handleOpenUploadFiles()}>
                  <FileUploadIcon/>
                  Dosya Yükle
                </MenuItem>
                {user?.type?.includes("manager") ?
                <MenuItem onClick={() => handleOpenDeleteActionDialog()}>
                  <DeleteIcon/>
                  Sil
                </MenuItem>:
                ""
                }
                <MenuItem onClick={() => handleOpenEditTaskDialog()}>
                  <EditIcon/>
                  Düzenle
                </MenuItem>
              </Menu>
              </QuickActionsContainer>
              <Divider style={{background:"gray", width:"100%", marginTop:"8px"}} />
               <h4>{selectedTask?.task}</h4>
               {selectedTask?.pdca === "Do" && selectedTask?.revision_text ?
               <Alert color="error">{selectedTask?.revision_text}</Alert>
               :
                ""
                }
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{width:"110px"}}>Bölge</TableCell>
                    <TableCell>{selectedTask?.region?.name + " / " +selectedTask?.section?.department_hierarchy}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{width:"110px"}}>Durum</TableCell>
                    <TableCell>{selectedTask?.pdca}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{width:"110px"}}>Talep Eden</TableCell>
                    <TableCell>{selectedTask?.created_by?.name + " " +selectedTask?.created_by?.surname}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{width:"110px"}}>Sorumlu</TableCell>
                    <TableCell>{selectedTask?.assignee?.name + " " +selectedTask?.assignee?.surname}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{width:"110px"}}>Termin Tarihi</TableCell>
                    <TableCell>{moment(selectedTask?.due_date).format("DD.MM.YYYY")}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{width:"110px"}}>Tamamlanma Tarihi</TableCell>
                    <TableCell>{selectedTask?.completed_at ? moment(selectedTask?.completed_at).format("DD.MM.YYYY") : "Tamamlanmadı"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{width:"110px"}}>Uygulama</TableCell>
                    <TableCell>{selectedTask?.from_app ? selectedTask?.from_app?.name : "-"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {selectedTask?.before_image_path ?  
              <>
              <h5>Görseller</h5>
              <TaskImageHolder>
              <img style={{width:"50%", objectFit:"contain"}} src={selectedTask?.before_image_path?.path} alt="dosya" /> 
              </TaskImageHolder>
              </>
              :
              ""}
              {selectedTask?.files?.length > 0 ?
              <>
              <Grid display="flex" justifyContent={"left"} alignItems={"center"} gap={"15px"} xs={12}>
              <h5>Dosyalar</h5>
              <Tooltip title={"Eklenen Dosyaları Silme"}>
                <DeleteIcon style={{cursor:"pointer"}} onClick={() => handleOpenDeleteFiles("task")} />
              </Tooltip>
              </Grid>
              <TaskImageHolder>
              {selectedTask?.files?.map((e) => (
                <Tooltip title={e?.file_name + " İndirmek İçin Tıklayın"} arrow>
                    <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                  <FileHolder>
                    {getFileIcon(e?.file_type)}
                    <FileName>{e?.file_name}</FileName>
                  </FileHolder>
                  </a>
                </Tooltip>
                 ))}
              </TaskImageHolder>
              </>
              :
              ""}
              <Divider style={{width:"100%", background:"white", marginTop:"15px"}}/>
              <h2 style={{textAlign:"center"}}>Alınan Aksiyon</h2>
              <h4>  {selectedTask?.action_text}</h4>
              {selectedTask?.after_image_path ?  
              <>
              <h5>Aksiyon Görselleri</h5>
              <TaskImageHolder>
              <img style={{width:"50%", objectFit:"contain"}} src={selectedTask?.after_image_path?.path} alt="dosya" /> 
              </TaskImageHolder>
              </>
              :
              ""}
              {selectedTask?.action_files?.length > 0 ?
              <>
              <Grid display="flex" justifyContent={"left"} alignItems={"center"} gap={"15px"} xs={12}>
              <h5>Dosyalar</h5>
              <Tooltip title={"Eklenen Dosyaları Silme"}>
                <DeleteIcon style={{cursor:"pointer"}} onClick={() => handleOpenDeleteFiles("actions")} />
              </Tooltip>
              </Grid>
              <TaskImageHolder>
              {selectedTask?.action_files?.map((e) => (
                <Tooltip title={e?.file_name + " İndirmek İçin Tıklayın"} arrow>
                    <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                  <FileHolder>
                    {getFileIcon(e?.file_type)}
                    <FileName>{e?.file_name}</FileName>
                  </FileHolder>
                  </a>
                </Tooltip>
                 ))}
              </TaskImageHolder>
              </>
              :
              ""}
            </MainTaskWrapper>
          </MainTaskContainer>
          }
            <TaskChatBoxContainer>
              <TaskChatBoxInner>
              <Avatar>AB</Avatar>
              <TextFieldV2 label={"Yorum veya mesajınızı yazın"} 
              value={message}
              setValue={setMessage}
              endAdornmentText={<SendIcon onClick={() => createChatInfo()} style={{cursor:"pointer"}}/>} />
              </TaskChatBoxInner>
              <p onClick={toggleChat} style={{position:"absolute", right:15, top:-45, display:!chatOpen ? "block": "none"}}><KeyboardDoubleArrowUpIcon style={{cursor:"pointer"}}/></p>
            </TaskChatBoxContainer>
            <TaskChatComBar show={chatOpen}>
              <p onClick={toggleChat} style={{position:"absolute", right:15, top:0, display:chatOpen ? "block": "none"}}><KeyboardDoubleArrowDownIcon style={{cursor:"pointer"}}/></p>
              <TaskChatComBarInner>
              {selectedTask?.taskmanagement_chat?.slice(0)?.reverse()?.map((e) => (
               <TaskChatItem>
                <Tooltip title={e?.sender?.name + " " + e?.sender?.surname}>
                  <Avatar/>
                </Tooltip>
                <TaskChatItemMessageCont>
                  <TaskChatItemMessageName>{e?.sender?.name + " " + e?.sender?.surname} | <span>{moment(e?.created_at).format("DD.MM.YYYY hh:mm")}</span></TaskChatItemMessageName>
                  <TaskChatItemMessageChat>{e?.message} </TaskChatItemMessageChat>
                </TaskChatItemMessageCont>
               </TaskChatItem>
              ))}
              </TaskChatComBarInner>
            </TaskChatComBar>
          </InnnerInfoBox>
        </GetGeneralInfoBox>
        <AddActionDialog
        open={addActionDialogOpen}
        setOpen={setAddActionDialogOpen}
        id={id}
        getSingleTaskInfoWithId={getSingleTaskInfoWithId}
        />
        <EditActionDialog
        open={editActionDialogOpen}
        setOpen={setEditActionDialogOpen}
        id={id}
        getSingleTaskInfoWithId={getSingleTaskInfoWithId}
        action_text={action_text}
        setActionText={setActionText}
        after_image_path = {after_image_path}
        setAfterImagePath = {setAfterImagePath}
        />
        <ActionControlDialog
          open={acceptActionDialogOpen}
          setOpen={setAcceptActionDialogOpen}
          id={id}
          getSingleTaskInfoWithId={getSingleTaskInfoWithId}
        />
        <DeleteTaskDialog
          open={deleteActionDialogOpen}
          setOpen={setDeleteActionDialogOpen}
          id={id}
          handleCloseDialog2={handleCloseDialog}
        />
        <EditTaskDialog
          open={editTaskDialogOpen}
          setOpen={setEditTaskDialogOpen}
          getSingleTaskInfoWithId={getSingleTaskInfoWithId}
          id={id}
          handleCloseDialog2={handleCloseDialog}
          region={region}
          setRegion={setRegion}
          department={department}
          setDepartment={setDepartment}
          responsible_by={responsible_by}
          setResponsibleBy={setResponsibleBy}
          due_date={due_date}
          setDueDate={setDueDate}
          before_image_path={before_image_path}
          task={task} 
          setTask={setTask}
        />
        <UploadFilesToTask
        open={uploadFilesToTaskDialogOpen}
        setOpen={setUploadFilesToTaskDialogOpen}
        getSingleTaskInfoWithId={getSingleTaskInfoWithId}
        id={id}
        />
        <DeleteFilesDialog
         open={deleteUploadedFilesDialogOpen}
         setOpen={setDeleteUploadedFilesDialogOpen}
         getSingleTaskInfoWithId={fileFromChange}
         uploadedFiles={uploadedFiles}
         />
         <BeforeAfterDialog
          open={beforeAfterDialogOpen}
          setOpen={setBeforeAfterDialogOpen}
          done_by={done_by}
          finding_image_path={before_image_path}
          finding={task}
          responsible_by={responsible_by}
          after_image_path={after_image_path}
          after_text={action_text}
          id={id}
          dueDate={due_date}
          completed_at={completed_at}
          sectionArray={sectionArray}
          regionArray={regionArray}
          />
      </OverLay>
  )
}
const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps)(TaskTrackingOverLay)