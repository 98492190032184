import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../../Subcomponents/PDCA/QAStyle'
import TaskManagementMainBoard from '../../../Subcomponents/TaskManagementViews/ListView'
import { ApplicationContainer } from '../HomePage'

const AuditCompleteTasks = () => {
    const [isFetching, setIsFetching] =useState(false)
    const [data, setData] = useState()
    const [rowCount, setRowCount] = useState(null)
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    })
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
        setIsFetching(true)
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagment/get-audit-tasks-overview/?page=${pagination?.pageIndex+1}&t=act`,  axiosConfig);
            setData(res.data.results)
            setRowCount(res.data.count)
            setIsFetching(false)

        }
        catch{
            setIsFetching(false)

        }
    }
    useEffect(() => {getInfo()},[pagination?.pageIndex])
  return (
    <ApplicationContainer style={{width:"100%"}}>
        <ActivitiesMainContainer>
            <ActivitiesMainWrapper>
                <TaskManagementMainBoard 
                rowCount={rowCount} 
                pagination={pagination}
                setPagination={setPagination}
                fetchBack={getInfo}
                data={data} isFetching={isFetching}  />
            </ActivitiesMainWrapper>
        </ActivitiesMainContainer>
    </ApplicationContainer>
  )
}

export default AuditCompleteTasks