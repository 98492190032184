import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
export const GeneralInfoNav = [
    {
        id:1,
        name:"Anasayfa",
        icon:(<HomeIcon fontSize="small"/>),
        urlPath: "/app/general-info-app",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:2,
        name:"Müşteriler",
        icon:(<GroupsIcon fontSize="small"/>),
        urlPath: "/app/general-info-app/customers",
        childeren_urlPath : [
            {
                id:1,
                urlPath: "/app/general-info-app/customers/create"
            },
          
        ],
        show_on_side_bar: true,
    },
    {
        id:2,
        name:"Ürünler",
        icon:(<Inventory2Icon fontSize="small"/>),
        urlPath: "/app/general-info-app/products",
        childeren_urlPath : [
            {
                id:1,
                urlPath: "/app/general-info-app/products/create"
            }
        ],
        show_on_side_bar: true,
    },
    {
        id:4,
        name:"Yönetim Sistemi",
        icon:(<ManageAccountsIcon fontSize="small"/>),
        urlPath: "/app/general-info-app/management-system",
        childeren_urlPath : [
            {
                id:1,
                urlPath:"/app/general-info-app/management-system/create"
            }
        ],
        show_on_side_bar: true,
    },
    {
        id:4,
        name:"Süreç",
        icon:(<AccountTreeIcon fontSize="small"/>),
        urlPath: "/app/general-info-app/process",
        childeren_urlPath : [
            {
                id:1,
                urlPath:"/app/general-info-app/process/create"
            },
            {
                id:2,
                urlPath:"/app/general-info-app/process/tree"
            },
        ],
        show_on_side_bar: true,
    },
    {
        id:5,
        name:"Müşteri Ekle",
        icon:(null),
        urlPath: "/app/general-info-app/customers/create",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:6,
        name:"Ürün Ekle",
        icon:(null),
        urlPath: "/app/general-info-app/products/create",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:7,
        name:"Yönetim Sistemi Ekle",
        icon:(null),
        urlPath: "/app/general-info-app/management-system/create",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:7,
        name:"Süreç Ağacı",
        icon:(null),
        urlPath: "/app/general-info-app/process/tree",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:8,
        name:"Süreç Ekle",
        icon:(null),
        urlPath: "/app/general-info-app/process/create",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },


    
   
    
]