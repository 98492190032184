import styled from "styled-components"


export const ListTableWrapper = styled.div`
    width: 100%;
   display:flex;
`
export const ListTableContainer = styled.div`
    width: 100%;
    justify-content:center;
    align-items:center;
    @media screen and (max-width:1322px){
        width:950px;
    }
    @media screen and (max-width:1176px){
        width:900px;
    }
    @media screen and (max-width:1122px){
        width:850px;
    }
    @media screen and (max-width:1076px){
        width:800px;
    }
    @media screen and (max-width:1020px){
        width:750px;
    }
    @media screen and (max-width:968px){
        width:700px;
    }
    @media screen and (max-width:920px){
        width:650px;
    }
    @media screen and (max-width:868px){
        width:600px;
    }
    @media screen and (max-width:818px){
        width:550px;
    }
    @media screen and (max-width:762px){
        width:500px;
    }
    @media screen and (max-width:712px){
        width:450px;
    }
    @media screen and (max-width:650px){
        width:400px;
    }
    @media screen and (max-width:600px){
        width:550px;
    }
    @media screen and (max-width:550px){
        width:500px;
    }
    @media screen and (max-width:550px){
        width:450px;
    }
    @media screen and (max-width:450px){
        width:350px;
    }
    @media screen and (max-width:400px){
        width:325px;
    }
`

export const GetGeneralInfoBox = styled.div`
    width:550px;
    z-index:99;
    background:#121212; 
    min-height: -webkit-fill-available;
    border-radius:8px;
    position:absolute;
    right:0;
    display:${({editBarOpen}) => editBarOpen ? "flex": "none"};
    @media screen and (max-width:600px){
        width:100%;
    }
`

export const InnnerInfoBox = styled.div`
    width:100%;
`

export const OverLay = styled.div`
    background: rgba(0,0,0,0.3);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
    display:${({editBarOpen}) => editBarOpen ? "flex": "none"};
    transition: transform .2s ease-out;
`

export const TaskChatBoxContainer = styled.div`
    position:absolute;
    bottom:0;
    height:110px;
    width:100%;
    background: #252525;
`
export const TaskChatBoxInner = styled.div`
    display:flex;
    justify-content:start;
    align-items:center;
    padding:15px;
    gap:15px;
`
export const TaskChatComBar = styled.div`
    position: absolute;
    bottom: 110px; /* set the height of the bottom div */
    width: 100%;
    height: 180px;
    background-color: #72727226;
    border-radius: 8px 8px 0 0;
    display: ${({show}) => show ? "flex" : "none"};
    flex-direction:column;
`


export const TaskChatComBarInner = styled.div`
    display:flex;
    padding: 15px;
    flex-direction:column;
    overflow-y:auto;
    gap:15px

`

export const TaskChatItem = styled.div`
    display:flex;
    align-items:center;
    gap:5px;

`

export const TaskChatItemMessageCont = styled.div`
    display:flex;
    flex-direction:column;
    gap:4px;
    width:90%;
`
export const TaskChatItemMessageName = styled.span`
    font-size:13px;
`
export const TaskChatItemMessageChat = styled.span`
    font-size:16px;
`

export const MainTaskContainer = styled.div`
    display:flex;
`

export const MainTaskWrapper = styled.div`
    padding:15px;
    width:100%;
    height: ${({chatOpen}) => chatOpen ?"calc(100vh - 450px)" : "calc(100vh - 250px)"} ;
    overflow-y:auto;
`

export const TaskImageHolder = styled.div`
    display:flex;
    overflow-x:auto;
    gap:25px
`
export const QuickActionsContainer = styled.div`
    display:flex;
    justify-content:end;
    align-items:center;
    gap:6px;
`