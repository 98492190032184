import React, { useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {  ListTableContainer, ListTableWrapper, } from './ListViewStyle';
import {  Divider, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import TaskTrackingOverLay from '../TaskTrackingOverLay';


const TaskManagementMainBoard = ({data, isFetching, rowCount,pagination,setPagination,fetchBack}) => {
  const [message, setMessage] = useState(null)
  const [id, setId] = useState(null)
  const [isFetchingDetailMain, setIsFetchingDetailMain] = useState(false)
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };


    const [editBarOpen, setEditBarOpen] = useState(false)
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'task', //access nested data with dot notation
        header: 'Görev Konusu',
      },
      {
        accessorKey: 'responsible_by', //access nested data with dot notation
        header: 'Sorumlu',
        Cell: ({ cell }) => (
          cell.row?.original?.assignee?.name + " " + cell.row?.original?.assignee?.surname
        )
      },
      {
        accessorKey: 'Saha', //access nested data with dot notation
        header: 'Bölge',
        Cell: ({ cell }) => (
          cell.row?.original?.region?.name + " / " + cell.row?.original?.section?.department_hierarchy
        )
      },
      {
        accessorKey: 'pdca',
        header: 'Durumu',
      },
      {
        accessorKey: 'due_date',
        header: 'Termin Tarihi',
      },
     
    ],
    [],
  );

  const handleCloseDialog = () => {
    setEditBarOpen(false)
    setSelectedTask(null)
    fetchBack()

  }

  const [chatOpen, setChatOpen] = useState(false)
  const toggleChat = () => {
    setChatOpen(!chatOpen)
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };

    const [selectedTask, setSelectedTask] = useState(null)
    async function createChatInfo (){
      const body = JSON.stringify({task_id: selectedTask?.id, message})
      try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/taskmanagment/chat-create/`, body, axiosConfig)
        setMessage("")
        // getChatInfo()
    }
    catch{

    }
    }
    async function getSingleTaskInfo (e){
      setId(e)
      setIsFetchingDetailMain(true)
      setEditBarOpen(true)
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagment/single-task/${e}/`,  axiosConfig)
        setSelectedTask(res.data)
        setIsFetchingDetailMain(false)

    }
    catch{
      setIsFetchingDetailMain(false)

    }
    }
  return (
<ListTableWrapper>
    <ListTableContainer>
      <TaskTrackingOverLay
          editBarOpen={editBarOpen}
          setEditBarOpen={setEditBarOpen}
          handleCloseDialog={handleCloseDialog}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          id={id}
          isFetchingDetailMain={isFetchingDetailMain}
          />
        <MaterialReactTable
          columns={columns}
          data={data ? data : []}
          rowCount={rowCount}
          onPaginationChange={setPagination}
          getRowId={(row) => row.id}
          manualFiltering
          manualPagination
          manualSorting
          state={{
              showProgressBars: isFetching,
              pagination
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [15],
            rowsPerPage: 15
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
              getSingleTaskInfo(row?.original?.id)
            },
            sx: {
              cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
          })}
          // positionActionsColumn="last"
          enableTopToolbar={false}
          enableStickyHeader={true}
          enableColumnResizing
          columnResizeMode="onEnd" //instead of the default "onChange" mode
          muiTableContainerProps={{
            sx: { height: '70vh'
          }, }}
           />
     </ListTableContainer>
     <Menu
        id="basic-menu"
        style={{maginTop:"10px",backgroundColor:"rgba(255,255,255,0)"}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              fontFamily:"apple",
              width:"200px",
              color:"white",
              background:"rgba(255,255,255,0.3)",
              backdropFilter: "blur( 20px )",
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
             
            },
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        MenuListProps={{
        'aria-labelledby': 'basic-button',
        }}
    >
        <MenuItem onClick={handleClose}>Aksiyon Ekle</MenuItem>
        <MenuItem onClick={handleClose}>Ek Belge Ekle</MenuItem>
        <Divider style={{color:"white", backgroundColor:"white"}}/>
        <MenuItem onClick={handleClose}>Mail Gönder</MenuItem>

    </Menu>
     </ListTableWrapper>
  )
};

export default TaskManagementMainBoard;