import React from 'react';
import Tree from 'react-d3-tree';


export default function OrgChartTree({data}) {


  return (
    <div id="treeWrapper" style={{ width: '100%', height: '80vh', border:"1px solid white" }}>
      <Tree data={{name:"şirket", children:data}} 
      orientation="vertical"
      pathFunc={"step"}
      separation={{siblings:2,nonSiblings:2}}
      />
    </div>
  );
}

