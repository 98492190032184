import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextareaAutosize } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import { EditButton } from '../../../../CompanySettings/MainSettings'

const CompleteSuggestionDialog = ({open, setOpen, getInfoBack,id,setConfetiiOpen}) => {
    const [rejectReason, setRejectReason] = useState(null)
    const handleCloseDialog = () => {
        setOpen(false)
    }
    console.log(rejectReason)
    const handleCloseSuccessMsg = () => {
        setSuccessMsg(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const vertical = "bottom"
    const horizontal = "center"
    const [successMsg, setSuccessMsg] = useState(false)
    async function handleDelete() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({total_complete:true})
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/suggestions/get/process_manager_suggestions/${id}/`, body,config);
            setOpen(false)
            setSuccessMsg(true)
            setButtonStart(false)
            getInfoBack()
            setConfetiiOpen(true)
        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Öneriyi Tamamla"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
           Öneriyi tamamlamak için lütfen tamamla butonuna basınız.
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleDelete} active>Tamamla</EditButton> : <EditButton>Tamamlanıyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default CompleteSuggestionDialog