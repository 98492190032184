import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { TaskFollowFooter } from '../../../../Assets/GeneralDesignElements';
import { Audio, TailSpin } from 'react-loader-spinner'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const QuickToDo = () => {
    const [isFetching, setIsFetching] = React.useState(false)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const [data, setData] = React.useState()
    const [count, setCount] = React.useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
        setIsFetching(true)
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagement/home-tasks/?t=${value === 0 ? "over_due": value === 1 ? "control": "act"}`,  axiosConfig);
            setData(res.data.results)
            setIsFetching(false)
            setCount(res.data.count)
        }
        catch{
            setIsFetching(false)

        }
    }
    React.useEffect(() => {
        getInfo()
    },[value])
  
    return (
        <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="TERMİNİ GEÇENLER" {...a11yProps(0)} />
            <Tab label="KONTROL" {...a11yProps(1)} />
            <Tab label="TAMAMLANANLAR" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Table stle={{width:"100%"}}>
            <TableBody>
                {
                isFetching?
                <TailSpin/>
                :
                data?.map((e) => (
                <TableRow>
                    <TableCell>
                        {e.task}
                    </TableCell>
                    <TableCell>
                        {e.due_date}
                    </TableCell>
                </TableRow>
                ))
                
                }
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value={value} index={1}>
       <Table stle={{width:"100%"}}>
            <TableBody>
                {
                isFetching?
                <TailSpin/>
                :
                data?.map((e) => (
                <TableRow>
                    <TableCell>
                        {e.task}
                    </TableCell>
                    <TableCell>
                        {e.due_date}
                    </TableCell>
                </TableRow>
                ))
                
                }
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value={value} index={2}>
        <Table stle={{width:"100%"}}>
            <TableBody>
                {
                isFetching?
                <TailSpin/>
                :
                data?.map((e) => (
                <TableRow>
                    <TableCell>
                        {e.task}
                    </TableCell>
                    <TableCell>
                        {e.due_date}
                    </TableCell>
                </TableRow>
                ))
                
                }
            </TableBody>
          </Table>
        </TabPanel>
      </Box>
    <TaskFollowFooter>
        Toplam: {count} | Tümünü Görüntüle
    </TaskFollowFooter>
    </>
  )
}

export default QuickToDo