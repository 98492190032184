import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGIN_PROCESS_START,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    SIGNUP_SUCCESS,
    LOGOUT,
    LOGIN_PROCESS_STOP,
} from "../Actions/types"

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    signUpSuccess: null,
    loginFail: null,
    loginProcess: false,
    user: null,

}
// eslint-disable-next-line
export default function(state = initialState, action) {
    const { type, payload } = action

    switch(type) {
        case LOGIN_PROCESS_START:
            return{
                ...state,
                loginProcess:true,
                loginFail: null,
            }
        case LOGIN_PROCESS_STOP:
            return{
                ...state,
                loginProcess:false
            }
        case AUTHENTICATED_SUCCESS:
            return{
                ...state,
                isAuthenticated: true,
                loginFail: null,
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('access', payload.access)
            localStorage.setItem('refresh', payload.refresh)
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh,
                loginFail: null,

            }
        case SIGNUP_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                signUpSuccess: true,
            }
        case USER_LOADED_SUCCESS:
            localStorage.setItem('company', payload.company_name)
            localStorage.setItem('company_id', payload.company_id)
            localStorage.setItem('name', payload.first_name)
            localStorage.setItem('surname', payload.last_name)
            return {
                ...state,
                user: payload,
            }
        
        case AUTHENTICATED_FAIL:
            return{
                ...state,
                isAuthenticated: false,
                loginFail: null,
            }
        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null,
                loginFail: null,

            }
        case LOGIN_FAIL:
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')
            localStorage.removeItem('company')
            localStorage.removeItem('name')
            localStorage.removeItem('surname')
            return {
                ...state,
                loginProcess:false,
                isAuthenticated: false,
                loginFail: true,
                access: null,
                refresh: null,
                user: null
            }
        
        case LOGOUT:
            localStorage.removeItem('access')
            localStorage.removeItem('refresh')
            localStorage.removeItem('company')
            localStorage.removeItem('name')
            localStorage.removeItem('surname')
            return {
                ...state,
                isAuthenticated: false,
                loginFail: null,
                access: null,
                refresh: null,
                user: null
            }
       
        default:
            return state

    }
}