import React, { useState } from 'react'
import { DataGrid, trTR } from '@mui/x-data-grid'
import moment from 'moment'
import { Chip } from '@mui/material'

const PAVQuickEdit = ({data,setSelectedTestID,setOpenEdit,setOpenDelete}) => {
    async function hadleEdit  (e) {
        await setSelectedTestID(e.id)
        setOpenEdit(true)
    }
    async function hadleDelete  (e) {
        await setSelectedTestID(e.id)
        setOpenDelete(true)
    }
    const columns = [
            {
                field: "id",
                headerName: "No",
                width: 70,
            }, 
            {
                field: "Department",
                headerName: "Birim",
                renderCell: (params) => {
                    return (
                        params?.row?.region.name
                      )    
                },
            },
            {
                field: "SubDepartment",
                headerName: "Alt Birim",
                renderCell: (params) => {
                    return (
                        params?.row?.department?.department_hierarchy
                      )    
                },
            },
            {
                field: "Responsible",
                headerName: "Sorumlusu",
                renderCell: (params) => {
                    return (
                       <p>{params?.row?.auditer?.name} {params?.row?.auditer?.surname} </p> 
                      )    
                },
            },
            {
                field: "Date",
                headerName: "Tarihi",
                renderCell: (params) => {
                    return (
                       <p>{moment(params?.row?.date).format("DD-MM-YYYY")}</p> 
                      )    
                 },
            },
            {
                field: "Status",
                headerName: "Durumu",
                width: 270,

                renderCell: (params) => {
                    return (
                        params?.row?.is_done 
                        ? 
                        parseFloat(params?.row?.plan * 100).toFixed(2) < 99
                        ?
                        <Chip style={{background:"#a7a7a8", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} } label="Açık Görevler var" />
                        :
                        <Chip style={{background:"#aecf55", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"}  } label="Tamamlanan denetim" />
                        :
                        moment(params?.row?.date).isBefore(moment().format("YYYY-MM-DD")) 
                        ?
                        <Chip style={{background:"#f06a6a", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"}  } label="Vakti geçmiş denetim" />
                        :
                        moment(params?.row?.date).isSame(moment().format("YYYY-MM-DD"))
                        ? 
                        <Chip style={ {background:"#f1bd6c", color:"#000",boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"}  } label="Bugün olan denetim"  />
                        :
                        <Chip style={{background:"white", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} } label="Gelecek denetim" />

                      )    
                 },
            },
            {
                field: "edit",
                headerName: "Düzenle",
                renderCell: (params) => {
                    return (
                        <Chip style={{background:"#aecf55", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px", cursor:"pointer"}  } label="Düzenle"
                        onClick={() => hadleEdit(params.row)}
                        />
                      )    
                 },
            },
            {
                field: "delete",
                headerName: "Sil",
                renderCell: (params) => {
                    return (
                        <Chip style={{background:"#f06a6a", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px", cursor:"pointer"}  } label="Sil" 
                        onClick={() => hadleDelete(params.row)}
                        />
                      )    
                 },
            },
    ]
  return (
           <DataGrid
                rows={data ? data : []}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true }}
                pagination
                pageSize={15}
                style={{color:"white"}}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
            />
  )
}

export default PAVQuickEdit