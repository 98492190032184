import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
export const TMNav = [
    {
        id:1,
        name:"Anasayfa",
        icon:(<HomeIcon fontSize="small"/>),
        urlPath: "/app/task-management-app",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:2,
        name:"Görevlerim",
        icon:(<AssignmentIcon fontSize="small"/>),
        urlPath: "/app/task-management-app/my-tasks",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:3,
        name:"Raporlar",
        icon:(<AssessmentIcon fontSize="small"/>),
        urlPath: "/task-management-app/reporting",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:6,
        name:"Ayarlar",
        icon:(null),
        urlPath: "/app/external-customer-complaints-app/settings",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
    {
        id:7,
        name:"Yeni Şikayet Oluştur",
        icon:(null),
        urlPath: "/app/external-customer-complaints-app/activities/create",
        childeren_urlPath : null,
        show_on_side_bar: false,
    },
   
   
    
]