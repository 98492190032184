import React, { useEffect, useState } from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../ActivitiesStyle'
import TaskManagementMainBoard from "../../../../Subcomponents/TaskManagementViews/ListView"
import axios from 'axios'
import { useParams } from 'react-router-dom'
const AuditFollowUp = () => {
    const [data, setData] = useState(null)
    const [isFetching, setIsFetching] = useState(false)
    const [rowCount, setRowCount] = useState(null)
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    })
    let params = useParams()
    const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
  };
  async function getInfo (){
    setIsFetching(true)
      try{
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagment/get-audit-tasks/?page=${pagination?.pageIndex+1}&id=${params?.id}`,  axiosConfig);
          setIsFetching(false)
          setData(res.data.results)
          setRowCount(res.data.count)
      }
      catch{
        setIsFetching(false)

      }
  }
  useEffect(() => {getInfo()},[params?.id])
  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
            <TaskManagementMainBoard 
            rowCount={rowCount} 
            pagination={pagination}
            setPagination={setPagination}
            fetchBack={getInfo}
            data={data} isFetching={isFetching} />
        </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default AuditFollowUp