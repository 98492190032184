import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../Assets/DialogTitle'
import { EditButton } from '../../../../Pages/CompanySettings/MainSettings'
import SuccessSnackBar from '../../../SuccessSnackBar'

const DeleteTaskDialog = ({open, setOpen, id,handleCloseDialog2}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [data, setData] = useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function handleDelete (){
      setButtonStart(true)
      try{
            const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/taskmanagment/single-task/${id}/`,  axiosConfig);
            setData(res.data)
            setSuccessMsg(true)
            setOpen(false)
            handleCloseDialog2()
      setButtonStart(false)
    }
        catch{
          setButtonStart(false)

        }
    }
  return (
    <>
    <SuccessSnackBar open={successMsg} setOpen={setSuccessMsg} data={"Görev Silindi"}/>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Görevi Sil"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            Görevi silmek istiyorsaniz sil butonuna basınız!
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleDelete} active>Sil</EditButton> : <EditButton>Siliniyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default DeleteTaskDialog