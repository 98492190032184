import React, {  useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import { EditButton } from '../../../CompanySettings/MainSettings';
import { connect } from 'react-redux';
import EditAuditQuestions from '../Dialog/EditAuditQuestions';
import SuccessSnackBar from '../../../../Subcomponents/SuccessSnackBar';
import ErrorSnackBar from '../../../../Subcomponents/ErrorSnackBar';
const AuditQuestionTable = ({data, getInfoBack, user,mainInfo}) => {
    const [editDialogOpen,setEditDialogOpen] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [question_text, setQuestionText] = useState(null)
    const [question_type, setQuestionType] = useState("max5")
    const [max_rating, setMaxRating] = useState(5)
    const [min_rating, setMinRating] = useState(3)
    const [successOpen, setSuccessOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const handleOpenEditDialog = (e) => {
        setQuestionText(e.question_text)
        setId(e.id)
        setEditDialogOpen(true)
        setAnchorEl(null)
        setSelectedCategory(e.category?.id)
    }


    const [deleteDialog, setDeleteDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };
    const [id, setId] = useState(null)
    const handleDeleteDialog = (e) => {
        setDeleteDialog(true)
        setId(e.id)
    }
    const handleCloseDeleteDialog = () => {
        setDeleteDialog(false)
    }

    async function handleDelete() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/internalaudit/questionpool/update/${id}/`,  config);
            getInfoBack()
            handleCloseDeleteDialog()
            setSuccessOpen(true)
        } catch (err) {
          window.scrollTo( 0, 0)
          setErrorOpen(true)
        }
    }
        let navigate = useNavigate()
      const columns = [

        {
        field: "id",
        headerName: "No",
            renderCell: (params) => {
                return (
                    <p>{params.row.id}</p> 
                )    
            },
        }, 
        {
        field: "question_text",
        headerName: "Soru",
        width: 350,
            renderCell: (params) => {
                return (
                    <p>{params.row?.question_text}</p> 
                )    
            },
        }, 
        {
        field: "Geçme Kriteri",
        headerName: "Soru Cevap Türü",
        width: 300,
        renderCell: (data) => (
                data.row.question_type === "NUM" ? "Sayısa Değerlendirme " + data?.row?.passing_criteria?.numeric_passing_criteria?.max_value + "/"+ data?.row?.passing_criteria?.numeric_passing_criteria?.fail_value : "İki Seçenekli Değerlendirme"
        ),
        },
        {
        field: "Kategori",
        headerName: "Soru Kategorisi",
        width: 300,
        renderCell: (data) => (
                data.row.category?.name
        ),
        },
        {
        field: "delete",
        headerName: "Aksiyonlar",
        align:"left",
        width: 200,
        renderCell: (data) => (
            <div style={{display:"flex", gap:"15px"}}>
               <EditButton onClick={() => handleOpenEditDialog(data.row)} >
                Düzenle
            </EditButton>
            {user?.type == "holding_manager"  || user?.type == "region_manager" ? 
            <EditButton style={{background:"#dc1c1c"}} onClick={() => handleDeleteDialog(data.row)} >
                Sil
            </EditButton>
            :
            ""
            }
            </div>
          
          ),

        },
      
    ]
    const [validation, setValidation] = useState(null)
    return(
        <React.Fragment>
            <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
            <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
            <div style={{ height: "75vh", width: '100%', marginBottom:"45px" }}>
                <DataGrid
                sx={{color:"white"}}
                rows={data ? data : [] }
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15]}
                />
            </div>
            <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here,
                    background:"#211c25",
                    color:"#fff",
                    marginBottom:"50px",
        
                  },
                },
              }}
            >
                <DialogTitle>Denetim Sorusunu Sil</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{color:"white"}}>
                        Eğer denetim sorusunu silerseniz bu soruya bağlı olan uygunsuzluklar ve diğer bulgular da silinecektir. Lütfen eğer soru değişikliği yapamak istiyorsanız,
                        denetim soru bilgilerini düzenleyiniz. Eğer silmek istediğinizden eminseniz lütfen aşağıdaki kutuya "Silmek İstiyorum." yazınız ve ardından silme butonu
                        aktif olacaktır. Doğrulama kutusu büyük küçük harf duyarlıdır.
                    </DialogContentText>
                    <TextField value={validation} onChange={(e) => setValidation(e.target.value)} fullWidth
                     InputLabelProps={{
                        style:{
                            color:"#A3A2A1"
                    }
                    }}
                    sx={{
                        marginBottom:"15px",
                        input: {
                            color: "white",
                        },
                        width:"100%",
                        '& label.Mui-focused': { color: 'white',},
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleCloseDeleteDialog}> İptal</Button>
                    <Button variant="contained" disabled={validation?.includes("Silmek İstiyorum") ? false : true} color="warning" onClick={handleDelete}> Sil</Button>
                </DialogActions>
            </Dialog>
            <EditAuditQuestions open={editDialogOpen} setOpen={setEditDialogOpen} getInfoBack={getInfoBack} 
            editDialogOpen={editDialogOpen}
            selectedCategory={selectedCategory}
            question_text={question_text}
            question_type2={question_type}
            max_rating={max_rating}
            min_rating={min_rating}
            setEditDialogOpen = {setEditDialogOpen}
            setSelectedCategory = {setSelectedCategory}
            setQuestionText = {setQuestionText}
            setQuestionType = {setQuestionType}
            setMaxRating = {setMaxRating}
            setMinRating = {setMinRating}
            category={mainInfo?.audit_category}
            setSuccessOpen={setSuccessOpen}
            id={id}/>
        </React.Fragment>

    )
}
const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps)  (AuditQuestionTable)