import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../Assets/DialogTitle'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import { EditButton } from '../../../../Pages/CompanySettings/MainSettings'
import * as markerjs2 from 'markerjs2';
import { styled as styledmui} from '@mui/material/styles'
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SuccessSnackBar from '../../../SuccessSnackBar'

const AddActionDialog = ({open, setOpen, getInfoBack,id,getSingleTaskInfoWithId}) => {
    const [files, setFiles] = useState([]);
    const [action_text, setActionText] = useState(null)
    const [successOpen, setSuccessOpen] = useState(false)
    const handleFileChange = (event) => {
        const newFiles = [];
        for (let i = 0; i < event.target.files.length; i++) {
          newFiles.push(event.target.files[i]);
        }
        setFiles((prevFiles) => [...newFiles, ...prevFiles]);
      };
    

    const handleRemoveFile = (indexToRemove) => {
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles.splice(indexToRemove, 1);
          return newFiles;
        });
      };

      const [product_image_path, setProductImagePath] = useState(null)
    const [imgData, setImgData] = useState(null);
    const onChangePicture = e => {
      if (e.target.files[0]) {
        setProductImagePath(e.target.files[0])
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    };
  let imgRef = React.createRef()
  function showMarkerArea() {
      if (imgRef.current !== null) {
        // create a marker.js MarkerArea
        const markerArea = new markerjs2.MarkerArea(imgRef.current);
        // attach an event handler to assign annotated image back to our image element
        markerArea.addEventListener("render", (event) => {
          if (imgRef.current) {
            imgRef.current.src = event.dataUrl;
            fetch(event.dataUrl)
            .then(res => res.blob())
            .then(blob2 => setProductImagePath(blob2))
          }
        });
        // launch marker.js
        markerArea.settings.defaultColor = 'green'  ;
        markerArea.show();
      }
    }
    const Input = styledmui('input')({
        display: 'none',
    });
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    async function handleDelete() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/internalaudit/update-delete/`, config);
            setOpen(false)

            setButtonStart(false)
            getInfoBack()

        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }

      async function handleSubmit () {
        setButtonStart(true)
        //submit the finding
        let form_data = new FormData();
        for (let i = 0; i < files.length; i++) {
          form_data.append(`file${i + 1}`, files[i]);
        }
        product_image_path ? form_data.append('before_image_path', product_image_path, "sonrasi.jpg")  :form_data.append('test', "null") 
        form_data.append('action_text', action_text)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };
        const body = form_data
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/taskmanagment/add-action/${id}/`, body, config);
            setOpen(false)
            setSuccessOpen(true)
            getSingleTaskInfoWithId()
            setActionText("")
            setProductImagePath(null)
            setFiles([])
            setButtonStart(false)
            setImgData(null)
      }
       catch (err) {
        setOpen(false)
        setButtonStart(false)
      }
      }
  return (
    <>
    <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data="İşlem başarıyla tamamlandı."/>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Aksiyon Ekle"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            <Grid container>
                <Grid item xs={12} mt={1}>
                    <TextFieldV2 value={action_text} setValue={setActionText} label="Alınan Aksiyon"/>
                </Grid>
                <Grid item xs={12} mt={1}>
                <React.Fragment>
                <Grid item xs={12}  mb={2} align="right">     
                    <label htmlFor="contained-button-file">
                    <Input accept="image/*" id="contained-button-file"  type="file" onChange={onChangePicture} />
                    <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={product_image_path === null ? "info" : "warning" }>
                        {product_image_path === null ? <> Fotoğraf Çek veya Ekle &nbsp; <CameraEnhanceIcon/> </> : <> Düzenlemek İçin Fotoğrafa Tıkla &nbsp; <CameraEnhanceIcon/> </>}
                    </Button>
                    </label>
                </Grid>   
                <Grid item xs={12}>     
                    {imgData ? 
                    <img ref={imgRef} 
                    src={imgData}
                    id="auditimg"
                    alt="sample" 
                    crossOrigin="anonymous"
                    style={{ maxWidth: '100%' }} 
                    onClick={() => showMarkerArea()}
                    /> 
                    : null}
                </Grid>   
                <Divider style={{background:"white", width:"100%"}}/>
            </React.Fragment>
            <React.Fragment>
            <Grid item xs={12} mt={2} mb={2} align="right">     
                <label htmlFor="contained-button">
                <Input accept="*/*" id="contained-button" multiple   type="file" onChange={handleFileChange} />
                <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={product_image_path === null ? "info" : "warning" }>
                     Ek döküman ekle &nbsp; <FileUploadIcon/> 
                </Button>
                </label>
            </Grid>   
            <ul>
            {Array.from(files).map((file, index) => (
             <li key={index}>
             {file.name}{' '}
             <span
               style={{ color: 'red', cursor: 'pointer' }}
               onClick={() => handleRemoveFile(index)}
             >
               x
             </span>
           </li>
            ))}
          </ul>
          </React.Fragment>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleSubmit} active>Kadet</EditButton> : <EditButton>Kadediliyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default AddActionDialog