import HomeIcon from '@mui/icons-material/Home';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RuleIcon from '@mui/icons-material/Rule';
export const AuditSidebarItems = [
    {
        id:1,
        name:"Anasayfa",
        icon:(<HomeIcon fontSize="small"/>),
        urlPath: "/app/audit-app",
        childeren_urlPath : null,
        show_on_side_bar: true,
    },
    {
        id:2,
        name:"Denetim Faaliyetleri",
        icon:(<VerifiedUserIcon fontSize="small"/>),
        urlPath: "/app/audit-app/activities",
        childeren_urlPath : [
            {
                id:5,
                urlPath: "/app/audit-app/activities/gemba",
            },
            {
                id:6,
                urlPath: "/app/audit-app/activities/workshop",
            },
           
        ],
        show_on_side_bar: true
    },
    {
        id:3,
        name:"Talepler",
        icon:(<RuleIcon fontSize="small"/>),
        urlPath: "/app/audit-app/deflects",
        childeren_urlPath : [
            {
                id:1,
                urlPath:"/app/audit-app/deflects/date"
            }
        ],
        show_on_side_bar: true
    },
    {
        id:4,
        name:"Raporlar",
        icon:(<AssessmentIcon fontSize="small"/>),
        urlPath: "/app/audit-app/reports",
        childeren_urlPath : null,
        show_on_side_bar: true
    },
    {
        id:5,
        name:"Saha Turu",
        icon:(null),
        urlPath: "/app/audit-app/activities/gemba",
        childeren_urlPath : null,
        show_on_side_bar: false
    },
    {
        id:6,
        name:"Çalıştay",
        icon:(null),
        urlPath: "/app/audit-app/activities/workshop",
        childeren_urlPath : null,
        show_on_side_bar: false
    },
    {
        id:6,
        name:"Tarih Değişikliği Talepleri",
        icon:(null),
        urlPath: "/app/audit-app/deflects/date",
        childeren_urlPath : null,
        show_on_side_bar: false
    },
]