import { Checkbox, FormControlLabel, FormGroup, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { Await } from 'react-router-dom'
import TextFieldV2 from '../../../Assets/TextFieldV2'
import SuccessSnackBar from '../../../Subcomponents/SuccessSnackBar'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../CompanySettings/MainSettings'

const NewManagementSystemAdd = () => {
    const [openFeedback, setOpenFeedbacks] = useState(false)
    const [feedback, setFeedback] = useState(null)
    const [multipleCustomersAdd, setMulitpleCustomersAdd] = useState(false)
    const [data,setData] = useState(null)



    const handleChange = (e) => {
        setMulitpleCustomersAdd(e.target.checked)
    }

    const [name, setName] = useState(null)

    const ManagementSystemNames =  [
        {
            id:1,
            name:"CCMI"
        },
        {
            id:2,
            name:"ISO 20000"
        },
        {
            id:3,
            name:"TS 18001"
        },
        {
            id:4,
            name:"TS 22000"
        },
        {
            id:5,
            name:"TS EN ISO 14001"
        },
        {
            id:6,
            name:"TS EN ISO 9001:2015"
        },
        {
            id:7,
            name:"TS EN ISO/IEC 17025"
        },
        {
            id:8,
            name:"TS ISO/IEC 17799"
        },
        {
            id:9,
            name:"TS ISO/IEC 27001"
        },
        {
            id:10,
            name:"TS ISO/IEC 16949"
        },
    ]
    const axiosConfigJson = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
      };

    async function createNewCustomer (){
        const body = JSON.stringify({name})
        try{
          axios.post(`${process.env.REACT_APP_API_URL}/api/general-info/management-systems/`, body,axiosConfigJson)
          setFeedback("Yönetim sistemi Başarıyla Eklendi")
          setOpenFeedbacks(true)
          setName("")
        }
        catch{
    
        }
      }
    async function createNewCustomerPreSet (e){
        const body = JSON.stringify({name:e})
        try{
          axios.post(`${process.env.REACT_APP_API_URL}/api/general-info/management-systems/`, body,axiosConfigJson)
          setFeedback("Yönetim sistemi Başarıyla Eklendi")
          setOpenFeedbacks(true)
          setName("")
          getData()
        }
        catch{
    
        }
      }
    async function getData (e){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/management-systems/`, axiosConfigJson)
            setData(res.data)
        }
        catch{
    
        }
      }

      useEffect(() => {getData()},[])
      console.log(data)
      const checkIfExists = (name) => {
        return data?.some((item) => item.name === name);
      };
    

  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
            <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked checked={multipleCustomersAdd} onChange={handleChange} />} label="Özel Yönetim Süreci Ekle" />
            </FormGroup>
            <Grid container>
                <Grid item md={6} xs={12}>
                <div style={{display:multipleCustomersAdd?"flex":"none", flexDirection:"column"}}>
                   <h3>Kendi yönetim sistminizi ekleyin </h3>
                   <TextFieldV2 label={"Yönetim Süreci İsmi"} value={name} setValue={setName} /> 
                   <EditButton active onClick={createNewCustomer}>
                        Kaydet
                    </EditButton>
                </div>
                <div style={{display:multipleCustomersAdd?"none":"flex", flexDirection:"column"}}>
                   <h3>Yönetim Süreci Ekle </h3> 
                   <p>Eğer kendinize özel bir isimle yönetim süreci eklemek isterseniz lütfen <i>"özel yönetim süreci ekle"</i> kutucuğunu işaretleyin  </p> 
                   <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Yönetim Sistemi İsmi</TableCell>
                                <TableCell>Ekle</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ManagementSystemNames?.map((e) => (
                            <TableRow key={e?.id}>
                                <TableCell>{e?.name}</TableCell>
                                <TableCell>
                                    {checkIfExists(e?.name) 
                                    ?
                                    <EditButton>Yönetim sistemi eklendi</EditButton>
                                        :
                                    <EditButton active onClick={() => createNewCustomerPreSet(e.name)}>Ekle</EditButton>
                                    }
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                   </Table>
                </div>
                </Grid>
            </Grid>
        </ActivitiesMainWrapper>
        <SuccessSnackBar open={openFeedback} setOpen={setOpenFeedbacks} data={feedback}/>
    </ActivitiesMainContainer>
    
  )
}

export default NewManagementSystemAdd