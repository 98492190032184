import {
    GET_ERRORS,
    GET_ERRORS_RESET
} from "../Actions/types"

const initialState = {
    msg:{},
    status: null
}
// eslint-disable-next-line
export default function(state = initialState, action){
    switch(action.type){
        case GET_ERRORS_RESET:
            return{
                msg:{},
                status: null
            }
        case GET_ERRORS:
            return{
                msg: action.payload.msg,
                status: action.payload.status
            }
        default:
            return state;

    }
}