import React from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../../AuditApp/Activities/ActivitiesStyle'
import IccComplaintsManagementProcessTable from './IccComplaintsManagementProcessTable'

const IccComplaintDevelopmentReport = () => {
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <IccComplaintsManagementProcessTable/>
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default IccComplaintDevelopmentReport