import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import DialogTitleV2 from '../../../../Assets/DialogTitle'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import { EditButton } from '../../../../Pages/CompanySettings/MainSettings'
import SuccessSnackBar from '../../../SuccessSnackBar'

const ActionControlDialog = ({open, setOpen, getSingleTaskInfoWithId,id}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [revision, setRevision] =useState(false)
    const [buttonStart, setButtonStart] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [revision_text, setRevisionText] = useState(null)
    const cancelRevision = () => {
        setRevisionText(null)
        setRevision(false)
    }
    const [data, setData] = useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function submitRevision (){
      setButtonStart(true)
      const body = JSON.stringify({revision, revision_text})
        try{
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/taskmanagment/add-action/${id}/`, body, axiosConfig);
            setSuccessMsg(true)
            setOpen(false)
            getSingleTaskInfoWithId()
            setRevisionText("")
      setButtonStart(false)
      setRevision(false)
        }
        catch{
            setOpen(false)
            setButtonStart(false)

        }
    }
    async function submitAccept (){
        const body = JSON.stringify({accept:true, revision_text})
        try{
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/taskmanagment/add-action/${id}/`, body, axiosConfig);
            setSuccessMsg(true)
            setOpen(false)
            getSingleTaskInfoWithId()
            setRevisionText("")
        }
        catch{
            setOpen(false)

        }
    }
  return (
    <>
    <SuccessSnackBar open={successMsg} setOpen={setSuccessMsg} data={"İşlem başarıyla tamamlandı"}/>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Aksiyon Kontrolü"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            {revision?
            <Grid container>
                <Grid item xs={12} mt={1}>
                    <TextFieldV2 label="Revizyon Açıklaması" value={revision_text} setValue={setRevisionText}/>
                </Grid>
                <Grid item display={"flex"} gap={"5px"} justifyContent={"end"} xs={12}>
                    <EditButton onClick={cancelRevision} active style={{width:"80px"}}>İptal</EditButton>
                    {!buttonStart ? <EditButton onClick={submitRevision} active style={{width:"150px",background:"red"}}>Revizyonu Gönder</EditButton> : <EditButton style={{width:"150px"}}>Gönderiliyor...</EditButton>}
                </Grid>
            </Grid>
            :
            <Grid container>
                <Grid item xs={12}>
                {!buttonStart ? <EditButton onClick={submitAccept} active>Kabul Et</EditButton> : <EditButton>Kabul Ediliyor...</EditButton>}
                </Grid>
                <Grid item xs={12} mt={1}>
                <EditButton style={{background:"red"}} onClick={() => setRevision(true)}>Revizyon İste</EditButton>
                </Grid>
            </Grid>
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default ActionControlDialog