import {  Dialog, DialogActions, DialogContent,} from '@mui/material'
import React from 'react'
import DialogTitleV2 from '../../../Assets/DialogTitle'
import { EditButton } from '../../../Pages/CompanySettings/MainSettings'


const KaizenInfo = ({open, setOpen}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
  
 
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"İç Müşteri Şikayetleri"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
        Kaizen Otomasyonu, işletmelerin sürekli gelişim süreçlerini daha hızlı ve etkili bir şekilde yönetmelerine olanak tanıyan bir uygulamadır. Bu uygulama, Kaizen süreçlerini otomatikleştirerek, işletmelerin daha verimli çalışmasını sağlar ve insan hatası riskini azaltır.

Kaizen Otomasyonu, veri toplama ve analiz süreçlerini yapay zeka ve otomasyon teknolojileriyle birleştirir. Bu sayede, işletmelerin sürekli gelişim için doğru kararlar alması daha kolay hale gelir. Ayrıca, uygulama sayesinde işletmeler, operasyonel mükemmeliyetlerini artırabilir ve müşteri memnuniyetini yükseltebilirler.

Kaizen Otomasyonu, işletmelerin sürekli gelişim hedeflerine ulaşmalarına yardımcı olurken aynı zamanda zaman ve maliyet tasarrufu sağlar. Bu sayede, işletmeler rekabet avantajı elde ederler ve daha başarılı bir gelecek için önemli bir adım atmış olurlar.        </DialogContent>
        <DialogActions>
            <EditButton active onClick={handleCloseDialog}>Tamam</EditButton>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default KaizenInfo