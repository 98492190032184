import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl,  InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import React, {  useState } from 'react'
const AddPeriodicAudit = ({open, setOpen}) => {
    const [showButton, setStartButton] = useState(false)
    async function handleStartWorkShop() {
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({})
        setStartButton(true)
        if(frequency === "Monthly"){
          try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/control/test/main/monthly/create/`, body, config);
             setStartButton(false)
             window.location.reload()
            }
              catch (err) {
              setStartButton(false)
            }
        }
        else if(frequency === "Yearly"){
          try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/control/test/main/yearly/create/`, body, config);
             setStartButton(false)
             window.location.reload()
            }
              catch (err) {
              setStartButton(false)
            }
        }
       
      }
    
    
    
    const handleCloseAutomation = () => {
        setOpen(false)
    }


    const [frequency, setFrequency] = useState("Monthly")

    const handleChangeFrequency = (e) => {
        setFrequency(e.target.value)
    }


  return (
    <Dialog open={open} onClose={handleCloseAutomation}sx={{
        "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#1e1f21",
            color:"#fff",
            marginBottom:"50px"
        },
        },
    }}
    >
        <DialogTitle>Oluşturmak İstediğiniz Denetimin Frekansını Seçiniz</DialogTitle>
        <Divider style={{background:"#A3A2A1"}}/>
        <DialogContent style={{minWidth:350}}>
            <FormControl fullWidth  sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    >
            <InputLabel id="demo-simple-select-label"  sx={{color:"#fff"}}>Denetim Frekansını Seç</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={frequency}
                label="Çalıştay Bölümü Seç"
                onChange={handleChangeFrequency}
                style={{color:"#fff"}}
            >
                    <MenuItem value={"Yearly"}>Yıllık</MenuItem>
                    <MenuItem value={"Monthly"}>Aylık</MenuItem>
            </Select>
            </FormControl>
            
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseAutomation}>Kapat</Button>
        {!showButton ? <Button onClick={handleStartWorkShop} variant="contained">Denetimi Oluştur</Button> : <Button variant="outlined">Denetim Oluşturuluyor...</Button>}
        </DialogActions>
    </Dialog>
  )
}

export default AddPeriodicAudit