import React from 'react'
import { ApplicationContainer } from '../HomePage'
import styled from 'styled-components'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
export const InnerNavigation = styled.nav`
  width: 100%;
  display:flex;
  gap:15px;
  justify-content:start;
  align-items:start;
  @media screen and (max-width:900px){
    justify-content:${({center}) => center ? "center" : "start"};

  }
`
export const InnerNavigationItems = styled.a`
  text-decoration:none;
  cursor:pointer;
  color: ${({active}) => active ? "white" : "grey"} ;

`
const Activities = () => {
  let navigate = useNavigate()
  const {pathname} = useLocation()
  return (
  <ApplicationContainer style={{width:"100%"}}>
      <InnerNavigation style={{borderBottom:"1px solid grey", paddingBottom:"10px", marginBottom:"10px"}}>
        <InnerNavigationItems active={pathname === "/app/audit-app/activities"} onClick={() => navigate("/app/audit-app/activities")}>Denetim</InnerNavigationItems>
        <InnerNavigationItems active={pathname.includes("gemba")} onClick={() => navigate("/app/audit-app/activities/gemba")}>Saha Turu</InnerNavigationItems>
        <InnerNavigationItems active={pathname.includes("workshop")}  onClick={() => navigate("/app/audit-app/activities/workshop")}>Çalıştay</InnerNavigationItems>
      </InnerNavigation>
      <Outlet/>
  </ApplicationContainer>
  )
}

export default Activities