import React from 'react'
import { ActivitiesMainContainer } from '../../AuditApp/Activities/ActivitiesStyle'
import { ApplicationContainer } from '../../AuditApp/HomePage'
import IccSolutionTable from './ComplaintSolutionTable'

const IccComplaintSolution = () => {
  
  return (
    <ApplicationContainer style={{width:"100%"}}>
      <ActivitiesMainContainer>
        <IccSolutionTable/>
      </ActivitiesMainContainer>
    </ApplicationContainer>
  )
}

export default IccComplaintSolution