import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import { EditButton } from '../../../../CompanySettings/MainSettings'

const GembaWalkStartDialog = ({open, setOpen, getInfoBack,gemba_id}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    async function handleDelete() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({start_action: true})
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/workshop/${gemba_id}/`,body, config);
            setOpen(false)
            setButtonStart(false)
            getInfoBack()

        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
         sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here,
                background:"#211c25",
                color:"#fff",
                marginBottom:"50px",
    
              },
            },
          }}
    >
        <DialogTitleV2 title={"Çalıştayı Başlat"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>Çalıştayı başlattıktan sonra ekip olarak uygunsuzlukları ekleyebilirsiniz.</DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleDelete} active>Başlat</EditButton> : <EditButton>Başlatılıyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default GembaWalkStartDialog