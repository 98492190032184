import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { ActivitiesMainContainer, ActivitiesMainWrapper,  InfoBar, MidBar, UsersBar } from '../ActivitiesStyle'
import {  Avatar,  Tooltip } from '@mui/material'
import SuccessSnackBar from '../../../../Subcomponents/SuccessSnackBar';
import ErrorSnackBar from '../../../../Subcomponents/ErrorSnackBar';
import { EditButton } from '../../../CompanySettings/MainSettings';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import {  green,blue, grey } from '@mui/material/colors';
import AttendanceDialog from './Dialogs/AttendanceDialog';
import GembaWalkStartDialog from './Dialogs/GembaWalkStartDialog';
import PDCA from '../../../../Subcomponents/PDCA';
import GembaWalkFinishDialog from './Dialogs/GembaWalkFinishDialog';
import NewGembaFindingDialog from './Dialogs/NewGembaFindingDialog';


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const GembaWalkDetails = ({user}) => {
  const [openAttendanceDialog, setOpenAttandanceDialog] = useState(false)
  const [openStartGembaWalkDialog, setOpenGembaWalkDialog] = useState(false)
  const [openFinishGembaWalkDialog, setOpenGembaWalkFinishDialog] = useState(false)
  const [reOpen, setReOpen] = useState(false)
  const handleOpenFinishDialog = (e) => {
    setReOpen(e)
    setOpenGembaWalkFinishDialog(true)
  }
  const [openNewFindingAdd, setOpenNewFindingAdd] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
      const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    let params = useParams() 
    const [openInfo, setOpenInfo] = useState(false)
    const [openSucces, setOpenSuccess] = useState(false)
    const [messages, setMessages] = useState([]);
    const [attendees, setAttendees] = useState([]);
    const [generalInfo, setGeneralInfo] = useState(null)
    const [ws, setWs] = useState(null);
    useEffect(() => {
      getMainInfo()
      setOnlineUsers(user?.id)
      getOnlineUsers()
      const newWs =  new WebSocket(`wss://${process.env.REACT_APP_API_URL_WS}/ws/gemba/${params?.id}/?${"uid="+user?.id}`);
      newWs.onopen = () => {
      };
      newWs.onmessage = (event) => {
        const message = JSON.parse(event.data);
        setMessages(message);
        getOnlineUsers()
        getMainInfo()
        setOpenInfo(true)

      
      };
      newWs.onclose = () => {
      };
      setWs(newWs);
        return () => {
          setOnlineUsersOffline(user?.id)
          newWs?.close()
        }
      }, [params?.id]);


    const sendNewFindingAdded = () => {
      const messageObject = {
        type: 'chat_message',
        message: "new_finding_added",
        uid: user?.id,
      };
      ws.send(JSON.stringify(messageObject));
    };


    async function getMainInfo (e){
      setDataLoading(true)
      try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/gembawalk/${params?.id}/`,  axiosConfig);
          setGeneralInfo(res.data)
      setDataLoading(false)

      } catch (err) {
      setDataLoading(false)

      }
    }
    async function getOnlineUsers (e){
      try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/gembawalk/attendees/?gemba_id=${params?.id}`,  axiosConfig);
          setAttendees(res.data)
      } catch (err) {
      }
    }
    async function setOnlineUsers (e){
      const body = JSON.stringify({
        is_here: true, user_id : e
      })
      try {
          await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/update/gembawalk/attendees/${params?.id}/`, body, axiosConfig);
          getOnlineUsers()
      } catch (err) {
      }
    }
    async function setOnlineUsersOffline (e){
      const body = JSON.stringify({
        is_leaving: true, user_id : e
      })
      try {
          await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/update/gembawalk/attendees/${params?.id}/`, body, axiosConfig);
      } catch (err) {
      }
    }
    function stringAvatar(name) {
      return {
       
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
      {messages?.message === "user_coming_in"
      ?
      <SuccessSnackBar open={openInfo} setOpen={setOpenInfo} data={`${messages?.user} Saha Turuna Katıldı`} />
      :
      messages?.message === "outgoing_user"
      ?
      <ErrorSnackBar open={openInfo} setOpen={setOpenInfo} data={`${messages?.user} Saha Turundan Ayrıldı`} />
      :
      messages?.message === "new_finding_added"
      ?
      <SuccessSnackBar open={openInfo} setOpen={setOpenInfo} data={`${messages?.user} Yeni Bulgu Ekledi`} />
      :
      ""
      }
      <SuccessSnackBar setOpen={setOpenSuccess} open={openSucces} data="İşlem Başarıyla Geçekleşti"/>
      <InfoBar>
          <h1 style={{fontSize:"20px"}}>{generalInfo?.region?.name} Saha Turu</h1>
          <MidBar>
          <UsersBar>
            {attendees?.map((e) => (
              e.is_present
                ?
              <Tooltip title={e.attendee?.name + " " + e.attendee?.surname} arrow>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
              <Avatar key={e?.id}
              {...stringAvatar(e.attendee?.name + " " + e.attendee?.surname)}
              sx={{ bgcolor: green[400] }}
              >
              </Avatar>
              </StyledBadge>
              </Tooltip>
              :
              <Tooltip title={e.attendee?.name + " " + e.attendee?.surname} arrow>
              
              <Avatar key={e?.id}
              sx={{ bgcolor: e.did_attended ? blue[500] : grey[600] }}
              {...stringAvatar(e.attendee?.name + " " + e.attendee?.surname)}

              >
              </Avatar>
              </Tooltip>
            ))}
          </UsersBar>
          {user?.type?.includes("manager")
          ?
          <EditButton active onClick={() => setOpenAttandanceDialog(true)}>
              Yoklama Al
          </EditButton>
          : 
          ""
          }
          
          </MidBar>
         {
        generalInfo?.is_started === false ?
         user?.type.includes("manager") ?
          <EditButton active onClick={() => setOpenGembaWalkDialog(true)}>
              Saha Turunu Başlat
          </EditButton>
          :
          ""
          :
          generalInfo?.is_done === true ?
          <div style={{display:"flex", gap:"15px"}}>
            <EditButton active>
                Raporu Görüntüle
            </EditButton>
            {user?.type.includes("manager") ?
            <EditButton style={{background:"red"}} onClick={() => handleOpenFinishDialog(true)}>
                Saha Turunu Geri Başlat
            </EditButton>
            :
            ""
            }
          </div>    
          :
          <div style={{display:"flex", gap:"15px"}}>
            <EditButton active onClick={() => setOpenNewFindingAdd(true)}>
                Yeni Bulgu Ekle
            </EditButton>
            {user?.type.includes("manager") ?
            <EditButton style={{background:"red"}} onClick={() => handleOpenFinishDialog(false)}>
                Saha Turunu Tamamla
            </EditButton>
            :
            ""
            }
          </div>
         }
      </InfoBar>
      {
      generalInfo?.is_started === false ?
      <b style={{textAlign:"justify"}}>Saha turu henüz başlamadı, lütfen yöneticinin saha turunu başlatmasını bekleyiniz. </b>
      :
      <PDCA data={generalInfo?.findings}
      newActions={true}
      dataLoading={dataLoading}
      />
      }
      </ActivitiesMainWrapper>
      <AttendanceDialog setOpenSuccess={setOpenSuccess} open={openAttendanceDialog} setOpen={setOpenAttandanceDialog} attandees={attendees} getInfoBack={getOnlineUsers} gemba_id={params?.id}/>
      <GembaWalkStartDialog open={openStartGembaWalkDialog} setOpen={setOpenGembaWalkDialog}  getInfoBack={getMainInfo} gemba_id={params?.id}/>
      <GembaWalkFinishDialog setOpenSuccess={setOpenSuccess} reOpen={reOpen} open={openFinishGembaWalkDialog} setOpen={setOpenGembaWalkFinishDialog}  getInfoBack={getMainInfo} gemba_id={params?.id}/>
      <NewGembaFindingDialog hardCoreGetBack={getMainInfo} open={openNewFindingAdd} setOpen={setOpenNewFindingAdd} gemba_id={params?.id} selectedRegion={generalInfo?.region} callBackBroadCast={sendNewFindingAdded}/>
    </ActivitiesMainContainer>
  )
}

const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps) (GembaWalkDetails)