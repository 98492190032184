import React from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../AuditApp/Activities/ActivitiesStyle'
import ComplaintsManagmentProcessTable from "./ComplaintManagementProcessTable"
const EccProcessManagement = () => {
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <ComplaintsManagmentProcessTable/>
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default EccProcessManagement