import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DialogTitleV2 from '../../../../Assets/DialogTitle'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import { EditButton } from '../../../CompanySettings/MainSettings'

const EditAuditCategory = ({open, setOpen, getInfoBack,setSuccessOpen,setErrorOpen}) => {
    const [name, setName] = useState(null)
    const [editable, setEditable] = useState(false)
    const [id, setId] = useState(null)
    const [editName, setEditName] = useState(null)

    const handleChangeEditable = (e) => {
        setEditable(true)
        setId(e.id)
        setEditName(e.name)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }
    let params = useParams()
    const handleCloseSuccessMsg = () => {
        setSuccessMsg(false)
    }
    async function getData(){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
          };
          try {
              const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/category/?id=${params?.id}`, config);
              setData(res?.data)
            }
          catch (err) {
          }
      }
    const [buttonStart, setButtonStart] = useState(false)
    const vertical = "bottom"
    const horizontal = "center"
    const [successMsg, setSuccessMsg] = useState(false)
    async function handleDelete(e) {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/internalaudit/update-delete/category/${e}/`, config);
            setSuccessMsg(true)
            getData()
            setSuccessOpen(true)

        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)
            setErrorOpen(true)

        }
      }
      const [data, setData] = useState(null)
      
      async function handleCreate(){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
          };
          const body = JSON.stringify({name, id:params?.id })
          try {
              const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/category/`, body,config);
              getData()
              setName("")
              setSuccessOpen(true)

            }
          catch (err) {
            setErrorOpen(true)

          }
      }
      async function handleUpdateCategory (e){
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
          };
          const body = JSON.stringify({name: editName })
          try {
              const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/update-delete/category/${e}/`, body,config);
              getData()
              setEditable(false)
              setSuccessOpen(true)
            }
          catch (err) {
            setErrorOpen(true)
          }
      }
      useEffect(() => {
        if(open){
            getData()
        }
        return()=>{
            getInfoBack()
        }
      },[open])
  
      
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Denetim Kategorisi Düzenle"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            <Table style={{width:"100%"}}>
                <TableHead>
                    <TableRow>
                            <TableCell>Kategori İsmi</TableCell>
                            <TableCell>Düzenle</TableCell>
                            <TableCell>Sil</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((e) =>(
                    <TableRow>
                        <TableCell>
                            {editable && id === e.id ?
                            <TextFieldV2 size="small" label={"Kategori İsmi"}
                            value={editName}
                            setValue={setEditName}
                            />
                            :
                            e.name
                            }
                        </TableCell>
                        <TableCell>
                            {editable && id === e.id ?
                            <EditButton active onClick={() => handleUpdateCategory(e?.id)} style={{background:"green"}}>Kaydet</EditButton>
                            :
                            <EditButton active onClick={() => handleChangeEditable(e)}>Düzenle</EditButton>
                            }
                        </TableCell>
                        <TableCell>
                            <EditButton active style={{background:"red"}} onClick={() => handleDelete(e?.id)}>Sil</EditButton>
                        </TableCell>
                    </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={2}>
                            <TextFieldV2 size="small" label={"Kategori İsmi"}
                            value={name}
                            setValue={setName}
                            />
                        </TableCell>
                        <TableCell colSpan={1}>
                            <EditButton active onClick={handleCreate}>Ekle</EditButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </DialogContent>
      
    </Dialog>
    </>
  )
}

export default EditAuditCategory