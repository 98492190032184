import React from 'react'
import { InnerNavigation, InnerNavigationItems } from '../../AuditApp/Activities'
import { ApplicationContainer } from '../../AuditApp/HomePage'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const GeneralInfoProcess = () => {
  let navigate = useNavigate()
  const {pathname} = useLocation()
  return (
    <ApplicationContainer style={{width:"100%"}}>
        <InnerNavigation style={{borderBottom:"1px solid grey", paddingBottom:"10px", marginBottom:"10px"}}>
            <InnerNavigationItems active={pathname === "/app/general-info-app/process"} onClick={() => navigate("/app/general-info-app/process")}>Süreçler</InnerNavigationItems>
            <InnerNavigationItems active={pathname === "/app/general-info-app/process/tree"} onClick={() => navigate("/app/general-info-app/process/tree")}>Süreç Ağacı</InnerNavigationItems>
        </InnerNavigation>
        <Outlet/>
    </ApplicationContainer>
  )
}

export default GeneralInfoProcess