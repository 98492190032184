import { Alert, Avatar, Divider, Grid, InputAdornment, Tooltip } from '@mui/material'
import { green, grey, red, yellow } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { CommentOutline, FileHolder, FileName, FileThumbnail, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer, PostedCommentContainer, PostedCommentInfo, SuggestionInfoBoxContainer, SuggestionInfoBoxSection, SuggestionInfoBoxWrapper } from '../../../../Assets/GeneralDesignElements'
import { StyledBadge } from '../../../../Assets/StyledBadge'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../../CompanySettings/MainSettings'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import moment from 'moment'
import RejectSuggestionDialog from './Dialogs/RejectSuggestionDialog'
import SendToCommittee from './Dialogs/SendToCommitteeDialog'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ExcelIcon from '../../../../Assets/FileIcons/excel.png'
import WordIcon from '../../../../Assets/FileIcons/word.png'
import PdfIcon from '../../../../Assets/FileIcons/pdf.png'
import ImageIcon from '../../../../Assets/FileIcons/Image.png'
import RarIcon from '../../../../Assets/FileIcons/rar.png'
import UnKnown from '../../../../Assets/FileIcons/UnKnown.webp'
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
const ProcessManagmentInspect = () => { 
  function getFileIcon(extension) {
    switch (extension) {
      case 'xls':
      case 'xlsx':
        return <FileThumbnail src={ExcelIcon} alt="xls" />;
      case 'pdf':
        return <FileThumbnail src={PdfIcon} alt="xls" />;
      case 'doc':
      case 'docx':
        return <FileThumbnail src={WordIcon} alt="xls" />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'pjpeg':
        return <FileThumbnail src={ImageIcon} alt="xls" />;
      case 'zip':
      case 'rar':
        return <FileThumbnail src={RarIcon} alt="xls" />;
      default:
        return <FileThumbnail src={UnKnown} alt="xls" />;
    }
  }
  const [data, setData] = useState(null)
  async function getInfo (){
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/create/${params?.id}/`,  axiosConfig)
      setData(res.data)
      setNeededEmployementForce(res?.data?.needed_employee_force)
      setNeededMaterils(res?.data?.needed_materials)
      setNeededEquipments(res?.data?.needed_equipments)
      setNeededCash(res?.data?.needed_cash)
      setOtherNeeds(res?.data?.other_needs)
      setEstimatedCost(res?.data?.estimated_cost)
      setBenefitsToCompany(res?.data?.benefits_to_company)
      setEstimatedFinancialEarnings(res?.data?.estimated_financial_earnings)

    }
    catch{

    }
  }
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [openCommitteeDialog, setOpenCommitteeDialog] = useState(false)
  
  const [needed_employee_force, setNeededEmployementForce] = useState(null)
  const [needed_employee_force_focus, setNeededEmployementForceFocus] = useState(false)
  const [needed_materials, setNeededMaterils] = useState(null)
  const [needed_materials_focus, setNeededMaterilsFcous] = useState(null)
  const [needed_equipments, setNeededEquipments] = useState(null)
  const [needed_equipments_focus, setNeededEquipmentsFocus] = useState(null)
  const [needed_cash, setNeededCash] = useState(null)
  const [needed_cash_focus, setNeededCashFocus] = useState(null)
  const [other_needs, setOtherNeeds] = useState(null)
  const [other_needs_focus, setOtherNeedsFocus] = useState(null)
  const [estimated_cost, setEstimatedCost] = useState(null)
  const [estimated_cost_focus, setEstimatedCostFocus] = useState(null)
  const [benefits_to_company, setBenefitsToCompany] = useState(null)
  const [benefits_to_company_focus, setBenefitsToCompanyFocus] = useState(null)
  const [estimated_financial_earnings, setEstimatedFinancialEarnings] = useState(null)
  const [estimated_financial_earnings_focus, setEstimatedFinancialEarningsFocus] = useState(null)



  const [comment, setComment] = useState(null)
  let params = useParams()
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
  };


  const setFocus = (e) => {
    if(data?.state==="CONTINUE"){
      if (e === "employee"){
        setNeededEmployementForceFocus(true)
      }
      else if (e === "material"){
        setNeededMaterilsFcous(true)
      }
      else if (e === "equipment"){
        setNeededEquipmentsFocus(true)
      }
      else if (e === "cash"){
        setNeededCashFocus(true)
      }
      else if (e === "other"){
        setOtherNeedsFocus(true)
      }
      else if (e === "estimated"){
        setEstimatedCostFocus(true)
      }
      else if (e === "benefits"){
        setBenefitsToCompanyFocus(true)
      }
      else if (e === "fin_benefits"){
        setEstimatedFinancialEarningsFocus(true)
      }
    }
   
  }
  async function handleChangeNeededs(e){
    if(data?.state==="CONTINUE"){
    const body = JSON.stringify(
      e === "employee" ?
      {needed_employee_force}
      :
      e === "material" ?
      {needed_materials}
      :
      e === "equipment" ?
      {needed_equipments}
      :
      e === "cash" ?
      {needed_cash}
      :
      e === "other" ?
      {other_needs}
      :
      e === "estimated" ?
      {estimated_cost}
      :
      e === "benefits" ?
      {benefits_to_company}
      :
      e === "fin_benefits" ?
      {estimated_financial_earnings}
      :
      ""
    )
  
   
    try{
      const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/suggestions/get/process_manager_suggestions/${params?.id}/`,  body,axiosConfig)
      if (e === "employee"){
        setNeededEmployementForceFocus(false)
      }
      else if (e === "material"){
        setNeededMaterilsFcous(false)
      }
      else if (e === "equipment"){
        setNeededEquipmentsFocus(false)
      }
      else if (e === "cash"){
        setNeededCashFocus(false)
      }
      else if (e === "other"){
        setOtherNeedsFocus(false)
      }
      else if (e === "estimated"){
        setEstimatedCostFocus(false)
      }
      else if (e === "benefits"){
        setBenefitsToCompanyFocus(false)
      }
      else if (e === "fin_benefits"){
        setEstimatedFinancialEarningsFocus(false)
      }
      
      getInfo()
    }
    catch{
      setNeededEmployementForceFocus(false)

    }
  }
  }


  console.log(data?.state==="CONTINUE" ? needed_employee_force_focus ? "Kaydediliyor..." : "Kaydedildi": "Konu Kapalı","state")



  const [buttonClick, setButtonClick] = useState(false)
 
  async function postComment (){
    if (comment && comment?.length > 0) {
      setButtonClick(true)
      const body = JSON.stringify({suggestion_id: params?.id, comment})
      try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions/create/comment/`, body, axiosConfig)
        getInfo()
        setButtonClick(false)
        setComment("")
      }
      catch{
  
        setButtonClick(false)
      }
    }
    else{
      
    }
  }
  useEffect(() => {getInfo()},[params?.id])
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <InfoBar around top>
          <PostedCommentContainer>
            <Avatar/>
            <PostedCommentInfo>
            <span> <b style={{fontSize:"13px"}}>{data?.process_manager?.name} {data?.process_manager?.surname}</b></span>
            </PostedCommentInfo>
          </PostedCommentContainer>
          {data?.stage === "MANAGERCONTROL" ?
          <div style={{display:"flex", gap:"15px"}}>
            <EditButton active onClick={() => setOpenRejectDialog(true)}>
              Reddet
            </EditButton>
            <EditButton active style={{width:"125px"}} onClick={() => setOpenCommitteeDialog(true)}>
              Öneriyi Komiteye İlet
            </EditButton>
          </div>
          :""}
          
        </InfoBar>
        <InfoBar around top>
          <SuggestionInfoBoxContainer>
            <SuggestionInfoBoxWrapper>
              <SuggestionInfoBoxSection>
                  <b>Öneren Bilgileri</b>
                  <p>{data?.suggester?.name} {data?.suggester?.surname}</p>
                  <p>{data?.suggester?.email}</p>
              </SuggestionInfoBoxSection>
              <SuggestionInfoBoxSection>
                  <b>Öneri Detayları</b>
                  <p>Öneri No: {data?.uuid}</p>
              </SuggestionInfoBoxSection>
            </SuggestionInfoBoxWrapper>
          </SuggestionInfoBoxContainer>
          <HorizantalTimeLineContainer>
              <HorizantalTimelineCardWrapper>
                <Tooltip title="Öneri Başlangıcı" arrow>
                    <Avatar
                    sx={{ bgcolor: green[600] }}
                    >
                      <DoneOutlineIcon/>
                    </Avatar>
                  </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title={`Hat Yöneticisi (${data?.process_manager?.name + "  " + data?.process_manager?.surname}) Değerlendirmesi`} arrow>
                  <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant={data?.stage ==="MANAGERCONTROL" && data?.state === "CONTINUE" ?"dot":""}>
                  <Avatar
                  sx={{ bgcolor: data?.stage ==="MANAGERCONTROL" ? data?.state === "CONTINUE" || data?.state === "PASS" ? green[400] : red[400] : green[600]}}
                  >
                    {data?.stage ==="MANAGERCONTROL" && data?.state === "CONTINUE" ?
                    "YD"
                    :
                    data?.state === "FAILED" ?
                    <HighlightOffIcon/>
                    :
                    <DoneOutlineIcon/>
                    }
                  </Avatar>
                  </StyledBadge>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Komite Değerlendirmesi" arrow>
                  <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant={data?.stage ==="COMMITEECONTROL" ?"dot":""}>
                  <Avatar
                  sx={{ bgcolor: data?.stage ==="COMMITEECONTROL" ? data?.state === "CONTINUE" || data?.state === "PASS" ? yellow[400] : red[400] : grey[600]}}
                  >
                    KD
                  </Avatar>
                  </StyledBadge>
                </Tooltip>
               
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Tamamlandı" arrow>
                <Avatar>
                  T
                </Avatar>
                </Tooltip>
              </HorizantalTimelineCardWrapper>
            </HorizantalTimeLineContainer>
        </InfoBar>
        <Divider style={{width:"100%", background:"grey"}} />
        <Grid container direction={"row"} justifyContent={"space-between"}>
          <Grid item container md={6} xs={12}>
            <Grid item md={12} xs={12}>
              <h1>{data?.uuid} Nolu Öneri</h1>
              <b>{data?.heading}</b>
              <p>{data?.suggestion}</p>
              <p>Bölge: {data?.region?.name + ' ' + data?.section?.name}</p>
              <p>Süreç Yöneticisi: {data?.process_manager?.name + ' ' + data?.process_manager?.surname}</p>
              {data?.state === "FAILED" ? 
              <Alert color="error" style={{width: "90%"}}>
               {data?.reject_reason}
              </Alert>
              :
              data?.state === "PASS" ?
              <Alert color="error" style={{width: "90%"}}>
                {`Tebrikler öneriniz kabul edilierek uygulamaya koyuldu ve ${data?.earned_point} kazandınız`}
              </Alert>
              :
              ""
              }
              {data?.additional_info ?
              <Alert color="warning" style={{width: "90%"}}>
                {`${data?.additional_info}`}
              </Alert>
                :
                ""
              }
             
            </Grid>
            <Grid item md={12} xs={12} mr={15}>
            {data?.files?.length > 0 ?
              <h1>Eklenen Dökümanlar</h1>
              :""}
              <div style={{display:"flex", gap:"45px"}}>
              {data?.files?.length > 0 ?
                  <>
                  <TaskImageHolder>
                  {data?.files?.map((e,index) => (
                    <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                        <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                      <FileHolder>
                        {getFileIcon(e?.file_type)}
                        <FileName>{e?.file_name}</FileName>
                      </FileHolder>
                      </a>
                    </Tooltip>
                      ))}
                  </TaskImageHolder>
                  </>
                :
              ""}
              
             
              </div>
            </Grid>
          </Grid>
            <Grid item xs={12} md={6}>
              <h1>Maliyet Hesaplaması</h1>
              <TextFieldV2 label={"Gerekli İş Güçü"} onBlur={(e) => handleChangeNeededs(e.target.name)} value={needed_employee_force} setValue={setNeededEmployementForce}
              endAdornmentText={data?.state==="CONTINUE" ? needed_employee_force_focus ? "Kaydediliyor..." : "Kaydedildi": "Konu Kapalı"}
              onFocus={(e) => setFocus(e.target.name)}
              name={"employee"}
              focused
              />
              <TextFieldV2 label={"Gerekli Materyaller"} onBlur={(e) => handleChangeNeededs(e.target.name)} value={needed_materials} setValue={setNeededMaterils}
              endAdornmentText={data?.state==="CONTINUE" ?needed_materials_focus ? "Kaydediliyor..." : "Kaydedildi": "Konu Kapalı"}
              onFocus={(e) => setFocus(e.target.name)}
              name={"material"}
              focused/>
              <TextFieldV2 label={"Gerekli Ekipmanlar"} onBlur={(e) => handleChangeNeededs(e.target.name)} value={needed_equipments} setValue={setNeededEquipments}
              endAdornmentText={data?.state==="CONTINUE" ?needed_equipments_focus ? "Kaydediliyor..." : "Kaydedildi": "Konu Kapalı"}
              onFocus={(e) => setFocus(e.target.name)}
              name={"equipment"}
              focused/>
              <TextFieldV2 label={"Sermaye (Para) Eğer Gerekiyorsa"} onBlur={(e) => handleChangeNeededs(e.target.name)} value={needed_cash} setValue={setNeededCash}
              endAdornmentText={data?.state==="CONTINUE" ?needed_cash_focus ? "Kaydediliyor..." : "Kaydedildi": "Konu Kapalı"}
              onFocus={(e) => setFocus(e.target.name)}
              name={"cash"}
              focused/>
              <TextFieldV2 label={"Gerekli Diğer Kaynaklar"} onBlur={(e) => handleChangeNeededs(e.target.name)} value={other_needs} setValue={setOtherNeeds}
              endAdornmentText={ data?.state==="CONTINUE" ? other_needs_focus ? "Kaydediliyor..." : "Kaydedildi" : "Konu Kapalı"}
              onFocus={(e) => setFocus(e.target.name)}
              name={"other"}
              focused/>
              <TextFieldV2 label={"Tahmini Maliyet"} onBlur={(e) => handleChangeNeededs(e.target.name)} value={estimated_cost} setValue={setEstimatedCost}
              endAdornmentText={data?.state==="CONTINUE" ?estimated_cost_focus ? "Kaydediliyor..." : "Kaydedildi": "Konu Kapalı"}
              onFocus={(e) => setFocus(e.target.name)}
              name={"estimated"}
              focused/>
              <h1>Kazanç Hesaplaması</h1>
              <TextFieldV2 label="Şirkete Faydalarını Belirtiniz"  onBlur={(e) => handleChangeNeededs(e.target.name)} value={benefits_to_company} setValue={setBenefitsToCompany}
              endAdornmentText={data?.state==="CONTINUE" ?benefits_to_company_focus ? "Kaydediliyor..." : "Kaydedildi": "Konu Kapalı"}
              onFocus={(e) => setFocus(e.target.name)}
              name={"benefits"}
              focused/>
              <TextFieldV2 label="Tahmini Finansal Kazanç" onBlur={(e) => handleChangeNeededs(e.target.name)} value={estimated_financial_earnings} setValue={setEstimatedFinancialEarnings}
              endAdornmentText={data?.state==="CONTINUE" ?estimated_financial_earnings_focus ? "Kaydediliyor..." : "Kaydedildi": "Konu Kapalı"}
              onFocus={(e) => setFocus(e.target.name)}
              name={"fin_benefits"}

              focused/>
              
          </Grid>
         
        </Grid>
        <Divider style={{background:"grey", width:"100%"}} />
        <Grid container>
          <Grid item xs={12} md={12}>
          <CommentOutline>
              <Avatar/>
              <TextFieldV2 label={"Yorumunuzu Ekleyin"} value={comment} setValue={setComment} style={{marginBottom:"0px !important"}} 
              onKeyPress={(e) => {
                if (e.key === 'Enter' && comment?.length > 0 ) {
                  postComment()
                }
            }}
              disabled={data?.state==="FAILED"}
              endAdornmentText={
                buttonClick? 
                  <EditButton>
                    Gönderiliyor...
                  </EditButton>
                  :
                  <EditButton active={comment} onClick={postComment}>
                    Yorum Yap
                </EditButton>
              }
              />
             
            </CommentOutline>
            <div style={{display:"flex", flexDirection:"column", gap:"25px"}}>
            <h2>Yorumlar {data?.comment_suggestions?.length}</h2>
             {data?.comment_suggestions?.slice(0)?.reverse()?.map((e) => (
             <PostedCommentContainer key={e.id}>
              <Avatar/>
              <PostedCommentInfo>
              <span> <b style={{fontSize:"13px"}}>{e?.commenter?.name + " " +e?.commenter?.surname}</b> <span style={{fontSize:"11px", color:"grey"}}>{moment(e?.created_at).format("DD-MM-YYYY")}</span> </span>
              <b>{e.comment}</b>
              </PostedCommentInfo>
              {/* <FaTrash/> */}
            </PostedCommentContainer>
            ))}
            </div>
           
          </Grid>
        </Grid>
      </ActivitiesMainWrapper>
      <RejectSuggestionDialog open={openRejectDialog} setOpen={setOpenRejectDialog} getInfoBack={getInfo} id={params?.id}/>
      <SendToCommittee open={openCommitteeDialog} setOpen={setOpenCommitteeDialog} getInfoBack={getInfo} id={params?.id} />
    </ActivitiesMainContainer>
  )
}

export default ProcessManagmentInspect