import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../AuditApp/Activities/ActivitiesStyle';
import { Checkbox, FormControlLabel, FormGroup, Grid, Tooltip } from '@mui/material';
import { FileHolder, FileName, FileThumbnail } from '../../../Assets/GeneralDesignElements';
import ExcelLogo from "../../../Assets/FileIcons/excel.png"
import Template from "./MusteriSablonu.xls"
import TextFieldV2 from '../../../Assets/TextFieldV2';
import { EditButton } from '../../CompanySettings/MainSettings';
import SuccessSnackBar from '../../../Subcomponents/SuccessSnackBar';
import { useNavigate } from 'react-router-dom';
const NewCustomerAdd = () => {
  const [openFeedback, setOpenFeedbacks] = useState(false)
  const [feedback, setFeedback] = useState(null)
  const [multipleCustomersAdd, setMulitpleCustomersAdd] = useState(false)
  let navigate = useNavigate()

  const handleChange = (e) => {
    setMulitpleCustomersAdd(e.target.checked)
  }
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const axiosConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
    }
  };
  const axiosConfigJson = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('excel_file', file);
    try{
      axios.post(`${process.env.REACT_APP_API_URL}/api/general-info/customers/`, formData,axiosConfig)
      setFeedback("Müşteriler başarıyla yüklendi")
      setFile(null)
      setOpenFeedbacks(true)
      navigate("/app/general-info-app/customers")
    }
    catch(err){
      console.log(err);
    }
  
  };

  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)

  async function createNewCustomer (){
    const body = JSON.stringify({name, email})
    try{
      axios.post(`${process.env.REACT_APP_API_URL}/api/general-info/single-customers-create/`, body,axiosConfigJson)
      setFeedback("Müşteri Başarıyla Eklendi")
      setOpenFeedbacks(true)
      setName("")
      setEmail("")
    }
    catch{

    }
  }

  return (
    <ActivitiesMainContainer>
    <ActivitiesMainWrapper>
    <FormGroup>
      <FormControlLabel control={<Checkbox defaultChecked checked={multipleCustomersAdd} onChange={handleChange} />} label="Müşterileri toplu olarak yükle" />
    </FormGroup>
    <Grid container>
      <Grid item md={6} xs={12}>
        <div style={{display:multipleCustomersAdd?"flex":"none", flexDirection:"column"}}>
        <h1>Toplu müşteri yüklemesi nasıl yapılır?</h1>
        <h3>Şablonu indir</h3>
        <p>Toplu müşteri yüklemesi yapmak için öncelikle aşağıda bulunan excel şablonunu indiriniz.</p>
        <Tooltip title="İndirmek İçin Tıklayın" arrow>
          <FileHolder>
            <a href={Template} download style={{color:"white", textDecoration:"none", textAlign:"center"}}>
            <FileThumbnail src={ExcelLogo} alt="Dosya"/>
            <FileName>Müşteri Şablonu.xls</FileName>
            </a>
          </FileHolder>
        </Tooltip>
        <h3>Şablona Verilerini Aktar</h3>
        <p>Şablonun ilk iki satırını <b>silmeden</b> 2. satırından başlayarak ilk sütuna müşteri isimlerini, ikinci sütuna ise müşteri iletişim e-posta adreslerini ekleyiniz.</p>
        <h3>Şablonu Yükle</h3>
        <form onSubmit={handleSubmit}>
            <input type="file" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  onChange={handleFileChange} />
            <EditButton active style={{width:"110px", marginTop:"15px"}} onClick={handleSubmit}>
              Müşterileri Kaydet
            </EditButton>
        </form> 
        </div>
        <div style={{display:multipleCustomersAdd?"none":"flex", flexDirection:"column"}}>
          <TextFieldV2 label={"Müşteri İsmi"} value={name} setValue={setName} />
          <TextFieldV2 label={"Müşteri E-Posta Adresi"} value={email} setValue={setEmail} />
          <EditButton active onClick={createNewCustomer}>
            Kaydet
          </EditButton>
        </div>
      </Grid>
    </Grid>

     
    </ActivitiesMainWrapper>
      <SuccessSnackBar open={openFeedback} setOpen={setOpenFeedbacks} data={feedback}/>
    </ActivitiesMainContainer>
  );
}

export default NewCustomerAdd