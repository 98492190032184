import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckIcon from '@mui/icons-material/Check';
import { Avatar, Chip, Grid, TextareaAutosize, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { TaskFollowContainer, TaskFollowFooter, TaskFollowHeader, TaskFollowHeaderContainer, TaskFollowWrapper } from '../../../Assets/GeneralDesignElements';
import LockIcon from '@mui/icons-material/Lock';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useNavigate } from 'react-router-dom';
import { green, orange } from '@mui/material/colors';
import axios from 'axios';
import QuickToDo from './Widgets/QuickToDo';
export const ApplicationContainer = styled.div`
    padding: 25px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-bottom:25px;
`
export const ApplicationWrapper = styled.div`
    max-width:1200px;
    align-items:center;
    justify-content:center;
    width: 100%;
    text-align: -webkit-center;
`
export const ApplicationGreeting = styled.h1`
    font-weight:400;
    font-size:30px;
    color:white;
    text-align:center;
    @media screen and (max-width:450px){
        font-size:25px;
    }
    
`

export const ActionGeneralInfo = styled.div`
    padding:15px;
    width:600px;
    border-radius:35px;
    display:flex;
    background: #252628;
    color:#a2a0a2;
    gap:15px;
    justify-content:center;
    @media screen and (max-width:906px){
        display:none;
    }
`
export const InfoItems = styled.div`
    display:flex;
    align-items:center;
    gap:3px;
`

export const ApplicationDate = styled.div`
    display:flex;
    color:white;
    text-align:center;
    align-items:center;
    justify-content:center;
`
const TMHomePage = ({user}) => {
    let navigate = useNavigate()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
      };
      async function getNotes (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagement/self-notes/`,  axiosConfig);
            try{
              setSelfNotes(res.data[0].notes)
            }
            catch{
              setSelfNotes(res.data)
  
            }
        }
        catch{
  
        }
    }
  const [data, setData] = useState(null)
  const [selfNotes, setSelfNotes] = useState(null)
  const [editNotes, setEditNote] = useState(false)
  async function manipulateNotes (){
    const body = JSON.stringify({notes:selfNotes})
    try{
        await axios.post(`${process.env.REACT_APP_API_URL}/api/taskmanagement/self-notes/`,  body, axiosConfig);
        getNotes()
        setEditNote(false)
    }
    catch{

    }
}
  useEffect(() => {
    const token = localStorage.getItem("access")
        const opts = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "JWT " + token
            }
        }
        fetch(`${process.env.REACT_APP_API_URL}/api/taskmanagment/get-task-count/`, opts)
        .then(response => response.json()
        .then(data => {
            setData(data)
        }))
  }, [])
  useEffect(() => {getNotes()
  }, [])
  return (
    <ApplicationContainer>
    <ApplicationWrapper>
      <ApplicationDate>
        Süreç Yönetim Uygulamasına Hoş Geldiniz
      </ApplicationDate>
      <ApplicationGreeting>
        Merhaba, {user?.name + " " + user?.surname}
      </ApplicationGreeting>
     {user?.type === "holding_manager"
          ?
          <Chip label="Şirket Yöneticisi" color="success"  style={{marginBottom:"15px"}}/>
          :
          user?.type === "holding_user"
          ?
          <Chip label="Şirket Çalışanı"  color="warning" style={{marginBottom:"15px"}}/>
          :
          user?.type === "region_manager"
          ?
          <Chip label="Bölge Yöneticisi"  color="primary" style={{marginBottom:"15px"}}/>
          :
          user?.type === "department_manager"
          ?
          <Chip label="Departman Yöneticisi"  color="secondary" style={{marginBottom:"15px"}}/>
          :
          <Chip label="Bölge Çalışanı"  color="error" style={{marginBottom:"15px"}}/>
      }
      <ActionGeneralInfo>
        <InfoItems>
          <HourglassBottomIcon/>
          {data?  data?.number_of_tasks_planned : 0} Görev Planlanmış |
        </InfoItems>
        <InfoItems>
          <RadioButtonCheckedIcon/>
          {data ? data?.number_of_tasks_control : 0} Görev Kontrolde |
        </InfoItems>
        <InfoItems>
          <CheckIcon/>
          {data ? data?.number_of_tasks_act : 0} Görev Tamamlanmış 
        </InfoItems>
      </ActionGeneralInfo>
      <Grid container mt={5} spacing={2} style={{justifyContent: "start"}}>
        <Grid item xs={12} md={6} xl={6}>
            <TaskFollowContainer>
                <TaskFollowWrapper>
                    <TaskFollowHeader>
                    <TaskFollowHeaderContainer>
                        <Typography variant="h6">Uygulamalar</Typography>
                    </TaskFollowHeaderContainer>
                    </TaskFollowHeader>
                   <Grid container gap={5}>
                        <Grid item xs={5} style={{cursor:"pointer"}} display={"flex"} alignItems={"center"} gap={1} onClick={() =>navigate("/app/task-management-app/apps/internal_audit")}>
                            <Avatar style={{background:"#36ff1f"}}><FormatListBulletedIcon/></Avatar>
                            <p style={{fontSize:"13px"}}>İç Denetim</p>
                        </Grid>
                        <Grid item xs={5} style={{cursor:"pointer"}}  display={"flex"} alignItems={"center"} gap={1} onClick={() =>navigate("/app/task-management-app/apps/suggestion_system")}>
                        <Avatar style={{background:"#d300ff"}}><FormatListBulletedIcon/></Avatar>
                            <p style={{fontSize:"13px"}}>Öneri Sistemi</p>
                           
                        </Grid>
                        <Grid item xs={5} style={{cursor:"pointer"}}  display={"flex"} alignItems={"center"} gap={1} onClick={() =>navigate("/app/task-management-app/apps/kaizen_system")}>
                            <Avatar style={{background:"#00fff1"}}><FormatListBulletedIcon/></Avatar>
                            <p style={{fontSize:"13px"}}> Kaizen Uygulaması </p>
                           
                        </Grid>
                        <Grid item xs={5}  style={{cursor:"pointer"}} display={"flex"} alignItems={"center"} gap={1} onClick={() =>navigate("/app/task-management-app/apps/ecc")}>
                            <Avatar style={{background:"#ff8a8a"}}><FormatListBulletedIcon/></Avatar>
                            <p style={{fontSize:"13px"}}>Müşteri Şikayetleri  </p>
                        </Grid>
                        <Grid item xs={5} style={{cursor:"pointer"}}  display={"flex"} alignItems={"center"} gap={1} onClick={() =>navigate("/app/task-management-app/apps/icc")}>
                            <Avatar style={{background:"#5da283"}}><FormatListBulletedIcon/></Avatar>
                            <p style={{fontSize:"13px"}}>İç Müşteri Şikayetleri </p>
                        </Grid>
                      
                   </Grid>
                </TaskFollowWrapper>
            </TaskFollowContainer>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
        <TaskFollowContainer>
                <TaskFollowWrapper>
                    <TaskFollowHeader>
                    <TaskFollowHeaderContainer>
                        <Typography variant="h6">Kişisel Notlar<LockIcon style={{color:"gray", fontSize:"18px", marginLeft:"5px"}}/></Typography>
                    </TaskFollowHeaderContainer>
                    </TaskFollowHeader>
                   <TextareaAutosize 
                   minRows={20}
                   maxRows={20}
                   value={selfNotes}
                   onChange = {(e) => setSelfNotes(e.target.value)}
                   onBlur={manipulateNotes}
                   onFocus={() => setEditNote(true)}
                   style={{width:"100%", background:"rgba(255,255,255,0)", color:"white"}}
                   />
                    {editNotes ?
                   <div style={{display:"flex", gap:"5px"}}>
                   <Avatar
                      sx={{ width: 8, height: 8, bgcolor: green[500] }}
                    >
                      &nbsp;
                    </Avatar>
                    Kaydediliyor...
                   </div>
                   :
                   <div style={{display:"flex", gap:"5px"}}>
                   <Avatar
                      sx={{ width: 8, height: 8, bgcolor: orange[500] }}
                    >
                      &nbsp;
                    </Avatar>
                    Kaydedildi
                   </div>
                  }
                </TaskFollowWrapper>
            </TaskFollowContainer>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
        <TaskFollowContainer>
                <TaskFollowWrapper>
                    <TaskFollowHeader>
                    <TaskFollowHeaderContainer>
                        <Typography variant="h6">Önceliklerim</Typography>
                    </TaskFollowHeaderContainer>
                    </TaskFollowHeader>
                   <QuickToDo/>
                   
                </TaskFollowWrapper>
          </TaskFollowContainer>
        </Grid>
      </Grid>
    </ApplicationWrapper>
  </ApplicationContainer>
  )
}

const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps) (TMHomePage)