import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { KanbanContainer, KanbanSingleLine, KanbanSingleLineHeading, KanbanSingleLineMainContainer, KanbanSingleLineMainItem, KanbanSingleLineMainWrapper, KanbanWrapper } from './KanbanStyle'
import { Skeleton } from '@mui/material';
import TaskTrackingOverLay from '../TaskTrackingOverLay';

const KanbanView = () => {
    const [data, setData] = useState(null)
    const [isFetching, setIsFetching] = useState(false)
    const [isFetchingDetails, setIsFetchingDetails] = useState(false)

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getChatInfo (){
      setIsFetching(true)
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/nearby_task/`, axiosConfig)
        setData(res.data)
      setIsFetching(false)

    }
    catch{

    }
    }
   
    useEffect(() => {getChatInfo()},[])
    const [editBarOpen, setEditBarOpen] = useState(false)
    const handleCloseDialog = () => {
    setEditBarOpen(false)
  }

  const [chatOpen, setChatOpen] = useState(false)
 
 

    const [selectedTask, setSelectedTask] = useState(null)
    
    const [id, setId] = useState(null)
    async function getSingleTaskInfo (e){
      setId(e)
      setEditBarOpen(true)
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagment/single-task/${e}/`,  axiosConfig)
        setSelectedTask(res.data)

    }
    catch{

    }
    }

  return (
    <KanbanContainer>
        <TaskTrackingOverLay
        editBarOpen={editBarOpen}
        setEditBarOpen={setEditBarOpen}
        handleCloseDialog={handleCloseDialog}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        id={id}
        />
        <KanbanWrapper>
            <KanbanSingleLine>
                <KanbanSingleLineHeading>
                    PLAN
                </KanbanSingleLineHeading>
                <KanbanSingleLineMainContainer>
                  <KanbanSingleLineMainWrapper>
                    {!isFetching ? 
                      data?.map((e) => (
                    <KanbanSingleLineMainItem
                    onClick={() => [
                        getSingleTaskInfo(e.id)
                    ]}
                    >
                        {e?.task}
                    </KanbanSingleLineMainItem>
                    ))
                    :
                    <>
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    
                    </>
                    }
                  </KanbanSingleLineMainWrapper>
                   
                </KanbanSingleLineMainContainer>
            </KanbanSingleLine>
            <KanbanSingleLine>
                <KanbanSingleLineHeading>
                    DO
                </KanbanSingleLineHeading>
                <KanbanSingleLineMainContainer>
                <KanbanSingleLineMainWrapper>
                {!isFetching ? 
                      data?.map((e) => (
                    <KanbanSingleLineMainItem
                    onClick={() => [
                        setEditBarOpen(true),
                        setSelectedTask(e)
                    ]}
                    >
                        {e?.task}
                    </KanbanSingleLineMainItem>
                    ))
                    :
                    <>
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    
                    </>
                    }
                </KanbanSingleLineMainWrapper>

               
                </KanbanSingleLineMainContainer>
            </KanbanSingleLine>
            <KanbanSingleLine>
                <KanbanSingleLineHeading>
                    CONTROL
                </KanbanSingleLineHeading>
                <KanbanSingleLineMainContainer>
                {!isFetching ? 
                      data?.map((e) => (
                    <KanbanSingleLineMainItem
                    onClick={() => [
                        setEditBarOpen(true),
                        setSelectedTask(e)
                    ]}
                    >
                        {e?.task}
                    </KanbanSingleLineMainItem>
                    ))
                    :
                    <>
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    
                    </>
                    }
                </KanbanSingleLineMainContainer>
            </KanbanSingleLine>
            <KanbanSingleLine>
                <KanbanSingleLineHeading>
                    ACT
                </KanbanSingleLineHeading>
                <KanbanSingleLineMainContainer>
                {!isFetching ? 
                      data?.map((e) => (
                    <KanbanSingleLineMainItem
                    onClick={() => [
                        setEditBarOpen(true),
                        setSelectedTask(e)
                    ]}
                    >
                        {e?.task}
                    </KanbanSingleLineMainItem>
                    ))
                    :
                    <>
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    <Skeleton variant="rectangular" width={210} height={80} />
                    
                    </>
                    }
                </KanbanSingleLineMainContainer>
            </KanbanSingleLine>
        </KanbanWrapper>

    </KanbanContainer>
  )
}

export default KanbanView