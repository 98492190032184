import { Alert, Avatar, Divider, Grid, Tooltip } from '@mui/material'
import { green, grey, red, yellow } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { ChatBoxContainer, CommentOutline, EmployeeCardImage, EmployeeInfoCardContainer, EmployeeInfoCardWrapper, EmployeeInfoMain, FileHolder, FileName, FileThumbnail, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer, PostedCommentContainer, PostedCommentInfo } from '../../../../Assets/GeneralDesignElements'
import { StyledBadge } from '../../../../Assets/StyledBadge'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../../CompanySettings/MainSettings'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import axios from 'axios'
import { useParams } from 'react-router-dom'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import moment from 'moment'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import ExcelIcon from '../../../../Assets/FileIcons/excel.png'
import WordIcon from '../../../../Assets/FileIcons/word.png'
import PdfIcon from '../../../../Assets/FileIcons/pdf.png'
import ImageIcon from '../../../../Assets/FileIcons/Image.png'
import RarIcon from '../../../../Assets/FileIcons/rar.png'
import UnKnown from '../../../../Assets/FileIcons/UnKnown.webp'
const TrackSuggestion = () => {
  const [comment, setComment] = useState(null)
  let params = useParams()
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
  };
  const [buttonClick, setButtonClick] = useState(false)
  const [data, setData] = useState(null)
  async function getInfo (){
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/create/${params?.id}/`,  axiosConfig)
      setData(res.data)

    }
    catch{

    }
  }
  console.log(comment?.length)
  async function postComment (){
    if (comment && comment?.length > 0) {
      setButtonClick(true)
      const body = JSON.stringify({suggestion_id: params?.id, comment})
      try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions/create/comment/`, body, axiosConfig)
        getInfo()
        setButtonClick(false)
        setComment("")
      }
      catch{
  
        setButtonClick(false)
      }
    }
    else{
      
    }
   
  }

  useEffect(() => {getInfo()},[params?.id])
  function getFileIcon(extension) {
    switch (extension) {
      case 'xls':
      case 'xlsx':
        return <FileThumbnail src={ExcelIcon} alt="xls" />;
      case 'pdf':
        return <FileThumbnail src={PdfIcon} alt="xls" />;
      case 'doc':
      case 'docx':
        return <FileThumbnail src={WordIcon} alt="xls" />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'pjpeg':
        return <FileThumbnail src={ImageIcon} alt="xls" />;
      case 'zip':
      case 'rar':
        return <FileThumbnail src={RarIcon} alt="xls" />;
      default:
        return <FileThumbnail src={UnKnown} alt="xls" />;
    }
  }
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
      <InfoBar around top>
            <EmployeeInfoCardContainer>
              <EmployeeInfoCardWrapper>
                <EmployeeCardImage/>
                <EmployeeInfoMain>
                  <b>{data?.suggester?.name} {data?.suggester?.surname}</b>
                  <b>Şirket Yöneticisi</b>
                  <p>{data?.suggester?.email}</p>
                </EmployeeInfoMain>
              </EmployeeInfoCardWrapper>
            </EmployeeInfoCardContainer>
            <HorizantalTimeLineContainer>
              <HorizantalTimelineCardWrapper>
                <Tooltip title="Öneri Başlangıcı" arrow>
                    <Avatar
                    sx={{ bgcolor: green[600] }}
                    >
                      <DoneOutlineIcon/>
                    </Avatar>
                  </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title={`Hat Yöneticisi (${data?.process_manager?.name + "  " + data?.process_manager?.surname}) Değerlendirmesi`} arrow>
                <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant={data?.stage ==="MANAGERCONTROL" && data?.state === "CONTINUE" ?"dot":""}>
                  <Avatar
                  sx={{ bgcolor: data?.stage ==="MANAGERCONTROL" ? data?.state === "CONTINUE" || data?.state === "PASS" ? yellow[400] : red[400] : green[600]}}
                  >
                    {data?.stage ==="MANAGERCONTROL" && data?.state === "CONTINUE" ?
                    "YD"
                    :
                    data?.stage ==="MANAGERCONTROL" && data?.state === "FAILED" ?
                    <HighlightOffIcon/>
                    :
                    <DoneOutlineIcon/>
                    }
                  </Avatar>
                  </StyledBadge>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Komite Değerlendirmesi" arrow>
                  <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant={data?.stage ==="COMMITEECONTROL" ?"dot":""}>
                  <Avatar
                  sx={{ bgcolor: data?.stage ==="COMMITEECONTROL" ? data?.state === "CONTINUE" || data?.state === "PASS" ? yellow[400] : red[400] :  data?.stage ==="COMPLETED" ? green[600] : grey[600]}}
                  >
                    {data?.stage ==="MANAGERCONTROL" ||  data?.stage ==="COMMITEECONTROL" && data?.state === "CONTINUE" ?
                    "KD"
                    :
                    data?.state === "FAILED" ?
                    <HighlightOffIcon/>
                    :
                    <DoneOutlineIcon/>}
                  </Avatar>
                  </StyledBadge>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="Tamamlandı" arrow>
                    <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant={ data?.stage === "COMPLETED" && data?.state ==="CONTINUE" ?"dot":""}>
                        <Avatar
                        sx={{ bgcolor: data?.stage === "COMPLETED" ? green[600] : grey[600] }}
                        >
                        {data?.state ==="CONTINUE" ?
                        "T"
                        :
                        <DoneOutlineIcon/>
                        }
                        </Avatar>
                    </StyledBadge>
                </Tooltip>
              </HorizantalTimelineCardWrapper>
            </HorizantalTimeLineContainer>
        </InfoBar>
        <Grid container direction={"row"} justifyContent={"space-between"}>
          <Grid item container md={12} xs={12}>
            <Grid item md={12} xs={12}>
              <h1>{data?.uuid} Nolu Öneri</h1>
              <b>{data?.heading}</b>
              <p>{data?.suggestion}</p>
              <p>Bölge: {data?.region?.name + " / " + data?.section?.name}</p>
              <p>Süreç Yöneticisi: {data?.process_manager?.name + "  " + data?.process_manager?.surname}</p>
              {data?.state === "FAILED" ? 
              <Alert color="error" style={{width: "90%"}}>
               {data?.reject_reason}
              </Alert>
              :
              data?.state === "PASS" ?
              <Alert color="error" style={{width: "90%"}}>
                {`Tebrikler öneriniz kabul edilierek uygulamaya koyuldu ve ${data?.earned_point} kazandınız`}
              </Alert>
              :
              ""
              }
            </Grid>
            <Grid item md={12} xs={12} mr={15}>
            {data?.files?.length > 0 ?
              <h1>Eklenen Dökümanlar</h1>
              :""}
              <div style={{display:"flex", gap:"45px"}}>
              {data?.files?.length > 0 ?
                  <>
                  <TaskImageHolder>
                  {data?.files?.map((e,index) => (
                    <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                        <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                      <FileHolder>
                        {getFileIcon(e?.file_type)}
                        <FileName>{e?.file_name}</FileName>
                      </FileHolder>
                      </a>
                    </Tooltip>
                      ))}
                  </TaskImageHolder>
                  </>
                :
              ""}
              
             
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{background:"grey", width:"100%"}} />
        <Grid container>
          <Grid item xs={12} md={12}>
            <CommentOutline>
              <Avatar/>
              <TextFieldV2 label={"Yorumunuzu Ekleyin"} value={comment} setValue={setComment} style={{marginBottom:"0px !important"}} 
              onKeyPress={(e) => {
                if (e.key === 'Enter' && comment?.length > 0 ) {
                  postComment()
                }
            }}
              disabled={data?.state==="FAILED"}
              endAdornmentText={
                buttonClick? 
                  <EditButton>
                    Gönderiliyor...
                  </EditButton>
                  :
                  <EditButton active={comment} onClick={postComment}>
                    Yorum Yap
                </EditButton>
              }
              />
             
            </CommentOutline>
            <div style={{display:"flex", flexDirection:"column", gap:"25px"}}>
            <h2>Yorumlar {data?.comment_suggestions?.length}</h2>
             {data?.comment_suggestions?.slice(0)?.reverse()?.map((e) => (
             <PostedCommentContainer key={e.id}>
              <Avatar/>
              <PostedCommentInfo>
              <span> <b style={{fontSize:"13px"}}>{e?.commenter?.name + " " +e?.commenter?.surname}</b> <span style={{fontSize:"11px", color:"grey"}}>{moment(e?.created_at).format("DD-MM-YYYY")}</span> </span>
              <b>{e.comment}</b>
              </PostedCommentInfo>
              {/* <FaTrash/> */}
            </PostedCommentContainer>
            ))}
            </div>
           
          </Grid>
        </Grid>
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default TrackSuggestion