import { Grid } from '@mui/material'
import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import styled from "styled-components"
import CompanyInfoEditDialog from './DialogContaners/CompanyInfoEditDialog'
import TaxInfoDialog from './DialogContaners/TaxInfoDialog'
import TaxInfoEditDialog from './DialogContaners/TaxInfoEditDialog'
import { styled as muiStyled } from '@mui/material/styles';
import SuccessSnackBar from '../../Subcomponents/SuccessSnackBar'
import ErrorSnackBar from '../../Subcomponents/ErrorSnackBar'

const Input = muiStyled('input')({
  display: 'none',
});

export const MainSettingsContainer =styled.div`
  width: 100%;
  display:flex;
` 

export const MainSettingsWrapper = styled.div`
  display:flex;
  padding:25px;
  width: 100%;
  flex-direction:column;
  max-height:95vh;
  overflow-y: auto;
`
export const RowHolder = styled.div`
    display:flex;
    width: 100%;
    justify-content:center;
    align-items:center;
`
export const RowDualHolder = styled.div`
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    margin-top: ${({mt}) => mt}
`

export const ColumnHolder = styled.div`
    display:flex;
    flex-direction:column;
    width: 100%;
    justify-content:center;
    align-items:center;
    gap:0;
`
export const GatheredText = styled.p`
  line-height:0px;
`

export const CompanyLogo = styled.div`
    height:150px;
    width:150px;
    border-radius:50%;
    background:grey;
    display:flex;
    justify-content:center;
    align-items:center;
    background-image : ${({image}) => image ? `url(${image})` : "none"};
    background-repeat : no-repeat;
    background-size: cover;
`
export const HorizantalBox = styled.div`
  height:150px;
  width:350px;
  background:rgba(255,255,255,0.1);
  border-radius:15px;
  padding:20px;
  backdrop-filter: blur(200px);
`
export const HeadingOfBox = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:center;
`
export const EditButton = styled.div`
  background: ${({active}) => active ? "#1c76dc" : "#5e5a61"} ;
  min-width:70px;
  display:flex;
  justify-content:center;
  align-items:center;
  height:15px;
  padding:5px;
  border-radius:8px;
  font-size:13px;
  cursor:pointer;
`


const MainSettings = ({user}) => {
  const [successOpen, setSuccessOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [openTaxInfoCreateBox, setOpenTaxInfoCreateBox] = useState(false)
  const [openTaxInfoEditCreateBox, setOpenTaxInfoEditCreateBox] = useState(false)
  const [openCompanyInfoEditCreateBox, setOpenCompanyInfoEditCreateBox] = useState(false)
  const[id,setId] = useState(null)
  const[logoPath, setLogoPath] = useState(null)
  const[info,setInfo] = useState(null)
  const[taxInfo, setTaxInfo] = useState(null)

  async function getTaxInfo () {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taxinfo/main/`, config);
        setTaxInfo(res.data[0])
    } catch (err) {
    }
  }
  async function getCompanyInfo () {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/main/`, config);
        setInfo(res.data[0])
        setId(res.data[0].id)
        setLogoPath(res.data[0].logo_path)
    } catch (err) {
    }
  }
 
  useEffect(()=>{
      getTaxInfo();
      getCompanyInfo();
  }, [])

  const onSubmitLogo = e => {
      let form_data = new FormData();
      form_data.append('logo_path', e,  e.name )
      const config = {
          headers: {
              'Authorization': `JWT ${localStorage.getItem('access')}`,

          },
  };
  const body = form_data
  axios.patch(`${process.env.REACT_APP_API_URL}/api/company/update/${id}/`, body, config)
      .then(response => {
        getCompanyInfo()
        setSuccessOpen(true)
      }).catch(error => {
        setErrorOpen(true)
      })
  } 
  const onChangePicture = e => {
    if (e.target.files[0]) {
    onSubmitLogo(e.target.files[0])
    }
};

  return (
    <MainSettingsContainer>
      <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
      <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
      <MainSettingsWrapper className='container'>
        <RowHolder>
          <CompanyLogo image={logoPath}>
            {logoPath?
            <label htmlFor="icon-button-file" style={{width:"100%",height:"100%", cursor:"pointer"}}>
                  <Input accept="image/*" id="icon-button-file" type="file"  onChange={onChangePicture} />
                  
            </label>
            :
            <label htmlFor="icon-button-file">
            <Input accept="image/*" id="icon-button-file" type="file"  onChange={onChangePicture} />
            Logo Ekle

        </label>
            }
          </CompanyLogo>
        </RowHolder>
        <RowHolder>
          <ColumnHolder>
            <GatheredText>{info?.name}</GatheredText>
            <GatheredText>{info?.holding ? "Entreprise" : "Business"}</GatheredText>
          </ColumnHolder>
        </RowHolder>
        <Grid container spacing={5}>
          <Grid item md={6} xs={12} display="flex" justifyContent={"center"} alignItems="center">
            <HorizantalBox>
              <HeadingOfBox>
              <b>Fatura Bilgileri</b>
              {user?.type === "holding_manager" ?
              taxInfo ? 
              <EditButton onClick={() => setOpenTaxInfoEditCreateBox(true)}>
              Düzenle...
              </EditButton>
              :
              <EditButton onClick={() => setOpenTaxInfoCreateBox(true)}>
              Bilgi Ekle...
              </EditButton>  
              :
              ""
            }
              </HeadingOfBox>
            
              <p>{taxInfo?.tax_number}</p>
              <p>{taxInfo?.tax_office}</p>
              <p>{taxInfo?.adress}</p>
          </HorizantalBox>
          </Grid>
          <Grid item md={6} xs={12} display="flex" justifyContent={"center"} alignItems="center">
            <HorizantalBox>
              <HeadingOfBox>
              <b>İletişim Bilgileri</b>
              {user?.type === "holding_manager" ?
              <EditButton onClick={() => setOpenCompanyInfoEditCreateBox(true)}>
                Düzenle...
              </EditButton>
               :
               ""
             }
              </HeadingOfBox>
              <p>{info?.email}</p>
              <p>{info?.phone}</p>
              <p>{info?.is_holding ? "Çok Lokasyonlu Kullanıcı" : "Tek Lokasyonlu Kullanıcı"}</p>
            </HorizantalBox>
          </Grid>
        </Grid>
        <TaxInfoDialog setErrorOpen={setErrorOpen} setSuccessOpen={setSuccessOpen} open={openTaxInfoCreateBox} setOpen={setOpenTaxInfoCreateBox} getInfoBack={getTaxInfo} company_id = {user?.company_id} />
        <TaxInfoEditDialog setErrorOpen={setErrorOpen} setSuccessOpen={setSuccessOpen} data={taxInfo} open={openTaxInfoEditCreateBox} setOpen={setOpenTaxInfoEditCreateBox} getInfoBack={getTaxInfo} company_id = {user?.company_id} />
        <CompanyInfoEditDialog setErrorOpen={setErrorOpen} setSuccessOpen={setSuccessOpen} data={info} id={id} open={openCompanyInfoEditCreateBox} setOpen={setOpenCompanyInfoEditCreateBox} getInfoBack={getCompanyInfo} company_id = {user?.company_id} />
      </MainSettingsWrapper>
    </MainSettingsContainer>
  )
}

const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps)(MainSettings)