import { Divider } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { SettingsPageHeading, SettingsPagesContainer, SettingsPageWrapper } from '../../../Assets/SettingPagesDesigns'
import { InfoBar } from '../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../CompanySettings/MainSettings'
import TreeComponent from '../../GeneralInfoApp/Process/ProcessTreeViewV2'
import NewCategoryAddDialog from './Dialogs/NewCategoryAddDialog'
import IccCategoryTable from './Tables/IccCategoriesTable'
import EccCategoryTree from './Tree/EccCategoryTree'

const IccSettings = () => {
    const [newCategoryAddOpen, setNewCategoryAddOpen] = useState(false)
    const [data, setData] = useState(null)
    const [tableView, setTableView] = useState(true)
    
    
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getCategoryInfo (){
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/icc/category/`, axiosConfig)
        setData(res.data)
    }
    catch{

    }
    }
    useEffect(() => {getCategoryInfo()},[])
  return (
    <SettingsPagesContainer>
        <SettingsPageWrapper>
            <InfoBar>
                <SettingsPageHeading>İç Müşteri Şikayetleri Kategorileri </SettingsPageHeading>
                <EditButton active onClick={() => setNewCategoryAddOpen(true)}>Yeni Kategori Ekle</EditButton>
            </InfoBar>
            <Divider style={{width:"100%",background:"grey",marginBottom:"15px"}} />
            <InfoBar  style={{marginBottom:"15px"}}>
                <div></div>
                <div style={{display:"flex",gap:"8px"}}>
                    <EditButton active={tableView} onClick={() =>setTableView(true)}>Tablo Görünümü</EditButton>
                    <EditButton active={!tableView} onClick={() =>setTableView(false)}>Kategori Ağacı</EditButton>
                </div>
            </InfoBar>
            {tableView ?
            <IccCategoryTable getInfoBack={getCategoryInfo} data={data} />
            :
            <EccCategoryTree data={data} />
            }
        </SettingsPageWrapper>
        <NewCategoryAddDialog open={newCategoryAddOpen} setOpen={setNewCategoryAddOpen} getInfoBack={getCategoryInfo} data={data}/>
    </SettingsPagesContainer>
  )
}

export default IccSettings