import { Alert, Avatar, Button, Dialog, DialogContent, DialogTitle, Grid, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Snackbar, Typography } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { TopNavActions } from '../AuditDashboardsContainer/activitiesdashboard';
import styled from "styled-components"
import ChatApp from "../../Assets/AppIcons/chat_app.png"


export const NotificationsContainer = styled.div`
`

export const NotificationsWrapper = styled.div`
    padding: 15px;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:5px;
`
export const NotificationTabs = styled.div`
    width: ${({width}) => width ? width: "100px"};
    border: 1px solid gray;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    padding:5px;
    cursor:pointer;
    background: ${({active}) => active ? "#323232" : "none"}
`

export const NotificationsMainWrapper = styled.div`
    height: 250px;
    overflow-y:auto;
`
export const NotificationLoadMoreWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
`

const NotificationsDialog = ({open, setOpen}) => {
    const [tab, setTab] = useState("new")
    function PaperComponent(props) {
        return (
          <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
          >
            <Paper {...props} />
          </Draggable>
        );
      }
    const handleCloseDialog = () => {
        setOpen(false)
    }
  
  
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitle id="draggable-dialog-title"  style={{background:"rgba(54,51,58,0.8)", height:"8px",cursor: 'move'}}>
        <Grid container spacing={2} justifyContent={"center"} alignItems="center">
            <Grid container item xs={12}>
                <Grid item container xs={4} style={{flexDirection:"row"}}>
                    <TopNavActions id="close" style={{ marginLeft:"-5px"}} onClick={handleCloseDialog} background={"#ee5c54"}>
                    </TopNavActions>
                    <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                    </TopNavActions>
                    <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                    </TopNavActions>
                </Grid>
                <Grid item xs={4} style={{fontSize:"15px"}} display={"flex"} justifyContent={"center"} >
                    Bildirimler
                </Grid>
                <Grid item xs={4} style={{fontSize:"15px"}}></Grid>
            </Grid>
        </Grid>
        </DialogTitle>
        <DialogContent>
            <NotificationsContainer>
                <NotificationsWrapper>
                    <NotificationTabs active={tab === "new"} onClick={() => setTab("new")} >
                        Yeni
                    </NotificationTabs>
                    <NotificationTabs active={tab === "old"}  onClick={() => setTab("old")} >
                        Eski
                    </NotificationTabs>
                </NotificationsWrapper>
            </NotificationsContainer>
            {tab === "new" ?
            <NotificationsMainWrapper>
            <List sx={{ width: '100%'}}>
                <MenuItem sx={{ width: '100%'}}> 
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={ChatApp} />
                        </ListItemAvatar>
                        <ListItemText primary={
                        <Typography variant="inherit" noWrap>
                        A very long text that overflows asf asf asfas dS
                        </Typography>
                        } 
                        secondary="Jan 9, 2014" />
                    </ListItem>
                </MenuItem>
                
            </List>
            <NotificationLoadMoreWrapper>
            <p>Daha Fazla Yükle</p>
            </NotificationLoadMoreWrapper>
            </NotificationsMainWrapper>
            :
            <NotificationsMainWrapper>
            <List sx={{ width: '100%'}}>
                <MenuItem sx={{ width: '100%'}}> 
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={ChatApp} />
                        </ListItemAvatar>
                        <ListItemText primary={
                        <Typography variant="inherit" noWrap>
                       Eski Bildiriml
                        </Typography>
                        } 
                        secondary="Jan 9, 2014" />
                    </ListItem>
                </MenuItem>
                
            </List>
            <NotificationLoadMoreWrapper>
            <p>Daha Fazla Yükle</p>
            </NotificationLoadMoreWrapper>
            </NotificationsMainWrapper>
            }
        </DialogContent>
      
    </Dialog>
    </>
  )
}

export default NotificationsDialog