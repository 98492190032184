import React from 'react'
import { InnerNavigation, InnerNavigationItems } from '../Activities'
import { ApplicationContainer } from '../HomePage'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
const AuditDeflects = () => {
  let navigate = useNavigate()
  const {pathname} = useLocation()
  return (
    <ApplicationContainer style={{width:"100%"}}>
      <InnerNavigation style={{borderBottom:"1px solid grey", paddingBottom:"10px", marginBottom:"10px"}}>
        <InnerNavigationItems active={pathname === "/app/audit-app/deflects"} onClick={() => navigate("/app/audit-app/deflects")}>Silme Talepleri</InnerNavigationItems>
        <InnerNavigationItems active={pathname.includes("date")} onClick={() => navigate("/app/audit-app/deflects/date")}>Tarih Değişikliği Talepleri</InnerNavigationItems>
      </InnerNavigation>
      <Outlet/>
    </ApplicationContainer>
  )
}

export default AuditDeflects