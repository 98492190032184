import moment from 'moment'
import React, {  useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import NavBarLogo from '../../Subcomponents/NavBarLogo'
import { TopBarLeft, TopBarRight } from '../MainAppScreen'
import ApartmentIcon from '@mui/icons-material/Apartment';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GroupIcon from '@mui/icons-material/Group';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { EditButton } from './MainSettings'
import ReigonAddDialog from './DialogContaners/ReigonAddDialog'
import { connect } from 'react-redux'
import axios from 'axios'
import SectionAddDialog from './DialogContaners/SectionAddDialog'
import SectionAddFromRegionDialog from './DialogContaners/SectionAddFromRegionDialog'
import SectionAddFromSectionDialog from './DialogContaners/SectionAddFromSectionDialog'
import UserAddDialog from './DialogContaners/UserAddDialog'
import { FaChevronCircleLeft, FaChevronCircleRight, FaMap,  FaTable} from "react-icons/fa"
import { CgChevronLeftR,CgChevronRightR} from "react-icons/cg"
import { Tooltip } from '@mui/material'
import SuccessSnackBar from '../../Subcomponents/SuccessSnackBar'
import ErrorSnackBar from '../../Subcomponents/ErrorSnackBar'

export const SettingsContainer = styled.div`
    display:flex;
    flex-direction:column;
`

export const TopNav = styled.div`
    width: 100%;
    height:30px;
    font-size:14px;
    display: ${({fullHeight}) => !fullHeight ? "flex" :"none" } ;
    background: rgba(75, 23, 124, 0.95);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 40px );
    -webkit-backdrop-filter: blur( 40px );
    align-items:center;
    justify-content:space-between;
    @media screen and (max-width:600px){
        display:none;
    }
`
export const TopNavActions = styled(Link)`
    height:13px;
    width:13px;
    border-radius: 50%;
    background: ${({background}) => background};
    margin-left:15px;
    margin-right:15px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    color:white;
    text-decoration:none
`
export const TopNavTitle = styled.p`
    font-weight: "600";
`

export const SetttingsContainer = styled.div`
    height: ${({ht}) => !ht ?"100vh" :"100vh"} ;
    width:100%;
    background:#211c27;
    display:flex;
    @media screen and (max-width:600px){
        height: ${({ht}) => !ht ?"100vh" :"100vh"} ;
    }

`
export const SideBar = styled.div`
    display:flex;
    flex-direction:column;
    background:#26252b;
    width:260px;
    border-right:1px solid black;
    border-radius:8px;
    z-index:99;
    @media screen and (max-width:600px){
        position:absolute;
        height:100vh;
        display:${({showSidebar}) => showSidebar ? "flex": "none"}
    }
`

export const SideBarTopElements = styled.div`
    margin-top:25px;
    display:flex;
    align-items: center;
    justify-content:space-between;
`
export const SideBarTopElementsActions = styled.div`
    display:flex;
    justify-content:start;
    align-items:left;
`
export const SideBarMainElements  = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:15px;
    margin-left:5px;
    gap:5px
`
export const SideBarMainElementsItem  = styled(Link)`
    color:  white;
    text-decoration:none;
    display:flex;
    padding:10px;
    background: ${({select}) => select ? "#3f3d44" : "none"};
    border-radius:5px;
    width:85%;
    align-items:center;
    cursor:pointer;
`
export const SideBarMainElementsIcon  = styled.div`
    margin-right:5px;
    color:#00fff1;
    //#00fff1 kaizen color
`
export const SideBarMainElementsName  = styled.div`
    font-size:13px
`
export const PageMainContainer = styled.div`
    display:flex;
    min-height:95vh;
    overflow-y:auto;
    justify-content:center;
    align-items:start;
    flex: 1;
`
export const AppOutline = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    min-height:100vh;
` 
export const TitleHolder = styled.div`
    width:100%;
    height:45px;
    background:rgba(54,51,58,0.8);
    display:flex;
    justify-content:center;
    align-items:center;
`

export const LeftArrow = styled(CgChevronLeftR)`
    display:none;
    @media screen and (max-width:600px){
        display:block;

    }
`
const CompanySettings = ({user}) => {
    const [successOpen, setSuccessOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const {pathname} = useLocation()
    let navigate = useNavigate()
    const [showSidebar, setShowSidebar] = useState(true)
    useEffect(() => {setShowSidebar(false)},[pathname])
    const [sideBarClose, setSideBarClose] = useState("flex")
    let params = useParams()
    const [treeView, setTreeView] = useState(false)
    const [reigonAddOpen, setReigonAddOpen] = useState(false)
    const [regionData, setRegionData] = useState(null)
    async function getRegionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, config);
            setRegionData(res.data)
        } catch (err) {
        }
      }
    const [sectionAddOpen, setSectionAddOpen] = useState(false)
    const [sectionData, setSectionData] = useState(null)
    async function getSectionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/section/main/`, config);
            setSectionData(res.data)
        } catch (err) {
        }
      }
     
    


    const [sectionRegionAddOpen, setSectionRegionAddOpen] = useState(false)
    const [sectionRegionData, setSectionRegionData] = useState(null)
    async function getSectionRegionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/department/${params.id}/`, config);
            setSectionRegionData(res.data)
        } catch (err) {
        }
      }

    const [sectionSectionAddOpen, setSectionSectionAddOpen] = useState(false)
    const [sectionSectionData, setSectionSectionData] = useState(null)
    async function getSectionSectionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/section/department/${params.id}/`, config);
            setSectionSectionData(res.data)
        } catch (err) {
        }
      }
    
      const [userAddOpen, setUserAddOpen] = useState(false)

      const [userData, setUserData] = useState(null)
      async function getUserInfo () {
          const config = {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `JWT ${localStorage.getItem('access')}`,
                  'Accept': 'application/json'
              }
          };
          try {
              const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/create/`, config);
              setUserData(res.data)
          } catch (err) {
          }
        }

   

    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    const [iconMinimizeDisplay, setIconMinimizeDisplay] = useState("none")
    const [iconMaximizeDisplay, setIconMaximizeDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
        else if (e.target.id ==="minimize"){
            setIconMinimizeDisplay("block")
        }
        else{
            setIconMaximizeDisplay("block")
        }
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
        setIconMinimizeDisplay("none")
        setIconMaximizeDisplay("none")

    }
    const [fullHeight, setFullHeight] = useState(false)
    function TopbarHidden(){
        setFullHeight(true)
    }
    function TopbarShow(){
        setFullHeight(false)
    }
  return (
    <SettingsContainer>
        <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
        <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
        <TopNav style={{display:fullHeight ? "none" : "flex"}}>
            <TopBarLeft>
                <NavBarLogo/>
                <TopNavTitle>Şirket Ayarları</TopNavTitle>
            </TopBarLeft>
            <TopBarRight>
            {moment().format("llll")}
            </TopBarRight>
        </TopNav>
            <SetttingsContainer ht={fullHeight}>
                <SideBar  showSidebar={showSidebar}>
                <SideBarTopElements>
                        <SideBarTopElementsActions>
                            <TopNavActions id="close" onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} background={"#ee5c54"} to="/app">
                                <p style={{fontSize:"8px", display:iconCloseDisplay}}>X</p>
                            </TopNavActions>
                            <TopNavActions id="minimize" onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} background={fullHeight ? "#F8BC45" : "#6d6971"} style={{marginLeft:"-5px"}} onClick={TopbarShow}>
                                <p style={{fontSize:"8px", display:iconMinimizeDisplay}}>-</p>
                            </TopNavActions>
                            <TopNavActions id="maximize" onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} background={!fullHeight ? "#5dc942" : "#6d6971"} style={{marginLeft:"-5px"}} onClick={TopbarHidden}>
                                <p style={{fontSize:"8px", display:iconMaximizeDisplay}}>+</p>
                            </TopNavActions>
                        </SideBarTopElementsActions>
                        <LeftArrow style={{marginRight:"15px"}} onClick={() => setShowSidebar(false)}/>
                    </SideBarTopElements>
                    <SideBarMainElements>
                        <SideBarMainElementsItem select={pathname === "/app/company-settings"} to="/app/company-settings">
                            <SideBarMainElementsIcon>
                                <ApartmentIcon fontSize="small"/>
                            </SideBarMainElementsIcon>
                            <SideBarMainElementsName>
                                Şirket Ayarları
                            </SideBarMainElementsName>
                        </SideBarMainElementsItem>
                        <SideBarMainElementsItem  select={pathname === "/app/company-settings/region-settings"}  to="/app/company-settings/region-settings">
                            <SideBarMainElementsIcon>
                                <AddLocationAltIcon fontSize="small"/>
                            </SideBarMainElementsIcon>
                            <SideBarMainElementsName>
                                Bölge Ayarları
                            </SideBarMainElementsName>
                        </SideBarMainElementsItem>
                        <SideBarMainElementsItem  select={pathname === "/app/company-settings/department-settings"}  to="/app/company-settings/department-settings">
                            <SideBarMainElementsIcon>
                                <CorporateFareIcon fontSize="small"/>
                            </SideBarMainElementsIcon>
                            <SideBarMainElementsName>
                                Departman Ayarları
                            </SideBarMainElementsName>
                        </SideBarMainElementsItem>
                        <SideBarMainElementsItem select={pathname === "/app/company-settings/user-settings"} to="/app/company-settings/user-settings">
                            <SideBarMainElementsIcon>
                                <GroupIcon fontSize="small"/>
                            </SideBarMainElementsIcon>
                            <SideBarMainElementsName>
                                Kullanıcı Ayarları
                            </SideBarMainElementsName>
                        </SideBarMainElementsItem>
                    </SideBarMainElements>
                </SideBar>
                <AppOutline>
                    <TitleHolder>
                    <div style={{position:"absolute", left:"15px", display:"flex", alignItems:"center"}}>
                        <CgChevronRightR style={{marginRight:"15px"}} onClick={() => setShowSidebar(true)}/>
                    </div>
                        {sideBarClose === "none" ?
                        <div style={{position:"absolute", left:"15px"}}>
                            <FaChevronCircleRight  onClick={() => setSideBarClose("flex")}/>
                        </div>
                        :
                        ""
                        }
                        {pathname === "/app/company-settings" ? "Şirket Ayarları" : pathname === "/app/company-settings/department-settings" ? "Departman Ayarları" : pathname.includes("/app/company-settings/department-settings/") ? "Alt Departman Ayarları" : pathname === "/app/company-settings/region-settings" ? "Bölge Ayarları" :  pathname.includes("/app/company-settings/region-settings/") ? "Bölgeye Bağlı Departman Ayarları" :"Kullanıcı Ayarları"}
                        {pathname === "/app/company-settings/region-settings"
                        ?
                        <div style={{position:"absolute", right:"15px", display:"flex", gap:"5px"}}>
                            {!treeView ?
                             <Tooltip title="Organizasyon Şeması Olarak Görüntüle" placement="bottom" arrow>
                             <EditButton active onClick={() =>setTreeView(true)}>
                               <FaMap/>
                            </EditButton>
                            </Tooltip>
                            :
                            <Tooltip title="Tablo Görünümü" placement="bottom" arrow>
                            <EditButton active onClick={() =>setTreeView(false)}>
                              <FaTable/>
                            </EditButton>
                            </Tooltip>
                            }
                            {user?.type === "holding_manager" ? 
                            <EditButton active onClick={() =>setReigonAddOpen(true)}>
                                Bölge Ekle
                            </EditButton>
                            :""}
                        </div>
                        :
                        pathname.includes("/app/company-settings/region-settings/")
                        ?
                        user?.type === "holding_manager" || user?.type ==="region_manager" ? 
                        <div style={{position:"absolute", right:"15px"}}>
                            <EditButton active onClick={() =>setSectionRegionAddOpen(true)}>
                                Bölgeye Departman Ekle
                            </EditButton>
                        </div>
                        :
                        ""
                        :
                        pathname === "/app/company-settings/department-settings"?
                        user?.type === "holding_manager" || user?.type ==="region_manager" ? 
                        <div style={{position:"absolute", right:"15px"}}>
                        <EditButton active onClick={() =>setSectionAddOpen(true)}>
                            Departman Ekle
                        </EditButton>
                        </div>
                        :
                        ""
                        :
                        pathname.includes("/app/company-settings/department-settings/")
                        ?
                        user?.type === "holding_manager" || user?.type ==="region_manager" || user?.type === "department_manager" ? 
                        <div style={{position:"absolute", right:"15px"}}>
                            <EditButton active onClick={() =>setSectionSectionAddOpen(true)}>
                                Alt Departman Ekle
                            </EditButton>
                        </div>
                        :
                        ""
                        :
                        pathname === "/app/company-settings/user-settings"?
                        user?.type === "holding_manager" || user?.type ==="region_manager" || user?.type === "department_manager" ? 
                        <div style={{position:"absolute", right:"15px"}}>
                        <EditButton active onClick={() => setUserAddOpen(true)}>
                            Kullanıcı Ekle
                        </EditButton>
                        </div>
                        :
                        ""
                        :
                        ""
                        }
                  
                    </TitleHolder>
                    <PageMainContainer>
                        <Outlet context={[regionData,getRegionInfo, sectionData, getSectionInfo,sectionRegionData,getSectionRegionInfo,sectionSectionData,getSectionSectionInfo, treeView,getUserInfo,userData]} />
                        <ReigonAddDialog setErrorOpen={setErrorOpen} setSuccessOpen={setSuccessOpen} open={reigonAddOpen}  setOpen={setReigonAddOpen} getInfoBack={getRegionInfo}  company_id = {user?.company_id}/>
                        <SectionAddDialog setErrorOpen={setErrorOpen} setSuccessOpen={setSuccessOpen} open={sectionAddOpen} regionData={regionData} setOpen={setSectionAddOpen} getInfoBack={getSectionInfo}  company_id = {user?.company_id}/>
                        <SectionAddFromRegionDialog setErrorOpen={setErrorOpen} setSuccessOpen={setSuccessOpen} param={params}  open={sectionRegionAddOpen} regionData={sectionRegionData} setOpen={setSectionRegionAddOpen} getInfoBack={getSectionRegionInfo}  company_id = {user?.company_id}/>
                        <SectionAddFromSectionDialog setErrorOpen={setErrorOpen} setSuccessOpen={setSuccessOpen} param={params} open={sectionSectionAddOpen} regionData={sectionSectionData} setOpen={setSectionSectionAddOpen} getInfoBack={getSectionSectionInfo}  company_id = {user?.company_id}/>
                        <UserAddDialog  setErrorOpen={setErrorOpen} setSuccessOpen={setSuccessOpen} open={userAddOpen} setOpen={setUserAddOpen} getInfoBack={getUserInfo} />
                    </PageMainContainer>
                </AppOutline>
            </SetttingsContainer>
    </SettingsContainer>
  )
}


const mapStateToProps = state => ({   
    user: state.auth.user
  })
  export default connect(mapStateToProps)(CompanySettings)