import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextareaAutosize } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import { EditButton } from '../../../../CompanySettings/MainSettings'

const SendToCommittee = ({open, setOpen, getInfoBack,id}) => {
    const [additionalInfo, setAdditionalInfo] =useState(null)
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const handleCloseSuccessMsg = () => {
        setSuccessMsg(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const vertical = "bottom"
    const horizontal = "center"
    const [successMsg, setSuccessMsg] = useState(false)
    async function handleDelete() {
      setButtonStart(true)
      const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
      };
      const body = JSON.stringify({additionalInfo})
      try {
          await axios.patch(`${process.env.REACT_APP_API_URL}/api/kaizen/get/process_manager_kaizen/${id}/`, body,config);
          setOpen(false)
          setSuccessMsg(true)
          setButtonStart(false)
          getInfoBack()

      }
      catch (err) {
          setButtonStart(false)
          setOpen(false)

      }
      }
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Öneriyi Çözüme İlet"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
           <label>Ek açıklama:</label>
            <TextareaAutosize minRows={6} style={{width:"100%"}}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleDelete} active>İlet</EditButton> : <EditButton>İletiliyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default SendToCommittee