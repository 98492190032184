import { Avatar, Divider, Grid, Table, TableCell, TableRow, Tooltip } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { EmployeeCardImage, EmployeeInfoCardContainer, EmployeeInfoCardWrapper, EmployeeInfoMain, FileHolder, FileName, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer } from '../../../../Assets/GeneralDesignElements'
import getFileIcon from '../../../../Assets/GetFileIconsFunction'
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'

const IccComplaintProcessDetails = () => {
    const [data, setData] = useState([])

    let params = useParams()
    const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
      };
      async function getInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/icc/create-read/${params?.id}/`,  axiosConfig);
            setData(res.data)
        }
        catch{
    
        }
      }
    
      

      useEffect(() => {getInfo()},[])

  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
            <InfoBar around top>
                <EmployeeInfoCardContainer>
                    <EmployeeInfoCardWrapper>
                        <EmployeeInfoMain>
                            <b>Çözüm Lideri: {data?.solution_team?.team_lead?.name + " " + data?.solution_team?.team_lead?.surname}</b>
                            <b>Çözüm Takım Üyeleri</b>
                            <div style={{display:"flex" , gap:"5px"}}>
                                {data?.solution_team?.users?.map((e) => (

                                <Tooltip title={e?.name  +  " " +  e?.surname} arrow>
                                <EmployeeCardImage small>
                                    {e?.name?.charAt(0) + " " + e?.surname?.charAt(0)}
                                </EmployeeCardImage>
                                </Tooltip>
                                ))}
                
                            </div>
                        </EmployeeInfoMain>
                    </EmployeeInfoCardWrapper>
                </EmployeeInfoCardContainer>
                <HorizantalTimeLineContainer>
                <HorizantalTimelineCardWrapper>
                    <Tooltip title="PLAN" arrow>
                        <Avatar
                        sx={{ bgcolor: green[600] }}
                        >
                        P
                        </Avatar>
                    </Tooltip>
                    <Divider style={{background:"grey", width:"25px"}}/>
                    <Tooltip title="DO" arrow>
                        <Avatar
                        sx={{ bgcolor: data?.pdca === "Plan" ? grey[500] : green[600] }}
                        >
                        D
                        </Avatar>
                    </Tooltip>
                    <Divider style={{background:"grey", width:"25px"}}/>
                    <Tooltip title="CONTROL" arrow>
                        <Avatar
                        sx={{ bgcolor: data?.pdca === "Plan" || data?.pdca === "Do"  ? grey[500] : green[600] }}
                        >
                        C
                        </Avatar>
                    </Tooltip>
                    <Divider style={{background:"grey", width:"25px"}}/>
                    <Tooltip title="ACT" arrow>
                        <Avatar
                        sx={{ bgcolor: data?.pdca === "Plan" || data?.pdca === "Do" || data?.pdca === "Control" ? grey[500] : green[600] }}
                        >
                        A
                        </Avatar>
                    </Tooltip>
                </HorizantalTimelineCardWrapper>
                </HorizantalTimeLineContainer>
            </InfoBar>
            <Grid container display={"flex"} justifyContent={"space-between"} >
                <Grid items md={12} xs={12}>
                <h1>İç Şikayet Detayları</h1>
                <Table>
                <TableRow>
                  <TableCell>
                      İç Müşteri:
                  </TableCell>
                  <TableCell>
                      {data?.customer?.department_hierarchy}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Şikayet Tanımı:
                  </TableCell>
                  <TableCell>
                      {data?.heading}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Şikayet Detayları:
                  </TableCell>
                  <TableCell>
                      {data?.details}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Bölge:
                  </TableCell>
                  <TableCell>
                      {data?.region?.name + " / " + data?.section?.department_hierarchy}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Şikayet Kategorisi:
                  </TableCell>
                  <TableCell>
                      {data?.category?.map((e) =>(
                        <p>{e?.name}</p>
                      ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Yönetim sistemleri:
                  </TableCell>
                  <TableCell>
                      {data?.quality_system?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Süreç:
                  </TableCell>
                  <TableCell>
                      {data?.process?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Ürün:
                  </TableCell>
                  <TableCell>
                  {data?.product?.name}
                  </TableCell>
                </TableRow>
                {data?.files?.length > 0 ?
                <TableRow>
                  <TableCell>
                      Dosyalar:
                  </TableCell>
                  <TableCell>
                    <div style={{display:"flex", gap:"45px"}}>
                    {data?.files?.length > 0 ?
                        <>
                        <TaskImageHolder>
                        {data?.files?.map((e,index) => (
                          <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                              <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                            <FileHolder>
                              {getFileIcon(e?.file_type)}
                              <FileName>{e?.file_name}</FileName>
                            </FileHolder>
                            </a>
                          </Tooltip>
                            ))}
                        </TaskImageHolder>
                        </>
                      :
                    ""}
                    </div>
                  </TableCell>
                </TableRow>
                :""}
              </Table>
                </Grid>
            </Grid>
        </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default IccComplaintProcessDetails