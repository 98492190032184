import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import SolutionManagmentTable from './SolutionTable'

const SuggestionSystemSolutionProcess = ({user}) => {
  const [level, setLevel] = useState(false)
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
  };
  async function getInfo (){
      try{
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/solution/get/?t=${level ? "admin": "user"}`,  axiosConfig);
          setData(res.data)
      }
      catch{

      }
  }
  useEffect(() => {getInfo()},[level])
  
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        {user?.type?.includes("manager") ?
            <InfoBar>
                <FormGroup>
                    <FormControlLabel
                    FormControlLabel control={<Switch checked={level} onChange={(e) => setLevel(!level)} defaultChecked />} label="Tümünü Görüntüle" 
                    />
                </FormGroup>
            </InfoBar>
            :""}
        <SolutionManagmentTable data={data}/>
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps) (SuggestionSystemSolutionProcess)