import { InputAdornment, TextField } from '@mui/material'
import React from 'react'

const TextFieldV2 = ({size,style, value, setValue, label, type, max, min, onBlur,startAdornmentText,endAdornmentText,onFocus,name,focused,disabled, placeholder,onKeyPress}) => {
  return (
    <TextField value={value} onChange={(e) => setValue(e.target.value)}
    onKeyPress={onKeyPress}
    placeholder={placeholder}
    size={size}
    name={name}
    onBlur={onBlur}
    onFocus={onFocus}
    disabled={disabled}
    focused={focused}
    style={style}
    type={type ? type : "text"} fullWidth
        label={label}
        InputProps={{
            inputProps: { 
                max: max ? max : 100, min: min ? min : 0 
            },
            endAdornment: <InputAdornment position="end">{endAdornmentText}</InputAdornment>,
            startAdornment: (
                <InputAdornment position="start">
                  {startAdornmentText}
                </InputAdornment>
              ),
        }}
        InputLabelProps={{
            
            style:{
                color:"#A3A2A1"
        }
        }}
        sx={{
            marginBottom:"15px",
            input: {
                color: "white",
            },
            width:"100%",
            '& label.Mui-focused': { color: 'white',},
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: 'white',
                },
                '&:hover fieldset': {
                borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                borderColor: 'white',
                },}
            }}
          
        />
  )
}

export default TextFieldV2