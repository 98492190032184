import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { registerables, Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend,...registerables);
const SuggestionStats = ({data}) => {

  
  const chartData = {
    labels: data?.map((weekData) => `${weekData?.week}. hafta`),
    datasets: [
      {
        label: 'Oluşturuldu',
        backgroundColor: 'blue',
        data: data?.map((weekData) => weekData?.suggestions),
      },
      {
        label: 'Kabul Edildi',
        backgroundColor: 'green',
        data: data?.map((weekData) => weekData?.accepted),
      },
      {
        label: 'İnceleniyor',
        backgroundColor: 'orange',
        data: data?.map((weekData) => weekData?.pending),
      },
      {
        label: 'Reddedildi',
        backgroundColor: 'red',
        data: data?.map((weekData) => weekData?.rejected),
      },
    ],
  };
  const chartOptions = {
    scales: {
      x: {
        type: 'category',
        labels: chartData?.labels,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{maxWidth:"100%"}}>
      <Bar data={chartData}options={chartOptions}  />
    </div>
  );
};

export default SuggestionStats;