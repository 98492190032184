import { Box, Chip, FormControl, Grid, InputLabel, Tab, MenuItem, Select, Typography, IconButton, Divider, Button, Menu, Snackbar, Alert, TextField, InputAdornment, Tooltip, CardHeader, Switch, FormControlLabel, FormGroup } from '@mui/material'
import React, { useEffect, useState, useCallback } from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper, DatePickerContainer, DepartmentContainer, DepartmentHeadingContainer, SubDepartmentContainer, SubDepartmentTestContainer } from '../ActivitiesStyle'
import {weeks} from "./PAVSupportFiles"
import moment from "moment"
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import { connect } from 'react-redux'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import CalendarIcon from '@mui/icons-material/CalendarTodayRounded';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import DeleteWarning from './Dialogs/DeleteWarning'
import AnswerAuidCard from './Dialogs/AnswerAuidCard'
import { css } from "@emotion/react";
import EditCardInfo from './Dialogs/EditCardInfo'
import { useNavigate } from 'react-router-dom'
import SendMail from './Dialogs/SendMail'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PAVQuickEdit from './PAVQuickEdit'
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/tr'; // import Turkish locale for moment
import RequestDateChange from './Dialogs/RequestDateChange'
import RequestDeleteDialog from './Dialogs/RequestDeleteDialog'



const Audit = ({user, audits}) => {
    const [startDate, setStartDate] = useState(moment().startOf('month')); // set initial startDate to the 1st day of the current month
    const [endDate, setEndDate] = useState(moment()); // set initial endDate to the current day
    const [myAudits, setMyAudits] = useState(true)
    const handleStartDateChange = (date) => {
      setStartDate(date);
      if (endDate && date > endDate) {
        setEndDate(date);
      }
    };
  
    const handleEndDateChange = (date) => {
      setEndDate(date);
      if (startDate && date < startDate) {
        setStartDate(date);
      }
    };

    const[filter, setFilter] = useState(null)
    const[selectedYear, setSelectedYear] = useState(2023)
    const[searchFilter, setSearchFilter] = useState(null)
    const[page, setPage] = useState(0)
    const localizer = momentLocalizer(moment) // or globalizeLocalizer
    const [rdcOpen, setRdcOpen] = useState(false)
    const [reqDeleteOpen, setReqDeleteOpen] = useState(false)
    
    const [loadingMain, setLoadingMain] = useState(true)
    const [selectedWeek, setSelectedWeek] = useState(moment().format('W'))
    const [data, setData] = useState(null)
    const [dataMain, setDataMain] = useState(null)
    const [value, setValue] = useState("1")
    const handleSelectEvent = useCallback(
        (event) => handleClickOpen(event),
         []
     )
    const [openAudit, setOpenAudit] = useState(false)
    const [idfortest, setIdfortest] = useState(null)
    const handleOpenRdc = (e) => {
        setRdcOpen(true)
    }
    const handleOpenReqDelete = (e) => {
        setReqDeleteOpen(true)
    }
    const [auditFilter, setAuditFilter] = useState(null)
    var now = moment();
    const changeFilter = () => {
        setSearchFilter(filter)
    }
    const cancelFilter = () => {
        setSearchFilter(null)
        setFilter("")
    }
    async function filterForOpen  (e)  {
        
        if (auditFilter === "Open"){
            setAuditFilter(null) 
            setDataMain(data)
        }
        else{
            setDataMain(data)
            setAuditFilter(e) 
            setDataMain(dataMain.map((e) => e).filter(e => e.is_done === true))
        }
    }
    const filterForOverDue = (e) => {
        if (auditFilter === "Overdue"){
            setAuditFilter(null) 
            setDataMain(data)
        }
        else{
            setDataMain(data)
            setAuditFilter(e) 
            setDataMain(dataMain.map((e) => e).filter(e => e.is_done === false).filter(j => moment(j.date).isBefore(now)))
        }
    }



    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
      };
    useEffect(() => {
        setLoadingMain(true)

        const token = localStorage.getItem("access")
            const opts = {
                method : "GET",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "JWT " + token
                }
            }
            const url = new URL(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/audit/`)
            url.searchParams.set('start_date', `${moment(startDate).format("YYYY-MM-DD")}`);
            url.searchParams.set('end_date', `${moment(endDate).format("YYYY-MM-DD")}`);
            url.searchParams.set('is_mine', `${myAudits}`);
            if(searchFilter){
                url.searchParams.set('search', `${searchFilter}`)
            }
            fetch(`${url.href}`, opts)
            .then(response => response.json()
            .then(data => {
                setData(data)
                setDataMain(data)
                setLoadingMain(false)
            })
            .catch(
              setLoadingMain(false)

            )
            )
            
      }, [startDate, searchFilter,endDate,myAudits,searchFilter])
      function getCardsAgain (){
        setLoadingMain(true)

        const token = localStorage.getItem("access")
            const opts = {
                method : "GET",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "JWT " + token
                }
            }
            fetch(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/audit/?start_date=${moment(startDate).format("YYYY-MM-DD")}&end_date=${moment(endDate).format("YYYY-MM-DD")}&is_mine=${myAudits}`, opts)
            .then(response => response.json()
            .then(data => {
                setData(data)
                setDataMain(data)
                setLoadingMain(false)
            }))
      }
    function getAuditCards (){
        setLoadingMain(true)
        const token = localStorage.getItem("access")
        const opts = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "JWT " + token
            }
        }
        fetch(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/audit/?start_date=${moment(startDate).format("YYYY-MM-DD")}&end_date=${moment(endDate).format("YYYY-MM-DD")}&is_mine=${myAudits}`, opts)
        .then(response => response.json()
        .then(data => {
            setData(data)
            setDataMain(data)
            setLoadingMain(false)
        })
        .catch(
          setLoadingMain(false)

        )
        )
    }
    const handleChangeSelectedWeek = (event) => {
        setSelectedWeek(event.target.value);
        setFilter(null)
        setLoadingMain(true)
        const token = localStorage.getItem("access")
            const opts = {
                method : "GET",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "JWT " + token
                }
            }
            fetch(`${process.env.REACT_APP_API_URL}}/api/control/test/main/week/${event.target.value}?s=2022`, opts)
            .then(response => response.json()
            .then(data => {
                setData(data)
                setLoadingMain(false)
            }))
      };
    const handleChangeSelectedYear = (event) => {
        setSelectedYear(event.target.value);
        setFilter(null)
        setLoadingMain(true)
        const token = localStorage.getItem("access")
            const opts = {
                method : "GET",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "JWT " + token
                }
            }
            fetch(`${process.env.REACT_APP_API_URL}/api/control/test/main/week/${selectedWeek}?s=2022/`, opts)
            .then(response => response.json()
            .then(data => {
                setData(data)
                setLoadingMain(false)
            }))
      };
    const [departments, setDepartments] = useState([])
    useEffect(() => {
        const token = localStorage.getItem("access")
            const opts = {
                method : "GET",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "JWT " + token
                }
            }
            fetch(`${process.env.REACT_APP_API_URL}/api/section/get_childeren/`, opts)
            .then(response => response.json()
            .then(data => {
            setDepartments(data)
            }))
    }, [])
  const events = data?.map((i) => ({id: i.id, title: `${i.audit?.name} / ${i.subdepartment?.department?.name} /${i.subdepartment?.name}`,  "allDay": true, "start": `${i.date}`, "end": `${i.date}`, "resourceId":i.subdepartment?.department.id }) )
  const resourceMap = departments?.map((item)=> ({resourceId: item.id, resourceTitle: `${item?.name}`}))
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  
    const[email, setEmail] = useState(null)
    const[emailList, setEmailList] = useState([])
    const handleEmail = (e) => {
        console.log(e,"handle e")
        setEmail(`Merhabalar, \n\nSorumlusu olduğunuz ${e?.region?.name + " / " + e?.department?.department_hierarchy} departmanında ${e.id} nolu denetim uygunsuzlukları tamamlanmamış olarak görünüyor. İlgili aksiyonları en kısa sürede almanızı rica ederiz. \n\niyi çalışmalar.`)
        setEmailList(e?.auditer?.id)
    } 
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const[selectedTestID, setSelectedTestID] = useState(null)

  const handleCloseMenu = (e) => {
    setAnchorEl(null);
  };
  function handleDelete() {
    setAnchorEl(null);
    setDeleteWarningOpen(true)
  }
  const [questions, setQuestions] = useState(null)
  const [subdepartment, setSubDepartment] = useState(null)
  function handleClickOpen(e){
    setIdfortest(e.id)
    setSubDepartment(e.subdepartment)
    setLoading(true)
    const token = localStorage.getItem("access")
    const opts = {
        method : "GET",
        headers : {
            "Authorization" : "JWT " + token
        }
    }
    fetch(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/audit/${e.id}/`, opts)
    .then(response => response.json()
    .then(data => {
      setQuestions(data)
        setLoading(false)

    }))
    setOpenAudit(true);
};
  const[deleteWarningOpen, setDeleteWarningOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const handleCloseErrorMsg = () =>{
    setErrorMsg(false)
  }
  const [successMsg, setSuccessMsg] = useState(false)
  const handleCloseSuccessMsg = () =>{
    setSuccessMsg(false)
  }
  const [successMsgForAuditAddFinding, setSuccessMsgForAuditAddFinding] = useState(false)
  const handleCloseSuccessMsgForAuditAddFinding = () =>{
    setSuccessMsgForAuditAddFinding(false)
  }
  const [successMsgForAuditAddFindingUpdate, setSuccessMsgForAuditAddFindingUpdate] = useState(false)
  const handleCloseSuccessMsgForAuditAddFindingUpdate = () =>{
    setSuccessMsgForAuditAddFindingUpdate(false)
  }
  const [successMsgForDeleteFinding, setSuccessMsgForDeleteFinding] = useState(false)
  const handleCloseSuccessMsgForDeleteFinding = () =>{
    setSuccessMsgForDeleteFinding(false)
  }
  const [successMsgForUpdateCard, setSuccessMsgForUpdateCard] = useState(false)
  const handleCloseSuccessMsgForUpdateCard = () =>{
    setSuccessMsgForUpdateCard(false)
  }

  const color = "#0292b4"
  const [loading, setLoading] = useState(false)
  const override = css`
  align-self:"center;
    `;


    const [cardUpdateDialog, setCardUpdateDialog] = useState(false)
    const handleUpdate = ()=> {
        setCardUpdateDialog(true)
        setAnchorEl(null)
    }
    const [openEmail, setOpenEmail] = useState(false)
    const handleSendReminder = () => {
        setOpenEmail(true)
        setAnchorEl(null)

    }
    let navigate = useNavigate()
  return (
    <ActivitiesMainContainer>
        <Snackbar open={errorMsg} autoHideDuration={6000} onClose={handleCloseErrorMsg} style={{zIndex:"9999 !important"}}>
            <Alert onClose={handleCloseErrorMsg} severity="success" sx={{ width: '100%'}}>
                Bulgu puanı güncellendi.
            </Alert>
        </Snackbar>
        <Snackbar open={successMsg} autoHideDuration={6000} onClose={handleCloseSuccessMsg} style={{zIndex:"9999 !important"}}>
            <Alert onClose={handleCloseSuccessMsg} severity="success" sx={{ width: '100%'}}>
                Yeni bulgu eklendi.
            </Alert>
        </Snackbar>
        <Snackbar open={successMsgForAuditAddFinding} autoHideDuration={6000} onClose={handleCloseSuccessMsgForAuditAddFinding} style={{zIndex:"9999 !important"}}>
            <Alert onClose={handleCloseSuccessMsgForAuditAddFinding} severity="success" sx={{ width: '100%'}}>
                Denetim sorusuna bulgu eklendi. 
            </Alert>
        </Snackbar>
        <Snackbar open={successMsgForAuditAddFindingUpdate} autoHideDuration={6000} onClose={handleCloseSuccessMsgForAuditAddFindingUpdate} style={{zIndex:"9999 !important"}}>
            <Alert onClose={handleCloseSuccessMsgForAuditAddFindingUpdate} severity="success" sx={{ width: '100%'}}>
                Bulgu güncellendi.
            </Alert>
        </Snackbar>
        <Snackbar open={successMsgForDeleteFinding} autoHideDuration={6000} onClose={handleCloseSuccessMsgForDeleteFinding} style={{zIndex:"9999 !important"}}>
            <Alert onClose={handleCloseSuccessMsgForDeleteFinding} severity="warning" sx={{ width: '100%'}}>
                Bulgu silindi.
            </Alert>
        </Snackbar>
        <Snackbar open={successMsgForUpdateCard} autoHideDuration={6000} onClose={handleCloseSuccessMsgForUpdateCard} style={{zIndex:"9999 !important"}}>
            <Alert onClose={handleCloseSuccessMsgForUpdateCard} severity="warning" sx={{ width: '100%'}}>
                Denetim bilgileri güncellendi.
            </Alert>
        </Snackbar>
        <ActivitiesMainWrapper>
            <DatePickerContainer>
                <div className="date-range-picker" style={{display:"flex", gap:"15px"}}>
                {/* <FormControl 
                    sx={{
                    width:"150px",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                >
                <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Yıl</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedYear}
                    label="Yıl"
                    style={{color:"white", height:"40px"}}
                    onChange={handleChangeSelectedYear}
                    >
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    </Select>
                </FormControl>
                <FormControl 
                    sx={{
                    width:"150px",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                >
                <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Hafta</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedWeek}
                    label="Hafta"
                    style={{color:"white", height:"40px"}}
                    onChange={handleChangeSelectedWeek}
                    >
                    {weeks.map((w) => (
                    <MenuItem value={w.id}>{w.id}</MenuItem>
                    ))}
                    </Select>
                </FormControl> */}
                <div>
                        <label htmlFor="start-date-picker">Başlangıç Tarihi</label>
                        <DatePicker
                        id="start-date-picker"
                        selected={startDate.toDate()}
                        onChange={date => handleStartDateChange(moment(date))}
                        selectsStart
                        startDate={startDate.toDate()}
                        endDate={endDate.toDate()}
                        placeholderText="Başlangıç Tarihi"
                        dateFormat="dd/MM/yyyy" // set dateFormat to "DD/MM/YYYY"
                        />
                    </div>
                    <div>
                        <label htmlFor="end-date-picker">Bitiş Tarihi</label>
                        <DatePicker
                        id="end-date-picker"
                        selected={endDate.toDate()}
                        onChange={date => handleEndDateChange(moment(date))}
                        selectsEnd
                        startDate={startDate.toDate()}
                        endDate={endDate.toDate()}
                        minDate={startDate.toDate()}
                        placeholderText="Bitiş Tarihi"
                        dateFormat="dd/MM/yyyy" // set dateFormat to "DD/MM/YYYY"
                        />
                    </div>
                </div>
                <Box>
                    <Grid container item gap={1}  xs={12} sm={12} md={12} align="center" style={{justifyContent: "center"}}>
                        <Chip style={auditFilter !== "Open" ? {background:"#a7a7a8", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} : {border: "2px solid black" ,background:"#a7a7a8", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"}} label="Açık Görevler var" onClick={() => filterForOpen("Open")} />
                        <Chip style={auditFilter !== "Overdue" ? {background:"#f06a6a", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} : {border: "2px solid black" ,background:"#f06a6a", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} } label="Vakti geçmiş denetim" onClick={() => filterForOverDue("Overdue")}/>
                        <Chip style={auditFilter !== "Today" ?  {background:"#f1bd6c", color:"#000",boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} : {border: "2px solid black" ,background:"#f1bd6c", color:"#000",boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} } label="Bugün olan denetim"  onClick={() => setAuditFilter("Today")}/>
                        <Chip style={auditFilter !== "Done" ? {background:"#aecf55", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} : {border: "2px solid black" ,background:"#aecf55", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} } label="Tamamlanan denetim" onClick={() => setAuditFilter("Done")}/>
                        <Chip style={auditFilter !== "Coming" ? {background:"white", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"} : {border: "2px solid black" ,background:"white", color:"#000", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px", width:"150px"}} label="Gelecek denetim" onClick={() => setAuditFilter("Coming")}/>
                    </Grid>
                </Box>
            </DatePickerContainer>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                <Box>
                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example" style={{color:"white"} } TabIndicatorProps={{ style: { background: "#A3A2A1", fontWeight:800 } }} >
                    <Tab label="Kart Görünümü" value="1"  style={{color:"white"}} />
                    {/* <Tab label="Takvim Görünümü" value="2"  style={{color:"white"}} /> */}
                    {user?.type?.includes("manager") ? 
                    <Tab label="Hızlı Düzenleme" value="3"  style={{color:"white"}} />
                    :
                    ""
                    }
                    </TabList>
                </Box>
                <TabPanel value="1">
                <Grid container spacing={3} mt={1}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="space-between" alignItems={"center"} style={{display:"flex", paddding:"0px"}}>
                        <Grid item>
                            {user?.type?.includes("manager")
                            ?
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={!myAudits} onChange={() => setMyAudits(!myAudits)} defaultChecked />} label="Sorumlusu Olduğum Sahalardaki Denetimleri Göster" />
                            </FormGroup>
                            :
                            <FormGroup>
                            </FormGroup>
                            }
                        </Grid>
                        <Grid item>
                        <Tooltip title="Denetçi ismi, birim ismi ve alt birim ismi ile filtreleme yapabilirsiniz." arrow>
                            <TextField size="small" value={filter} onChange={(e) => setFilter(e.target.value)} color="warning" 
                                placeholder='Filtrele'
                                InputProps={{
                                startAdornment: <InputAdornment position="start"><FilterAltIcon style={{color:"white"}}/></InputAdornment>,
                                }}
                                sx={{
                                    
                                    input: {
                                        color: "white",
                                    },
                                   
                                    '& label.Mui-focused': { color: 'white',},
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                        borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                        borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                        },}
                                    }}
                                />
                        </Tooltip>
                            {filter !== null & filter === searchFilter ?
                            <Tooltip title="Bu butona basarak yaptığınız filtrelemeyi iptal edebilirsiniz." arrow>
                                <Button color="error" variant="outlined" onClick={cancelFilter}>İptal Et</Button>
                            </Tooltip>
                            :
                            <Tooltip title="Filtreleme bilgisini doldurduktan sonra bu butona basarak filtreleme yapabilirsiniz." arrow>
                                <Button color="warning" variant="outlined" onClick={changeFilter}>Filtrele</Button>
                            </Tooltip>
                            }
                        </Grid>

                    </Grid>
                    </Grid> 
                    {loadingMain ?
                    <Grid container spacing={3} mt={1}>
                         {Array.from(new Array(4)).map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <Card sx={{ maxWidth: 345, height:450, m: 2 }}>
                                <CardHeader 
                                avatar={
                                <Skeleton animation="wave" variant="circular" width={40} height={40} />
                                } 
                                title={
                                    <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="80%"
                                    style={{ marginBottom: 6 }}
                                    />
                                }
                                />
                                <Skeleton sx={{ height: 300,mb:2 }} animation="wave" variant="rectangular" />
                                <Skeleton sx={{ height: 50 }} animation="wave" variant="rectangular" />
                            </Card>
                        </Grid>
                        ))}
                    </Grid>
                  
                    :
                    <Grid container spacing={3} mt={1}>
                        
                        {dataMain ? dataMain.map((e) => (  
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                        <DepartmentContainer style={
                            e.is_done 
                            ? 
                                parseFloat(e.plan * 100).toFixed(2) < 99
                                ?
                                {background:"#a7a7a8", color:"#000"}
                                :
                                {background:"#aecf55", color:"#000"} 
                            : 
                            moment(e.due_date).isBefore(moment().format("YYYY-MM-DD")) 
                                ? 
                                {background:"#f06a6a", color:"#000"} 
                                :  
                                moment(e.due_date).isSame(moment().format("YYYY-MM-DD"))
                                ? 
                                {background:"#F1BD6C", color:"#000"}
                                : 
                                {background:"#ffff", color:"#000"}}>
                            <DepartmentHeadingContainer>
                            <div>
                                % {parseFloat(e.plan * 100).toFixed(2)}
                                &nbsp;
                            </div>
                            <Typography variant="h6" style={{fontWeight:600}}>Denetim Kartı</Typography>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={openMenu ? 'long-menu' : undefined}
                                aria-expanded={openMenu ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) =>{ handleClick(event); setSelectedTestID(e.id); handleEmail(e)}}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            </DepartmentHeadingContainer>
                            <SubDepartmentContainer>
                            <Typography variant="h6" style={{fontSize:"18px"}}> <b>Denetim Türü: </b>   </Typography>{e?.audit_type?.name}
                            <Divider/>
                            <Typography variant="h6" style={{fontSize:"18px"}}> <b>Bölge: </b>   </Typography>{e?.region?.name}
                            <Divider/>
                            <Typography variant="h6" style={{fontSize:"18px"}}> <b>Departman:</b>  </Typography>   {e?.department?.department_hierarchy} 
                            <Divider/>
                            <Typography variant="h6" style={{fontSize:"18px"}}> <b>Denetçi İsmi:</b> </Typography>{e?.auditer ? e?.auditer?.name : "Bu Kullanıcı hesabı silinmiştir lütfen yeni bir denetçi atayın"} {e?.auditer?.surname}
                            <Divider/>
                            <Typography variant="h6" style={{fontSize:"18px"}}> <b>Denetim Tarihi:</b> </Typography>{e?.due_date}
                            <Divider/>
                            <Typography variant="h6" style={{fontSize:"18px"}}> <b>Denetim Haftası:</b> </Typography>{moment(e?.due_date).format("w")-1}
                            <Divider/>
                            <SubDepartmentTestContainer>
                            <br/>
                                {e.is_done ? <Button variant="contained" style={{backgroundColor:"#726ab9", color:"#fff", borderRadius:"10px"}} onClick={() => navigate(`/app/audit-app/activities/${e.id}`)}>Denetİm Aksİyonunu Takİp Et</Button> : <Button variant="contained" onClick={() => handleClickOpen(e)} style={{backgroundColor:"rgb(30 31 33)", color:"#fff", borderRadius:"10px"}}>Denetim Sorularını Cevapla</Button>}
                                <Menu
                                id="long-menu"
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                PaperProps={{
                                style: {
                                    width: '25ch',
                                },
                                }}

                            >

                            {user?.type?.includes("manager") ?
                            <div>
                            <MenuItem onClick={handleUpdate}>
                            <EditIcon />
                            Düzenle
                            </MenuItem>
                            {dataMain?.filter(e => e.id === selectedTestID)[0]?.auditer?.id === user?.id
                            ?
                            "":
                            <MenuItem onClick={handleSendReminder}>
                            <EmailIcon/>
                            Mesaj Gönder
                            </MenuItem>
                            }

                            <MenuItem onClick={handleDelete}>
                            <DeleteIcon />
                            Sil
                            </MenuItem>
                            </div>
                            : 
                            <div>
                            {dataMain?.filter(e => e.id === selectedTestID)[0]?.is_date_change_requested
                            ?
                            <MenuItem disabled>
                            <CalendarIcon />
                            Talebiniz İletildi
                            </MenuItem>
                            :
                            <MenuItem onClick={handleOpenRdc}>
                            <CalendarIcon />
                            Tarih Değişikliği Talebi
                            </MenuItem>
                            }
                            {
                            dataMain?.filter(e => e.id === selectedTestID)[0]?.is_delete_requested
                            ?
                            <MenuItem disabled>
                            <DeleteIcon />
                            Silme Talebiniz İletildi
                            </MenuItem>
                            :
                            <MenuItem onClick={handleOpenReqDelete}>
                            <DeleteIcon />
                            Silme Talebi
                            </MenuItem>
                            }
                            </div>
                            }
                            </Menu>   
                            </SubDepartmentTestContainer>
                            </SubDepartmentContainer>
                        </DepartmentContainer>
                        </Grid>
                        
                        )) : ""}
                    </Grid>
                    }
                </TabPanel>
                <TabPanel value="2">
                    {/* <div style={{height:"100vh", position:"relative",width:"100%", display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Calendar
                        localizer={localizer}
                        defaultView={Views.DAY}
                        onSelectEvent={handleSelectEvent}
                        events={events}
                        resourceIdAccessor="resourceId"
                        resources={resourceMap}
                        resourceTitleAccessor="resourceTitle"
                        step={60}
                    />
                    </div> */}
                </TabPanel>
                <TabPanel value="3">
                    <div style={{height:"60vh"}}>
                        <PAVQuickEdit data={dataMain}
                        setSelectedTestID={setSelectedTestID}
                        setOpenEdit = {setCardUpdateDialog}
                        setOpenDelete={setDeleteWarningOpen}
                        />
                    </div>
                </TabPanel>
                </TabContext>
            </Box>
        </ActivitiesMainWrapper>
        <DeleteWarning open={deleteWarningOpen} setOpen={setDeleteWarningOpen} cardId={selectedTestID} getAuditCards={getAuditCards}
                getCardsAgain={getCardsAgain}

        />
        <AnswerAuidCard 
        audits={audits}
        open={openAudit}  
        setOpen={setOpenAudit} 
        questions={questions} 
        subdepartment={subdepartment} 
        idfortest={idfortest} 
        getAuditCards={getAuditCards} 
        setQuestions={setQuestions}
        setErrorMsg={setErrorMsg} 
        setSuccessMsg={setSuccessMsg}
        setSuccessMsgForAuditAddFinding={setSuccessMsgForAuditAddFinding}
        setSuccessMsgForAuditAddFindingUpdate={setSuccessMsgForAuditAddFindingUpdate}
        setSuccessMsgForDeleteFinding={setSuccessMsgForDeleteFinding}
        color={color}
        override={override}
        loading={loading}
        />
        <EditCardInfo
        getCardsAgain={getCardsAgain}
        audits={audits}
        open={cardUpdateDialog}
        setOpen={setCardUpdateDialog}
        getAuditCards={getAuditCards}
        data={data}
        cardId={selectedTestID}
        setSuccessMsgForUpdateCard={setSuccessMsgForUpdateCard}
        />
        <SendMail
        open={openEmail}
        email={email}
        emailTo={emailList}
        setEmail={setEmail}
        setOpen={setOpenEmail}
        cardId={selectedTestID}
        getAuditCards={getAuditCards}/>
        <RequestDateChange
        open={rdcOpen}
        setOpen={setRdcOpen}
        audit_id={selectedTestID}
        getInfoBack={getAuditCards}
        />
        <RequestDeleteDialog
        open={reqDeleteOpen}
        setOpen={setReqDeleteOpen}
        audit_id={selectedTestID}
        getInfoBack={getAuditCards}
        />
    </ActivitiesMainContainer>
  )
}

const mapStateToProps = state => ({   
    user: state.auth.user
  })
  export default connect(mapStateToProps)(Audit)