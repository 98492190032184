import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Switch, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { green, pink, yellow } from '@mui/material/colors';
import { Box } from '@mui/system';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import * as markerjs2 from 'markerjs2';
import { styled as styledmui} from '@mui/material/styles'
import axios from 'axios';
import {FaCamera, FaPen, FaTrash} from "react-icons/fa"
import PuffLoader from 'react-spinners/PuffLoader';
import TextFieldV2 from '../../../../../Assets/TextFieldV2';
import DialogTitleV2 from '../../../../../Assets/DialogTitle';
import AutoCompleteV2 from '../../../../../Assets/AutoCompleteV2';

const AnswerAuidCard = ({open, color, override, loading, setSuccessMsgForDeleteFinding, setSuccessMsgForAuditAddFindingUpdate, setSuccessMsg, setOpen, questions, subdepartment, setErrorMsg, idfortest, getAuditCards, setQuestions, setSuccessMsgForAuditAddFinding}) => {
  const [employees, setEmployees] = useState([])
  const [currentRegion, setCurrentRegion] = useState(null)
  const [currentDepartment, setCurrentDepartment] = useState(null)
  const [level, setLevel] = useState("department")
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
};
  async function getEmployees(){
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/get_by_level/?level=${level}&level_id=${level === "department" ? currentDepartment : currentRegion}`,  axiosConfig);
      setEmployees(res.data)
    }
    catch{

    }
  }
  useEffect(()=>{
    if(open){
      getEmployees()
    }
  },[open,level,currentDepartment,currentRegion])
  
  const [selectedQuestionType, setSelectedQuestionType]  = useState(null)
  const [selectedPassingCriteria, setSElectedPassingCriteria] = useState(null)

  const StyledTableCell = styledmui(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: "#fff"

        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          color: "#fff"

        },
      }));
    const StyledTableRow = styledmui(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
          color: "#fff"
        
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
    const [newFindingToAudit, setNeWFindingToAudit] = useState(false)
    const [updateFindingId, setUpdateFindingId] = useState(null)
    const handleSetUpdateId = (e) =>{
        console.log(e)
        setUpdateFindingId(e.id)
        setSingleAudit(e?.assignee)
        setDate(e?.due_date)
        setComment(e?.task)
        setImgData(e?.before_image_path?.path)
      } 
      const[findingId, setFindingId] = useState(null)
      const[findingDeleteOpen, setFindingDeleteOpen] = useState(false)
      const handleDeleteFindingClose = () => {
        setFindingId(null)
        setFindingDeleteOpen(false)
      }
    const handleDeleteFindingOpen = (e) => {
        setFindingId(e)
        setFindingDeleteOpen(true)
    }
    const handleOpenNewFindingTab = (e) => {
        setNeWFindingToAudit(true)
        setQuestionRating(e.rating)
    }


    const [editRating, setEditRating] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const [questionRating, setQuestionRating] = useState(1)
    const handleQuestionRating = (e) => {
        setQuestionRating(e.target.value)
    }
    const [comment, setComment] = useState(null)
    const [singleAudit, setSingleAudit] = useState(null)

    const handleChangeSingleAudit = (event) => {
        setSingleAudit(event.target.value)
      }
    
    const [date, setDate] = useState(moment().add(7, "days").format("YYYY-MM-DD"))
    const [question, setQuestion] = useState(null)
    const [questionText, setQuestionText] = useState(null)
    const [questionId, setQuestionId] = useState(null)
    const [newFinding, setNewFinding] = useState(false)
    const [fivesStep, setFivesStep] = useState(null)
    const handleQuestionIdArrange = (e) => {
        console.log(e)
        setSelectedQuestionType(e?.audit_question?.question_type)
        setSElectedPassingCriteria(e?.audit_question?.passing_criteria?.id)
        setQuestion(e?.audit_question?.id)
        setQuestionText(e?.audit_question?.question_text)
        setFivesStep(e?.audit_question?.category)
        setQuestionId(e?.id)
        setCurrentRegion(e?.audit?.region)
        setCurrentDepartment(e?.audit?.department)
        setNewFinding(true)

    }

    const handleQuestionAnswerIdArrange = (e) => {
        setQuestion(e?.audit_question?.id)
        setQuestionId(e?.id)
        setCurrentRegion(e?.audit?.region)
        setCurrentDepartment(e?.audit?.department)
        setQuestionText(e?.audit_question?.question_text)
        setFivesStep(e?.audit_question?.FiveSType)
      }
    const handleClose = () => {
        setOpen(false)
        setQuestions(null)
        setQuestionId(null)
        setQuestion(null)
        setNewFinding(false)
    }
    const handleCloseQuestion = () => {
        setQuestion(null)
        setQuestionId(null)
        setNewFinding(false)

    }
    console.log(questionId,"qid")
    const Input = styledmui('input')({
        display: 'none',
    });
    const [product_image_path, setProductImagePath] = useState(null)
    const [imgData, setImgData] = useState(null);
    const onChangePicture = e => {
      if (e.target.files[0]) {
        setProductImagePath(e.target.files[0])
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    };
    let imgRef = React.createRef()
    function showMarkerArea() {
        if (imgRef.current !== null) {
          // create a marker.js MarkerArea
          const markerArea = new markerjs2.MarkerArea(imgRef.current);
          // attach an event handler to assign annotated image back to our image element
          markerArea.addEventListener("render", (event) => {
            if (imgRef.current) {
              imgRef.current.src = event.dataUrl;
              fetch(event.dataUrl)
              .then(res => res.blob())
              .then(blob2 => setProductImagePath(blob2))
            }
          });
          // launch marker.js
          markerArea.settings.defaultColor = questionRating < 3 ? 'red' : 'green' ;
          markerArea.show();
        }
      }
    async function handleSubmitCompleteTest(){
        const config = {
            method : "PATCH",
              headers: {
                  'Authorization': `JWT ${localStorage.getItem('access')}`,
                  "Content-Type": "Application/json"
              }
          };
          const body = JSON.stringify({complete_the_audit : true})
          setShowButton(true)
          try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/update-delete/${idfortest}/`, body, config);
            getAuditCards();
            setShowButton(false)
            setOpen(false)
          } catch (err) {
            console.log(err)
            setShowButton(false)
            setOpen(false)

          }
        } 
    async function handleSubmitAddFinding() {
        let form_data = new FormData();
        form_data.append('new_finding', true)
        product_image_path ? form_data.append('question_image_path', product_image_path,  "foraction.jpg" )  :form_data.append('test', "null") 
        comment === null ?  form_data.append('task', "") :  form_data.append('task', comment)
        questionRating === null ?  form_data.append('rating', null) :  form_data.append('rating', questionRating)
        date === null ?  console.log() :  form_data.append('re_control_date', date)
        singleAudit === null ?  form_data.append('responsible_by', null) :  form_data.append('responsible_by', singleAudit?.id) 
        currentDepartment === null ?  form_data.append('section_id', null) :  form_data.append('section_id', currentDepartment) 
        currentRegion === null ?  form_data.append('region_id', null) :  form_data.append('region_id', currentRegion) 
        questionText === null ? form_data.append("question_text", null) : form_data.append("question_text", questionText)
        selectedQuestionType === null ? form_data.append("question_type", null) : form_data.append("question_type", selectedQuestionType)
        selectedPassingCriteria === null ? form_data.append("passing_criteria", null) : form_data.append("passing_criteria", selectedPassingCriteria)
        form_data.append('fivesstep', fivesStep) 

        const token = localStorage.getItem("access")
        const opts = {
            method : "GET",
            headers : {
                "Authorization" : "JWT " + token
            }
        }
        const config = {
          method : "PATCH",
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };
    
        const body = form_data
        setShowButton(true)
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/question/update/${questionId}/`, body, config);
            const res2 = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/audit/${idfortest}/`, opts)
            setQuestions(res2.data)
            setQuestionRating(1)
            setDate(moment().add(7, "days").format("YYYY-MM-DD"))
            setComment(null)
            setProductImagePath(null)
            setSingleAudit(null)
            setImgData(null)
            setQuestion(null)
            setShowButton(false)
            setNewFinding(false)
            setSuccessMsgForAuditAddFinding(true)

            //navigate(`/api/control/main/`) 
           
        } catch (err) {
          console.log(err.response.data)
          window.scrollTo( 0, 0)
          setQuestion(null)
          setQuestionRating(null)
          setDate(moment().add(7, "days").format("YYYY-MM-DD"))
          setComment(null)
          setProductImagePath(null)
          setSingleAudit(null)
          setImgData(null)
            setNewFinding(false)
            setShowButton(false)
        }
    }
    const handleGoBack = () => {
        setQuestion(null)
        setProductImagePath(null)
        setImgData(null)
    };
    async function handleUpdateFindingScore (e) {
        
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({rating: questionRating})
        setShowButton(true)
        try {
          await axios.patch(`${process.env.REACT_APP_API_URL}/api/control/edit-findings-score/${e}/`, body, config);
          const res2 = await axios.get(`${process.env.REACT_APP_API_URL}/api/control/test/${idfortest}/`, config)
          setQuestions(res2.data)
          setUpdateFindingId(null)
          setShowButton(false)
          setErrorMsg(true)
          setEditRating(false)
        }
        catch (err) {
          console.log("test")
          setShowButton(false)

        }
      }
      async function handleSubmitQuestionAnswer (e){
        let form_data = new FormData();
        form_data.append('new_finding', true)
        product_image_path ? form_data.append('question_image_path', product_image_path,  "foraction.jpg" )  :form_data.append('test', "null") 
        comment === null ?  form_data.append('task', "") :  form_data.append('task', comment)
        questionRating === null ?  form_data.append('rating', null) :  form_data.append('rating', questionRating)
        date === null ?  console.log() :  form_data.append('re_control_date', date)
        singleAudit === null ?  form_data.append('responsible_by', null) :  form_data.append('responsible_by', singleAudit?.id) 
        currentDepartment === null ?  form_data.append('section_id', null) :  form_data.append('section_id', currentDepartment) 
        currentRegion === null ?  form_data.append('region_id', null) :  form_data.append('region_id', currentRegion) 
        questionText === null ? form_data.append("question_text", null) : form_data.append("question_text", questionText)
        form_data.append('fivesstep', fivesStep) 
        const token = localStorage.getItem("access")
        const opts = {
            method : "GET",
            headers : {
                "Authorization" : "JWT " + token
            }
        }
        const config = {
          method : "PATCH",
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };
    
        const body = form_data
        setShowButton(true)
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/taskmanagment/create-audit-task/${questionId}/`, body, config);
            const res2 = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/audit/${idfortest}/`, opts)
            setQuestions(res2.data)
            setNeWFindingToAudit(false)
            setQuestionRating(1)
            setDate(moment().add(7, "days").format("YYYY-MM-DD"))
            setComment(null)
            setProductImagePath(null)
            setSingleAudit(null)
            setImgData(null)
            setQuestion(null)
            setSuccessMsg(true)
            setShowButton(false)

            //navigate(`/api/control/main/`) 
           
        } catch (err) {
          console.log(err.response.data)
          window.scrollTo( 0, 0)
          setQuestion(null)
          setQuestionRating(null)
          setDate(moment().add(7, "days").format("YYYY-MM-DD"))
          setComment(null)
          setProductImagePath(null)
          setSingleAudit(null)
          setImgData(null)
          setShowButton(false)

        }
    }
    async function handleUpdateFinding () {
        let form_data = new FormData();
        form_data.append('new_finding', true)
        product_image_path ? form_data.append('finding_image_path', product_image_path,  "foraction.jpg" )  :form_data.append('test', "null") 
        comment === null ?  form_data.append('comment', "") :  form_data.append('comment', comment)
        date === null ?  console.log() :  form_data.append('re_control_date', date)
        singleAudit === null ?  form_data.append('responsible_by', null) :  form_data.append('responsible_by', singleAudit?.id) 
        subdepartment === null ?  form_data.append('subdepartment', null) :  form_data.append('subdepartment', subdepartment?.id) 
        
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
          }
        };
        const body = form_data
        setShowButton(true)
        try {
          await axios.patch(`${process.env.REACT_APP_API_URL}/api/taskmanagment/update-audit-task/${updateFindingId}/`, body, config);
          const res2 = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/audit/${idfortest}/`, config)
          setQuestions(res2.data)
          setUpdateFindingId(null)
          setShowButton(false)
          setSuccessMsgForAuditAddFindingUpdate(true)
    
        }
        catch (err) {
          setShowButton(false)
        }
      }
      async function handleDeleteFinding () {
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        setShowButton(true)
        try {
          await axios.delete(`${process.env.REACT_APP_API_URL}/api/taskmanagment/update-audit-task/${findingId}/`, config);
          const res2 = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/get/audit/${idfortest}/`, config)
          setQuestions(res2.data)
          setFindingDeleteOpen(false)
          setShowButton(false)
          setSuccessMsgForDeleteFinding(true)
        }
        catch (err) {
        setShowButton(false)
        console.log("test")
        }
      }
  return ( 
    question === null ?
    <React.Fragment>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{
                "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here,
                    background:"#1e1f21",
                    color:"#fff",
                    marginBottom:"50px"
                },
                },
            }}
        >
            <DialogTitleV2 handleCloseDialog={handleClose} title={"Denetim Soruları"}/>
            <Divider sx={{backgroundColor:"#A2A1A0"}}/>
            <DialogContent>
                <PuffLoader color={color} loading={loading} css={override} size={50}/>
                {questions?.map((row) => (
                <React.Fragment key={row?.id}>
                    <Grid container mb={2} mt={2} align="center" >
                        <Grid container item xs={12} mb={2}  align="center" style={{ justifyContent: "center"}}>
                            <Box style={{maxWidth:"80%"}}>
                                {row?.audit_question?.question_text}
                            </Box>
                        </Grid>
                        <Grid item xs={6}><Chip color={row?.is_done === false ? "warning" : !row?.pass_fail ? "error" : "success"} label={row?.is_done === false? "Henüz cevaplanmadı" :!row?.pass_fail ? "Uygunsuz" : "Uygun"}/></Grid>
                        <Grid item xs={6}>{row?.is_done === false ? <Button variant="contained" onClick={() => handleQuestionIdArrange(row)}> Aksiyon Ekle </Button> : <Button variant="contained" color="info" onClick={() => handleQuestionAnswerIdArrange(row)}> Bulguları Görüntüle</Button>}</Grid>
                    </Grid>
                    <Divider sx={{backgroundColor:"#A2A1A0"}}/>
                </React.Fragment>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Kapat</Button>
                <Button onClick={handleSubmitCompleteTest} variant="outlined" color="success">Denetimi Tamamla</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
    :  /*Tüm sorular görüntüleme burada bitiyor*/ 
    newFinding ?
    <React.Fragment>
     <Dialog
         open={open}
         onClose={handleClose}
         aria-describedby="alert-dialog-slide-description"
         sx={{
             "& .MuiDialog-container": {
             "& .MuiPaper-root": {
                 width: "100%",
                 maxWidth: "500px",  // Set your width here,
                 background:"#1e1f21",
                 color:"#fff",
                 marginBottom:"50px"
             },
             },
         }}
     >
        <DialogTitle>
        {questions.filter((row => row?.audit_question?.id === question)).map((e) => (
            <Grid container>
                <Grid item xs={6}>
                    <Button onClick={handleCloseQuestion} variant="contained">Geri</Button>
                </Grid>
            </Grid>
        ))}
        </DialogTitle>
         <Divider sx={{backgroundColor:"#A2A1A0"}}/>
         {questions.filter((row => row?.audit_question?.id === question)).map((e) => (
         <DialogContent>
            <Grid item container spacing={2} xs={12} >
              {e?.audit_question?.question_type === "NUM"  ?
                parseInt(e?.audit_question?.passing_criteria?.numeric_passing_criteria?.max_value) ===5 ?
                    <Grid item  xs={12}>
                        <FormControl style={{marginTop:10}}>
                        <FormLabel id="demo-row-radio-buttons-group-label"  style={{color:"#fff"}}>Soruyu Puanla </FormLabel>
                        <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={questionRating}
                        onChange={handleQuestionRating}
                        >
                        <FormControlLabel value="1" control={<Radio  sx={{color: pink[800],'&.Mui-checked': {color: pink[600],},}} />} label="1" />
                        <FormControlLabel value="2" control={<Radio  sx={{color: pink[300],'&.Mui-checked': {color: pink[200],},}} />} label="2" />
                        <FormControlLabel value="3" control={<Radio  sx={{color: green[300],'&.Mui-checked': {color: green[200],},}} />} label="3" />
                        <FormControlLabel value="4" control={<Radio  sx={{color: green[500],'&.Mui-checked': {color: green[600],},}} />} label="4" />
                        <FormControlLabel value="5" control={<Radio  sx={{color: green[800],'&.Mui-checked': {color: green[600],},}} />} label="5" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                :
                <Grid item  xs={12}>
                    <FormControl 
                      sx={{
                          width:"100%",
                          color:"#fff",
                          '& label.Mui-focused': { color: 'white',},
                          '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'white',
                              },
                              '&:hover fieldset': {
                                borderColor: 'white',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'white',
                              },}
                          }}
                      >
                          <InputLabel id="demo-simple-select-label"  sx={{color:"#fff"}}>Soruyu Puanla</InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={questionRating}
                              label="Soruyu Puanla"
                              onChange={handleQuestionRating}
                              sx={{color:"#fff"}}
                          >
                              {
                                Array.from(Array(parseInt(e?.audit_question?.passing_criteria?.numeric_passing_criteria?.max_value)+1), (e, i) => 
                                <MenuItem value={i} key={i}>{i}</MenuItem>
                                )
                              }
                          </Select>
                      </FormControl>
                </Grid>
                :
                <Grid item  xs={12}>
                  <FormControl style={{marginTop:10}}>
                    <FormLabel id="demo-row-radio-buttons-group-label"  style={{color:"#fff"}}>Soruyu Puanla </FormLabel>
                      <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={questionRating}
                      onChange={handleQuestionRating}
                      >
                        <FormControlLabel value="0" control={<Radio  sx={{color: pink[800],'&.Mui-checked': {color: pink[600],},}} />} label="KALIR" />
                        <FormControlLabel value="NA" control={<Radio  sx={{color: yellow[300],'&.Mui-checked': {color: yellow[200],},}} />} label="N/A" />
                        <FormControlLabel value="1" control={<Radio  sx={{color: green[800],'&.Mui-checked': {color: green[600],},}} />} label="GEÇER" />
                      </RadioGroup>
                  </FormControl>
                </Grid>
                }
            </Grid>
            <Divider sx={{backgroundColor:"#A2A1A0"}}/>
            <Grid item xs={12} mt={2} mb={2}>     
              <TextFieldV2 value={comment} setValue={setComment} label={"Lütfen bulgularınızı giriniz"}/>
            </Grid>
            <Divider sx={{backgroundColor:"#A2A1A0"}}/>
            {
            e?.audit_question?.question_type === "NUM" ?
            questionRating < e?.audit_question?.passing_criteria?.numeric_passing_criteria?.fail_value
            ?
            <>
            <Grid item xs={12} mt={2} mb={2}>
            <FormGroup>
              <FormControlLabel
              FormControlLabel control={<Switch checked={level === "department"} onChange={() => setLevel(level === "department" ? "region" : "department")} defaultChecked />} label="Denetim Departmanı ile Filtrele" 
              />
            </FormGroup>
            <AutoCompleteV2 label="Sorumlu Seç" options={employees} value={singleAudit} setValue={setSingleAudit} user/>
            </Grid>
            <Divider sx={{backgroundColor:"#A2A1A0"}}/>
            <Grid item xs={12} mt={2} mb={2}>     
            <TextFieldV2 value={date} setValue={setDate} label={"Termin Tarihi"}  type={"date"}/>
            </Grid> 
            </>
            :
            ""
            :
            e?.audit_question?.question_type === "TF" ?
            parseInt(questionRating) === 0 ?
            <>
            <Grid item xs={12} mt={2} mb={2}>
           <AutoCompleteV2 label="Sorumlu Seç" options={employees} value={singleAudit} setValue={setSingleAudit} user/>
            </Grid>
            <Divider sx={{backgroundColor:"#A2A1A0"}}/>
            <Grid item xs={12} mt={2} mb={2}>     
              <TextFieldV2 value={date} setValue={setDate} label={"Termin Tarihi"}  type={"date"}/>
            </Grid> 
            </>
            :
            ""
            :
            ""
            }
            <Divider sx={{backgroundColor:"#A2A1A0"}}/>
                <Grid item xs={12} mt={2} mb={2} align="right">     
                    <label htmlFor="contained-button-file">
                    <Input accept="image/*" id="contained-button-file"  type="file" onChange={onChangePicture} />
                    <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={product_image_path === null ? "info" : "warning" }>
                        {product_image_path === null ? <> Fotoğraf Çek veya Ekle &nbsp; <CameraEnhanceIcon/> </> : <> Düzenlemek İçin Fotoğrafa Tıkla &nbsp; <CameraEnhanceIcon/> </>}
                    </Button>
                    </label>
            </Grid>   
            <Grid item xs={12}>     
                {imgData ? 
                <img ref={imgRef} 
                src={imgData}
                id="auditimg"
                alt="sample" 
                crossOrigin="anonymous"
                style={{ maxWidth: '100%' }} 
                onClick={() => showMarkerArea()}
                /> 
            : null}
                </Grid>   
         </DialogContent>
         ))}
         <DialogActions>
             <Button onClick={handleClose}>Kapat</Button>
             {!showButton ? 
             <Button onClick={handleSubmitAddFinding} variant="outlined" color="success">Soruyu Cevapla</Button> 
             :
             <Button variant="outlined" color="warning">Soruyu Cevaplanıyor...</Button>}
         </DialogActions>
     </Dialog>
    </React.Fragment>
    : /* this ads the question */
    newFindingToAudit ?
    <React.Fragment> 
    <Dialog
         open={open}
         onClose={handleClose}
         aria-describedby="alert-dialog-slide-description"
         sx={{
             "& .MuiDialog-container": {
             "& .MuiPaper-root": {
                 width: "100%",
                 maxWidth: "700px",  // Set your width here,
                 background:"#1e1f21",
                 color:"#fff",
                 marginBottom:"50px"
             },
             },
         }}
     >
    <DialogTitleV2 handleCloseDialog={handleClose} title={"Denetime Yeni Bulgu Ekle"} />
    <Divider sx={{backgroundColor:"#A2A1A0"}}/>
    <DialogContent>
    <TableBody>
          <Grid container spacing={2}>
              {questions.filter((row => row?.audit_question?.id === question)).map((e) => (
                <>
                 <Grid item xs={6}>
                    <Button variant="outlined" onClick={() => setNeWFindingToAudit(false)}>Geri Dön</Button>
                </Grid>
                <Grid item xs={6} align="right">
                {
                 e.audit_question?.question_type === "TF" ?
                 <Chip color={!e?.pass_fail ? "error" : "success"} label={ !e?.pass_fail ? "uygunsuz" : "uygun"}/>:
                e.audit_question?.passing_criteria?.numeric_passing_criteria ?
                    <Chip color={e?.rating <= e.audit_question?.passing_criteria?.numeric_passing_criteria?.fail_value ? "error" : "success"} label={ e?.rating <= e.audit_question?.passing_criteria?.numeric_passing_criteria?.fail_value ? "uygunsuz" : "uygun"}/>
                    :
                    <Chip color={e?.rating < e.audit_question?.min_accepted_rating ? "error" : "success"} label={ e?.rating < e.audit_question?.min_accepted_rating ? "uygunsuz" : "uygun"}/>
                  }
                </Grid>
                <Grid item xs={12}>           
                <b> Soru: {e.audit_question?.question_text}</b>
                </Grid>   
                <Grid item xs={12}>
                  Puan: <b>{e?.rating} </b>
                </Grid>
                 
                <Grid item xs={12}>     
                <TextFieldV2 label="Lütfen bulgularınızı giriniz" value={comment} setValue={setComment} />
                </Grid>
               
                <Grid item xs={12}>
                  <FormGroup>
                      <FormControlLabel
                      FormControlLabel control={<Switch checked={level === "department"} onChange={() => setLevel(level === "department" ? "region" : "department")} defaultChecked />} label="Denetim Departmanı ile Filtrele" 
                      />
                  </FormGroup>
               <AutoCompleteV2 label="Sorumlu Seç" options={employees} value={singleAudit} setValue={setSingleAudit} user/>
                </Grid> 
                 <Grid item xs={12}>     
                 <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                label="Termin Tarihi"
                inputFormat="dd/MM/yyyy"
                value={date}
                autoFocus
                onChange={(e) => setDate(moment(e).format("YYYY-MM-DD"))}
                renderInput={(params) => <TextField focused {...params}
                sx={{
                    input: {
                        color: "white",
                    },
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'white',
                        },
                        '&:hover fieldset': {
                        borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: 'white',
                        },}
                    }}
                />}
            
                />
                </LocalizationProvider>
                </Grid> 
                
                <Grid item xs={12} align="right">     
                <label htmlFor="contained-button-file">
                  <Input accept="image/*" id="contained-button-file"  type="file" onChange={onChangePicture} />
                  <Button variant="contained" fullwidth component="span">
                    Fotoğraf Çek veya Ekle &nbsp; <FaCamera/>
                  </Button>
                </label>
                </Grid>   
                <Grid item xs={12}>     
                {imgData ? 
                <img ref={imgRef} 
                  src={imgData}
                  id="auditimg"
                  alt="sample" 
                  crossOrigin="anonymous"
                  style={{ maxWidth: '100%' }} 
                  onClick={() => showMarkerArea()}
                /> 
                : null}
                </Grid>   
                </>
          ))}
          </Grid>
        </TableBody>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose}>Kapat</Button>
        {!showButton ? 
         singleAudit?
         <Button onClick={handleSubmitQuestionAnswer} variant="contained">Kaydet</Button> 
         :
         <Button variant="outlined" color="error">Sorumlu Ekleyin !</Button> 
       
        :
         <Button variant="outlined">Kaydediliyor...</Button>}
    </DialogActions>
     </Dialog>
    </React.Fragment>
    :
    updateFindingId !== null ?
    <React.Fragment>
         <Dialog
         open={open}
         onClose={handleClose}
         aria-describedby="alert-dialog-slide-description"
         sx={{
             "& .MuiDialog-container": {
             "& .MuiPaper-root": {
                 width: "100%",
                 maxWidth: "700px",  // Set your width here,
                 background:"#1e1f21",
                 color:"#fff",
                 marginBottom:"50px"
             },
             },
         }}
     >
        <DialogTitle>{"UYGUNSUZLUK DÜZENLE"}</DialogTitle>
        <DialogContent>
        {questions.filter((row => row?.audit_question?.id === question)).map((e) => (
            <TableBody>
            <Grid container spacing={2}>
                  <>
                  <Grid item xs={6}>
                      <Button variant="outlined" onClick={() => setUpdateFindingId(null)}>Geri Dön</Button>
                  </Grid>
                  <Grid item xs={6} align="right">
                  {e.audit_question?.passing_criteria?.numeric_passing_criteria ?
                    <Chip color={e?.rating <= e.audit_question?.passing_criteria?.numeric_passing_criteria?.fail_value ? "error" : "success"} label={ e?.rating <= e.audit_question?.passing_criteria?.numeric_passing_criteria?.fail_value ? "uygunsuz" : "uygun"}/>
                    :
                    <Chip color={e?.rating < e.audit_question?.min_accepted_rating ? "error" : "success"} label={ e?.rating < e.audit_question?.min_accepted_rating ? "uygunsuz" : "uygun"}/>
                  }
                  </Grid>
                  <Grid item xs={12}>           
                  <b> Soru: {e.audit_question?.question_text}</b>
                  </Grid>   
                  <Grid item xs={12}>
                    Puanı: <b>{e?.rating} </b>
                  </Grid>
                  
                  <Grid item xs={12}>  
                  <TextFieldV2 label="Lütfen bulgularınızı giriniz" value={comment} setValue={setComment} />
                  </Grid>
                  <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                    FormControlLabel control={<Switch checked={level === "department"} onChange={() => setLevel(level === "department" ? "region" : "department")} defaultChecked />} label="Denetim Departmanı ile Filtrele" 
                    />
                  </FormGroup>
                  <AutoCompleteV2 label="Sorumlu Seç" options={employees} value={singleAudit} setValue={setSingleAudit} user/>
                  </Grid> 
                  <Grid item xs={12}>     
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                    label="Termin Tarihi"
                    inputFormat="dd/MM/yyyy"
                    value={date}
                    autoFocus
                    onChange={(e) => setDate(moment(e).format("YYYY-MM-DD"))}
                    renderInput={(params) => <TextField focused {...params}
                    sx={{
                        input: {
                            color: "white",
                        },
                        width:"100%",
                        color:"#fff",
                        '& label.Mui-focused': { color: 'white',},
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    />}
                
                    />
                </LocalizationProvider>
                  </Grid> 
                  <Grid item xs={12} align="right">     
                  <label htmlFor="contained-button-file">
                    <Input accept="image/*" id="contained-button-file"  type="file" onChange={onChangePicture} />
                    <Button variant="contained" fullwidth component="span">
                      Fotoğraf Çek veya Ekle &nbsp; <FaCamera/>
                    </Button>
                  </label>
                  </Grid>   
                  <Grid item xs={12}>     
                  {imgData ? 
                  <img ref={imgRef} 
                    src={imgData}
                    id="auditimg"
                    alt="sample" 
                    crossOrigin="anonymous"
                    style={{ maxWidth: '100%' }} 
                    onClick={() => showMarkerArea()}
                  /> 
                  : null}
                </Grid>   
                </>
          </Grid>
        </TableBody>
            ))}
        </DialogContent>
        <DialogActions>
            <Button  onClick={() =>  setEditRating(false)} color="error"> İptal</Button>
            {!showButton ?  <Button onClick={handleUpdateFinding} variant="contained">Uygunsuzluk Güncelle</Button> :  <Button variant="outlined">Güncelleniyor...</Button>}
        </DialogActions>
     </Dialog>
    </React.Fragment>
    :
    <React.Fragment>
    <Dialog
         open={open}
         onClose={handleClose}
         aria-describedby="alert-dialog-slide-description"
         sx={{
             "& .MuiDialog-container": {
             "& .MuiPaper-root": {
                 width: "100%",
                 maxWidth: "700px",  // Set your width here,
                 background:"#1e1f21",
                 color:"#fff",
                 marginBottom:"50px"
             },
             },
         }}
     >
    <DialogTitleV2 handleCloseDialog={handleClose} title={"Denetime Eklenen Bulgular"} />
    <Divider sx={{backgroundColor:"#A2A1A0"}}/>
    <TableBody style={{padding:"20px"}}>
          {questions.filter((row => row?.audit_question?.id === question)).map((e) => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button variant="outlined" onClick={handleGoBack}>Geri Dön</Button>
            </Grid>  
            <Grid item xs={6} align="right">
            {
            e.audit_question?.question_type === "TF" ?
            <Chip color={!e?.pass_fail ? "error" : "success"} label={ !e?.pass_fail ? "uygunsuz" : "uygun"}/>:
            e.audit_question?.passing_criteria?.numeric_passing_criteria ?
            <Chip color={e?.rating <= e.audit_question?.passing_criteria?.numeric_passing_criteria?.fail_value ? "error" : "success"} label={ e?.rating <= e.audit_question?.passing_criteria?.numeric_passing_criteria?.fail_value ? "uygunsuz" : "uygun"}/>
            :
            <Chip color={e?.rating < e.audit_question?.min_accepted_rating ? "error" : "success"} label={ e?.rating < e.audit_question?.min_accepted_rating ? "uygunsuz" : "uygun"}/>
            }
            </Grid>  
            <Grid item xs={12}>
              Soru:  <b>{e?.audit_question?.question_text}</b>
            </Grid>  
            {e.question_type === "TF" ? 
            <Grid item xs={8}>
              Toplam Puanı:   {editRating ? 
              <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label" style={{color:"#fff"}}>Düzenledikten Sonra Sağ Taraftan Kaydediniz</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={questionRating}
                onChange={handleQuestionRating}
              >
               <FormControlLabel value="1" control={<Radio  sx={{color: pink[800],'&.Mui-checked': {color: pink[600],},}} />} label="1" />
                        <FormControlLabel value="2" control={<Radio  sx={{color: pink[300],'&.Mui-checked': {color: pink[200],},}} />} label="2" />
                        <FormControlLabel value="3" control={<Radio  sx={{color: green[300],'&.Mui-checked': {color: green[200],},}} />} label="3" />
                        <FormControlLabel value="4" control={<Radio  sx={{color: green[500],'&.Mui-checked': {color: green[600],},}} />} label="4" />
                        <FormControlLabel value="5" control={<Radio  sx={{color: green[800],'&.Mui-checked': {color: green[600],},}} />} label="5" />
              </RadioGroup>
            </FormControl>
              :  
              <b>{e?.rating} </b>}
            </Grid>
            :""}
            <Grid item xs={4}  align="right">
            {/* {!editRating ? 
              <Button variant="contained" onClick={() =>  setEditRating(true)}> <FaPen/></Button>
              :
              <>
              {!showButton ? <Button variant="contained" onClick={() =>  handleUpdateFindingScore(e.id)}> Kaydet</Button> : <Button variant="outlined"> Kaydediliyor...</Button>}
              <Button  onClick={() =>  setEditRating(false)} color="error"> İptal</Button>
              
              </>
            } */}
            </Grid>
            <Grid item xs={12}>
              
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 380 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Bulgu</StyledTableCell>
                      <StyledTableCell align="left">Görsel</StyledTableCell>
                      <StyledTableCell align="right">Termin Tarihi</StyledTableCell>
                      <StyledTableCell align="right">Sorumlu</StyledTableCell>
                      <StyledTableCell align="right">Düzenle</StyledTableCell>
                      <StyledTableCell align="right">Sil</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {e?.finding?.map((g) => (
                  <TableBody key={g.id}>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {g?.task}
                        </StyledTableCell>
                        {g?.before_image_path ? <StyledTableCell align="left"><img src={g?.before_image_path?.path} style={{height:"100px", maxWidth: "100px", objectFit:"contain"}} alt="finding_image"/></StyledTableCell> : <StyledTableCell align="left">Görsel Eklenmedi</StyledTableCell>}
                        <StyledTableCell align="right">{g?.due_date}</StyledTableCell>
                        {g?.assignee ? <StyledTableCell align="right">{g?.assignee?.name + " " + g?.assignee?.surname}</StyledTableCell> : <StyledTableCell align="right">Sorumlu Atanmadı</StyledTableCell> }
                        <StyledTableCell align="right"><Button variant="contained" onClick={() => handleSetUpdateId(g)}> <FaPen/></Button></StyledTableCell>
                        <StyledTableCell align="right"><Button variant="contained" color="error" onClick={() =>handleDeleteFindingOpen(g.id)}> <FaTrash/></Button></StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                  ))}
                  <TableBody>
                    <StyledTableCell colSpan={6} align="left"><Button variant="contained" onClick={() => handleOpenNewFindingTab(e)}> Yeni Bulgu Gir</Button></StyledTableCell>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          ))}
        </TableBody>
     </Dialog>
     <Dialog open={findingDeleteOpen} onClose={handleDeleteFindingClose}>
        <DialogTitle>{findingId} Nolu Bulguyu Sil</DialogTitle>
        <DialogContent>Bulguyu silmek istediğinize emin misiniz?</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteFindingClose}>İptal</Button>
          {!showButton ? <Button onClick={handleDeleteFinding} variant="contained" color="error">Bulguyu Sil</Button> :  <Button variant="outlined" color="error">Siliniyor...</Button> }
        </DialogActions>
      </Dialog>
    </React.Fragment>
    
  )
}

export default AnswerAuidCard
