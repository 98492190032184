import { Dialog, DialogActions, DialogContent, DialogTitle,  Grid, TextField } from '@mui/material'
import axios from 'axios'
import React, {useState} from 'react'
import { EditButton } from '../../Pages/CompanySettings/MainSettings'
import { TopNavActions } from '../AuditDashboardsContainer/activitiesdashboard'
import styled from 'styled-components'
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';


export const AboutUsDialogContainer = styled.div`
    display:flex;
    background:#211c27;
    height: 100%;
    width: 100%;
    margin-top:15px;
    justify-content:space-evenly;
    
`

export const BackgroundHolder = styled.div`
    width: 150px;
    height: 150px;
    border: 8px solid white;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://4kwallpapers.com/images/wallpapers/macos-monterey-wwdc-21-stock-dark-mode-5k-3840x2160-5585.jpg");
`

export const InfoContainerHolder = styled.div`
    display:flex;
    flex-direction:column;
    width:50%

`

export const OverAllContainer = styled.div`
    display:flex;
    flex-direction:column;
`



const TaxInfoDialog = ({open, setOpen,getInfoBack,company_id,PaperComponent}) => {
  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
    }


    const [tax_number, setTaxNumber] = useState(null)
    const [tax_office, setTaxOffice] = useState(null)
    const [adress, setAdress] = useState(null)

    async function handleSubmitTaxInfo (e) {
        e.preventDefault()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        const body = JSON.stringify({ tax_number,tax_office,adress,company:company_id  });
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/taxinfo/main/`, body, config);
            getInfoBack()
            handleCloseDialog()
        } catch (err) {
            window.scrollTo( 0, 0)
        }
      }



  return (
    <Dialog open={open} onClose={handleCloseDialog}
    PaperComponent={PaperComponent}
    aria-labelledby="draggable-dialog-title"

    sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        
        <DialogTitle id="draggable-dialog-title"  style={{background:"rgba(54,51,58,0.8)", height:"8px",cursor: 'move'}}>
            <Grid container spacing={2} justifyContent={"center"} alignItems="center">
                <Grid container item xs={12}>
                  <Grid item container xs={4} style={{flexDirection:"row"}}>
                  <TopNavActions id="close" style={{ marginLeft:"-5px"}} onClick={handleCloseDialog} background={"#ee5c54"}>
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  </Grid>
                  <Grid item xs={6} style={{fontSize:"15px"}}>ONKASA QMOS HAKKINDA</Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent style={{display:"flex", flexDirection:"column", gap:"15px", paddingTop:"10px"}}>
            <OverAllContainer>
            <AboutUsDialogContainer>
                <BackgroundHolder>
                </BackgroundHolder>
                <InfoContainerHolder>
                    <span style={{fontSize: "21px"}}><b>qmOS</b> Entreprise</span>
                    <span style={{fontSize: "11px"}}>Versiyon 2.6</span>
                    <br/>
                    <span style={{fontSize: "11px", marginBottom:"5px"}}>Tek lokasyonlu hesap</span>
                    <span style={{fontSize: "11px", marginBottom:"5px"}}>1TB Storage</span>
                    <span style={{fontSize: "11px", marginBottom:"5px"}}>32GB Memory DDR3</span>
                </InfoContainerHolder>
            </AboutUsDialogContainer>
            <span style={{fontSize: "11px", marginTop:"25px", alignItems:"center", alignSelf:"center", textAlign:"center"}}>
                ™ and  © 2021-2023 Onkasa Ltd. Tüm Hakları Saklıdır.  <a href="//onkasa.com/user-agreement" style={{color:"white"}} target="_blank">Lisans Sözleşmesi</a> 
            </span>
            </OverAllContainer>
            
        </DialogContent>
        {/* <DialogActions>
            <EditButton style={{marginRight:"10px"}} onClick={handleCloseDialog}>
              İptal
            </EditButton>
            <EditButton active style={{marginRight:"10px"}} onClick={handleSubmitTaxInfo}>
              Kaydet
            </EditButton>
        </DialogActions> */}
    </Dialog>
  )
}

export default TaxInfoDialog