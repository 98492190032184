import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Snackbar, Switch } from '@mui/material'
import axios from 'axios'
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import React, { useEffect, useState } from 'react'
import AutoCompleteV2 from '../../../../Assets/AutoCompleteV2'
import DialogTitleV2 from '../../../../Assets/DialogTitle'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import { EditButton } from '../../../../Pages/CompanySettings/MainSettings'
import SuccessSnackBar from '../../../SuccessSnackBar'
import * as markerjs2 from 'markerjs2';
import { styled as styledmui} from '@mui/material/styles'
import moment from 'moment';
const EditTaskDialog = ({open, setOpen, 
    region,
    setRegion,
    department,
    setDepartment,
    responsible_by,
    setResponsibleBy,
    due_date,
    setDueDate,
    before_image_path,
    id,
    getSingleTaskInfoWithId,
    task,
    setTask
    }) => {
      const [product_image_path, setProductImagePath] = useState(null)
      const [imgData, setImgData] = useState(null);
      const onChangePicture = e => {
        if (e.target.files[0]) {
          setProductImagePath(e.target.files[0])
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            setImgData(reader.result);
          });
          reader.readAsDataURL(e.target.files[0]);
        }
      };
    let imgRef = React.createRef()
    function showMarkerArea() {
        if (imgRef.current !== null) {
          // create a marker.js MarkerArea
          const markerArea = new markerjs2.MarkerArea(imgRef.current);
          // attach an event handler to assign annotated image back to our image element
          markerArea.addEventListener("render", (event) => {
            if (imgRef.current) {
              imgRef.current.src = event.dataUrl;
              fetch(event.dataUrl)
              .then(res => res.blob())
              .then(blob2 => setProductImagePath(blob2))
            }
          });
          // launch marker.js
          markerArea.settings.defaultColor = 'green'  ;
          markerArea.show();
        }
      }
      const Input = styledmui('input')({
          display: 'none',
      });
      const [regions, setRegions] = useState(null)
    const handleCloseDialog = () => {
        setOpen(false)
    }
    console.log(responsible_by,"regionb")
    const [buttonStart, setButtonStart] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [data, setData] = useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function handleDelete (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/create/`,  axiosConfig);
            setData(res.data)
        }
        catch{

        }
    }
    async function getRegionInfo () {
      const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
      };
      try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, config);
          setRegions(res.data)
      } catch (err) {
      }
    }
    const departmentOptions = regions?.filter(e => e.id === region)
  const [employees, setEmployees] = useState([])
  const [level, setLevel] = useState("department")
    async function getEmployees(){
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/get_by_level/?level=${level}&level_id=${level === "department" ? department : region}`,  axiosConfig);
        setEmployees(res.data)
      }
      catch{
  
      }
    }
    useEffect(() => {
      if(open){
        getRegionInfo();
        getEmployees();
      }
    },[open])
    useEffect(() => {
      if(open){
        getEmployees();
      }
    },[open,level])

    async function handleSubmit () {
      setButtonStart(true)
      //submit the finding
      let form_data = new FormData();
      form_data.append('edit_task', true)
      product_image_path ? form_data.append('before_image_path', product_image_path, "sonrasi.jpg")  :form_data.append('test', "null") 
      form_data.append('section', department)
      form_data.append('region', region)
      form_data.append('assignee', responsible_by?.id)
      form_data.append('task', task)
      form_data.append('due_date', moment(due_date).format("YYYY-MM-DD"))

      const config = {
          headers: {
              'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
          }
      };
      const body = form_data
      try {
          await axios.patch(`${process.env.REACT_APP_API_URL}/api/taskmanagment/add-action/${id}/`, body, config);
          setOpen(false)
          setSuccessMsg(true)
          getSingleTaskInfoWithId()
          setProductImagePath(null)
      setButtonStart(false)
      setImgData(null)
    }
     catch (err) {
      setButtonStart(false)
      setOpen(false)
  }
    }
  return (
    <>
    <SuccessSnackBar open={successMsg} setOpen={setSuccessMsg} data={"Görev Başarıyla Düzenlendi"}/>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",
            
          },
        },
      }}
    >
        <DialogTitleV2 title={"Görevi Düzenle"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
        <Grid container>
        <Grid item xs={12} mt={1}>
          <TextFieldV2 label={"Görev Açıklaması"} value={task} setValue={setTask}/>
        </Grid>
        <Grid item xs={12} mt={1}>
                <FormControl
                sx={{
                width:"100%",
                color:"#fff",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'white',
                        },
                        '&:hover fieldset': {
                        borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: 'white',
                        },}
                    }}
                >
                    <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Bölge Seçin</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={region}
                        label="Bölge Seçin"
                        onChange={(e) => setRegion(e.target.value)}
                        style={{color:"#fff"}}
                    >
                    {regions?.map((reg, index) => (
                    <MenuItem value={reg?.id} key={reg?.id} >{reg.name}</MenuItem>
                    ))
                    } 
                </Select>
            </FormControl>
        </Grid>
            <Grid item xs={12} mt={2}>
                  <FormControl
                  sx={{
                  width:"100%",
                  color:"#fff",
                  '& label.Mui-focused': { color: 'white',},
                  '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                          borderColor: 'white',
                          },
                          '&:hover fieldset': {
                          borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                          borderColor: 'white',
                          },}
                      }}
                  >
                      <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Departman Seçin</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={department}
                          label="Departman Seçin"
                          onChange={(e) => setDepartment(e.target.value)}
                          style={{color:"#fff"}}
                      >
                         
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                <MenuItem value={child?.id}>{child?.name}</MenuItem>
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        <MenuItem value={child2?.id}>{child?.name} - {child2?.name}</MenuItem>
                                    ))
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        child2?.children?.map(child3 => (
                                            <MenuItem value={child3?.id}>{child?.name} - {child2?.name} - {child3?.name}</MenuItem>
                                        ))
                                    ))
                            ))))}
                  </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} mt={2}>
            <FormGroup>
              <FormControlLabel
              FormControlLabel control={<Switch checked={level === "department"} onChange={() => setLevel(level === "department" ? "region" : "department")} defaultChecked />} label="Denetim Departmanı ile Filtrele" 
              />
            </FormGroup>
            <AutoCompleteV2 label="Sorumlu Seç" options={employees} value={responsible_by} setValue={setResponsibleBy} user/>
            </Grid>
            <Grid item xs={12}>
              <TextFieldV2 type={"date"} label={"Termin Tarihi"} value={due_date} setValue={setDueDate}/>
            </Grid>
            <Divider style={{width:"100%", background:"white"}} />
            <Grid item xs={12} mt={2}>
              Öncesi Fotoğrafı:
            </Grid>
            <React.Fragment>
                <Grid item xs={12}  mb={2} align="right">     
                    <label htmlFor="contained-button-file">
                    <Input accept="image/*" id="contained-button-file"  type="file" onChange={onChangePicture} />
                    <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={product_image_path === null ? "info" : "warning" }>
                        {product_image_path === null ? <> Fotoğraf Çek veya Ekle &nbsp; <CameraEnhanceIcon/> </> : <> Düzenlemek İçin Fotoğrafa Tıkla &nbsp; <CameraEnhanceIcon/> </>}
                    </Button>
                    </label>
                </Grid>   
                <Grid item xs={12}>     
                    {imgData ? 
                    <img ref={imgRef} 
                    src={imgData}
                    id="auditimg"
                    alt="sample" 
                    crossOrigin="anonymous"
                    style={{ maxWidth: '100%' }} 
                    onClick={() => showMarkerArea()}
                    /> 
                    : <img style={{ maxWidth: '100%' }} src={before_image_path} />}
                </Grid>   
            </React.Fragment>
        </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleSubmit} active>Kaydet</EditButton> : <EditButton>Kaydediliyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default EditTaskDialog