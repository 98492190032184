import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../AuditApp/Activities/ActivitiesStyle'
import SuggestionPoolTable from './SuggestionPoolTable'
const SuggestionPool = () => {
    const [data, setData] = useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/create/`,  axiosConfig);
            setData(res.data)
        }
        catch{

        }
    }
    useEffect(() => {getInfo()},[])
  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
            <SuggestionPoolTable data={data} getInfoBack={getInfo}/>
        </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}


export default SuggestionPool