import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AutoCompleteV2 from '../../../../Assets/AutoCompleteV2'
import TextFieldV2 from '../../../../Assets/TextFieldV2'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import { EditButton } from '../../../CompanySettings/MainSettings'
import { styled as styledmui} from '@mui/material/styles'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { FileHolder, FileName } from '../../../../Assets/GeneralDesignElements'
import { TaskImageHolder } from '../../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'
import getFileIcon from '../../../../Assets/GetFileIconsFunction'
import moment from 'moment'

const IccCreateComplaints = () => {
  const Input = styledmui('input')({
    display: 'none',
});
let navigate = useNavigate()
const [files, setFiles] = useState([])
function getFileExtension(filename) {
    return filename.split('.').pop();
  }
  const handleFileChange = (event) => {
    const newFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
      newFiles.push(event.target.files[i]);
    }
    setFiles((prevFiles) => [...newFiles, ...prevFiles]);
  };
  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(indexToRemove, 1);
      return newFiles;
    });
  };
  // general headers
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
    };
    const axiosConfigFormData = {
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
      }
  };
  // end of general headers
  const [selectedDepartment, setSelectedDepartment] = useState(null)
  const [regionList, setRegionList] = useState([])
  const [selectedRegion, setSelectedRegion] = useState(null)
  const departmentList = regionList? regionList?.filter(e => e.id === selectedRegion?.id) : []
  const [selectedConsernedRegion, setSelectedConsernedRegion] = useState(null)
  const [selectedConsernedDepartment, setSelectedConsernedDepartment] = useState(null)
  const consernedDepartmentList =  regionList? regionList?.filter(e => e.id === selectedConsernedRegion?.id) : []
  const [heading, setHeading] = useState(null)
  const [details, setDetails] = useState(null)
  const [categoryList, setCategoryList] = useState([])
  const [selectedCategory,setSelectedCategory] = useState([]) 
  const [userList, setUserList] = useState([])
  const [selectedInformedUsers, setSelectedInformedUsers] = useState([])
  const [managementSystemsList, setManagementSystemsList] = useState([])
  const [processList, setProcessList] = useState([])
  const [selectedManagementSystems, setSelectedManagementSystems] = useState(null)
  const [selectedProcess, setSelectedProcess]  = useState(null)
  const [productsList, setProductsList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null)
  const [selectedSolutionTeamMember, setSelectedSolutionTeamMember] = useState([])
  const [due_date, setDueDate] = useState(moment().add(7,"days").format("YYYY-MM-DD"))
  async function handleSubmit(){
    let form_data = new FormData();
    for (let i = 0; i < files.length; i++) {
        form_data.append(`file${i + 1}`, files[i]);
    }
    form_data.append('customer_id', selectedDepartment)
    form_data.append('heading', heading)
    form_data.append('details', details)
    form_data.append('region_id', selectedRegion?.id)
    form_data.append('section_id', selectedConsernedDepartment)
    form_data.append('category', selectedCategory?.map((e) => e.id))
    form_data.append('informed_users', selectedInformedUsers?.map((e) => e.id))
    form_data.append('quality_system_id', selectedManagementSystems?.id)
    form_data.append('process_id', selectedProcess?.id)
    form_data.append('product', selectedProduct?.id)
    form_data.append('team_leader', selectedTeamLeader?.id)
    form_data.append('solution_team', selectedSolutionTeamMember?.map((e) => e.id))
    form_data.append('due_date', moment(due_date).format("YYYY-MM-DD"))
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/icc/create-read/`, form_data, axiosConfigFormData);
        setProductsList(res.data)
        navigate(`/app/internal-customer-complaints-app/activities`)
    }
    catch{

    }
  }
  // This part if for api calls
  async function getRegionInfo (){
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`,  axiosConfig);
        setRegionList(res.data)
    }
    catch{

    }
  }
  async function getCategoryInfo (){
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/icc/category/`,  axiosConfig);
        setCategoryList(res.data)
    }
    catch{

    }
}
async function getUserList (){
  try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/create/`,  axiosConfig);
      setUserList(res.data)
  }
  catch{

  }
}
async function getManagementSystems (){
  try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/management-systems/`,  axiosConfig);
      setManagementSystemsList(res.data)
  }
  catch{

  }
}
async function getProcessList (){
  try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/process/`,  axiosConfig);
      setProcessList(res.data)
  }
  catch{

  }
}
async function getProductsList (){
  try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/general-info/products/`,  axiosConfig);
      setProductsList(res.data)
  }
  catch{

  }
}
  useEffect(() => {
    getRegionInfo();
    getCategoryInfo();
    getUserList();
    getManagementSystems();
    getProcessList();
    getProductsList();
  },[])

  // End of API  Calls
  return (
    <ActivitiesMainContainer>
      <ActivitiesMainWrapper>
        <Grid container gap={1} justifyContent={"space-between"}>
          <Grid item  container xs={12} md={7} justifyContent={"space-between"}>
            <InfoBar>
                <h1>Şikayet Detayları</h1>

                <EditButton active onClick={handleSubmit}>Kaydet</EditButton>
            </InfoBar>
            <AutoCompleteV2 label={"Şikayet Bölgesi"} value={selectedRegion} setValue={setSelectedRegion} options={regionList} />
            {selectedRegion ? 
  <FormControl
  sx={{
  width:"100%",
  marginBottom:"15px",
  color:"#fff",
  '& label.Mui-focused': { color: 'white',},
  '& .MuiOutlinedInput-root': {
          '& fieldset': {
          borderColor: 'white',
          },
          '&:hover fieldset': {
          borderColor: 'white',
          },
          '&.Mui-focused fieldset': {
          borderColor: 'white',
          },}
      }}
  >
      <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Şikayet Departmanı Seçin</InputLabel>
      <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedDepartment}
          label="Şikayet Departmanı Seçin"
          onChange={(e) => setSelectedDepartment(e.target.value)}
          style={{color:"#fff"}}
      >
         
            {departmentList?.map(parent => (
                parent?.children?.map(child => (
                <MenuItem value={child?.id}>{child?.name}</MenuItem>
            ))))}
            {departmentList?.map(parent => (
                parent?.children?.map(child => (
                    child?.children?.map(child2 => (
                        <MenuItem value={child2?.id}>{child?.name} - {child2?.name}</MenuItem>
                    ))
            ))))}
            {departmentList?.map(parent => (
                parent?.children?.map(child => (
                    child?.children?.map(child2 => (
                        child2?.children?.map(child3 => (
                            <MenuItem value={child3?.id}>{child?.name} - {child2?.name} - {child3?.name}</MenuItem>
                        ))
                    ))
            ))))}
  </Select>
</FormControl>            :
            ""
            }
            <TextFieldV2 label={"Şikayet Tanımı"} value={heading} setValue={setHeading} />
            <TextFieldV2 label={"Şikayet Detayı"} value={details} setValue={setDetails} />
            <Divider style={{background:"white", width:"100%", marginBottom:"15px"}} />
            <Grid item xs={5}>
            <AutoCompleteV2 label={"İlgili Bölge"} options={regionList}  value={selectedConsernedRegion} setValue={setSelectedConsernedRegion} />
            </Grid>
            <Grid item xs={5}>
            <FormControl fullWidth
            InputLabelProps={{
                style:{
                    color:"#A3A2A1"
            }
            }}
            sx={{
            marginBottom:"15px",
                input: {
                    color: "white",
                },
                width:"100%",
                '& label.Mui-focused': { color: 'white',},
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    '&:hover fieldset': {
                    borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: 'white',
                    },}
                }}>
                      <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Departman Seçin</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedConsernedDepartment}
                        label="Departman Seçin"
                        onChange={(e)=> setSelectedConsernedDepartment(e.target.value)}
                        style={{color:"#fff"}}
                      >
                         
                            {consernedDepartmentList?.map(parent => (
                                parent?.children?.map(child => (
                                <MenuItem value={child?.id}>{child?.name}</MenuItem>
                            ))))}
                            {consernedDepartmentList?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        <MenuItem value={child2?.id}>{child?.name} - {child2?.name}</MenuItem>
                                    ))
                            ))))}
                            {consernedDepartmentList?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        child2?.children?.map(child3 => (
                                            <MenuItem value={child3?.id}>{child?.name} - {child2?.name} - {child3?.name}</MenuItem>
                                        ))
                                    ))
                            ))))}
                  </Select>
              </FormControl>
              </Grid>
              <AutoCompleteV2 label={"İlgili Şikayet Kategorisi"} multiple options={categoryList}  value={selectedCategory} setValue={setSelectedCategory} />
              <AutoCompleteV2 label={"Bilgilendirilecek Kişiler"} user multiple value={selectedInformedUsers} setValue={setSelectedInformedUsers} options={userList} />
              <Grid item xs={5}>
                <AutoCompleteV2 label={"Yönetim Sistemi"} options={managementSystemsList} value={selectedManagementSystems} setValue={setSelectedManagementSystems} />
              </Grid>
              <Grid item xs={5}>
                  <AutoCompleteV2 label={"Süreç"} options={processList} value={selectedProcess} setValue={setSelectedProcess} />
              </Grid>
              <AutoCompleteV2 label={"Ürün"} options={productsList} value={selectedProduct} setValue={setSelectedProduct}/>
              <Grid item xs={12} mt={2} mb={2} align="right">     
              <label htmlFor="contained-button">
                  <Input accept="*/*" id="contained-button" multiple   type="file" onChange={handleFileChange} />
                  <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={"info"}>
                      Ek döküman ekle &nbsp; <FileUploadIcon/> 
                  </Button>
              </label>
              </Grid>
              {files?.length > 0 ?
            <>
            <h5>Dosyalar</h5>
            <TaskImageHolder>
            {files?.map((e,index) => (
              <Tooltip title={e?.name + "Listeden çıkarmak için tıklayın"} arrow>
                  <a onClick={() => handleRemoveFile(index)} style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                <FileHolder>
                  {getFileIcon(getFileExtension(e?.name))}
                  <FileName>{e?.name}</FileName>
                </FileHolder>
                </a>
              </Tooltip>
                ))}
            </TaskImageHolder>
            </>
          :
          ""}
          </Grid>
          <Grid item xs={12} md={4}>
              <h1>Çözüm Ekibi</h1>
              <AutoCompleteV2 label={"Ekip Lideri"} user options={userList} value={selectedTeamLeader} setValue={setSelectedTeamLeader} />
              <AutoCompleteV2 label={"Çözüm Ekibi"} user multiple options={userList} value={selectedSolutionTeamMember} setValue={setSelectedSolutionTeamMember} />
              <TextFieldV2 type={"date"} value={due_date} setValue={setDueDate} label={"Termin Tarihi"} focused />
          </Grid>
        </Grid>
      </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}

export default IccCreateComplaints