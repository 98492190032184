import { TextareaAutosize } from '@mui/material'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'

const SendMail = ({open, setOpen, cardId, getAuditCards, email, setEmail,emailTo}) => {
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const handleCloseSuccessMsg = () => {
        setSuccessMsg(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const vertical = "bottom"
    const horizontal = "center"
    const [successMsg, setSuccessMsg] = useState(false)
    async function handleDelete() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({emailTo, content: email})
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/card-reminder/`, body, config)
            getAuditCards()
            setOpen(false)
            setSuccessMsg(true)
            setButtonStart(false)
        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }

      async function createChat (e){
        const body = JSON.stringify({user_ids:[emailTo], msg:email})
        try{
            await axios.post(`${process.env.REACT_APP_API_URL}/api/message/create/`, body, axiosConfig);
            setOpen(false)
            setSuccessMsg(true)

        }
        catch{

        }
    }
  return (
    <>
    <Snackbar open={successMsg} autoHideDuration={6000} onClose={handleCloseSuccessMsg} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleCloseSuccessMsg} severity="warning" sx={{ width: '100%' }}>
           Mesaj Başarıyla Gönderilmiştir.
        </Alert>
    </Snackbar>
    <Dialog open={open} onClose={handleCloseDialog}  sx={{
        "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",  // Set your width here,
            background:"#1e1f21",
            color:"#fff",
            marginBottom:"50px"
        },
        },
    }}>
        <DialogTitleV2 title={"Sorumluya bilgi gönder!"} handleCloseDialog={handleCloseDialog}/> 
        <DialogContent>
            <TextareaAutosize minRows={5} style={{width:"100%"}} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email..."/>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <Button onClick={createChat} color="warning" variant="outlined">Gönder</Button> : <Button variant="contained" color="warning">Gönderiliyor...</Button>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default SendMail