import React, {  useState } from 'react';
import {FaMinus, FaPlus} from "react-icons/fa"
function TreeComponent({ data }) {
  const [collapsed, setCollapsed] = useState([]);

  const toggle = (id) => {
    setCollapsed((prevState) =>
      prevState.includes(id)
        ? prevState.filter((val) => val !== id)
        : [...prevState, id]
    );
  };

  const renderTree = (items) => {
    return (
      <ul className="tree-component-list">
        {items.map((item) => (
          <li key={item.id}>
            <div
              className={`tree-component-item ${
                collapsed.includes(item.id) ? 'collapsed' : ''
              }`}
            >
              {item.children ? (
                <span
                  className="tree-component-toggle"
                  onClick={() => toggle(item.id)}
                >
                  {collapsed.includes(item.id) ? <FaPlus/> : <FaMinus/>}
                </span>
              ) : (
                <span className="tree-component-placeholder" />
              )}
              <span className="tree-component-label">{item.process_code + " - " +item.name}</span>
            </div>
            {item.children &&
              !collapsed.includes(item.id) &&
              renderTree(item.children)}
          </li>
        ))}
      </ul>
    );
  };

  const tree = []
  const treeMap = {};
  for (const item of data) {
    const newItem = { ...item, children: [] };
    treeMap[newItem.id] = newItem;
    if (newItem.parent === null) {
      tree.push(newItem);
    } else if (treeMap[newItem.parent]) {
      treeMap[newItem.parent].children.push(newItem);
    }
  }

  return <div className="tree-component">{renderTree(tree)}</div>;
}

export default TreeComponent;