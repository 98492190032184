import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextareaAutosize } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import { EditButton } from '../../../../CompanySettings/MainSettings'

const RejectTheSuggestionDialog = ({open, setOpen, getInfoBack,id}) => {
    const [rejectReason, setRejectReason] = useState(null)
    const handleCloseDialog = () => {
        setOpen(false)
    }
    console.log(rejectReason)
    const handleCloseSuccessMsg = () => {
        setSuccessMsg(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const vertical = "bottom"
    const horizontal = "center"
    const [successMsg, setSuccessMsg] = useState(false)
    async function handleDelete() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({rejectReason})
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/suggestions/get/process_manager_suggestions/${id}/`, body,config);
            setOpen(false)
            setSuccessMsg(true)
            setButtonStart(false)
            getInfoBack()

        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Öneriyi Reddet"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            <label>Red sebebini açıklayınız:</label>
            <TextareaAutosize minRows={6} style={{width:"100%"}}
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleDelete} active>Reddet</EditButton> : <EditButton>Red İletiliyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default RejectTheSuggestionDialog