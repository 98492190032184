import { Chip } from '@mui/material'
import { DataGrid, trTR } from '@mui/x-data-grid'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { EditButton } from '../../../CompanySettings/MainSettings'
import { ActivitiesMainContainer, ActivitiesMainWrapper } from '../../Activities/ActivitiesStyle'
import DeleteAcceptDialog from './AcceptDialog'
import DeleteRejectDialog from './RejectDialog'

const DeleteRequestTable = ({user,setSuccessOpen}) => {
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState()
    const [acceptDialogOpen, setAcceptDialogOpen] = useState(false)
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
    const [id, setId] = useState(null)
    const [audit_id, setAuditId] = useState(null)
    const [note, setNote] = useState(null)
    const [new_date, setNewDate] = useState(null)
    const handleOpenAcceptDialog = (e) => {
        setId(e.id)
        setNote(e.note)
        setNewDate(e.new_date)
        setAuditId(e.audit?.id)
        setAcceptDialogOpen(true)
    }
    const handleOpenRejectDialog = (e) => {
        setId(e.id)
        setNote(e.note)
        setNewDate(e.new_date)
        setAuditId(e.audit?.id)
        setRejectDialogOpen(true)
    }
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
        setDataLoading(true)
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/delete-request/`,  axiosConfig);
            setData(res.data)
            setDataLoading(false)
        }
        catch{
            setDataLoading(false)
        }
    }
    useEffect(() => {getInfo()},[])
    const columns = [
        {
            field: "id",
            headerName: "No",
            width: 50,
        }, 
        {
            field: "audit",
            headerName: "Denetim",
            width: 150,
            renderCell: (data) => (
                `${data?.row?.audit?.audit_type?.name}`
            ),
        }, 
        {
            field: "region",
            headerName: "Bölge",
            width: 150,
            renderCell: (data) => (
                   `${data?.row?.audit?.region?.name}  / 
                    ${data?.row?.audit?.department?.name}`

            ),
        }, 
        {
            field: "requster",
            headerName: "Talep Eden",
            width: 150,
            renderCell: (data) => (
                   `${data?.row?.requester?.name}  
                    ${data?.row?.requester?.surname}`

            ),
        }, 
        {
            field: "note",
            headerName: "Açıklama",
            width: 350,
        }, 
       
        {
            field: "stage",
            headerName: "Durum",
            width: 250,
            renderCell: (data) => (
                <Chip 
                label={data?.row?.is_pending ? "Beklemede" : data?.row?.is_accepted ? "Kabul Edildi" : "Reddedildi" }
                color={data?.row?.is_pending ? "primary" : data?.row?.is_accepted ? "success" : "error" }/> 
            ),
        }, 
        user?.type?.includes("manager") ?
        {
          field: "delete",
          headerName: "Sil",
          width: 250,
     
          renderCell: (data) => (
            data?.row?.is_pending ?
            <div style={{display:"flex", gap:"5px"}}>
            <EditButton active onClick={() => handleOpenAcceptDialog(data?.row)}>Onayla</EditButton> 
            <EditButton onClick={() => handleOpenRejectDialog(data?.row)} style={{background:"red"}} >Reddet</EditButton> 
            </div>
            :
            <div style={{display:"flex", gap:"5px"}}>
            <EditButton>Onayla</EditButton> 
            <EditButton>Reddet</EditButton> 
            </div>
          ),
         }:"",

    ]
  return (
    <ActivitiesMainContainer>
    <ActivitiesMainWrapper>
    <div style={{height:"75vh"}}>
      <DataGrid
        loading={dataLoading}
        rows={data ? data : []}
        columns={columns}
        style={{color:"white"}}
        pageSize={15}
        rowsPerPageOptions={[15]}
        localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 200}
      />
    </div>
    <DeleteAcceptDialog
    setSuccessOpen={setSuccessOpen}
    note={note}
    new_date={new_date}
    audit_id={audit_id}
    getInfoBack={getInfo}
    id={id} open={acceptDialogOpen} setOpen={setAcceptDialogOpen}/> 
    <DeleteRejectDialog
    setSuccessOpen={setSuccessOpen}
    note={note}
    new_date={new_date}
    audit_id={audit_id}
    getInfoBack={getInfo}
    id={id} open={rejectDialogOpen} setOpen={setRejectDialogOpen}/> 
    </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
  )
}
const mapStateToProps = state => ({   
    user: state.auth.user
  })
  export default connect(mapStateToProps)(DeleteRequestTable)