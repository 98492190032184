import React, { useEffect }  from 'react'
import {connect} from "react-redux"
import {checkAuthenticated, load_user} from "../Actions/auth"
import { useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const AuthCheck = ({ checkAuthenticated, load_user, children }) => {
  const { pathname } = useLocation();

    useEffect(() => {
        checkAuthenticated();
        load_user();
    }, [checkAuthenticated, load_user]);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    return (
      <ThemeProvider theme={darkTheme}>
        <React.Fragment>
            {children}
        </React.Fragment>
        </ThemeProvider>
    );
};

export default connect(null, { checkAuthenticated, load_user })(AuthCheck);