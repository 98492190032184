import {  Dialog, DialogActions, DialogContent,} from '@mui/material'
import React from 'react'
import DialogTitleV2 from '../../../Assets/DialogTitle'
import { EditButton } from '../../../Pages/CompanySettings/MainSettings'


const EccApplicationInfo = ({open, setOpen}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
  
 
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Dış Müşteri Şikayetleri"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
           Dış Müşteri Şikayetleri Yönetimi Uygulaması, firmaların dış müşteri şikayetlerini etkili bir şekilde yönetmelerine yardımcı olmak için tasarlanmıştır. Şikayetler, yazılım tarafından otomatik olarak toplanır ve yönlendirilir, böylece müşteri hizmetleri ekibi hızla müdahale edebilir. Ayrıca, yazılım müşteri şikayetlerini takip etmek için bir izleme sistemi sağlar ve sorunların nasıl çözüldüğüne dair raporlar oluşturur. Bu sayede, firmalar müşteri memnuniyetini artırabilir ve olumlu bir marka imajı oluşturabilirler. Yazılım ayrıca, müşteri şikayetlerinin nedenlerini analiz ederek, benzer sorunların gelecekte tekrarlanmasını önlemeye yardımcı olur.
        </DialogContent>
        <DialogActions>
            <EditButton active onClick={handleCloseDialog}>Tamam</EditButton>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default EccApplicationInfo