import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, {useState, useEffect} from 'react'
import moment from "moment"
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router-dom'
import DialogTitleV2 from '../../../Assets/DialogTitle'
import TextFieldV2 from '../../../Assets/TextFieldV2'
import ErrorSnackBar from '../../ErrorSnackBar'
const AddNewAudit = ({open, setOpen}) => {
    const [showButton, setShowButton] = useState(false)
    const [errMsg, setErrMsg] = useState(false)
    const [errMsgData, setErrMsgData] = useState("Bir sorunla karşılaşıldı")
    const location = useLocation();
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [subdeps, setSubdeps] = useState([])
    useEffect(() => {
        if (open){
            const token = localStorage.getItem("access")
            const opts = {
                method : "GET",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "JWT " + token
                }
            }
            fetch(`${process.env.REACT_APP_API_URL}/api/section/get_childeren/`, opts)
            .then(response => response.json()
            .then(data => {
                setSubdeps(data)
            }))
        }
    }, [open])
    const [audits, setAudits] = useState([])
    
    useEffect(() => {
        if (open){
            const token = localStorage.getItem("access")
            const opts = {
                method : "GET",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "JWT " + token
                }
            }
            fetch(`${process.env.REACT_APP_API_URL}/api/users/create/`, opts)
            .then(response => response.json()
            .then(data => {
                setAudits(data)
            }))
        }

    }, [open])

    const [control, setControl] = useState([])

    useEffect(() => {
        if (open){
            const token = localStorage.getItem("access")
            const opts = {
                method : "GET",
                headers : {
                    "Content-Type" : "application/json",
                    "Authorization" : "JWT " + token
                }
            }
            fetch(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/`, opts)
            .then(response => response.json()
            .then(data => {
                setControl(data)
            }))
        }
       
    }, [open])

    const [controlTest, setControlTest] = useState([])
    const [singleAudit, setSingleAudit] = useState([])
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
    const [singleDep, setSingleDep] = useState([])
    const handleChangeSubdep = (event) => {
        setSingleDep(event.target.value);
    };

    const handleChangeAudit = (event) => {
        setSingleAudit(event.target.value);
    };

    const handleChangeControl = (event) => {
        setControlTest(event.target.value);
    };

    const [sccMsg, setSccMsg] = useState(null)
    const handleCloseSccMsg = () => {
        setSccMsg(false)
    }
    let navigate = useNavigate()
    async function handleSubmit (e) {
        e.preventDefault()
        setShowButton(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
    
        const body = JSON.stringify({ 
            auditer_id: singleAudit.id,
            audit_id: controlTest.id,
            department_id: selectedDepartment,
            region_id: selectedRegion.id,
            date: date })

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/audit/`, body, config);
            setShowButton(false)
            if (location.pathname === "/app/audit-app/activities"){
                window.location.reload()
            }
            else{
                navigate("/app/audit-app/activities")
            }
            setOpen(false)
            setSccMsg(true)
        } catch (err) {
            setShowButton(false)
            setErrMsgData(err.response.data)
            console.log(err)
            setErrMsg(true)
        }
    };
    const vertical = "bottom"
    const horizontal = "center"

    const [region, setRegion] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)
    const departmentOptions = region?.filter(e => e.id === selectedRegion?.id)
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const handleChangeSelectedRegion = (e) => {
        setSelectedRegion(e.target.value)
        setSelectedDepartment(null)
    }
    async function getRegionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, config);
            setRegion(res.data)
        } catch (err) {
        }
      }
      useEffect(() => {
        if(open){
            getRegionInfo()

        }
    },[open])
      const handleChangeSelectedDepartment = (e) => {
        setSelectedDepartment(e.target.value)

    }

  return (
    <>
    <Snackbar open={sccMsg} autoHideDuration={6000} onClose={handleCloseSccMsg} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleCloseSccMsg} severity="success" sx={{ width: '100%' }}>
            Yeni Denetim Oluşturuldu.
        </Alert>
    </Snackbar>
    <ErrorSnackBar open={errMsg} setOpen={setErrMsg} data={errMsgData}/>
    <Dialog open={open} onClose={handleCloseDialog}
        sx={{
            "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here,
                background:"#1e1f21",
                color:"#fff",
                marginBottom:"50px"
            },
            },
        }}
        >
        <DialogTitleV2 title={"Yeni Denetim Oluştur "} handleCloseDialog={handleCloseDialog}/>
        <Divider sx={{backgroundColor:"#A2A1A0"}}/>
        <DialogContent>
        <Grid item xs={12}>
                <Box mb={2} mt={2}>
                    <FormControl
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    >
                        <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Bölge Seçin</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRegion}
                            label="Bölge Seçin"
                            onChange={handleChangeSelectedRegion}
                            style={{color:"#fff"}}
                        >
                        {region?.map((reg, index) => (
                        <MenuItem value={reg} key={reg?.id} >{reg.name}</MenuItem>
                        ))
                        } 
                    </Select>
                </FormControl>
                </Box>
            </Grid>
       
            {selectedRegion ?
            <Grid item xs={12}>
              <Box mb={2} mt={2}>
                  <FormControl
                  sx={{
                  width:"100%",
                  color:"#fff",
                  '& label.Mui-focused': { color: 'white',},
                  '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                          borderColor: 'white',
                          },
                          '&:hover fieldset': {
                          borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                          borderColor: 'white',
                          },}
                      }}
                  >
                      <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Departman Seçin</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedDepartment}
                          label="Departman Seçin"
                          onChange={handleChangeSelectedDepartment}
                          style={{color:"#fff"}}
                      >
                         
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                <MenuItem value={child?.id}>{child?.name}</MenuItem>
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        <MenuItem value={child2?.id}>{child?.name} - {child2?.name}</MenuItem>
                                    ))
                            ))))}
                            {departmentOptions?.map(parent => (
                                parent?.children?.map(child => (
                                    child?.children?.map(child2 => (
                                        child2?.children?.map(child3 => (
                                            <MenuItem value={child3?.id}>{child?.name} - {child2?.name} - {child3?.name}</MenuItem>
                                        ))
                                    ))
                            ))))}
                  </Select>
              </FormControl>
              </Box>
            </Grid>
            :""}
            <Grid item xs={12} mt={2} mb={2}>
                <FormControl 
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                >
                    <InputLabel id="demo-simple-select-label"  sx={{color:"#fff"}}>Sorumlu Ekle</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={singleAudit}
                        label="Sorumlu Ekle"
                        onChange={handleChangeAudit}
                        sx={{color:"#fff"}}
                    >
                        {audits?.map((audits, index) => (
                            <MenuItem value={audits} key={audits?.id}>{audits?.name + " " + audits?.surname}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} mt={2} mb={2}>
                <FormControl 
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                >
                    <InputLabel id="demo-simple-select-label"  sx={{color:"#fff"}}>Denetim Türü</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={controlTest}
                        label="department"
                        sx={{color:"#fff"}}

                        onChange={handleChangeControl}
                    >
                    {control?.map((control, index) => (
                    <MenuItem value={control}>{control.name}</MenuItem>
                    ))
                    }
                </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} mt={2} mb={2}>   
            <TextFieldV2 value={date} setValue={setDate} type={"date"}/>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                label="Denetim Tarihi"
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={(e) => setDate(moment(e).format("YYYY-MM-DD"))}
                renderInput={(params) => <TextField focused {...params}
                sx={{
                    input: {
                        color: "white",
                    },
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'white',
                        },
                        '&:hover fieldset': {
                        borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: 'white',
                        },}
                    }}
                />}
            
                />
                </LocalizationProvider>
            </Grid> 
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!showButton ? <Button variant="contained" onClick={handleSubmit}>Kaydet</Button> : <Button variant="outlined">Kaydediliyor...</Button> }
        </DialogActions>
    </Dialog>
    </>
  )
}

export default AddNewAudit

