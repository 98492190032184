import axios from 'axios'
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    GET_ERRORS,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    LOGOUT,
    LOGIN_PROCESS_START,
    LOGIN_PROCESS_STOP,
    GET_ERRORS_RESET,
} from "./types"

export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }; 

        const body = JSON.stringify({token: localStorage.getItem('access')})

        try{
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, body, config)
            if (res.data.code !== 'token_not_valid'){
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                })
            }
            else {
                const refresh_body = JSON.stringify({token: localStorage.getItem('refresh')})
                try{
                    const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, refresh_body, config)
                    if (res.data.code !== 'token_not_valid'){
                        dispatch({
                            type: AUTHENTICATED_SUCCESS
                        })
                    }
                    else {
                        dispatch({
                            type: AUTHENTICATED_FAIL
                    })
                    }
                }
                catch(err){
                    dispatch({
                        type: AUTHENTICATED_FAIL
                })
                }
            }
        }
        catch(err){
            dispatch({
                type: AUTHENTICATED_FAIL
            })
        }
    }
    else {
        dispatch({
            type: AUTHENTICATED_FAIL
        })
    }

}

export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }; 

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config);
    
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
};

export const login = (email, password) => async dispatch => {
    dispatch ({
        type: LOGIN_PROCESS_START,
    })
    dispatch ({
        type: GET_ERRORS_RESET,
    })
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({email, password})

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config)
        
        dispatch ({
            type: LOGIN_SUCCESS,
            payload: res.data
        })
        dispatch (load_user())
        dispatch ({
            type: LOGIN_PROCESS_STOP,
        })
    } catch (err) {
        dispatch ({
            type: LOGIN_PROCESS_STOP,
        })
        const errors = {
            msg: err.response.data,
            status: err.response.status
        }
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
        dispatch ({
            type: LOGIN_FAIL           
        })
    }
} 

export const signup = (company_name, phone, first_name, last_name, email, password, re_password, contact_perms, user_contract) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({company_id: "",company_name, phone, name: first_name, surname: last_name, email, password, re_password, contact_via_email: contact_perms, contact_via_phone: contact_perms, user_contract});

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config);
        console.log(res)
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        console.log(err)

        const errors = {
            msg: err.response.data,
            status: err.response.status
        }
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    }
};

export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, body, config);

        dispatch({
            type: ACTIVATION_SUCCESS,
        });
    } catch (err) {
        dispatch({
            type: ACTIVATION_FAIL
        })
    }
};

export const reset_password = (email) => async dispatch => {
  
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email });
    dispatch ({
        type: LOGIN_PROCESS_START,
    })
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, body, config);
        dispatch ({
            type: LOGIN_PROCESS_STOP,
        })
        dispatch({
            type: PASSWORD_RESET_SUCCESS
        });
    } catch (err) {
        
        dispatch({
            type: PASSWORD_RESET_FAIL
        });
        dispatch ({
            type: LOGIN_PROCESS_STOP,
        })
        const errors = {
            msg: err.response.data,
            status: err.response.status
        }
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    }
};

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config);

        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL
        });
    }
};

export const logout = () => async dispatch => {
    dispatch({
        type: LOGOUT
    })
    
}