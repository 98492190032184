import { Chip, Switch } from '@mui/material'
import { DataGrid,trTR } from '@mui/x-data-grid'
import axios from 'axios'
import moment from 'moment'
import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { EditButton } from '../../CompanySettings/MainSettings'
import DeleteDialog from './TableDialog/DeleteDialog'

const SuggestionComutiyTable = ({data, getInfoBack,isLoading}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [id, setId] = useState(null)
  const handleOpenDeleteDialog = (e) => {
    setId(e)
    setOpenDeleteDialog(true)
  }
  
  async function updateInfo (e){
    const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
    };
    const body = JSON.stringify({is_manager:!e?.is_manager})
    try{
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/suggestions/committee-member-create/${e.id}/`,  body, axiosConfig)
      getInfoBack()
    }
    catch{

    }
  }
    let navigate = useNavigate()
    const columns = [
        {
        field: "id",
        headerName: "No",
        hide:true,
        width: 50,
        }, 
        {
        field: "region",
        headerName: "Bölge",
        width: 200,
          renderCell: (params) => {
            return (
              <p>{params?.row?.region?.name}</p> 
              )    
          },
        }, 
  
        {
        field: "manager",
        headerName: "Kurul Üyesi",
        width: 200,
          renderCell: (params) => {
            return (
              params?.row?.member ?
              <p>{params?.row?.member?.name +" " + params?.row?.member?.surname}</p> 
              :
              <p>Süreç Yöneticisi Atanmadı</p> 

              )    
          },
        }, 
        {
        field: "stage",
        headerName: "Durumu",
        width: 200,
          renderCell: (params) => {
            return (
              params?.row?.is_manager ?
              <Chip label={"Yönetici"} color="success"/>
              :
              <Chip label={"Üye"} color="info"/>
              )    
          },
        }, 
        {
        field: "set_manager",
        headerName: "Yönetici ayarı",
        width: 200,
            renderCell: (params) => {
            return (
              <div style={{display:"flex", gap:"15px"}}>
               <Switch checked={params?.row?.is_manager} onChange={() => updateInfo(params?.row)} />
              </div>
                )    
            },
        }, 
        {
        field: "action",
        headerName: "Sil",
        width: 200,
            renderCell: (params) => {
            return (
              <div style={{display:"flex", gap:"15px"}}>
                <EditButton style={{background:"red"}} onClick={() => handleOpenDeleteDialog(params?.row?.id)}>
                    Sil
                </EditButton> 
              </div>
                )    
            },
        }, 
      
    ]
  return (
    <div style={{height:"75vh"}}>
          <DataGrid
            rows={data ? data : []}
            columns={columns}
            style={{color:"white"}}
            pageSize={15}
            loading={isLoading}
            rowsPerPageOptions={[15]}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
          />
          <DeleteDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          getInfoBack={getInfoBack}
          id={id}
          />
    </div>
  )
}

export default SuggestionComutiyTable