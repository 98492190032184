import moment from 'moment'
import React, {  useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import NavBarLogo from '../NavBarLogo'
import { TopBarLeft, TopBarRight } from '../../Pages/MainAppScreen'
import { AuditSidebarItems } from '../../Pages/AuditApp/AuditNav'
import { Badge, Divider } from '@mui/material'
import { AuditPDCASidebarItems } from '../../Pages/AuditApp/PdcaNav'
import ActionPopover from './ActionPopover'
import { EditButton } from '../../Pages/CompanySettings/MainSettings'
import AddNewAuditType from '../../Pages/AuditApp/Settings/Dialog/AddNewAuditType'
import {CgChevronLeftR, CgChevronRightR} from "react-icons/cg"
import AuditApplicationInfo from './ApplicationDefinition'
import axios from 'axios'
import SuccessSnackBar from '../SuccessSnackBar'
import ErrorSnackBar from '../ErrorSnackBar'
import { connect } from 'react-redux'
export const SettingsContainer = styled.div`
    display:flex;
    flex-direction:column;
`

export const TopNav = styled.div`
    width: 100%;
    height:30px;
    font-size:14px;
    display: ${({fullHeight}) => !fullHeight ? "flex" :"none" } ;
    background: rgba(75, 23, 124, 0.95);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 40px );
    -webkit-backdrop-filter: blur( 40px );
    align-items:center;
    justify-content:space-between;
    @media screen and (max-width:600px){
        display:none;
    }
`
export const TopNavActions = styled(Link)`
    height:13px;
    width:13px;
    border-radius: 50%;
    background: ${({background}) => background};
    margin-left:15px;
    margin-right:15px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    color:white;
    text-decoration:none
`
export const TopNavTitle = styled.p`
    font-weight: "600";
`

export const SetttingsContainer = styled.div`
    height: ${({ht}) => !ht ?"100vh" :"100vh"} ;
    width:100%;
    background:#211c27;
    display:flex;
    @media screen and (max-width:600px){
        height: ${({ht}) => !ht ?"100vh" :"100vh"} ;
    }

`
export const SideBar = styled.div`
    display:flex;
    flex-direction:column;
    background:#26252b;
    width:260px;
    border-right:1px solid black;
    border-radius:8px;
    z-index:99;
    @media screen and (max-width:600px){
        position:absolute;
        height:100vh;
        display:${({showSidebar}) => showSidebar ? "flex": "none"}
    }
`

export const SideBarTopElements = styled.div`
    margin-top:25px;
    display:flex;
    align-items: center;
    justify-content:space-between;
`
export const SideBarTopElementsActions = styled.div`
    display:flex;
    justify-content:start;
    align-items:left;
`
export const SideBarMainElements  = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:15px;
    margin-left:5px;
    gap:5px
`
export const SideBarMainElementsItem  = styled(Link)`
    color:  white;
    text-decoration:none;
    display:flex;
    padding:10px;
    background: ${({select}) => select ? "#3f3d44" : "none"};
    border-radius:5px;
    width:85%;
    align-items:center;
    cursor:pointer;
`
export const SideBarMainElementsIcon  = styled.div`
    margin-right:5px;
    color:#36ff1f;
`
export const SideBarMainElementsName  = styled.div`
    font-size:13px
`
export const PageMainContainer = styled.div`
    display:flex;
    min-height:95vh;
    overflow-y:auto;
    justify-content:center;
    align-items:start;
    flex: 1;
`
export const AppOutline = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    min-height:100vh;
` 
export const TitleHolder = styled.div`
    width:100%;
    height:45px;
    background:rgba(54,51,58,0.8);
    display:flex;
    justify-content:center;
    align-items:center;
`

export const LeftArrow = styled(CgChevronLeftR)`
    display:none;
    @media screen and (max-width:600px){
        display:block;

    }
`

const ActivitiesDashBoard = ({user}) => {
    const [successOpen, setSuccessOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const [openInfoDialog, setOpenInfoDialog] = useState(false)
    const {pathname} = useLocation()
    let navigate = useNavigate()
    const [showSidebar, setShowSidebar] = useState(true)
    useEffect(() => {setShowSidebar(false)},[pathname])
    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    const [iconMinimizeDisplay, setIconMinimizeDisplay] = useState("none")
    const [iconMaximizeDisplay, setIconMaximizeDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
        else if (e.target.id ==="minimize"){
            setIconMinimizeDisplay("block")
        }
        else{
            setIconMaximizeDisplay("block")
        }
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
        setIconMinimizeDisplay("none")
        setIconMaximizeDisplay("none")

    }
    const [fullHeight, setFullHeight] = useState(false)
    function TopbarHidden(){
        setFullHeight(true)
    }
    function TopbarShow(){
        setFullHeight(false)
    }


    /*this part is for the dialogs*/
    const [openAddNewAudit, setOpenAddNewAudit] = useState(false)
    /*this part is for the dialogs*/
    function isPathnameInUrlPath(pathname, children_urlPath) {
        if (children_urlPath !== null ){
            for (let i = 0; i < children_urlPath.length; i++) {
                if (pathname.includes(children_urlPath[i].urlPath)) {
                  return true;
                }
              }
              return false;
        }
        else{
            return false;
        }       
      }

      const [auditData, setAuditData] = useState(null)
      async function getAudits () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/`, config);
            setAuditData(res.data)
        } catch (err) {
        }
      }
      const [overDueCount, setOverDueCounts] = useState(0)
      const [todoCount, setTodoCounts] = useState(0)
      const [controlCount, setControlCounts] = useState(0)
      const [actCount, setActCounts] = useState(0)
      async function getPdcaCounts () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/taskmanagment/get-audit-tasks-counts/`, config);
            setOverDueCounts(parseInt(res.data?.over_due))
            setTodoCounts(parseInt(res.data?.todo))
            setControlCounts(parseInt(res.data?.control))
            setActCounts(parseInt(res.data?.act))
        } catch (err) {
        }
      }
      useEffect(() => {getPdcaCounts()},[pathname])




  return (
    <SettingsContainer>
        <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
        <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
        <TopNav fullHeight={fullHeight}>
            <TopBarLeft>
                <NavBarLogo/>
                <TopNavTitle style={{cursor:"pointer"}} onClick={() => setOpenInfoDialog(true)} >İç Denetim Uygulaması </TopNavTitle>
                {user?.type?.includes("manager")
                ?
                <TopNavTitle style={{cursor:"pointer"}} onClick={() => navigate("/app/audit-app/settings")}>Uygulama Ayarları </TopNavTitle>
                :
                ""
                }
            </TopBarLeft>
            <TopBarRight>
            {moment().format("llll")}
            </TopBarRight>
        </TopNav>
            <SetttingsContainer ht={fullHeight}>
                <SideBar showSidebar={showSidebar} >
                    <SideBarTopElements>
                        <SideBarTopElementsActions>
                            <TopNavActions id="close" onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} background={"#ee5c54"} to="/app">
                                <p style={{fontSize:"8px", display:iconCloseDisplay}}>X</p>
                            </TopNavActions>
                            <TopNavActions id="minimize" onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} background={fullHeight ? "#F8BC45" : "#6d6971"} style={{marginLeft:"-5px"}} onClick={() =>TopbarShow()}>
                                <p style={{fontSize:"8px", display:iconMinimizeDisplay}}>-</p>
                            </TopNavActions>
                            <TopNavActions id="maximize" onMouseEnter={(e) => onShowIcon(e)} onMouseLeave={(e) => onHideIcon(e)} background={!fullHeight ? "#5dc942" : "#6d6971"} style={{marginLeft:"-5px"}} onClick={() => TopbarHidden()}>
                                <p style={{fontSize:"8px", display:iconMaximizeDisplay}}>+</p>
                            </TopNavActions>
                        </SideBarTopElementsActions>
                       
                        <LeftArrow style={{marginRight:"15px"}} onClick={() => setShowSidebar(false)}/>

                    </SideBarTopElements>
                    <SideBarMainElements>
                        {AuditSidebarItems.filter(j => j.show_on_side_bar === true).map((e) => (
                        <SideBarMainElementsItem select={pathname === e.urlPath || isPathnameInUrlPath(pathname, e.childeren_urlPath)} to={e.urlPath}>
                            <SideBarMainElementsIcon>
                                {e.icon} 
                            </SideBarMainElementsIcon>
                            <SideBarMainElementsName>
                               {e.name}
                            </SideBarMainElementsName>
                        </SideBarMainElementsItem>
                       ))}
                       <Divider style={{background:"white"}}/>
                       {AuditPDCASidebarItems.map((e) => (
                        <SideBarMainElementsItem select={pathname === e.urlPath} to={e.urlPath}>
                            <Badge badgeContent={e?.urlPath?.includes("over_due") ? overDueCount : e?.urlPath?.includes("doing") ? todoCount : e?.urlPath?.includes("control") ?controlCount : actCount} 
                            color={e?.urlPath?.includes("over_due") ? "error" : e?.urlPath?.includes("doing") ? "warning" : e?.urlPath?.includes("control") ? "info" : "success"}  >
                                <SideBarMainElementsIcon>
                                    {e.icon} 
                                </SideBarMainElementsIcon>
                                <SideBarMainElementsName>
                                {e.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </SideBarMainElementsName>
                            </Badge>
                        </SideBarMainElementsItem>
                       ))}
                    </SideBarMainElements>
                </SideBar>
                <AppOutline>
                {pathname.includes("/app/audit-app/settings") ? 
                <TitleHolder>
                    <p>Denetim Ayarları</p>
                    {pathname.includes("/app/audit-app/settings/") ? 
                   ""
                    :
                    <div style={{position:"absolute", right:"15px"}}>
                    <EditButton active onClick={() => setOpenAddNewAudit(true)}>
                        Denetim Ekle
                    </EditButton>
                    </div> }
                </TitleHolder>
                :
                <TitleHolder>
                    <div style={{position:"absolute", left:"15px", display:"flex", alignItems:"center"}}>
                        <CgChevronRightR style={{marginRight:"15px"}} onClick={() => setShowSidebar(true)}/>
                    </div>
                    {AuditSidebarItems?.filter((e) => e.urlPath === pathname)[0]?.name}
                        <div style={{position:"absolute", right:"15px"}}>
                            <ActionPopover/>
                        </div>
                </TitleHolder>
                }
                    <PageMainContainer>
                        <Outlet  context={[getAudits, auditData]}/>
                        <AddNewAuditType setSuccessOpen={setSuccessOpen} getInfoBack={getAudits} open={openAddNewAudit} setOpen={setOpenAddNewAudit}/>
                        <AuditApplicationInfo open={openInfoDialog} setOpen={setOpenInfoDialog} />
                    </PageMainContainer>
                </AppOutline>
            </SetttingsContainer>
    </SettingsContainer>
  )
}

const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps) (ActivitiesDashBoard)