import React, {useState, useEffect} from 'react'
import { ActivitiesMainContainer, ActivitiesMainWrapper, InfoBar } from '../../../AuditApp/Activities/ActivitiesStyle'
import SuggestionCommitteeTable from './SuggestionCommitteeTable'
import axios from "axios"
import { ApplicationContainer } from '../../../AuditApp/HomePage'
import { connect } from 'react-redux'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
const SuggestionCommitteePast = ({user}) => {
  const [data, setData] = useState()
  const [level,setLevel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
  };
  async function getInfo (){
    setIsLoading(true)
    try{
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/suggestions/committee/get/?t=${level?"admin":"user"}&pnd=no`,  axiosConfig);
          setData(res.data)
    setIsLoading(false)
}
      catch{
        setIsLoading(false)

      }
  }
  useEffect(() => {getInfo()},[level])

  return (
    <ApplicationContainer style={{width:"100%"}}>
        <ActivitiesMainContainer>
            <ActivitiesMainWrapper>
                {user?.type?.includes("manager") ?
                <InfoBar>
                    <FormGroup>
                        <FormControlLabel
                        FormControlLabel control={<Switch checked={level} onChange={(e) => setLevel(!level)} defaultChecked />} label="Tümünü Görüntüle" 
                        />
                    </FormGroup>
                </InfoBar>
                :""}
                <SuggestionCommitteeTable isLoading={isLoading} data={data} getInfoBack={getInfo}/>
            </ActivitiesMainWrapper>
        </ActivitiesMainContainer>
    </ApplicationContainer>
  )
}

const mapStateToProps = state => ({   
    user: state.auth.user
  })
export default connect(mapStateToProps) (SuggestionCommitteePast)