import styled from "styled-components"


export const FileManagementContainer = styled.div`
    width:100%;
    height: 100%;
`


export const FileManagementWrapper = styled.div`
    width: 100%;
    display:flex;
    height:100%;
`
export const FileManagementSideBar = styled.div`
    width: 25%;
    border: 1px solid rgba(0,0,0,0.3);
    height: -webkit-fill-available;
    border-radius:8px;
    
`
export const FileManagementSideBarWrapper = styled.div`
    padding:10px;
    display:flex;
    gap:15px;
    flex-direction:column;
`
export const FileManagementSideBarItems = styled.div`
    cursor:pointer;
    display:flex;
    gap:5px;
    align-items:center;
    background: ${({active}) => active ?"#565656" : "none"} ;
    padding:10px;
    border-radius:8px;
`
export const FileManagementMainContainer = styled.div`
    width: 75%;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius:8px;
    height: -webkit-fill-available;

`
export const FileManagementMainTopFunctions = styled.div`
    height:55px;
    width: 100%;
    background:#474747;
    border-radius:8px 8px 0 0;
    margin-right:15px
`
export const FileManagementMainTopFunctionsWrapper = styled.div`
    height:55px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    background:#474747;
    border-radius:8px 8px 0 0;
    padding-right: 15px;
    padding-left: 15px;
    width: -webkit-fill-available
`

export const FileManagementMainArea = styled.div`
    display:flex;
    width: 100%;
    max-height:88%;
    overflow: auto;
`
