import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { InnerNavigation, InnerNavigationItems } from '../../AuditApp/Activities'
import { ApplicationContainer } from '../../AuditApp/HomePage'

const KaizenPool = () => {
  let navigate = useNavigate()
  const {pathname} = useLocation()
  return (
    <ApplicationContainer style={{width:"100%"}}>
    <InnerNavigation style={{borderBottom:"1px solid grey", paddingBottom:"10px", marginBottom:"10px"}}>
        <InnerNavigationItems active={pathname === "/app/kaizen-app/improvement/pool"} onClick={() => navigate("/app/kaizen-app/improvement/pool")}>İç Denetim</InnerNavigationItems>
        <InnerNavigationItems active={pathname.includes("internal")} onClick={() => navigate("/app/kaizen-app/improvement/pool/internal-customers")}>İç Müşteri Şikayetleri</InnerNavigationItems>
        <InnerNavigationItems active={pathname.includes("external")} onClick={() => navigate("/app/kaizen-app/improvement/pool/external-customers")}>Dış Müşteri Şikayetleri</InnerNavigationItems>
    </InnerNavigation>
    <Outlet/>
  </ApplicationContainer>
  )
}

export default KaizenPool