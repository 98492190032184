import { Alert, Avatar, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { EmployeeCardImage, EmployeeInfoCardContainer, EmployeeInfoCardWrapper, EmployeeInfoMain, FileHolder, FileName, HorizantalTimelineCardWrapper, HorizantalTimeLineContainer } from '../../../Assets/GeneralDesignElements'
import { ActivitiesMainContainer,ActivitiesMainWrapper, InfoBar } from '../../AuditApp/Activities/ActivitiesStyle'

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { EditButton } from '../../CompanySettings/MainSettings'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import {MutatingDots} from "react-loader-spinner"
import getFileIcon from '../../../Assets/GetFileIconsFunction'
import { TaskImageHolder } from '../../../Subcomponents/TaskManagementViews/ListView/ListViewStyle'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ComplaintsDetailsPage = () => {
  let params = useParams()
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
    };
  const axiosConfigOpenAI = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API}`,
    }
    };
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [data, setData] = useState([])

  const [dOnePerceptronFetching, setDOnePerceptronFetching] = useState(false)
  const [dTwoPerceptronFetching, setDTwoPerceptronFetching] = useState(false)
  const [dThreePerceptronFetching, setDThreePerceptronFetching] = useState(false)
  

  async function getInfo (){
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/ecc/create/${params?.id}/`,  axiosConfig);
        setData(res.data)
    }
    catch{

    }
  }

  

  async function DOnePerceptronGet(){
    setDOnePerceptronFetching(true)
    const body = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [{"role": "user", "content": `Bana ${data?.heading} sorunu için Öncelikli düzeltici faaliyet önerileri söyleyebilir misin?`}]
    })
    try{
      const res = await axios.post(`${process.env.REACT_APP_OPENAI_API_URL}`,  body, axiosConfigOpenAI);
      PerceptronOneEdit(res.data?.choices ? res?.data?.choices[0].message?.content : "")
      setDOnePerceptronFetching(false)
    }
    catch{
      setDOnePerceptronFetching(false)

    }
  }

  async function PerceptronOneEdit(e){
    const body = JSON.stringify({d_one_perceptron:e})
    try{
      const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/ecc/create/${params?.id}/`,  body, axiosConfig);
      setData(res.data)
    }
    catch{

    }
  }
  async function DTwoPerceptronGet(){
    setDTwoPerceptronFetching(true)
    const body = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [{"role": "user", "content": `Bana ${data?.heading} sorunu için potansiyel kök neden analizi ve kaçan nokta tanımlamalarını söyler misin?`}]
    })
    try{
      const res = await axios.post(`${process.env.REACT_APP_OPENAI_API_URL}`,  body, axiosConfigOpenAI);
      PerceptronTwoEdit(res.data?.choices ? res?.data?.choices[0].message?.content : "")
      setDTwoPerceptronFetching(false)

    }
    catch{
      setDTwoPerceptronFetching(false)

    }
  }

  async function PerceptronTwoEdit(e){
    const body = JSON.stringify({d_two_perceptron:e})
    try{
      const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/ecc/create/${params?.id}/`,  body, axiosConfig);
      setData(res.data)
    }
    catch{

    }
  }
  async function DThreePerceptronGet(){
    setDThreePerceptronFetching(true)

    const body = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [{"role": "user", "content": `Bana ${data?.heading} sorunu için potansiyel düzenleyici önleyici faaliyetleri söylebilir misin? Bu sorun tekrarlanmaması için nasıl önlemler alınabilir?`}]
    })
    try{
      const res = await axios.post(`${process.env.REACT_APP_OPENAI_API_URL}`,  body, axiosConfigOpenAI);
      PerceptronThreeEdit(res.data?.choices ? res?.data?.choices[0].message?.content : "")
      setDThreePerceptronFetching(false)
    }
    catch{
      setDThreePerceptronFetching(false)

    }
  }

  async function PerceptronThreeEdit(e){
    const body = JSON.stringify({d_three_perceptron:e})
    try{
      const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/ecc/create/${params?.id}/`,  body, axiosConfig);
      setData(res.data)
    }
    catch{

    }
  }

  useEffect(() => {getInfo()},[])

  return (
    <ActivitiesMainContainer>
        <ActivitiesMainWrapper>
          <InfoBar around top>
            <EmployeeInfoCardContainer>
              <EmployeeInfoCardWrapper>
                
                <EmployeeInfoMain>
                  <b>Çözüm Lideri: {data?.solution_team?.team_lead?.name + " " + data?.solution_team?.team_lead?.surname}</b>
                  <b>Çözüm Takım Üyeleri</b>
                  <div style={{display:"flex" , gap:"5px"}}>
                    {data?.solution_team?.users?.map((e) => (

                    <Tooltip title={e?.name  +  " " +  e?.surname} arrow>
                      <EmployeeCardImage small>
                        {e?.name?.charAt(0) + " " + e?.surname?.charAt(0)}
                      </EmployeeCardImage>
                    </Tooltip>
                    ))}
       
                  </div>
                </EmployeeInfoMain>
              </EmployeeInfoCardWrapper>
            </EmployeeInfoCardContainer>
            <HorizantalTimeLineContainer>
              <HorizantalTimelineCardWrapper>
              <Tooltip title="PLAN" arrow>
                    <Avatar
                    sx={{ bgcolor: green[600] }}
                    >
                      P
                    </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="DO" arrow>
                    <Avatar
                    sx={{ bgcolor: data?.pdca === "Plan" ? grey[500] : green[600] }}
                    >
                      D
                    </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="CONTROL" arrow>
                    <Avatar
                    sx={{ bgcolor: data?.pdca === "Plan" || data?.pdca === "Do"  ? grey[500] : green[600] }}
                    >
                      C
                    </Avatar>
                </Tooltip>
                <Divider style={{background:"grey", width:"25px"}}/>
                <Tooltip title="ACT" arrow>
                    <Avatar
                    sx={{ bgcolor: data?.pdca === "Plan" || data?.pdca === "Do" || data?.pdca === "Control" ? grey[500] : green[600] }}
                    >
                      A
                    </Avatar>
                </Tooltip>
              </HorizantalTimelineCardWrapper>
            </HorizantalTimeLineContainer>
          </InfoBar>
          <Grid container display={"flex"} justifyContent={"space-between"} >
            <Grid items md={12} xs={12}>
              <h1>Şikayet Detayları</h1>
              <Table>
                <TableRow>
                  <TableCell>
                      Müşteri:
                  </TableCell>
                  <TableCell>
                      {data?.customer?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Şikayet Tanımı:
                  </TableCell>
                  <TableCell>
                      {data?.heading}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Şikayet Detayları:
                  </TableCell>
                  <TableCell>
                      {data?.details}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Bölge:
                  </TableCell>
                  <TableCell>
                      {data?.region?.name + " / " + data?.section?.department_hierarchy}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Şikayet Kategorisi:
                  </TableCell>
                  <TableCell>
                      {data?.category?.map((e) =>(
                        <p>{e?.name}</p>
                      ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Yönetim sistemleri:
                  </TableCell>
                  <TableCell>
                      {data?.quality_system?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Süreç:
                  </TableCell>
                  <TableCell>
                      {data?.process?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                      Ürün:
                  </TableCell>
                  <TableCell>
                  {data?.product?.name}
                  </TableCell>
                </TableRow>
                {data?.files?.length > 0 ?
                <TableRow>
                  <TableCell>
                      Dosyalar:
                  </TableCell>
                  <TableCell>
                    <div style={{display:"flex", gap:"45px"}}>
                    {data?.files?.length > 0 ?
                        <>
                        <TaskImageHolder>
                        {data?.files?.map((e,index) => (
                          <Tooltip title={e?.file_name + "Listeden çıkarmak için tıklayın"} arrow>
                              <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                            <FileHolder>
                              {getFileIcon(e?.file_type)}
                              <FileName>{e?.file_name}</FileName>
                            </FileHolder>
                            </a>
                          </Tooltip>
                            ))}
                        </TaskImageHolder>
                        </>
                      :
                    ""}
                    </div>
                  </TableCell>
                </TableRow>
                :""}
              </Table>
            </Grid>
            
           
          </Grid>
        </ActivitiesMainWrapper>
    </ActivitiesMainContainer>
        
  )
}

export default ComplaintsDetailsPage