import { Chip } from '@mui/material'
import { DataGrid,trTR } from '@mui/x-data-grid'
import axios from 'axios'
import moment from 'moment'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledBadge } from '../../../../Assets/StyledBadge'
import { EditButton } from '../../../CompanySettings/MainSettings'
import DeleteDialog from './TableDialog/DeleteDialog'

const MySuggestionTable = ({data, getInfoBack,isLoading}) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [id, setId] = useState(false)
    const handleOpenDeleteDialog = (e) => {
      setId(e)
      setDeleteDialogOpen(true)
    }
    let navigate = useNavigate()
    const columns = [
        {
        field: "id",
        headerName: "No",
        hide:true,
        width: 50,
        }, 
        {
        field: "uuid",
        headerName: "Öneri Numarası",
        width: 135,
        }, 
        {
        field: "region",
        headerName: "Bölge",
        width: 130,
          renderCell: (params) => {
            return (
              <p>{params?.row?.region?.name}</p> 
              )    
          },
        }, 
        {
        field: "section",
        headerName: "Departman",
        width: 200,
          renderCell: (params) => {
            return (
              <p>{params?.row?.section?.department_hierarchy}</p> 
              )    
          },
        }, 
        {
        field: "heading",
        headerName: "Öneri Başlığı",
        width: 350,
          renderCell: (params) => {
            return (
              <p>{params?.row?.heading}</p> 
              )    
          },
        }, 
        {
        field: "start_date",
        headerName: "Başlama Tarihi",
        width: 200,
          renderCell: (params) => {
            return (
              <p>{moment(params?.row?.created_at).format("DD/MM/YYYY")}</p> 
              )    
          },
        }, 
        {
        field: "suggester",
        headerName: "Öneren",
        width: 200,
          renderCell: (params) => {
            return (
              params?.row?.suggester ?
              <p>{params?.row?.suggester?.name +" " + params?.row?.suggester?.surname}</p> 
              :
              <p>Süreç Yöneticisi Atanmadı</p> 

              )    
          },
        }, 
        {
        field: "manager",
        headerName: "Süreç Yöneticisi",
        width: 200,
          renderCell: (params) => {
            return (
              params?.row?.process_manager ?
              <p>{params?.row?.process_manager?.name +" " + params?.row?.process_manager?.surname}</p> 
              :
              <p>Süreç Yöneticisi Atanmadı</p> 

              )    
          },
        }, 
        {
        field: "stage",
        headerName: "Durumu",
        width: 200,
          renderCell: (params) => {
            return (
              params?.row?.state === "CONTINUE" ?
              <Chip label={"İşlem Bekliyor"} color="primary"/>
              :
              params?.row?.state === "Pass" ? 
              <Chip label={"Tamamlandı"} color="success"/>
              :   
              <Chip label={"Reddedildi"} color="error"/>
              )    
          },
        }, 
        {
        field: "action",
        headerName: "İncele",
        width: 200,
            renderCell: (params) => {
            return (
                <EditButton active onClick={() => navigate(`/app/suggestions-app/activities/create/${params?.row?.id}`)}>
                    Süreci Takip Et / Düzenle
                </EditButton> 
                )    
            },
        }, 
        {
        field: "delete",
        headerName: "sil",
        width: 200,
            renderCell: (params) => {
            return (
                <EditButton active onClick={() => handleOpenDeleteDialog(params?.row?.id)}>
                   Sil
                </EditButton> 
                )    
            },
        }, 
    ]
  return (
    <div style={{height:"75vh"}}>
          <DataGrid
            rows={data ? data : []}
            columns={columns}
            style={{color:"white"}}
            pageSize={15}
            rowsPerPageOptions={[15]}
            loading={isLoading}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
            
          />
          <DeleteDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          getInfoBack={getInfoBack}
          id={id}
          />
    </div>
  )
}

export default MySuggestionTable