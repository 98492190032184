import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { TopNavActions } from '../InternalMessagingApp/InternalMessagingAppDashboard'
import { ApplicationIconColor } from '../TaskManagementDashboard/TaskManagementDashboard'
import { FileManagementContainer, FileManagementMainArea, FileManagementMainContainer, FileManagementMainTopFunctions, FileManagementMainTopFunctionsWrapper, FileManagementSideBar, FileManagementSideBarItems, FileManagementSideBarWrapper, FileManagementWrapper } from './FileManagementStyle'
import { EditButton } from '../../Pages/CompanySettings/MainSettings'
import getFileIcon from '../../Assets/GetFileIconsFunction'
import moment from 'moment'
import TextFieldV2 from '../../Assets/TextFieldV2'
const FileManagement = ({open, setOpen, getInfoBack}) => {
 
    // focus consts
    const [focus, setFocus] = useState("all")
    const handleChangeFocus = (e) => {
      setFocus(e)
    }
    // Search consts
    const [searchFilter, setSearchFilter] = useState("")

    const handleCloseDialog = () => {
        setOpen(false)
    }

    // data manipulation
    const [data, setData] = useState([])
    const [next, setNext] = useState(false)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function getInfo (){
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/filemanagement/all/?page=${page}&search=${searchFilter}&app=${focus}`,  axiosConfig);
            setCount(res?.data?.count)
            if(searchFilter?.length > 0 ){
              if (page === 1){
                setData(res.data.results)
              }
              else{
                data.push(...res.data.results)
              }
            }
            if (page > 1){
              data.push(...res.data.results)
            }
            else{
              setData(res.data.results)
            }
            if (res?.data?.next !== null){
              setNext(true)
            }
        }
        catch{
          setNext(false)
          setPage(1)
        }
    }
    useEffect(() => {
      if (open){
        getInfo();
      }
      if (!open){
        setData([])
        setPage(1)
        setNext(false)
      }
    },[open,page,searchFilter, focus])

 
    

   
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1200px",  // Set your width here,
            background:"#211c25",
            height:"70vh",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
      <DialogTitle  style={{background:"rgba(54,51,58,0.8)", height:"8px", marginBottom:"15px"}}>
            <Grid container spacing={2} justifyContent={"center"} alignItems="center">
                <Grid container item xs={12}>
                  <Grid item container xs={4} style={{flexDirection:"row"}}>
                  <TopNavActions id="close" style={{ marginLeft:"-5px"}} onClick={handleCloseDialog} background={"#ee5c54"}>
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  <TopNavActions id="minimize"background={"#6d6971"} style={{marginLeft:"-5px"}} >
                  </TopNavActions>
                  </Grid>
                  <Grid item xs={6} style={{fontSize:"15px"}}>DOSYALAR</Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
            </Grid>
        </DialogTitle>
        {/* <DialogTitleV2 title={"Dosyalar"} handleCloseDialog={handleCloseDialog} /> */}
        <DialogContent>
          <FileManagementContainer>
            <FileManagementWrapper>
              <FileManagementSideBar>
                <FileManagementSideBarWrapper>
                  <FileManagementSideBarItems onClick={()=>handleChangeFocus("all")} active={focus === "all"}>
                    Tüm Dosyalar
                  </FileManagementSideBarItems>
                  <Divider style={{width:"100%"}}/>
                  <FileManagementSideBarItems onClick={()=>handleChangeFocus("taskmanagement")} active={focus === "taskmanagement"}>
                    <ApplicationIconColor style={{background:"#36ff1f"}}/>
                    Süreç Yönetimi
                  </FileManagementSideBarItems>
                  <FileManagementSideBarItems onClick={()=>handleChangeFocus("suggestion")} active={focus === "suggestion"}>
                    <ApplicationIconColor style={{background:"#d300ff"}}/>
                    Öneri Sistemi
                  </FileManagementSideBarItems>
                  <FileManagementSideBarItems onClick={()=>handleChangeFocus("kaizen")} active={focus === "kaizen"}>
                    <ApplicationIconColor style={{background:"#00fff1"}}/>
                    Kaizen
                  </FileManagementSideBarItems>
                  <FileManagementSideBarItems onClick={()=>handleChangeFocus("ecc")} active={focus === "ecc"}>
                    <ApplicationIconColor style={{background:"#ff8a8a"}}/>
                    Dış Müşteri
                  </FileManagementSideBarItems>
                  <FileManagementSideBarItems onClick={()=>handleChangeFocus("icc")} active={focus === "icc"}>
                    <ApplicationIconColor style={{background:"#ff8900"}}/>
                    İç Müşteri
                  </FileManagementSideBarItems>
                </FileManagementSideBarWrapper>
              </FileManagementSideBar>
              <FileManagementMainContainer>
                <FileManagementMainTopFunctions>
                  <FileManagementMainTopFunctionsWrapper>
                    <p>
                      Dosyalar: {data?.length + " / "+  count}
                    </p>
                    <TextFieldV2
                    label={"Arama..."}
                    value={searchFilter} setValue={setSearchFilter}
                     startAdornmentText={<SearchIcon/>}
                    size={"small"} style={{width:"250px", padding:0, margin:0}}/>
                  </FileManagementMainTopFunctionsWrapper>
                </FileManagementMainTopFunctions>
                <FileManagementMainArea>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell size="small" style={{width:"10px"}}>
                        </TableCell>
                        <TableCell size="small" style={{width:"10px"}}>
                          <Typography noWrap   style={{
                            width:"120px",
                            fontSize:"15px"
                          }}>
                            Dosya Adı
                          </Typography>
                        </TableCell>
                        <TableCell size="small" style={{width:"10px"}}>
                          <Typography noWrap   style={{
                            fontSize:"15px"
                          }}>
                            Boyutu
                          </Typography>
                          
                        </TableCell>
                        <TableCell>
                          <Typography noWrap   style={{
                            fontSize:"15px"
                          }}>
                            Yükleme Tarihi
                          </Typography>
                          
                        </TableCell>
                        <TableCell>
                          <Typography noWrap   style={{
                            fontSize:"15px"
                          }}>
                            Yükleyen
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography noWrap   style={{
                            fontSize:"15px"
                          }}>
                            İndir
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((e) => (
                      <TableRow size="small">
                        <TableCell>
                        {getFileIcon(e?.file_type, "small")}
                        </TableCell>
                        <TableCell 
                        size="small" 
                        style={{
                          width:"60px"
                        }}>
                          <Tooltip title={e?.file_name}>
                          <Typography noWrap   
                          style={{
                          width:"120px",
                          fontSize:"15px"
                          }}>
                          {e?.file_name}
                          </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell> 
                          <Typography noWrap style={{fontSize:"15px"}}>
                           {e?.file_size / 1024 > 1000 ?
                           `${(e?.file_size / 1024 / 1024).toFixed(2)} MB`
                           :
                           `${(e?.file_size / 1024).toFixed(2)} KB`} 
                          </Typography>
                        </TableCell>
                        <TableCell>
                           <Typography noWrap style={{fontSize:"15px"}}>
                           {`${moment(e?.uploaded_at).format("DD-MM-YYYY HH:mm")}`} 
                          </Typography>
                        </TableCell>
                        <TableCell>
                        <Tooltip title={e?.created_by?.name + " "+ e?.created_by?.surname}>
                          <Typography noWrap   style={{
                          fontSize:"15px"
                          }}>
                          {e?.created_by?.name + " "+ e?.created_by?.surname}
                          </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                        <a href={e?.path} download target="__blank" style={{color:"white", textDecoration:"none", textAlign:"center"}}>
                          <EditButton active>İndir</EditButton>
                        </a>
                        </TableCell>
                      </TableRow>
                      ))}
                      {count > data?.length
                      ?
                      <TableRow size="small">
                        <TableCell colSpan={6}>
                        <EditButton active onClick={()=>setPage(page+1)}>Devamını Yükle</EditButton>
                        </TableCell>
                      </TableRow>
                        :
                      <TableRow size="small">
                        <TableCell colSpan={6}>
                        Sayfa Sonu
                        </TableCell>
                      </TableRow>
                        }
                    </TableBody>
                  </Table>
                </FileManagementMainArea>
              </FileManagementMainContainer>
            </FileManagementWrapper>
          </FileManagementContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>Kapat</Button>
        </DialogActions>
    </Dialog>
    </>
  )
}

export default FileManagement