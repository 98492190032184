import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import ErrorSnackBar from '../../Subcomponents/ErrorSnackBar';
import SuccessSnackBar from '../../Subcomponents/SuccessSnackBar';
import { MainSettingsContainer, MainSettingsWrapper } from './MainSettings';
import DepartmentTable from './Tables/DepartmentTable';

const SubDepartmentSettings = () => {
  let params = useParams()
  const [successOpen, setSuccessOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [  regionData,getRegionInfo, sectionData, getSectionInfo,sectionRegionData,getSectionRegionInfo,sectionSectionData,getSectionSectionInfo] = useOutletContext();
  useEffect(() => {
    getSectionRegionInfo()
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    getSectionSectionInfo();
  },[params.id])
  console.log()
  return (
    <MainSettingsContainer>
      <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
        <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
        <MainSettingsWrapper className='container'>
          <DepartmentTable setErrorOpen={setErrorOpen} setSuccessOpen={setSuccessOpen} data={sectionSectionData} getDepartments={getSectionSectionInfo}/>
        </MainSettingsWrapper>
    </MainSettingsContainer>
  )
}
export default SubDepartmentSettings