import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import TextFieldV2 from '../../../../../Assets/TextFieldV2'
import SuccessSnackBar from '../../../../../Subcomponents/SuccessSnackBar'

const RequestDateChange = ({open, setOpen, getInfoBack,audit_id}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const handleCloseSuccessMsg = () => {
        setSuccessMsg(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const [note, setNote] = useState(null)
    const [new_date, setNewDate] = useState(null)
    const [successMsg, setSuccessMsg] = useState(false)
    async function handleSubmit() {
        setButtonStart(true)
        const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `JWT ${localStorage.getItem('access')}`,
              'Accept': 'application/json'
          }
        };
        const body = JSON.stringify({audit_id, note, new_date})
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/internalaudit/date-change-request/`, body,config);
            setOpen(false)
            setSuccessMsg(true)
            setButtonStart(false)
            getInfoBack()

        }
        catch (err) {
            setButtonStart(false)
            setOpen(false)

        }
      }
  return (
    <>
    <SuccessSnackBar open={successMsg} setOpen={setSuccessMsg} data="Tarih Değişikliği Talebi İletildi" />
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Denetim Tarihi Değiştirme İsteği"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            <Grid container>
                <Grid item xs={12} mt={1}>
                    <TextFieldV2 label={"Değişiklik Sebebi"} value={note} setValue={setNote} />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <TextFieldV2 type="date" label={"Talep Edilen Yeni Tarih"} value={new_date} setValue={setNewDate} />
                </Grid>
            </Grid>     
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <Button onClick={handleSubmit} variant="contained" color="primary">Gönder</Button> : <Button variant="outlined" color="error">Gönderiliyor...</Button>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default RequestDateChange