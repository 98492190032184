import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import { EditButton } from '../../../../CompanySettings/MainSettings'
import { Box, Checkbox,Button, Dialog, DialogActions, DialogContent, Divider, FormControl, FormControlLabel, Grid,  InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import TextFieldV2 from '../../../../../Assets/TextFieldV2'
import * as markerjs2 from 'markerjs2';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled as styledmui} from '@mui/material/styles'
import moment from 'moment';

const AddTaskDialog = ({open, setOpen,selectedRegion,departmentSelected, suggestion_id,callBackBroadCast}) => {
    const [buttonStart, setButtonStart] = useState(false)
    const handleCloseDialog = () => {
        setOpen(false)
    }


    // This part is for the finding vars
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [region, setRegion] = useState()
    const handleChangeSelectedDepartment = (e) => {
      setSelectedDepartment(e.target.value)
    }
    const departmentOptions = region?.filter(e => e.id === selectedRegion?.id)


    // This part is for getting the region info 
    const [files, setFiles] = useState([]);
    const [date, setDate] = useState(moment().add(7, "days").format("YYYY-MM-DD"))

  
    const handleFileChange = (event) => {
        const newFiles = [];
        for (let i = 0; i < event.target.files.length; i++) {
          newFiles.push(event.target.files[i]);
        }
        setFiles((prevFiles) => [...newFiles, ...prevFiles]);
      };
    

    const handleRemoveFile = (indexToRemove) => {
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles.splice(indexToRemove, 1);
          return newFiles;
        });
      };

      
    const [product_image_path, setProductImagePath] = useState(null)
    const [imgData, setImgData] = useState(null);
    const onChangePicture = e => {
      if (e.target.files[0]) {
        setProductImagePath(e.target.files[0])
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    };
  let imgRef = React.createRef()
  function showMarkerArea() {
      if (imgRef.current !== null) {
        // create a marker.js MarkerArea
        const markerArea = new markerjs2.MarkerArea(imgRef.current);
        // attach an event handler to assign annotated image back to our image element
        markerArea.addEventListener("render", (event) => {
          if (imgRef.current) {
            imgRef.current.src = event.dataUrl;
            fetch(event.dataUrl)
            .then(res => res.blob())
            .then(blob2 => setProductImagePath(blob2))
          }
        });
        // launch marker.js
        markerArea.settings.defaultColor = 'red'  ;
        markerArea.show();
      }
    }
    const Input = styledmui('input')({
        display: 'none',
    });

    const [findingAuditRelation, setFindingAuditRelation] = useState(false)
    const [audits, setAudits] = useState(null)
    const [selectedAudit, setSelectedAudit] = useState(null)
    const [selectedAuditCategory, setSelectedAuditCategory] = useState(null)
    const auditCategoryOptions = audits?.filter(e => e.id === selectedAudit?.id)[0]
    const [taskName, setTaskName] = useState(null)
    const [assignee, setAssignee] = useState(null)
    const [selectedAssignee, setSelectedAssignee] = useState(null)


    function  ValidationCheck (){
        if(selectedRegion && selectedDepartment && taskName?.length > 0 && selectedAssignee){
            return true
        }
        else{
            return false
        }
    }


    const handleChangeSelectedAudit = (e) => {
        setSelectedAudit(e.target.value)
        setSelectedAuditCategory(null)
    }
    const handleChangeSelectedAuditCategory = (e) => {
        setSelectedAuditCategory(e.target.value)
    }
    const handleChangeSelectedAssignee = (e) => {
        setSelectedAssignee(e.target.value)
    }
    async function getAudits () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/internalaudit/create/`, config);
            setAudits(res.data)
            
        } catch (err) {
        }
      }
    async function getAssignees () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/create/`, config);
            setAssignee(res.data)
        } catch (err) {
        }
      }
    const handleChangeFindingAuditRelation = (e) => {
        if (e.target.checked){
            getAudits()
        }
        else{
            setSelectedAudit(null)
            setSelectedAuditCategory(null)
        }
        setFindingAuditRelation(e.target.checked)
    }

  
    
    async function getRegionInfo () {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/region/childeren/`, config);
            setRegion(res.data)
        } catch (err) {
        }
      }
      useEffect(() => {getRegionInfo();
        getAssignees()},[])

      console.log(files)
      async function handleSubmit () {
        //submit the finding
        let form_data = new FormData();
        for (let i = 0; i < files.length; i++) {
          form_data.append(`file${i + 1}`, files[i]);
        }
        product_image_path ? form_data.append('before_image_path', product_image_path, "nearby_finding_image.jpg")  :form_data.append('test', "null") 
        selectedRegion ? form_data.append('region_id', selectedRegion?.id) : form_data.append('region_id', "")
        departmentSelected ? form_data.append('section_id', departmentSelected) : form_data.append('section_id', "")
        taskName? form_data.append('task', taskName) : form_data.append('task', "")
        selectedAssignee? form_data.append('assignee_id', selectedAssignee?.id) : form_data.append('assignee_id', "")
        files?.length >0 ? form_data.append('files', files[0]) : form_data.append('test2', "")
        date ? form_data.append('due_date', date) : form_data.append('due_date', "")
        suggestion_id ? form_data.append('suggestion_id', suggestion_id) : form_data.append('suggestion_id', "")

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };
        const body = form_data
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/suggestions/task/get-create/`, body, config);
            callBackBroadCast()
            setSelectedAssignee(null)
            setSelectedAudit(null)
            setSelectedAuditCategory(null)
            setSelectedDepartment(null)
            setImgData(null)
            setTaskName(null)
            handleCloseDialog()
      }
       catch (err) {
        }
      }

   
  return (
    <>
    <Dialog open={open} onClose={handleCloseDialog}
       sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Çözüm için görev ekle"} handleCloseDialog={handleCloseDialog}/>
        <Divider sx={{backgroundColor:"#A2A1A0"}}/>
        <DialogContent>
        
            {selectedDepartment ? 
              <FormControlLabel
             
              control={
              <Checkbox checked={findingAuditRelation} onChange={handleChangeFindingAuditRelation} name="gilad" 
               style={{maginBottom:"10px"}}
              sx={{
                  color: "white",
                  '&.Mui-checked': {
                  color: "white",
                  },
              }}
              />
              }
              label="Bir denetim türüne bağlı bulgu."
          />
            :""}
            {findingAuditRelation && selectedDepartment ? 
            <Grid container justifyContent={"space-between"} xs={12} gap={1}>
                <Grid item xs={selectedAudit?.audit_category?.length > 0  ? 6 : 12}>
                <Box mb={2}>
                    <FormControl
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    >
                        <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Denetim Türü</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedAudit}
                            label="Denetim Türü"
                            onChange={handleChangeSelectedAudit}
                            style={{color:"#fff"}}
                        >
                        {audits?.map((reg, index) => (
                            <MenuItem value={reg} key={reg?.id} >{reg.name}</MenuItem>
                            ))
                        } 
                           
                    </Select>
                </FormControl>
                </Box>
              </Grid>
              {selectedAudit?.audit_category?.length > 0 ?
              <Grid item xs={5}>
              <Box>
                    <FormControl
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    >
                        <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Denetim Kategorisi</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedAuditCategory}
                            label="Denetim Kategorisi"
                            onChange={handleChangeSelectedAuditCategory}
                            style={{color:"#fff"}}
                        >
                        {auditCategoryOptions?.audit_category?.map((reg, index) => (
                        <MenuItem value={reg} key={reg?.id} >{reg.name}</MenuItem>
                        ))
                        } 
                    </Select>
                </FormControl>
                </Box>
              </Grid>
              :
              ""}
            </Grid>
            :"" }
          
            <Grid item xs={12}>
              <Box>
                <TextFieldV2 label={"Görev Açıklaması"} value={taskName} setValue={setTaskName} />
              </Box>
            </Grid>
        
            <Grid item xs={12}>
              <Box mb={2}>
              <FormControl
                    sx={{
                    width:"100%",
                    color:"#fff",
                    '& label.Mui-focused': { color: 'white',},
                    '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'white',
                            },
                            '&:hover fieldset': {
                            borderColor: 'white',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            },}
                        }}
                    >
                        <InputLabel id="demo-simple-select-label" sx={{color:"#fff"}}>Sorumlu</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedAssignee}
                            label="Sorumlu"
                            onChange={handleChangeSelectedAssignee}
                            style={{color:"#fff"}}
                        >
                        {assignee?.map((reg, index) => (
                        <MenuItem value={reg} key={reg?.id} >{reg.name} {reg.surname}</MenuItem>
                        ))
                        } 
                    </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <TextFieldV2 label={"Termin Tarihi"} type={"date"} value={date} setValue={setDate} />
              </Box>
              <Divider style={{background:"white", width:"100%"}}/>
            </Grid>
         
            <React.Fragment>
                <Grid item xs={12} mt={2} mb={2} align="right">     
                    <label htmlFor="contained-button-file">
                    <Input accept="image/*" id="contained-button-file"  type="file" onChange={onChangePicture} />
                    <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={product_image_path === null ? "info" : "warning" }>
                        {product_image_path === null ? <> Fotoğraf Çek veya Ekle &nbsp; <CameraEnhanceIcon/> </> : <> Düzenlemek İçin Fotoğrafa Tıkla &nbsp; <CameraEnhanceIcon/> </>}
                    </Button>
                    </label>
                </Grid>   
                <Grid item xs={12}>     
                    {imgData ? 
                    <img ref={imgRef} 
                    src={imgData}
                    id="auditimg"
                    alt="sample" 
                    crossOrigin="anonymous"
                    style={{ maxWidth: '100%' }} 
                    onClick={() => showMarkerArea()}
                    /> 
                    : null}
                </Grid>   
                <Divider style={{background:"white", width:"100%"}}/>
            </React.Fragment>
         
            <React.Fragment>
            <Grid item xs={12} mt={2} mb={2} align="right">     
                <label htmlFor="contained-button">
                <Input accept="*/*" id="contained-button" multiple   type="file" onChange={handleFileChange} />
                <Button variant="contained" fullwidth component="span" style={{fontSize:"11px"}} color={product_image_path === null ? "info" : "warning" }>
                     Ek döküman ekle &nbsp; <FileUploadIcon/> 
                </Button>
                </label>
            </Grid>   
            <ul>
            {Array.from(files).map((file, index) => (
             <li key={index}>
             {file.name}{' '}
             <span
               style={{ color: 'red', cursor: 'pointer' }}
               onClick={() => handleRemoveFile(index)}
             >
               x
             </span>
           </li>
            ))}
          </ul>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={handleSubmit} active>Kaydet</EditButton> : <EditButton variant="outlined" color="error">Kaydediliyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default AddTaskDialog