import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import ErrorSnackBar from '../../Subcomponents/ErrorSnackBar';
import SuccessSnackBar from '../../Subcomponents/SuccessSnackBar';
import { MainSettingsContainer, MainSettingsWrapper } from './MainSettings'
import RegionTable from './Tables/RegionTable';
import OrgChartTree from './Trees';

const RegionSettings = () => {
  const [successOpen, setSuccessOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [regionData,getRegionInfo, sectionData, getSectionInfo,sectionRegionData,getSectionRegionInfo,sectionSectionData,getSectionSectionInfo, treeView] = useOutletContext();
  useEffect(() => {
    getRegionInfo()
    // eslint-disable-next-line
  },[])
  return (
    <MainSettingsContainer>
        <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
        <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
        <MainSettingsWrapper className='container'>
          {!treeView ? 
          <RegionTable setSuccessOpen={setSuccessOpen} setErrorOpen={setErrorOpen} data={regionData} getDepartments={getRegionInfo}/>
          :
          <OrgChartTree data={regionData}/>}
        </MainSettingsWrapper>
    </MainSettingsContainer>
  )
}

export default RegionSettings