import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import DialogTitleV2 from '../../../../../Assets/DialogTitle'
import SuccessSnackBar from '../../../../../Subcomponents/SuccessSnackBar'
import { EditButton } from '../../../../CompanySettings/MainSettings'

const DeleteDialog = ({open, setOpen, getInfoBack,id}) => {
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [buttonStart, setButtonStart] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [data, setData] = useState()
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    async function deleteInfo (e){
      setButtonStart(true)
      const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
      };
      try{
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/suggestions/create/${id}/`,  axiosConfig)
        getInfoBack()
        setSuccessMsg(true)
      setButtonStart(false)
      setOpen(false)
      }
      catch{
        setOpen(false)
        setButtonStart(false)
  
      }
    }
  return (
    <>
    <SuccessSnackBar open={successMsg} setOpen={setSuccessMsg} data={"İşlem başarıyla tamamlandı"}/>
    <Dialog open={open} onClose={handleCloseDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here,
            background:"#211c25",
            color:"#fff",
            marginBottom:"50px",

          },
        },
      }}
    >
        <DialogTitleV2 title={"Öneriyi Sil"} handleCloseDialog={handleCloseDialog} />
        <DialogContent>
            Öneriyi silmek için lütfen sil butonuna basınız.
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>İptal</Button>
            {!buttonStart ? <EditButton onClick={deleteInfo} active>Sil</EditButton> : <EditButton>Siliniyor...</EditButton>}
        </DialogActions>
    </Dialog>
    </>
  )
}

export default DeleteDialog