import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import ErrorSnackBar from '../../Subcomponents/ErrorSnackBar';
import SuccessSnackBar from '../../Subcomponents/SuccessSnackBar';
import { MainSettingsContainer, MainSettingsWrapper } from './MainSettings';
import DepartmentTable from './Tables/DepartmentTable';

const SubRegionSettings = () => {
  const [successOpen, setSuccessOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [ regionData,getRegionInfo, sectionData, getSectionInfo,sectionRegionData,getSectionRegionInfo] = useOutletContext();
  useEffect(() => {
    getSectionRegionInfo()
    // eslint-disable-next-line
  },[])
  
  return (
    <MainSettingsContainer>
        <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} data={"İşlem başarıyla gerçekleşti."} />
        <ErrorSnackBar open={errorOpen} setOpen={setErrorOpen} data={"Beklenmedik bir hata ile karşılaşıldı."} />
        <MainSettingsWrapper className='container'>
          <DepartmentTable  setSuccessOpen={setSuccessOpen} setErrorOpen={setErrorOpen} data={sectionRegionData} getDepartments={getSectionRegionInfo}/>
        </MainSettingsWrapper>
    </MainSettingsContainer>
  )
}
export default SubRegionSettings