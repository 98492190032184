import { Avatar, Chip, Grid, TextareaAutosize, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ApplicationContainer, ApplicationWrapper, TaskFollowContainer, TaskFollowHeader, TaskFollowHeaderContainer, TaskFollowWrapper } from '../../../Assets/GeneralDesignElements'
import { ActionGeneralInfo, ApplicationDate, ApplicationGreeting, InfoItems } from '../../AuditApp/HomePage'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckIcon from '@mui/icons-material/Check';
import { HomePageMainContainer, HomePageMainItemHeading, HomePageMainItemText, HomePageMainWrapper } from '../../AuditApp/HomePage/HomePageElements'
import LockIcon from '@mui/icons-material/Lock';
import axios from 'axios'
import { green, orange } from '@mui/material/colors'

const EccHome = ({user,data3}) => {
  const [data2, setData2] = useState(null)
  const [selfNotes, setSelfNotes] = useState(null)
  const [editNotes, setEditNote] = useState(false)
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
    }
  };
  async function getNotes (){
      try{
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/ecc/self-notes/`,  axiosConfig);
          try{
            setSelfNotes(res.data[0].notes)
          }
          catch{
            setSelfNotes(res.data)

          }
      }
      catch{

      }
  }
  async function manipulateNotes (){
      const body = JSON.stringify({notes:selfNotes})
      try{
          await axios.post(`${process.env.REACT_APP_API_URL}/api/ecc/self-notes/`,  body, axiosConfig);
          getNotes()
          setEditNote(false)
      }
      catch{

      }
  }
  useEffect(() => {
    const token = localStorage.getItem("access")
        const opts = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "JWT " + token
            }
        }
        fetch(`${process.env.REACT_APP_API_URL}/api/kaizen/homepage-data/`, opts)
        .then(response => response.json()
        .then(data => {
            setData2(data)
        }))
  }, [])
  const [data, setData] = useState(null)
  useEffect(() => {
    const token = localStorage.getItem("access")
        const opts = {
            method : "GET",
            headers : {
                "Content-Type" : "application/json",
                "Authorization" : "JWT " + token
            }
        }
        fetch(`${process.env.REACT_APP_API_URL}/api/taskmanagment/get-ecc-tasks-counts/`, opts)
        .then(response => response.json()
        .then(data => {
            setData(data)
        }))
        getNotes()
  }, [])
  return (
    <ApplicationContainer>
    <ApplicationWrapper>
      <ApplicationDate>
        Dış Müşteri Şikayetleri Uygulamasına Hoş Geldiniz
      </ApplicationDate>
      <ApplicationGreeting>
        Merhaba, {user?.name + " " + user?.surname}
      </ApplicationGreeting>
     {user?.type === "holding_manager"
          ?
          <Chip label="Şirket Yöneticisi" color="success"  style={{marginBottom:"15px"}}/>
          :
          user?.type === "holding_user"
          ?
          <Chip label="Şirket Çalışanı"  color="warning" style={{marginBottom:"15px"}}/>
          :
          user?.type === "region_manager"
          ?
          <Chip label="Bölge Yöneticisi"  color="primary" style={{marginBottom:"15px"}}/>
          :
          user?.type === "department_manager"
          ?
          <Chip label="Departman Yöneticisi"  color="secondary" style={{marginBottom:"15px"}}/>
          :
          <Chip label="Bölge Çalışanı"  color="error" style={{marginBottom:"15px"}}/>
      }
        <ActionGeneralInfo>
        <InfoItems>
          <HourglassBottomIcon/>
          {data?  data?.todo : 0} Görev Planlanmış |
        </InfoItems>
        <InfoItems>
          <RadioButtonCheckedIcon/>
          {data ? data?.control : 0} Görev Kontrolde |
        </InfoItems>
        <InfoItems>
          <CheckIcon/>
          {data ? data?.act : 0} Görev Tamamlanmış 
        </InfoItems>
      </ActionGeneralInfo>
      <Grid container mt={5} spacing={2} style={{justifyContent: "start"}}>
      <Grid item xs={12} md={4} xl={4}>
          <HomePageMainContainer  to={"/app/external-customer-complaints-app/activities"}>
            <HomePageMainWrapper>
              <HomePageMainItemHeading>Oluşturduğum Şikayetler</HomePageMainItemHeading>
              <HomePageMainItemText>{data2?.my_suggestions}</HomePageMainItemText>
            </HomePageMainWrapper>
          </HomePageMainContainer>
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <HomePageMainContainer to={"/app/external-customer-complaints-app/activities/main/process"}>
            <HomePageMainWrapper>
              <HomePageMainItemHeading >Süreç Yönetimindeki Şikayetler</HomePageMainItemHeading>
              <HomePageMainItemText>{data2?.my_control}</HomePageMainItemText>
            </HomePageMainWrapper>
          </HomePageMainContainer>
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <HomePageMainContainer to={"/app/external-customer-complaints-app/requests"}>
            <HomePageMainWrapper>
              <HomePageMainItemHeading >Çözüm Yönetici Olduğum Şikayetler</HomePageMainItemHeading>
              <HomePageMainItemText>{data2?.my_solution}</HomePageMainItemText>
            </HomePageMainWrapper>
          </HomePageMainContainer>
        </Grid>
        
        <Grid item xs={12} md={6} xl={6}>
        <TaskFollowContainer>
                <TaskFollowWrapper>
                    <TaskFollowHeader>
                    <TaskFollowHeaderContainer>
                        <Typography variant="h6">Öneriler</Typography>
                    </TaskFollowHeaderContainer>
                    </TaskFollowHeader>
                  </TaskFollowWrapper>
            </TaskFollowContainer>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
        <TaskFollowContainer>
                <TaskFollowWrapper>
                    <TaskFollowHeader>
                    <TaskFollowHeaderContainer>
                        <Typography variant="h6">Kişisel Notlar<LockIcon style={{color:"gray", fontSize:"18px", marginLeft:"5px"}}/></Typography>
                    </TaskFollowHeaderContainer>
                    </TaskFollowHeader>
                   <TextareaAutosize
                   minRows={16}
                   maxRows={16}
                   value={selfNotes}
                   onChange = {(e) => setSelfNotes(e.target.value)}
                   onBlur={manipulateNotes}
                   onFocus={() => setEditNote(true)}
                   style={{width:"100%", background:"rgba(255,255,255,0)", color:"white"}}
                   />
                   {editNotes ?
                   <div style={{display:"flex", gap:"5px"}}>
                   <Avatar
                      sx={{ width: 8, height: 8, bgcolor: green[500] }}
                    >
                      &nbsp;
                    </Avatar>
                    Kaydediliyor...
                   </div>
                   :
                   <div style={{display:"flex", gap:"5px"}}>
                   <Avatar
                      sx={{ width: 8, height: 8, bgcolor: orange[500] }}
                    >
                      &nbsp;
                    </Avatar>
                    Kaydedildi
                   </div>
                  }
                </TaskFollowWrapper>
            </TaskFollowContainer>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
         
        </Grid>
      </Grid>
    </ApplicationWrapper>
  </ApplicationContainer>
  )
}

const mapStateToProps = state => ({   
  user: state.auth.user
})
export default connect(mapStateToProps)(EccHome)