import {  Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select,  TextField } from '@mui/material'
import React, { useState } from 'react'
import { TopNavActions } from '../../../../Subcomponents/AuditDashboardsContainer/activitiesdashboard'
import { EditButton } from '../../../CompanySettings/MainSettings'

import axios from 'axios';
import ErrorSnackBar from '../../../../Subcomponents/ErrorSnackBar';
import TextFieldV2 from '../../../../Assets/TextFieldV2';
import DialogTitleV2 from '../../../../Assets/DialogTitle';


const EditAuditQuestions = ({open, setOpen,setSuccessOpen, getInfoBack, category,id,
    selectedCategory, setSelectedCategory,
    question_text, setQuestionText,
    question_type2, setQuestionType,
    max_rating, setMaxRating,
    min_rating, setMinRating,
    choices, setChoicse,
    }) => {
        console.log(category)
    const [err, setErr] = useState(null)
    const [openErr, setOpenErr] = useState(false)
   
    const handleSelectQuestionType = (e) => {
        setQuestionType(e.target.value)
    }
    const handleSelectCategory = (e) => {
        setSelectedCategory(e.target.value)
    }
    const handleCloseDialog = () => {
        setOpen(false)
    }
    const [iconCloseDisplay, setIconCloseDisplay] = useState("none")
    function onShowIcon (e){
        if (e.target.id ==="close"){
            setIconCloseDisplay("block")
        }
      
    }
    function onHideIcon (){
        setIconCloseDisplay("none")
    }

    async function handleSubmit (e) {
        e.preventDefault()
        if(question_text?.length > 0){
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${localStorage.getItem('access')}`,
                    'Accept': 'application/json'
                }
            };
            const body = JSON.stringify({ audit: id, selectedCategory,question_text, question_type2, max_rating,min_rating  });
            try {
                await axios.patch(`${process.env.REACT_APP_API_URL}/api/internalaudit/questionpool/update/${id}/`, body, config);
                getInfoBack()
                handleCloseDialog()
                //setAdress(null)
                setOpen(false)
                setSuccessOpen(true)
            } catch (err) {
                console.log(err)
                setErr(err.response?.data)
                setOpenErr(true)
                window.scrollTo( 0, 0)
            }
        }
        else{
           
        }     
      }


  return (
    <React.Fragment>
        <ErrorSnackBar open={openErr} setOpen={setOpenErr} data={err}/>
        <Dialog open={open} onClose={handleCloseDialog}
        sx={{
            "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here,
                background:"#211c25",
                color:"#fff",
                marginBottom:"50px",

            },
            },
        }}
        >
           <DialogTitleV2 title={"Denetim Sorusunu Düzenle"} handleCloseDialog={handleCloseDialog}/>
            <Divider style={{background:"white"}}/>
            <DialogContent>
                {category?.length > 0 ?
                 <FormControl fullWidth
                 InputLabelProps={{
                     style:{
                         color:"#A3A2A1"
                 }
                 }}
                 sx={{
                    marginBottom:"15px",
                     input: {
                         color: "white",
                     },
                     width:"100%",
                     '& label.Mui-focused': { color: 'white',},
                     '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                         borderColor: 'white',
                         },
                         '&:hover fieldset': {
                         borderColor: 'white',
                         },
                         '&.Mui-focused fieldset': {
                         borderColor: 'white',
                         },}
                     }}
                >
                 <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Soru Kategorisi</InputLabel>
                 <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={selectedCategory}
                     label="Soru Kategorisi"
                     onChange={handleSelectCategory}
                     style={{color:"#fff"}}
                 >
                     {category?.map((e) => (
                     <MenuItem value={e.id} key={e.id}>{e.name}</MenuItem>
                     ))}
                 </Select>
                 </FormControl>
                :
                ""
                }
                <TextFieldV2 value={question_text} setValue={setQuestionText} label={"Denetim Sorusu"} />
                <FormControl fullWidth
                 InputLabelProps={{
                     style:{
                         color:"#A3A2A1"
                 }
                 }}
                 sx={{
                    marginBottom:"15px",
                     input: {
                         color: "white",
                     },
                     width:"100%",
                     '& label.Mui-focused': { color: 'white',},
                     '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                         borderColor: 'white',
                         },
                         '&:hover fieldset': {
                         borderColor: 'white',
                         },
                         '&.Mui-focused fieldset': {
                         borderColor: 'white',
                         },}
                     }}
                >
                 <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Puanlandırma Türü</InputLabel>
                 <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={question_type2}
                     label="Puanlandırma Türü"
                     onChange={handleSelectQuestionType}
                     style={{color:"#fff"}}
                 >
                    <MenuItem value={"max5"}>5 Üzerinden</MenuItem>
                    <MenuItem value={"max10"}>10 Üzerinden</MenuItem>
                    <MenuItem value={"num"}>Özel Sayısal</MenuItem>
                    {/* <MenuItem value={"mcq"}>Çoktan Seçmeli</MenuItem> */}
                    <MenuItem value={"tf"}>Çift Seçenek</MenuItem>
                 </Select>
                 </FormControl>
                 {
                 question_type2 === "num" ? 
                <TextFieldV2 value={max_rating} setValue={setMaxRating} label={"Tavan Puan"} type={"number"} />
                 :
                 ""
                 }
                 {
                 question_type2 === "num" ||  question_type2 === "max5" ||  question_type2 === "max10"? 
                <TextFieldV2 value={min_rating} setValue={setMinRating} label={"Taban Puan"} type={"number"} />
                 :
                 ""
                 }
            </DialogContent>
            <DialogActions>
            <EditButton onClick={handleCloseDialog}>
                İptal
            </EditButton>
            <EditButton active={question_text?.length > 0} onClick={handleSubmit}>
                Kaydet
            </EditButton>
            </DialogActions>
        </Dialog>
    </React.Fragment>

  )
}

export default EditAuditQuestions