import styled from "styled-components"
import {Link} from "react-router-dom"

export const HomePageMainContainer = styled(Link)`
    display:flex;
    width: 100%;
    text-decoration:none;
    color:white;
`

export const HomePageMainWrapper = styled.div`
    display:flex;
    padding:40px;
    flex-direction:column;
    border-radius: 8px;
    background: rgba(150, 150, 150, 0.4);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.2px);
    -webkit-backdrop-filter: blur(8.2px);
    border: 1px solid rgba(0, 0, 0, 0.09);
    width: 100%;
`

export const HomePageMainItemHeading = styled.span`
    font-size:15px;
`
export const HomePageMainItemText = styled.span`
    font-size:21px;
`
export const TaskGraphHolder = styled.div`
    width:100%;
    height:360px;
`