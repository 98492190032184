import { Alert, Snackbar } from '@mui/material'
import React from 'react'

const SuccessSnackBar = ({open, setOpen,data}) => {
    const handleCloseErr = () => {
        setOpen(false)
    }
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseErr}>
        <Alert onClose={handleCloseErr} severity="success" sx={{ width: '100%' }}>
            {data}
        </Alert>
    </Snackbar>
  )
}

export default SuccessSnackBar