import { Autocomplete, TextField } from '@mui/material';
import React from 'react'

const AutoCompleteV2 = ({value, setValue,options,multiple, label,user,department}) => {
  return (
    <Autocomplete
        multiple={multiple}
        sx={{
        marginBottom:"15px",
        input: {
            color: "white",
        },
        width:"100%",
        '& label.Mui-focused': { color: 'white',},
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: 'white',
            },
            '&:hover fieldset': {
            borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
            borderColor: 'white',
            },}
        }}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setValue({
              title: newValue,
            });
          } 
          else{
            setValue(newValue);
          }
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option?.inputValue) {
            return option?.name;
          }
          // Regular option
          return user ? option?.name + " " +option?.surname : department ? option?.department_hierarchy : option?.name
        }}
        renderOption={(props, option) => <li {...props}>{user ? option?.name + " " +option?.surname : department ? option?.department_hierarchy : option?.name}</li>}
        freeSolo
        options={options}
        renderInput={(params) => <TextField {...params} label={label} />}
    />
  )
}

export default AutoCompleteV2