import React, {useEffect, useState} from 'react'
import { PageContainerFlexCenter, PageWrapper } from '../../Assets/GeneralDesignElements'
import { LoginContainer, MainForm, LogoHolder, LoginFooter, ListItem, List, ListItemLink } from './LoginElements'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InlineLogo from "../../Assets/Images/Logos/logoshadowed.png"
import { useNavigate } from 'react-router-dom';
import { login } from "../../Actions/auth";
import { connect } from 'react-redux'
import { Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation } from "react-router"; 
import styled from "styled-components"
import { TopBar, TopBarLeft, TopBarRight } from '../MainAppScreen';
import moment from 'moment';

export const GlassMorphAlert = styled(Alert)`
background: rgba( 171, 0, 0, 0.55 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
margin-bottom:15px
`

const LoginPage = ({login, isAuthenticated, loginProcess, loginFail}) => {
    let navigate = useNavigate()
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    useEffect(() => {
        document.title = "Giriş yap - Onkasa QMOS"
    }, [])
    const [formData, setFormData ] = useState({
        email: '',
        password: ''
    })
    const { email, password } = formData
    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value})
    const onSubmit = e => {
        e.preventDefault();
        login(email, password)
    }
    if(isAuthenticated) {
       navigate("/app")
    }
    return (
        <PageWrapper>
            <PageContainerFlexCenter>
                <TopBar style={{background:"none"}}>
                    <TopBarLeft>
                      
                    </TopBarLeft>
                    <TopBarRight style={{color:"white"}}>
                    {moment().format("llll")}
                    </TopBarRight>
                </TopBar>
                <LoginContainer>
                <LogoHolder src={InlineLogo} alt="test" onClick={() => window.location.href = 'http://www.onkasa.com'} />
                    <MainForm noValidate  onSubmit={e => onSubmit(e)} style={{marginTop:"20px"}}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-Posta Adresi"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange = {e  => onChange (e)}
                            value={email}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Şifre"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange = {e  => onChange (e)}
                            value={password}
                            style={{marginBottom:"30px"}}
                        />
                        {!loginProcess ? 
                         <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{marginBottom:"15px", backgroundColor:"#575089", borderRadius:"8px"}}
                        >
                            GİRİŞ YAP
                        </Button>
                        :
                        <LoadingButton fullWidth loading variant="outlined"  style={{marginBottom:"15px", backgroundColor:"#575089", borderRadius:"8px", color:"white"}}>
                            Giriş Yapılıyor...
                        </LoadingButton>
                        }
                        {loginFail ? <GlassMorphAlert  variant="filled" severity="error" >Giriş Yapmaya Çalışırken Bir Sorun ile Karşılaştık. Şifrenizi veya E-Posta Adresinizi Yanlış Girmiş Olabilirsiniz.</GlassMorphAlert> :   <></>}
                        {query.get("reset") ? (
                            <Alert>
                            {query.get("reset") ===  "true" ? "Şifreniz başarıyla yenilendi." 
                            :
                            query.get("info") ===  "rnw" ? "Hesabınızın şifre yenileme linki kayıtlı e-posta adresine iletildi." : " "}
                            </Alert>
                        ) : (
                            ""
                        )}
                    </MainForm>
                    <p>Şifreni hatırlamıyor musunuz? <b style={{color:"#037dac", fontWeight:"600", cursor:"pointer"}} onClick={() => navigate("/password-reset")}>Şifre Yenile</b></p>
                </LoginContainer>
                <LoginFooter>
                    <nav>
                        <List>
                            <ListItem><ListItemLink href="//www.onkasa.com" target="__blank">Onkasa</ListItemLink></ListItem>
                            <ListItem><ListItemLink href="//onkasa.com/user-agreement" target="__blank">Koşullar</ListItemLink></ListItem>
                            <ListItem><ListItemLink href="//onkasa.com/gdpr" target="__blank">KVKK</ListItemLink></ListItem>
                            <ListItem><ListItemLink href="//onkasa.com/gdpr" target="__blank">Veri Güvenliği</ListItemLink></ListItem>
                            <ListItem><ListItemLink href="//onkasa.com/blog" target="__blank">Blog</ListItemLink></ListItem>
                        </List>
                    </nav>
                </LoginFooter>
            </PageContainerFlexCenter>
        </PageWrapper>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loginFail: state.auth.loginFail,
    loginProcess: state.auth.loginProcess
})
export default connect (mapStateToProps, { login })(LoginPage) 